import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  favFriendCaddie,
  favFriend,
} from "../../../../components/friend/FavFriend";
import CaddieCard from "./component/CaddieCard";
import ModalCaddieDetail from "./component/ModalCaddieDetail";
import ModalFilter from "./component/ModalFilter";
import CaddieSearch from "./component/CaddieSearch";
import StackFilter from "./component/StackFilter";
import Label from "../../../createlabel/Label";

import axios from "axios";
import { ChatTo } from "../../../../components/chat/Chat";
import { getData } from "./module/fetchCaddie";
import Load from "../../../../components/Load";

function CourseDetailCaddieBody({ courseData, setCourseData }) {
  const [caddieList, setCaddieList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [filteredCaddieList, setFilteredCaddieList] = useState([]);
  const [caddieData, setCaddieData] = useState({});
  const [favoriteFriendsList, setFavoriteFriendsList] = useState({});
  const [caddieProfile, setCaddieProfile] = useState({});
  const [userLabels, setUserLabels] = useState([]);
  const [listUserBlocked, setListUserBolcked] = useState([]);
  const [selectedUserLabel, setSelectedUserLabel] = useState(null);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [triggerFriendList, setTriggerFriendList] = useState(true);
  const [triggerGetLabel, setTriggerGetLabel] = useState(false);
  const [originalCaddieList, setOriginalCaddieList] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [listIdDescription, setListIdDescription] = useState([]);
  const [favoriteFriends, setFavoriteFriends] = useState(false);
  const [userLocation, setUserLocation] = useState(null);

  const userData = useSelector((state) => state.user.data);
  const navigate = useNavigate();

  const [sortData, setSortData] = useState({
    isLabel: { state: false, selectedLabels: [] },
    isFavorites: { state: false },
    isStar: { state: false },
    isPro: { state: false },
    age: { state: false, min: 18, max: 40 },
    isBlocks: { state: false },
  });

  const [tempSortData, setTempSortData] = useState({
    isLabel: { state: true, selectedLabels: [] },
    isFavorites: { state: true },
    isStar: { state: true },
    isPro: { state: true },
    age: { state: true, min: 18, max: 40 },
    isBlocks: { state: true },
  });

  const [search, setSearch] = useState({
    province: {
      state: false,
      value: "",
    },
    text: {
      state: false,
      value: "",
    },
    nearMe: {
      state: false,
    },
  });

  const trueStates = Object.keys(sortData).filter(
    (key) => sortData[key].state === true
  );

  // useEffect(() => {
  //   async function fetchData() {
  //     const q = query(
  //       collection(firestore, "labels"),
  //       where("userId", "==", userData.uid)
  //     );
  //     const querySnapshot = await getDocs(q);
  //     let dataArray = [];
  //     querySnapshot.forEach((doc2) => {
  //       const data = doc2.data();
  //       dataArray.push({ docId: doc2.id, ...data });
  //     });
  //     setUserLabels(dataArray);
  //   }
  //   if (userData) {
  //     fetchData();
  //   }
  // }, [userData, triggerGetLabel]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       let caddieAssignments = [];
  //       const q = query(
  //         collection(firestore, "caddieRequests"),
  //         where("courseId", "==", courseData.courseId),
  //         where("status", "!=", "removed")
  //       );
  //       const querySnapshot = await getDocs(q);
  //       querySnapshot.forEach((doc) => {
  //         caddieAssignments.push(doc.data());
  //       });

  //       let caddieList = [];
  //       for (let index = 0; index < caddieAssignments.length; index++) {
  //         const element = caddieAssignments[index];

  //         const qCaddie = query(
  //           collection(firestore, "users"),
  //           where("uid", "==", element.caddieId)
  //         );
  //         const querySnapshotCaddie = await getDocs(qCaddie);

  //         for (const docCaddie of querySnapshotCaddie.docs) {
  //           const caddieData = docCaddie.data();

  //           const qCaddieRequests = query(
  //             collection(firestore, "caddieRequests"),
  //             where("caddieId", "==", element.caddieId)
  //           );
  //           const querySnapshotCaddieRequests = await getDocs(qCaddieRequests);

  //           let caddieNumber = null;

  //           querySnapshotCaddieRequests.forEach((docRequest) => {
  //             caddieNumber = docRequest.data().caddieNumber || null;
  //           });

  //           caddieList.push({
  //             imageUrl: caddieData.profileImage,
  //             name: `${caddieData.firstName} ${caddieData.lastName}`,
  //             bgImage: caddieData.coverImage,
  //             // uId: caddieData.uid,
  //             location: courseData.location,
  //             caddieNumber, // Include the fetched caddieNumber
  //             ...caddieData,
  //           });
  //         }
  //       }

  //       for (let index = 0; index < caddieList.length; index++) {
  //         const element = caddieList[index];

  //         const q3 = query(
  //           collection(firestore, "labeledUsers"),
  //           where("friendId", "==", element.uid),
  //           where("userId", "==", userData.uid)
  //         );
  //         const querySnapshot3 = await getDocs(q3);
  //         querySnapshot3.forEach((doc2) => {
  //           const data = doc2.data();
  //           let labels = [];
  //           if (data.labels) {
  //             for (let index = 0; index < data.labels.length; index++) {
  //               const element = data.labels[index];
  //               const item = userLabels.find((item) => item.docId === element);
  //               labels.push(item?.label);
  //             }
  //           }
  //           caddieList[index].labels = labels;
  //         });
  //       }
  //       setCaddieList(caddieList);
  //       setOriginalCaddieList(caddieList);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   if (courseData) {
  //     fetchData();
  //   }
  // }, [courseData, userLabels]);

  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [noMoreData, setNoMoreData] = useState(false);

  useEffect(() => {
    if (!userData) return;

    getData(
      userData,
      setCaddieList,
      setListUserBolcked,
      setOriginalCaddieList,
      setUserLabels,
      triggerGetLabel,
      setLoading,
      courseData
    ).then((lastDoc) => setLastVisibleDoc(lastDoc));
  }, [userData]);

  const handleLoadMore = () => {
    if (!lastVisibleDoc || loading || noMoreData) return;

    setLoading(true);

    getData(
      userData,
      setCaddieList,
      setListUserBolcked,
      setOriginalCaddieList,
      setUserLabels,
      triggerGetLabel,
      setLoading,
      courseData,
      lastVisibleDoc
    )
      .then((lastDoc) => {
        if (lastDoc) {
          setLastVisibleDoc(lastDoc);
        } else {
          setLastVisibleDoc(null);
          setNoMoreData(true);
        }
      })
      .catch((error) => {
        console.error("Error loading more caddies:", error);
        setLoading(false);
      });
  };

  const handleScroll = debounce(() => {
    const isBottom =
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.scrollHeight - 200;

    if (isBottom && !loading && !noMoreData) {
      handleLoadMore();
    }
  }, 200);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, noMoreData]);

  const handleShow = async (docID) => {
    setFavoriteFriends(false);
    setShow(true);

    const docRef = doc(firestore, "users", docID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      let isFriend = false;

      if (docSnap.data().uid) {
        const q3 = query(
          collection(firestore, "friends"),
          where("friendId", "==", docSnap.data().uid),
          where("userId", "==", userData?.uid)
        );

        const q2 = query(
          collection(firestore, "friends"),
          where("friendId", "==", userData?.uid),
          where("userId", "==", docSnap.data().uid)
        );

        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach(async (doc2) => {
          if (doc2.data().status == "active") {
            isFriend = true;
          }
        });

        const querySnapshot3 = await getDocs(q3);
        querySnapshot3.forEach(async (doc3) => {
          if (doc3.data().status == "active") {
            isFriend = true;
          }
        });
      }

      // const q_caddieAssignments = query(
      //   collection(firestore, "caddieAssignments"),
      //   where("caddieId", "==", docSnap.data().uid)
      // );
      const q_caddieAssignments = query(
        collection(firestore, "caddieRequests"),
        where("caddieId", "==", docSnap.data().uid)
      );
      const querySnapshot_caddieAssignments = await getDocs(
        q_caddieAssignments
      );
      querySnapshot_caddieAssignments.forEach(async (docCaddieAssignment) => {
        const docRefCourse = doc(
          firestore,
          "courses",
          docCaddieAssignment.data().courseId
        );
        const docSnapCourse = await getDoc(docRefCourse);

        if (docSnapCourse.exists()) {
          setCourseData({
            ...docSnapCourse.data(),
            courseId: docSnapCourse.id,
          });
        } else {
          console.log("No such document!");
        }
      });

      setCaddieData({ isFriend: isFriend, ...docSnap.data() });

      // const q_caddieProfiles = query(
      //   collection(firestore, "caddieProfiles"),
      //   where("caddieId", "==", docSnap.data().uid)
      // );
      // const querySnapshot_caddieProfiles = await getDocs(q_caddieProfiles);
      // querySnapshot_caddieProfiles.forEach(async (docCaddieprofile) => {
      //   let listImage = [
      //     { imageUrl: docSnap.data()?.profileImage, isPrimary: true, order: 0 },
      //   ];
      //   const querySnapshot = await getDocs(
      //     collection(firestore, "caddieProfiles", docCaddieprofile.id, "image")
      //   );
      //   querySnapshot.forEach((doc) => {
      //     listImage.push(doc.data());
      //   });
      //   setCaddieProfile({ ...docCaddieprofile.data(), image: listImage });
      // });

      const q_caddieProfiles = query(
        collection(firestore, "caddieProfiles"),
        where("caddieId", "==", docSnap.data().uid)
      );

      const querySnapshot_caddieProfiles = await getDocs(q_caddieProfiles);

      if (!querySnapshot_caddieProfiles.empty) {
        querySnapshot_caddieProfiles.forEach(async (docCaddieProfile) => {
          let listImage = [
            {
              imageUrl: docSnap.data()?.profileImage,
              isPrimary: true,
              order: 0,
            },
          ];

          const querySnapshot = await getDocs(
            collection(
              firestore,
              "caddieProfiles",
              docCaddieProfile.id,
              "image"
            )
          );

          querySnapshot.forEach((doc) => {
            listImage.push(doc.data());
          });

          setCaddieProfile({ ...docCaddieProfile.data(), image: listImage });
        });
      } else {
        // ถ้าไม่มีข้อมูลใน caddieProfiles ใช้ profileImage จาก docSnap โดยตรง
        const listImage = [
          { imageUrl: docSnap.data()?.profileImage, isPrimary: true, order: 0 },
        ];
        setCaddieProfile({ ...docSnap.data(), image: listImage });
      }
    }

    if (docSnap.data().uid === undefined) {
    } else {
      const q = query(
        collection(firestore, "favoriteFriends"),
        where("caddieId", "==", docSnap.data().uid),
        where("userId", "==", userData.uid)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        setFavoriteFriends(false);
      } else {
        querySnapshot.forEach((doc1) => {
          if (doc1.data().status == "active") {
            setFavoriteFriends(true);
          } else {
            setFavoriteFriends(false);
          }
        });
      }
    }
  };

  useEffect(() => {
    const fav = [];

    async function getFavUserCaddie() {
      const caddieQuery = query(
        collection(firestore, "favoriteFriends"),
        where("userId", "==", userData.uid),
        where("status", "==", "active")
      );

      const querySnapshot = await getDocs(caddieQuery);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          fav.push(doc.data().caddieId);
        });
      }
    }

    async function getFavUser() {
      const playerQuery = query(
        collection(firestore, "favoritePlayers"),
        where("userId", "==", userData.uid),
        where("status", "==", "active")
      );

      const querySnapshot = await getDocs(playerQuery);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          fav.push(doc.data().playerId);
        });
      }
    }

    if (userData) {
      getFavUserCaddie();
      getFavUser();

      setFavoriteFriendsList(fav);
    }
  }, [userData]);

  const handleFav = async (isCaddie, friendId, favType) => {
    if (userData && friendId) {
      if (favType === "fav") {
        if (isCaddie === "caddie") {
          setFavoriteFriendsList((prev) => [...prev, friendId]);
          const isFav = await favFriendCaddie(userData?.uid, friendId);
        } else {
          setFavoriteFriendsList((prev) => [...prev, friendId]);
          const isFav = await favFriend(userData?.uid, friendId);
        }
      } else {
        if (isCaddie === "caddie") {
          setFavoriteFriendsList((prev) =>
            prev.filter((id) => id !== friendId)
          );
          const isUnFav = await favFriendCaddie(userData?.uid, friendId);
        } else {
          setFavoriteFriendsList((prev) =>
            prev.filter((id) => id !== friendId)
          );
          const isUnFav = await favFriend(userData?.uid, friendId);
        }
      }
    }
  };

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  useEffect(() => {
    const filterCaddie = () => {
      let filteredCaddies = [...originalCaddieList];
      // Filter by province if the state is true
      if (search.province.state && search.province.value) {
        filteredCaddies = filteredCaddies.filter(
          (course) =>
            course.location.provinceEng.toLowerCase() ===
            search.province.value.toLowerCase()
        );
      }

      // Filter by text search if the state is true
      if (search.text.state && search.text.value) {
        // Assuming Algolia search API is used for text search
        let caddieIdList = [];

        const url =
          "https://RJJNPL7MRL.algolia.net/1/indexes/caddieProfiles/query";
        const payload = {
          query: search.text.value,
        };
        const headers = {
          "x-algolia-application-id": "RJJNPL7MRL",
          "x-algolia-api-key": "bb21db75be816cbedc8dd1b5838e4bba",
          "Content-Type": "application/json",
        };

        axios
          .post(url, payload, { headers })
          .then((response) => {
            const caddieIdList = response.data.hits.map((hit) => hit.caddieId);
            setListIdDescription(caddieIdList);
          })
          .catch((error) => {
            console.error("Error fetching courses:", error);
          });
      } else {
      }
    };
    filterCaddie();
  }, [
    search.province.state,
    search.province.value,
    search.text.state,
    search.text.value,
    originalCaddieList,
    // filter, // Include the filter state dependencies
    userLocation,
  ]);

  useEffect(() => {
    const sortedList = [...caddieList];

    const provinceFilteredList =
      search.province.state && search.province.value
        ? sortedList.filter(
            (caddie) =>
              caddie.location.provinceEng.toLowerCase() ===
              search.province.value.toLowerCase()
          )
        : sortedList;

    // Apply additional filtering criteria
    const filteredList = provinceFilteredList.filter((caddie) => {
      const isStarMatch =
        !sortData.isStar.state || (sortData.isStar.state && caddie.isStar);
      const isProMatch =
        !sortData.isPro.state || (sortData.isPro.state && caddie.isPro);

      const hasValidBirthDate = caddie.birthDate.year;
      const isAgeMatch =
        !sortData.age.state ||
        (hasValidBirthDate &&
          2024 - caddie.birthDate.year >= sortData.age.min &&
          2024 - caddie.birthDate.year <= sortData.age.max);

      const isFavoriteMatch =
        !sortData.isFavorites.state ||
        Object.values(favoriteFriendsList).includes(caddie.uid);

      const selectedLabels =
        sortData.isLabel?.selectedLabels.map((option) => option.label) || [];

      const isLabelMatch =
        !sortData.isLabel?.state ||
        selectedLabels.length === 0 ||
        (caddie.labels &&
          selectedLabels.every((label) => caddie.labels.includes(label)));

      const isBlockMatch =
        !sortData.isBlocks.state ||
        Object.values(listUserBlocked).includes(caddie.uid);

      const isSearchMatch =
        !search.text.state ||
        (listIdDescription.length === 0
          ? caddie.firstName
              .toLowerCase()
              .includes(search.text.value.toLowerCase()) ||
            caddie.lastName
              .toLowerCase()
              .includes(search.text.value.toLowerCase()) ||
            caddie.caddieNumber?.includes(search.text.value)
          : Object.values(listIdDescription).includes(caddie.uid));

      return (
        isStarMatch &&
        isProMatch &&
        isAgeMatch &&
        isFavoriteMatch &&
        isLabelMatch &&
        isSearchMatch
      );
    });

    setFilteredCaddieList(filteredList);
  }, [caddieList, sortData, favoriteFriendsList, search, listIdDescription]);

  const handleChat = async (dooNineId) => {
    const result = await ChatTo(userData.dooNineId, dooNineId);
    if (result) {
      navigate("/chat-room?channelId=" + result[0].channel_url);
    }

    // try {
    //   const response = await axios.get(
    //     `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels?members_exactly_in=${userData.dooNineId}&show_member=false&members_exactly_in=${dooNineId}`,
    //     {
    //       headers: {
    //         "Content-Type": "application/json; charset=utf8",
    //         "Api-Token": sendBird_api_token,
    //       },
    //     }
    //   );

    //   if (response.data.channels.length > 0) {
    //     isMobile
    //       ? navigate("/chat-room", {
    //           state: {
    //             channelUrl: response.data.channels[0].channel_url,
    //             previousPage: "caddie",
    //           },
    //         })
    //       : navigate("/chat", {
    //           state: {
    //             channelUrl: response.data.channels[0].channel_url,
    //             previousPage: "caddie",
    //           },
    //         });
    //   } else {
    //     const response2 = await axios.post(
    //       "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels",
    //       {
    //         user_ids: [userData.dooNineId, dooNineId],
    //       },
    //       {
    //         headers: {
    //           "Content-Type": "application/json; charset=utf8",
    //           "Api-Token": sendBird_api_token,
    //         },
    //       }
    //     );
    //     isMobile
    //       ? navigate("/chat-room", {
    //           state: {
    //             channelUrl: response.data.channels[0].channel_url,
    //             previousPage: "caddie",
    //           },
    //         })
    //       : navigate("/chat", {
    //           state: {
    //             channelUrl: response.data.channels[0].channel_url,
    //             previousPage: "caddie",
    //           },
    //         });
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const handleSwitchChange = (checked, switchId) => {
    setTempSortData((prevState) => ({
      ...prevState,
      [switchId]: { ...prevState[switchId], state: checked },
    }));
  };

  const handleSubmit = () => {
    setSortData(tempSortData);
    handleCloseSortAndFilter();
  };

  const [showSortAndFilter, setShowSortAndFilter] = useState(false);
  const handleCloseSortAndFilter = () => {
    setTempSortData(sortData);
    setShowSortAndFilter(false);
  };
  const handleShowSortAndFilter = () => {
    setTempSortData(sortData);

    setShowSortAndFilter(true);
  };

  const handleInputChange = (e, field, type) => {
    const value = e && e.target ? e.target.value : e;

    setTempSortData((prevState) => ({
      ...prevState,
      [field]: { ...prevState[field], [type]: value },
    }));
  };

  return (
    <>
      <div className="card d-block border-0 rounded-3 overflow-hidden p-3 shadow-xss">
        <div className="d-flex w-100 flex-column flex-md-row p-2">
          <div className="d-flex align-items-center ms-md-auto mt-0 mt-md-0 w-md-auto">
            <div className="search-form-2 flex-grow-1">
              <CaddieSearch
                search={search}
                setSearch={setSearch}
                openSearch={openSearch}
                setOpenSearch={setOpenSearch}
              ></CaddieSearch>
            </div>

            <div
              onClick={() => handleShowSortAndFilter()}
              style={{ cursor: "pointer" }}
              className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3"
            >
              <i className="feather-filter font-xss text-grey-500"></i>
            </div>
          </div>
        </div>

        <StackFilter
          trueStates={trueStates}
          sortData={sortData}
          setSortData={setSortData}
          setTempSortData={setTempSortData}
          search={search}
          setSearch={setSearch}
          originalCaddieList={originalCaddieList}
        ></StackFilter>

        <div className="row gx-1 ps-1 pe-1">
          {filteredCaddieList.length > 0 ? (
            filteredCaddieList.map((value, index) => (
              <>
                <CaddieCard
                  key={value.id || index}
                  value={value}
                  index={index}
                  handleShow={handleShow}
                  handleChat={handleChat}
                  favoriteFriendsList={favoriteFriendsList}
                  handleFav={handleFav}
                  setSelectedUserLabel={setSelectedUserLabel}
                  setShowLabelModal={setShowLabelModal}
                ></CaddieCard>

                <ModalCaddieDetail
                  show={show}
                  setShow={setShow}
                  caddieData={caddieData}
                  setCaddieData={setCaddieData}
                  setCourseData={setCourseData}
                  setFavoriteFriends={setFavoriteFriends}
                  favoriteFriends={favoriteFriends}
                  caddieProfile={caddieProfile}
                  setCaddieProfile={setCaddieProfile}
                ></ModalCaddieDetail>
              </>
            ))
          ) : !loading ? (
            <h5 className="text-center p-2">No caddie available</h5>
          ) : null}
          {loading && (
            <div className="d-flex justify-content-center">
              <Load />
            </div>
          )}
        </div>
      </div>

      <ModalFilter
        showSortAndFilter={showSortAndFilter}
        setShowSortAndFilter={setShowSortAndFilter}
        handleCloseSortAndFilter={handleCloseSortAndFilter}
        tempSortData={tempSortData}
        handleSubmit={handleSubmit}
        handleSwitchChange={handleSwitchChange}
        handleInputChange={handleInputChange}
        userLabels={userLabels}
      />

      <Label
        triggerFriendList={triggerFriendList}
        setTriggerFriendList={setTriggerFriendList}
        showLabelModal={showLabelModal}
        setShowLabelModal={setShowLabelModal}
        setSelectedUserLabel={setSelectedUserLabel}
        selectedUserLabel={selectedUserLabel}
        userLabels={userLabels}
        triggerGetLabel={triggerGetLabel}
        setTriggerGetLabel={setTriggerGetLabel}
      />
    </>
  );
}

export default CourseDetailCaddieBody;

function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}
