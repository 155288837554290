import React, { useRef, useEffect, useState, useMemo } from "react";
import Header from "../../components/Header";
import Rightchat from "../../components/Rightchat";
import Leftnav from "../../components/Leftnav";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import InfiniteScroll from "react-infinite-scroll-component";
import GoogleMapReact from "google-map-react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../components/language/dropdown/textconvert";
import "instantsearch.css/themes/satellite.css";
import { Configure, useInfiniteHits, useInstantSearch } from "react-instantsearch";
import Load from "../../components/Load";
import { firestore } from "../../components/firebase/FirebaseConfig";
import CourseCard from "./component/CourseCard";
import FilterStackV2 from "./component/FilterStackV2";
import FilterCoursesV2 from "./component/FilterCoursesV2";
import { useFilter } from "./module/filterContext";
import CustomSearchBox from "./component/SearchBox";
function CourseV2() {
    const userData = useSelector((state) => state.user.data);
    const { t } = useTranslation();

    const { facet, numericFilters, filterState } = useFilter();

    const [showFilter, setShowFilter] = useState(false);

    const { items, isLastPage, showMore } = useInfiniteHits();
    const { status } = useInstantSearch();
    const courseRef = useRef(null);

    const mapRef = useRef(null);
    const [mapRendered, setMapRendered] = useState(false);

    const [favoriteCourses, setFavoriteCourses] = useState([]);


    useEffect(() => {
        const fetchFavCourse = () => {
            const q = query(
                collection(firestore, "favoriteCourses"),
                where("userId", "==", userData.uid)
            );

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                let arrayData = [];
                querySnapshot.forEach((doc) => {
                    arrayData.push(doc.data());
                });
                setFavoriteCourses(arrayData);
            });

            return () => unsubscribe();
        };

        if (userData) {
            fetchFavCourse();
        }
    }, [userData, items]);

    useEffect(() => {
        if (mapRendered) {
            const maps = window.google.maps;

            mapRef.current.markers?.forEach((marker) => marker.setMap(null));
            mapRef.current.markers = [];

            if (items.length > 0) {
                const bounds = new maps.LatLngBounds();

                items.forEach((courseData) => {
                    const marker = new maps.Marker({
                        position: {
                            lat: courseData.location._geoloc.lat,
                            lng: courseData.location._geoloc.lng,
                        },
                        map: mapRef.current,
                        title: courseData.courseName,
                    });

                    bounds.extend(marker.getPosition());

                    mapRef.current.markers.push(marker);

                    const infoWindow = new maps.InfoWindow({
                        headerContent: courseData.courseName,
                        position: {
                            lat: courseData.location._geoloc.lat,
                            lng: courseData.location._geoloc.lng,
                        },
                    });

                    infoWindow.open(mapRef.current, marker);

                });

                mapRef.current.fitBounds(bounds);
            }
        }
    }, [items, mapRef, mapRendered]);

    const renderMap = useMemo(() => {

        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i)) {
            return null

        } else {
            return (
                <div style={{ height: 'calc(100dvh - 100px - 20px)', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key: "AIzaSyAD6ycBR-QVUjiHoTk-qphLx8Ij9ZiEdUY",
                        }}
                        defaultCenter={{
                            lat: 13.831645904382652,
                            lng: 100.55372919513437,
                        }}
                        defaultZoom={9}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={({ map, maps }) => {
                            setMapRendered(true);
                            mapRef.current = map;
                            mapRef.current.markers = [];
                        }}
                    />
                </div>
            )
        }

    }, []);

    return (
        <>
            <Header />
            <Leftnav />
            <Rightchat />
            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div
                        className="middle-sidebar-left pe-0"
                        style={{ maxWidth: "100%" }}
                    >
                        <div className="row">
                            <div className="col-xl-6 chat-left scroll-bar" ref={courseRef}>
                                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                                    <div className="d-flex w-100 flex-wrap">
                                        <h2 className="d-flex fw-600 mb-2 mb-md-0 mt-0 align-items-center">
                                            {t(textConvert('Golf Course'))}
                                        </h2>
                                        <div className="d-inline-flex align-items-center gap-2 ms-auto align-items-stretch">
                                            <CustomSearchBox />
                                            <Configure
                                                hitsPerPage={10}
                                                facetFilters={facet.length ? facet : undefined}
                                                numericFilters={numericFilters.length ? numericFilters : undefined}
                                                aroundLatLngViaIP={filterState.loc === 'Near Me' ? true : undefined}
                                                aroundRadius={filterState.loc === 'Near Me' ? 50000 : undefined}
                                            />
                                            <button className="btn btn-light" onClick={() => setShowFilter(true)}>
                                                <i className="feather-filter font-xss text-grey-500"></i>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <FilterStackV2 />
                                    </div>
                                </div>
                                {courseRef.current &&
                                    <InfiniteScroll
                                        dataLength={items.length}
                                        next={showMore}
                                        hasMore={!isLastPage}
                                        loader={<Load />}
                                        scrollableTarget={courseRef.current}
                                        endMessage={
                                            <>
                                                {status === 'loading' || status === 'stalled' ?
                                                    <Load />
                                                    :
                                                    items.length ? null : <div className="text-center p-2"><h4 className="fw-600">No Result</h4></div>
                                                }
                                            </>
                                        }
                                    >
                                        <div className="row ps-2 pe-2">
                                            {items.map((value, index) => (
                                                <div
                                                    key={index}
                                                    className="col-lg-6 col-md-6 col-sm-6 mb-3 pe-2 ps-2"
                                                >
                                                    <CourseCard
                                                        value={value}
                                                        favoriteCourses={favoriteCourses}
                                                    ></CourseCard>
                                                </div>
                                            ))}
                                        </div>
                                    </InfiniteScroll>
                                }
                            </div>
                            <div className="col-xl-6 ps-0 d-none d-xl-block">
                                <div className="card w-100 border-0 shadow-none rounded-3 border-0 overflow-hidden">
                                    {renderMap}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Popupchat />
            <Appfooter />
            <FilterCoursesV2
                isShow={showFilter}
                close={() => setShowFilter(false)}
            />
        </>
    )
}

export default CourseV2


