import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ReactSVG } from "react-svg";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import { ModalFooter } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../../components/language/dropdown/textconvert";

function AddFriendModal({
  show,
  handleClose,
  setFindDooNineId,
  findDooNineId,
  handleFindFriendByDooNineId,
  resultDooNineState,
  resultDooNine,
  handleProfileClick,
  handleChat,
  handleAddFriend,
  isLoading,
  iconSrc,
  isFriendRequest,
  setQRCodeModal,
  setScanQRCodeModal,
  handleUnblock,
}) {
  const { t } = useTranslation()
  const userData = useSelector((state) => state.user.data);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="d-flex align-items-center">
            <ReactSVG
              src="assets/images/myFriend/CaddieFriendFav-Add FriendID.svg"
              style={{ width: "30px" }}
            />
            <h2 className="fw-600 mb-0 ms-2 mt-0">{t(textConvert('Add friend'))}</h2>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="mb-2 pt-0 pb-0 fw-600">{t(textConvert('Search by doonine ID'))}</h4>

        <div className="d-flex align-items-center justify-content-between pt-0 pb-0">
          <div className="col-10 search-form-2 d-flex align-items-center">
            <input
              onChange={(e) => setFindDooNineId(e.target.value)}
              type="text"
              className="form-control text-grey-700 mb-0 bg-greylight theme-dark-bg border-0 ms-2"
              placeholder={t(textConvert('Search here'))}
              value={findDooNineId}
            />
          </div>
          <a
            onClick={() => handleFindFriendByDooNineId()}
            className="btn-round-md bg-greylight theme-dark-bg rounded-3 ms-2"
            style={{ cursor: "pointer" }}
          >
            <i className="ti-search font-xss text-grey-600"></i>
          </a>
        </div>

        <br></br>

        {resultDooNineState == "myself" ? (
          <div className="row ps-2 pe-2">
            <div className="col-md-12 col-sm-6 pe-2 ps-2">
              <div className="text-center">
                <figure
                  className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative z-index-1 mb-2"
                  style={{ width: "100px", height: "100px" }}
                >
                  <img
                    src={`${userData.profileImage}`}
                    alt="avatar"
                    className="bg-image-cover float-left p-1 rounded-circle w-100 h-100"
                    style={{
                      backgroundColor:
                        resultDooNine?.role === "caddie"
                          ? "#EE3D7F"
                          : "#4A74E5",
                      borderRadius: "50%",
                    }}
                  />
                </figure>
                <h5 className="fw-600 mb-3">
                  You can't add your self as a friend.
                </h5>
              </div>
            </div>
          </div>
        ) : resultDooNineState == "user-not-found" ? (
          <h2 className="text-center">
            <figure
              className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative z-index-1 mb-2"
              style={{ width: "100px", height: "100px" }}
            >
              <img
                src="assets/images/myFriend/CaddieFriendFav-UserNotfound.svg"
                alt="avater"
                className="bg-image-cover float-left p-1 rounded-circle w-100 h-100"
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#f5f5f5",
                }}
              />
            </figure>

            <h5 className="fw-600 mb-3">User not found.</h5>
          </h2>
        ) : resultDooNineState == "friend" ? (
          <div className="row ps-2 pe-2">
            <div className="col-md-12 col-sm-6 pe-2 ps-2">
              <div className="text-center">
                <figure
                  className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative z-index-1"
                  style={{ width: "120px", height: "120px" }}
                >
                  <img
                    src={`${resultDooNine?.profileImage}`}
                    alt="avater"
                    className="bg-image-cover float-left p-1 rounded-circle w-100 h-100"
                    style={{
                      backgroundColor:
                        resultDooNine?.role === "caddie"
                          ? "#EE3D7F"
                          : "#4A74E5",
                      borderRadius: "50%",
                    }}
                  />
                </figure>
                <div className="clearfix w-100"></div>
                <h5 className="fw-600 mt-3 mb-0">
                  {resultDooNine?.firstName} {resultDooNine?.lastName}{" "}
                </h5>
                <p className="fw-500 font-xssss text-grey-700 mt-0 mb-3"></p>
                <button
                  onClick={() => handleProfileClick(resultDooNine?.uid)}
                  className="btn text-white font-xssss mx-2"
                  style={{ backgroundColor: "#EE3D7F" }}
                >
                  <span style={{ display: "inline-block", marginRight: "5px" }}>
                    <div style={{ width: "30px", height: "30px" }}>
                      <ReactSVG src="assets/images/myFriend/CaddieFriendFav-Friends.svg" />
                    </div>
                  </span>
                  <span>{t(textConvert('Friends'))}</span>
                </button>
                <div
                  onClick={() => handleChat(resultDooNine?.dooNineId)}
                  className="d-inline-block mx-2"
                  style={{ cursor: "pointer" }}
                >
                  <ReactSVG
                    src="assets/images/myFriend/CaddieFriendFav-Chat.svg"
                    style={{ width: "55px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : resultDooNineState == "not-friend" ? (
          <div className="row ps-2 pe-1">
            <div className="col-md-12 col-sm-6 pe-2 ps-2">
              <div className="text-center">
                <figure
                  className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative z-index-1"
                  style={{ width: "120px", height: "120px" }}
                >
                  <img
                    src={`${resultDooNine?.profileImage}`}
                    alt="avatar"
                    className="bg-image-cover float-left p-1 rounded-circle w-100 h-100"
                    style={{
                      objectFit: "cover",
                      borderRadius: "50%",
                      backgroundColor:
                        resultDooNine?.role === "caddie"
                          ? "#EE3D7F"
                          : "#4A74E5",
                    }}
                  />
                </figure>
                <div className="clearfix w-100"></div>
                <h5 className="fw-600 mt-3 mb-0">
                  {resultDooNine?.firstName} {resultDooNine?.lastName}{" "}
                </h5>
                <p className="fw-500 font-xssss text-grey-700 mt-0 mb-3"></p>
                <button
                  onClick={() =>
                    handleAddFriend(resultDooNine?.uid, userData.uid)
                  }
                  className="btn text-white font-xssss"
                  style={{ backgroundColor: "#EE3D7F" }}
                >
                  {isLoading ? (
                    <div
                      style={{ display: "inline-block", marginRight: "5px" }}
                    >
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : (
                    <span
                      style={{ display: "inline-block", marginRight: "5px" }}
                    >
                      <div style={{ width: "30px", height: "30px" }}>
                        <ReactSVG src={iconSrc} />
                      </div>
                    </span>
                  )}
                  <span>
                    {isFriendRequest ? "Cancel Request" : "Add friend"}
                  </span>
                </button>

                <div
                  onClick={() => handleChat(resultDooNine?.dooNineId)}
                  className="d-inline-block"
                  style={{ cursor: "pointer" }}
                >
                  <ReactSVG
                    src="assets/images/myFriend/CaddieFriendFav-Chat.svg"
                    style={{ width: "55px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : resultDooNineState == "my-self-block" ? (
          <div className="row ps-2 pe-1">
            <div className="col-md-12 col-sm-6 pe-2 ps-2">
              <div className="text-center">
                <figure
                  className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative z-index-1"
                  style={{ width: "120px", height: "120px" }}
                >
                  <img
                    src={`${resultDooNine?.profileImage}`}
                    alt="avatar"
                    className="bg-image-cover float-left p-1 rounded-circle w-100 h-100"
                    style={{
                      objectFit: "cover",
                      borderRadius: "50%",
                      backgroundColor:
                        resultDooNine?.role === "caddie"
                          ? "#EE3D7F"
                          : "#4A74E5",
                    }}
                  />
                </figure>
                <div className="clearfix w-100"></div>
                <h5 className="fw-600 mt-3 mb-0">
                  {resultDooNine?.firstName} {resultDooNine?.lastName}{" "}
                </h5>
                <p className="fw-500 font-xssss text-grey-700 mt-0 mb-3"></p>
                <button
                  onClick={() => handleUnblock(resultDooNine)}
                  className="btn text-white font-xssss"
                  style={{ backgroundColor: "#EE3D7F" }}
                >
                  <span>{t(textConvert('Unblock'))}</span>
                </button>
              </div>
            </div>
          </div>
        ) : resultDooNineState == "me-blocked-by-user" ? (
          <h2 className="text-center">
            <figure
              className="overflow-hidden avatar ms-auto me-auto mb-0 position-relative z-index-1 mb-2"
              style={{ width: "100px", height: "100px" }}
            >
              <img
                src="assets/images/myFriend/CaddieFriendFav-UserNotfound.svg"
                alt="avater"
                className="bg-image-cover float-left p-1 rounded-circle w-100 h-100"
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#f5f5f5",
                }}
              />
            </figure>

            <h5 className="fw-600 mb-3">User not found.</h5>
          </h2>
        ) : (
          ""
        )}
      </Modal.Body>
      <ModalFooter>
        <div
          className=" p-2 z-index-1 rounded-3 text-white fw-700 ls-3"
          style={{ backgroundColor: "#EE3D7F", cursor: "pointer" }}
          onClick={() => setQRCodeModal(true)}
        >
          {t(textConvert('My QR Code'))}
        </div>

        <div
          className=" p-2 z-index-1 rounded-3 text-white fw-700 ls-3"
          style={{ backgroundColor: "#EE3D7F", cursor: "pointer" }}
          onClick={() => setScanQRCodeModal(true)}
        >
          {t(textConvert('scan'))}
        </div>

        <div
          className="bg-greylight p-2 z-index-1 rounded-3 text-grey fw-700 ls-3"
          onClick={() => handleClose()}
          style={{ cursor: "pointer" }}
        >
          {t(textConvert('close'))}
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default AddFriendModal;
