import React, { Fragment, useState, useEffect, useRef } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";

import { useSelector } from "react-redux";
import axios from "axios";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import { ReactSVG } from "react-svg";

import Swal from "sweetalert2";

import SendbirdChat from "@sendbird/chat";
import { useNavigate } from "react-router-dom";

import {
  GroupChannelModule,
  GroupChannelFilter,
} from "@sendbird/chat/groupChannel";

import { containerStyle } from "./component/StyleComponent";

import ChannelInviteList from "./component/ChannelInviteList";

import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../components/language/dropdown/textconvert";

let sb;
const APP_ID = "B72515CE-E0DA-417F-AF02-4BF3D07B3D50";

function ChatInvite() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.data);
  const [state, updateState] = useState({
    applicationUsers: [],
    groupChannelMembers: [],
    currentlyJoinedChannel: null,
    messages: [],
    channels: [],
    messageInputValue: "",
    userIdInputValue: userData?.dooNineId,
    channelNameUpdateValue: "",
    settingUpUser: true,
    file: null,
    messageToUpdate: null,
    messageCollection: null,
    loading: false,
    error: false,
  });

  const [searchInput, setSearchInput] = useState("");
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [inviteChannel, setInviteChannel] = useState([]);

  const stateRef = useRef();
  stateRef.current = state;

  const setupUser = async () => {
    const { userIdInputValue } = state;
    const sendbirdChat = await SendbirdChat.init({
      appId: APP_ID,
      localCacheEnabled: false,
      modules: [new GroupChannelModule()],
    });

    await sendbirdChat.connect(
      userData?.dooNineId,
      userData?.sendBirdAccessToken
    );
    await sendbirdChat.setChannelInvitationPreference(true);

    sb = sendbirdChat;
    updateState({ ...state, loading: true });
    const [channels, error] = await loadChannels(channelHandlers);

    if (error) {
      return onError(error);
    }
    updateState({
      ...state,
      channels: channels,
      loading: false,
      settingUpUser: false,
    });
  };

  const channelHandlers = {
    onChannelsAdded: (context, channels) => {
      const updatedChannels = [...channels, ...stateRef.current.channels];
      updateState({
        ...stateRef.current,
        channels: updatedChannels,
        applicationUsers: [],
      });
    },
    onChannelsDeleted: (context, channels) => {
      const updatedChannels = stateRef.current.channels.filter((channel) => {
        return !channels.includes(channel.url);
      });
      updateState({ ...stateRef.current, channels: updatedChannels });
    },
    onChannelsUpdated: (context, channels) => {
      const updatedChannels = stateRef.current.channels.map((channel) => {
        const updatedChannel = channels.find(
          (incomingChannel) => incomingChannel.url === channel.url
        );
        if (updatedChannel) {
          return updatedChannel;
        } else {
          return channel;
        }
      });

      updateState({ ...stateRef.current, channels: updatedChannels });
    },
  };

  const loadChannels = async (channelHandlers) => {
    // สร้าง filter โดยเปิดใช้งาน includeEmpty
    const groupChannelFilter = new GroupChannelFilter();
    groupChannelFilter.includeEmpty = true; // ให้รวมแชนแนลที่ไม่มีข้อความ

    // สร้าง collection โดยไม่ระบุเงื่อนไขการจัดลำดับ (optional)
    const collection = sb.groupChannel.createGroupChannelCollection({
      filter: groupChannelFilter, // ใช้ filter ที่รวมทุกแชนแนล
    });

    collection.setGroupChannelCollectionHandler(channelHandlers);

    const channels = await collection.loadMore();
    return [channels, null];
  };

  const onError = (error) => {
    updateState({ ...state, error: error.message });
    console.log(error);
  };

  const ChannelName = ({ members, channel }) => {
    const membersToDisplay = members
      .slice(0, 2)
      .filter((member) => member.userId !== userData.dooNineId);
    const membersNotToDisplay = members.slice(2);
    return (
      <>
        {membersToDisplay.map((member) => {
          return <span key={member.userId}>{member.nickname}</span>;
        })}
        {membersNotToDisplay.length > 0 && `+ ${membersNotToDisplay.length}`}
      </>
    );
  };

  useEffect(() => {
    if (userData) {
      setupUser();
    }
  }, [navigate, userData]);

  const colorSet = {
    "--sendbird-light-primary-500": "#ee3d7f",
    "--sendbird-light-primary-400": "#f16398",
    "--sendbird-light-primary-300": "#ee3d7f",
    "--sendbird-light-primary-200": "#f8b1cb",
    "--sendbird-light-primary-100": "#fbd8e5",
  };

  useEffect(() => {
    const filteredChannels = state.channels
      .map((channel) => {
        const filteredMembers = channel.members.filter(
          (member) =>
            member.userId === userData.dooNineId && member.state !== "joined"
        );

        if (filteredMembers.length > 0) {
          return {
            ...channel, // คัดลอกข้อมูลของ channel ทั้งหมด
            members: filteredMembers, // แทนที่ members ด้วยสมาชิกที่กรองแล้ว
          };
        }

        return null;
      })
      .filter((channel) => channel !== null); // ลบ channel ที่ไม่มีสมาชิกตรงเงื่อนไข

    setInviteChannel(filteredChannels);
  }, [state.channels]);

  console.log(inviteChannel);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />
      <SendbirdProvider
        appId={"B72515CE-E0DA-417F-AF02-4BF3D07B3D50"}
        userId={userData?.dooNineId}
        accessToken={userData?.sendBirdAccessToken}
        // breakpoint={true}
        // theme="dark"
        colorSet={colorSet}
      >
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12 pageMyFriend">
                  <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                    <div className="d-flex w-100 align-items-center">
                      <Link to="/chat" className="d-inline-block me-3">
                        <i className="ti-arrow-left font-sm text-black"></i>
                      </Link>
                      <h2 className="fw-700 mb-0 font-md text-grey-900">
                        {t(textConvert('Invite Chat'))} ({inviteChannel.length})
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12 pageMyFriend">
                  <div className="d-flex w-100 flex-column flex-md-row">
                    <div
                      className="scroll-bar"
                      style={{
                        ...containerStyle,
                        // overflowY: "auto",
                        // height: "65vh",
                      }}
                    >
                      {inviteChannel.map((channel) => (
                        <ChannelInviteList
                          key={channel._url}
                          channel={channel}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SendbirdProvider>
      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}

export default ChatInvite;
