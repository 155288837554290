import React, { useState, useEffect, useRef } from 'react'
import { useWizard } from './wizardContext'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { addDoc, updateDoc, collection, doc, serverTimestamp, getDoc, query, where, getDocs, Timestamp } from 'firebase/firestore'
import { firestore } from '../../../../components/firebase/FirebaseConfig'
import { updateScoreCard } from '../joinRound/module/updateScoreCard';
import { handleUploadImage } from './module/uploadRoundImage';
import Swal from 'sweetalert2';

// player
import EditStepOneByPlayer from './editByPlayer/EditStepOneByPlayer';
import EditStepTwoByPlayer from './editByPlayer/EditStepTwoByPlayer';
import EditStepThreeByPlayer from './editByPlayer/EditStepThreeByPlayer';

// caddie
import EditStepOneByCaddie from './editByCaddie/EditStepOneByCaddie';
import EditStepTwoByCaddie from './editByCaddie/EditStepTwoByCaddie';
import EditStepThreeByCaddie from './editByCaddie/EditStepThreeByCaddie';

import ReqPreview from './ReqPreview'

import { gameModeDef, handicapDef, holesDef, scoringDef } from './module/holeDef';
import { changeRoundDateNotification } from '../../../../components/notification/sentNotification';
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../../components/language/dropdown/textconvert';

const MainWizardEdit = ({ roundId }) => {
    const { t } = useTranslation();
    const userData = useSelector((state) => state.user.data);
    const { stepData, updateStepData, currentStep, prevStep, nextStep } = useWizard();

    const navigate = useNavigate();
    const [editLoading, setEditLoading] = useState(false);

    const isPastRef = useRef(true);

    const data1 = stepData.stepOne;
    const data2 = stepData.stepTwo;
    const data3 = stepData.stepThree;

    // console.log(data3);

    useEffect(() => {
        async function getRoundData() {
            try {
                const q = doc(firestore, 'round', roundId);
                const roundSnapshot = await getDoc(q);
                if (roundSnapshot.exists) {
                    const roundData = roundSnapshot.data();



                    if (userData.role === 'caddie') {
                        const creatorUserCaddie = roundData.userList.find(user => user.caddie !== null && user.caddie?.isCreator === true && user.caddie.uid === userData.uid);
                        if (!creatorUserCaddie) {
                            console.warn('You are not the round creator');
                            navigate('/round-activity');
                            return;
                        }
                    } else {
                        const creatorUserPlayer = roundData.userList.find(user => user.player !== null && user.player?.isCreator === true && user.player.uid === userData.uid);
                        if (!creatorUserPlayer) {
                            console.warn('You are not the round creator');
                            navigate('/round-activity');
                            return;
                        }
                    }

                    // update step 1
                    updateStepData('stepOne', {
                        course: roundData.course,
                        roundName: roundData.roundName,
                        holes: holesDef(roundData.holes),
                        holesCourse: roundData.holesCourse,
                        gameMode: gameModeDef(roundData.gameMode),
                        handicap: handicapDef(roundData.handicap),
                        scoring: roundData.scoring,
                        scoringType: scoringDef(gameModeDef(roundData.gameMode), handicapDef(roundData.handicap))
                    })

                    // update step 2
                    updateStepData('stepTwo', {
                        row: await getUserData(roundData.userList)
                    })

                    // update step 3
                    updateStepData('stepThree', {
                        caddieReqBtn: roundData.caddieReq,
                        groupChatBtn: roundData.groupChat,
                        groupChatName: roundData.groupChatName,
                        imageUrl: roundData.coverImage,
                        scheduleTime: roundData.scheduleTime,
                        teeTimeBtn: roundData.teeTime
                    })

                    if (roundData.teeTime === 'schedule') {
                        const currentTimestamp = Date.now();
                        const isPast = currentTimestamp > (roundData.scheduleTime * 1000) || roundData.teeTime === 'now' ? true : false;
                        isPastRef.current = isPast
                    }

                } else {
                    console.log('Not found round ', roundId);
                }
            } catch (error) {
                console.error('Fail to get round data for ', roundId, error);
            }
        }

        async function getUserData(userList) {
            const userListCopy = [...userList]; // Create a copy of the user list to modify
            const userUidList = [];

            // Collect UIDs from both player and caddie fields
            userList.forEach(user => {
                if (user.player) {
                    userUidList.push(user.player.uid);
                }
                if (user.caddie) {
                    userUidList.push(user.caddie.uid);
                }
            });

            try {
                const userCollection = collection(firestore, 'users');
                const q = query(userCollection, where('uid', 'in', userUidList));
                const userSnapshot = await getDocs(q);

                if (!userSnapshot.empty) {
                    userSnapshot.forEach(doc => {
                        const firestoreUserData = doc.data();
                        const firestoreUid = firestoreUserData.uid;

                        userListCopy.forEach(user => {
                            if (user.player && user.player.uid === firestoreUid) {
                                user.player = {
                                    ...user.player,
                                    ...firestoreUserData
                                };
                            }
                            if (user.caddie && user.caddie.uid === firestoreUid) {
                                user.caddie = {
                                    ...user.caddie,
                                    ...firestoreUserData
                                };
                            }
                        });
                    });
                }

                // console.log('Updated User List:', userListCopy);
                return userListCopy;

            } catch (error) {
                console.error('Error fetching user data:', error);
                return userListCopy;
            }
        }
        if (roundId && userData) {
            getRoundData()
        }
    }, [roundId, userData])


    const isDisabled = () => {
        switch (currentStep) {
            case 1:
                if (data1.holes !== "" && data1.roundName !== "") return false;
                else return true;

            case 2:
                return false;

            case 3:
                if ((data3.teeTimeBtn === 'schedule' && !data3.scheduleTime) || (data3.groupChatBtn === 'create' && data3.groupChatName === '')) return true
                else return false;

            case 4:
                return true;

            default:
                return true;
        }
    }

    const handleRequestClick = async () => {

        setEditLoading(true);

        const userList = data2.row.map(item => ({
            row: item.row,
            player: item.player ? { ...item.player } : null,
            caddie: item.caddie ? { ...item.caddie } : null
        }));

        if (!userList.length) {
            if (userData.role === 'player') {
                userList.push({
                    row: 0,
                    player: { ...userData, isCreator: true },
                    caddie: null
                });
            } else {
                userList.push({
                    row: 0,
                    caddie: { ...userData, isCreator: true },
                    player: null
                });
            }

        } else {
            userList.forEach((item) => {
                if (item.player) {
                    if (item.player.uid === userData.uid) {
                        item.player = {
                            uid: item.player.uid,
                            isCreator: true,
                            isJoin: item.player.isJoin ? item.player.isJoin : true
                        };
                    } else {
                        item.player = {
                            uid: item.player.uid,
                            isCreator: false,
                            isJoin: item.player.isJoin ? item.player.isJoin : false
                        };
                    }
                }

                if (item.caddie) {
                    if (item.caddie.uid === userData.uid) {
                        item.caddie = {
                            uid: item.caddie.uid,
                            isCreator: true,
                            isJoin: item.caddie.isJoin ? item.caddie.isJoin : true
                        };
                    } else {
                        item.caddie = {
                            uid: item.caddie.uid,
                            isCreator: false,
                            isJoin: item.caddie.isJoin ? item.caddie.isJoin : false
                        };
                    }
                }
            });
        }

        const data = {
            gameMode: gameModeDef(data1.gameMode),
            holes: holesDef(data1.holes),
            handicap: handicapDef(data1.handicap),
            scoring: data1.scoring,
            roundName: data1.roundName,
            holesCourse: data1.holesCourse,
            course: data1.course.id,
            userList: userList,
            caddieReq: data3.caddieReqBtn,
            groupChat: data3.groupChatBtn,
            groupChatName: data3.groupChatName,
            coverImage: "",
            scheduleTime: data3.scheduleTime,
            teeTime: data3.teeTimeBtn,
            creator: userData.uid
        }

        try {
            const activityCollection = collection(firestore, 'roundActivity');

            let imageUrl = data3.imageUrl ? data3.imageUrl : "";
            if (data3.imageFile) {
                imageUrl = await handleUploadImage(roundId, data3.imageFile)
            }

            const roundDoc = doc(firestore, 'round', roundId);
            const roundSnapshot = await getDoc(roundDoc);

            if (roundSnapshot.exists()) {
                const roundData = roundSnapshot.data();

                // Update the document first
                await updateDoc(roundDoc, {
                    ...data,
                    scheduleTime: data3.teeTimeBtn === 'now' ? Timestamp.now().seconds : data.scheduleTime,
                    coverImage: imageUrl,
                    lastUpdate: serverTimestamp()
                });

                // Proceed with notification logic
                if ((roundData.scheduleTime !== data3.scheduleTime) || (roundData.teeTime !== data3.teeTimeBtn)) {
                    const notiUids = [];
                    userList.forEach(user => {
                        if (user.player && user.player.isJoin && user.player.uid !== userData.uid) {
                            notiUids.push(user.player.uid);
                        }
                        if (user.caddie && user.caddie.isJoin && user.caddie.uid !== userData.uid) {
                            notiUids.push(user.caddie.uid);
                        }
                    });

                    await changeRoundDateNotification(
                        userData.uid,
                        notiUids,
                        data1.roundName,
                        roundId,
                        data3.teeTimeBtn === 'now' ? Timestamp.now().seconds : data3.scheduleTime,
                        roundData.scheduleTime
                    );
                }
            }


            await updateScoreCard(userList, roundId);

            // After successfully updating the round, add the round activity
            const uidList = userList.flatMap((user) => {
                const uids = [];
                if (user.player) {
                    uids.push(user.player.uid);
                }
                if (user.caddie) {
                    uids.push(user.caddie.uid);
                }
                return uids;
            });

            const activityData = {
                roundId: roundId,
                creator: userData.uid,
                userList: uidList,
                caddieReq: data.caddieReq
            };

            const q = query(activityCollection, where('roundId', '==', roundId));
            const activitySnapshot = await getDocs(q);
            if (!activitySnapshot.empty) {
                await updateDoc(activitySnapshot.docs[0].ref,
                    {
                        ...activityData,
                        lastUpdate: serverTimestamp()
                    }
                )
            } else {
                await addDoc(activityCollection,
                    {
                        ...activityData,
                        created: serverTimestamp()
                    }
                );
            }

            setEditLoading(false);
            Swal.fire({
                title: t(textConvert("Round Edited")),
                icon: "success",
                confirmButtonColor: '#ee3c7f'
            }).then(() => {
                navigate('/round-activity');
            });


        } catch (error) {
            setEditLoading(false);

            console.error('Fail to edit round', error);
        }
    }



    return (
        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="middle-wrap">
                        <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                            <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                <Link to="/round-activity" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">{t(textConvert('Edit Round'))} {currentStep !== 4 ? (`(${currentStep}/3)`) : null}</h4>
                            </div>
                            <div className="card-body p-lg-5 p-4 w-100 border-0">
                                <div className="row">
                                    <div className="col-lg-12">
                                        {userData?.role === 'player' ?
                                            <>
                                                {currentStep === 1 && <EditStepOneByPlayer />}
                                                {currentStep === 2 && <EditStepTwoByPlayer />}
                                                {currentStep === 3 && <EditStepThreeByPlayer isPast={isPastRef.current} />}
                                                {currentStep === 4 && <ReqPreview />}
                                            </>
                                            :
                                            <>
                                                {currentStep === 1 && <EditStepOneByCaddie />}
                                                {currentStep === 2 && <EditStepTwoByCaddie />}
                                                {currentStep === 3 && <EditStepThreeByCaddie isPast={isPastRef.current} />}
                                                {currentStep === 4 && <ReqPreview />}
                                            </>
                                        }
                                        <div className='text-end'>
                                            <button className='btn btn-light mx-1 fs-5'
                                                onClick={() => prevStep()}
                                                disabled={currentStep === 1 ? true : false}>
                                                {t(textConvert('Back'))}
                                            </button>
                                            {currentStep !== 4 ? (
                                                <button className='btn bg-current text-white mx-1 fs-5'
                                                    onClick={() => nextStep()}
                                                    disabled={isDisabled()}>
                                                    {t(textConvert('Next'))}
                                                </button>
                                            ) : (
                                                <button className='btn bg-current text-white mx-1 fs-5' onClick={handleRequestClick}>
                                                    {!editLoading ?
                                                        t(textConvert('Send Request'))
                                                        :
                                                        <Spinner variant="light" animation="border" role="status" size="sm">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </Spinner>
                                                    }
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainWizardEdit