import { initialFilterState } from "./filterContext";


export function tmpFilterRec(state, action) {
    switch (action.type) {
        case "OFFICIAL":
            return { ...state, official: !state.official };
        case "PRICE":
            return { ...state, price: !state.price };
        case "PRICEMIN":
            return { ...state, price_min: action.payload };
        case "PRICEMAX":
            return { ...state, price_max: action.payload };
        case "NIGHT":
            return { ...state, night: !state.night };
        case "DRIVE":
            return { ...state, drive: !state.drive };
        case "RESTAURANT":
            return { ...state, restaurant: !state.restaurant };
        case "BANQUET":
            return { ...state, banquet: !state.banquet };
        case "HOTEL":
            return { ...state, hotel: !state.hotel };
        case "LOC":
            return { ...state, loc: action.payload };
        case "RESET":
            return { ...initialFilterState, loc: state.loc };
        default:
            return state;
    }
}

// Utility function for facet filters
export const getFacetFilters = (filter) => {
    const facetFiltersArr = [];

    if (filter.official) facetFiltersArr.push(`features.officialCourse:${filter.official}`);
    if (filter.night) facetFiltersArr.push(`features.nightGolf:${filter.night}`);
    if (filter.drive) facetFiltersArr.push(`features.drivingRange:${filter.drive}`);
    if (filter.restaurant) facetFiltersArr.push(`additionalServices.restaurant:${filter.restaurant}`);
    if (filter.banquet) facetFiltersArr.push(`additionalServices.banquetRooms:${filter.banquet}`);
    if (filter.hotel) facetFiltersArr.push(`additionalServices.hotel:${filter.hotel}`);

    if (filter.loc && filter.loc !== 'Near Me') {
        facetFiltersArr.push(`location.provinceEng:${filter.loc}`);
    }
    return facetFiltersArr;
};

// Utility function for numeric filters
export const getNumericFilters = (filter) => {
    let numericFiltersArr = [];

    if (filter.price) {
        numericFiltersArr.push(`features.startingPriceRange.minPrice >= ${filter.price_min}`);
        numericFiltersArr.push(`features.startingPriceRange.maxPrice <= ${filter.price_max}`);
    }

    return numericFiltersArr;
};


