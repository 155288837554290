import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../../../components/firebase/FirebaseConfig";

export async function getPlayingRound(userId, isCaddie) {
    if (!userId || typeof userId !== 'string') {
        console.error('Invalid userId', userId);
        return [];
    }

    try {
        const chunkSize = 30;
        const activityIdList = [];

        const activityQ = query(collection(firestore, 'roundActivity'), where('userList', 'array-contains', userId));
        const activitySnap = await getDocs(activityQ);

        if (activitySnap.empty) {
            console.log('Not found activity');
        } else {
            activitySnap.forEach(doc => {
                activityIdList.push(doc.data().roundId);
            });
        }

        const roundIdList = [];
        for (let i = 0; i < activityIdList.length; i += chunkSize) {
            const batchIds = activityIdList.slice(i, i + chunkSize);
            const scoreQ = query(
                collection(firestore, 'scoreCards'),
                where(isCaddie ? 'caddieId' : 'userId', '==', userId),
                where('roundId', 'in', batchIds),
            );

            const scoreSnap = await getDocs(scoreQ);

            if (!scoreSnap.empty) {
                scoreSnap.forEach(doc => {
                    const data = doc.data();
                    if (data.status !== 'playing') {
                        roundIdList.push(data.roundId);
                    }
                });
            }
        }

        const uniqueFilteredRound = [...new Set(activityIdList.filter(id => !roundIdList.includes(id)))];

        const filteredRoundId = [];
        for (let i = 0; i < uniqueFilteredRound.length; i += chunkSize) {
            const batchIds = uniqueFilteredRound.slice(i, i + chunkSize);
            const roundQ = query(
                collection(firestore, 'round'),
                where('roundId', 'in', batchIds),
            );

            const roundSnap = await getDocs(roundQ);

            if (!roundSnap.empty) {
                roundSnap.forEach(doc => {
                    const roundData = doc.data();
                    if (!roundData.status || roundData.status !== 'deleted') {
                        const userList = roundData.userList;
                        const foundUser = userList.find(user => (user.player && user.player.uid === userId) || (user.caddie && user.caddie.uid === userId));
                        if (foundUser && isCaddie ? foundUser.caddie.isJoin : foundUser.player.isJoin) {
                            if (roundData.teeTime === 'now') {
                                filteredRoundId.push(roundData);
                            }
                        }
                    }
                });
            }
        }
        return filteredRoundId;

    } catch (error) {
        console.error('Failed to get playing round', error);
        return [];
    }
}

