import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import TimeAgo from './utilsfunction/TimeConvert';
import Audience from './utilsfunction/Audience';
import { ReactSVG } from 'react-svg';
import SelectedPrivBtn from './utilsfunction/SelectedPriv';
import Swal from 'sweetalert2';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { storage, firestore } from '../../../components/firebase/FirebaseConfig';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject } from 'firebase/storage';
import { collection, addDoc, updateDoc, serverTimestamp, runTransaction, doc } from 'firebase/firestore';
import { commentedPostNotification } from '../../../components/notification/sentNotification';

import imageCompression from 'browser-image-compression';
import FetchComment from './utilsfunction/FetchComment';
import ExpandableContent from './utilsfunction/ExpandableContent';

import { PhotoProvider, PhotoView } from 'react-photo-view';
import Like from './Like';
import { imageConverter } from '../../../components/imageConverter';
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../components/language/dropdown/textconvert';

const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';

function Comment({ isShow, handleClose, commentData, likeTrigger }) {
    const { t } = useTranslation();
    const [showIsImage, setShowIsImage] = useState(null);
    const [isImage, setIsImage] = useState(null);
    const [selectedPrivacy, setSelectedPrivacy] = useState('public');
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState(0);
    const navigate = useNavigate();

    const scrollParent = useRef(null);
    const textInputRef = useRef();
    const editRef = useRef(null);

    const userData = useSelector((state) => state.user.data);

    const isMobile = useRef(window.matchMedia("(max-width: 768px)").matches);

    const sliderStyle = {
        arrows: isMobile.current ? false : true,
        dots: true,
        className: "center",
        infinite: false,
        slidesToShow: 2,
        swipeToSlide: true,
    }

    async function handleImageSelect(e) {
        const selectedImage = e.target.files[0];
        const convertedImage = await imageConverter(selectedImage)
        const imageUrl = URL.createObjectURL(convertedImage);
        setShowIsImage(imageUrl);
        setIsImage(convertedImage);
    }

    const handleImageDelete = () => {
        setIsImage(null);
        setShowIsImage(null)
    };

    const handleSelectPriv = (selectedPriv) => {
        setSelectedPrivacy(selectedPriv);
    }

    const handleCommentCheck = () => {
        if (document.getElementById('comment-input').value || showIsImage) {
            Swal.fire({
                html: `
                <div class="post-warning-container">
                    <img src="assets/images/dooNineIconV2/others/warning-sign.svg" alt="waring" class="w100">
                    <div class="post-warning-text"><h2>${t(textConvert("Your comment will be discard"))}</h2></div>
                </div>
                `,
                cancelButtonText: t(textConvert('Cancel')),
                confirmButtonText: t(textConvert('Discard')),
                showCancelButton: true,
                customClass: {
                    cancelButton: 'post-warning-cancel-btn',
                    confirmButton: 'post-warning-confirm-btn',
                    actions: 'post-warning-actions',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    setIsImage(null);
                    setShowIsImage(null);
                    editRef.current = null;
                    handleClose();
                }
            });
        } else {
            editRef.current = null;
            handleClose();
        }
    }

    const handleCommentPostBtn = async () => {
        if (editRef.current) {
            await handleCommentEditPost();
        } else {
            await handleCommentNewPost();
        }
    }
    // console.log(commentData);

    const handleCommentNewPost = async () => {
        const content = document.getElementById('comment-input').value;
        let uploadedUrl;

        try {
            if (content === "" && !isImage) return;

            setLoading(true);
            if (isImage) {
                uploadedUrl = await handleUploadImage(commentData.postId, isImage);
            }

            const docRef = await addDoc(collection(firestore, "comments"), {
                postId: commentData.postId,
                userId: userData.uid,
                content: content,
                imageUrl: uploadedUrl || null,
                audience: selectedPrivacy
            })

            await updateDoc(docRef, {
                commentId: docRef.id,
                timestamp: serverTimestamp(),
            });

            const postRef = doc(firestore, 'posts', commentData.postId);
            await runTransaction(firestore, async transaction => {
                const postDoc = await transaction.get(postRef);
                if (!postDoc.exists()) {
                    setLoading(false);
                    throw new Error('Post does not exist!');
                }

                const currentCommentsCount = postDoc.data().commentsCount || 0;
                transaction.update(postRef, {
                    commentsCount: currentCommentsCount + 1
                });
            });

            if (commentData.userId !== userData.uid && commentData.notification) {
                // notification page refresh is require or need to query this post data again
                await commentedPostNotification(userData.uid, commentData.userId, commentData.postId, content)
            }

            setLoading(false);
            setIsImage(null);
            setShowIsImage(null);
            setKey(prevKey => prevKey + 1);
            document.getElementById('comment-input').value = "";
        } catch (error) {
            setLoading(false);
            console.error("Error posting comment: ", error);
        }
    };

    const handleCommentEditPost = async () => {
        const content = document.getElementById('comment-input').value;

        if (content || showIsImage) {
            let uploadedUrl = null;
            try {
                setLoading(true);
                const commentRef = doc(firestore, "comments", editRef.current.commentId);

                if (!showIsImage && editRef.current.imageUrl) {
                    const storageRef = ref(storage, editRef.current.imageUrl);
                    await deleteObject(storageRef);
                    uploadedUrl = null

                } else if (showIsImage && !editRef.current.imageUrl) {
                    uploadedUrl = await handleUploadImage(editRef.current.postId, isImage);

                } else if (showIsImage && editRef.current.imageUrl) {
                    if (isImage) uploadedUrl = await handleUploadImage(editRef.current.postId, isImage);
                    else uploadedUrl = editRef.current.imageUrl

                } else {
                    uploadedUrl = null
                }

                await updateDoc(commentRef, {
                    content: content,
                    imageUrl: uploadedUrl,
                    lastUpdate: serverTimestamp(),
                    audience: selectedPrivacy || 'public'
                });

                editRef.current = null;
                setLoading(false);
                setIsImage(null);
                setShowIsImage(null);
                setKey(prevKey => prevKey + 1);
                document.getElementById('comment-input').value = "";

            } catch (error) {
                console.error('Fail to update comment', error);
            }
        }
    }

    const handleUploadImage = async (post_id, file) => {
        try {

            const convertedImage = await imageConverter(file)

            // Compress the image
            const compressedFile = await imageCompression(convertedImage, {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            });

            const storageRef = ref(storage, `comment_image/${post_id}/${file.name}.jpg`);
            const uploadTask = uploadBytesResumable(storageRef, compressedFile);

            return new Promise((resolve, reject) => {
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log(`Upload is ${progress}% done`);
                    },
                    (error) => {
                        console.error(error);
                        reject(error);
                    },
                    async () => {
                        try {
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            resolve(downloadURL);
                        } catch (error) {
                            console.error(error);
                            reject(error);
                        }
                    }
                );
            });
        } catch (error) {
            console.error('Error compressing or uploading file:', error);
            throw error;
        }
    };
    const handleLikeTrigger = () => {
        likeTrigger();
    }

    const handleProfileClick = (uid) => {

        if (!uid) return;

        if (uid === userData.uid) {
            navigate('/userPage')
        } else {
            navigate("/user", { state: { uid: uid } });
        }
    }

    const handleEditComment = (data) => {
        editRef.current = data;
        textInputRef.current.value = data.content;
        setShowIsImage(data.imageUrl)
    }

    return (
        <>
            <Modal show={isShow}
                onHide={handleCommentCheck}
                className="comment-modal"
                centered
                fullscreen="md-down"
                size='lg'
            >
                <Modal.Body className='main-comment-body right-scroll-bar' id='main-comment-body' ref={scrollParent}>
                    <ReactSVG src='assets/images/dooNineIconV2/others/post-close.svg' className='close-comment-btn dooNine-close-button d-sm-none d-block' onClick={handleCommentCheck} />
                    {commentData?.media?.length > 0 && (
                        <Modal.Body className='comment-media-body pt-0'>
                            <PhotoProvider>
                                <Slider {...sliderStyle} className='comment-image-slider'>
                                    {commentData?.media.map((image, index) => (
                                        <div key={index} className='each-comment-image'>
                                            <PhotoView key={index} src={image}>
                                                <img src={image} aria-hidden alt="image-comment" />
                                            </PhotoView>
                                        </div>
                                    ))}
                                </Slider>
                            </PhotoProvider>
                        </Modal.Body>
                    )}

                    <Modal.Body className='d-flex comment-user-body'>
                        <Link to={commentData?.userData?.uid !== userData?.uid ? `/user?uid=${commentData?.userData?.uid}` : `/userPage`}>
                            <figure className="avatar me-3 pointer">
                                <img
                                    src={commentData?.userData?.profileImage || defaultProfile}
                                    alt="avatar"
                                    className="shadow-sm rounded-circle w45"
                                    style={{ width: '45px', height: '45px', objectFit: 'cover' }}
                                    onError={(e) => e.target.src = defaultProfile}
                                />
                            </figure>
                        </Link>
                        <Link to={commentData?.userData?.uid !== userData?.uid ? `/user?uid=${commentData?.userData?.uid}` : `/userPage`}>
                            <h4 className="d-block fw-700 text-grey-900 font-xssss pointer">
                                {commentData?.userData?.firstName || commentData?.userData?.firstname || commentData?.userData?.lastName || commentData?.userData?.lastname
                                    ? `${commentData?.userData?.firstName || commentData?.userData?.firstname || ''} ${commentData?.userData?.lastName || commentData?.userData?.lastname || ''}`.trim()
                                    : 'Invalid User'}
                                <div className='d-flex align-content-center mt-1'>
                                    <TimeAgo timestamp={commentData?.timestamp?.seconds} />
                                    <Audience privacy={commentData?.audience} />
                                </div>
                            </h4>
                        </Link>

                    </Modal.Body>

                    {commentData?.content?.length > 0 && (
                        <Modal.Body className='comment-content-body'>
                            <h5>
                                <ExpandableContent content={commentData?.content} />
                            </h5>
                        </Modal.Body>
                    )}
                    <div className="emoji-bttn pointer d-flex align-items-center gap-3 w-100">
                        <span className='d-flex align-items-center'>
                            <Like
                                isPost={false}
                                handleLikeTrigger={handleLikeTrigger}
                                postData={commentData}
                            />
                        </span>
                        <span className='d-flex align-items-center'>
                            <ReactSVG src='assets/images/dooNineIconV2/others/post-Comment.svg' style={{ zIndex: 1 }} className='w35' />
                            <span><h5 className='m-0 ms-1'>{commentData?.commentsCount || 0}</h5></span>
                        </span>
                    </div>
                    <hr />
                    <Modal.Body style={{ overflowX: 'visible' }}>
                        <FetchComment key={key} postId={commentData?.postId} scrollParent={scrollParent} postData={commentData} handleEditComment={handleEditComment} />
                    </Modal.Body>
                </Modal.Body>
                <Modal.Footer className={clsx('comment-footer justify-content-start', {

                })}>

                    <div className='w-100 input-comment-container'>
                        <div className='d-flex justify-content-center align-items-center w-100'>
                            <figure className="avatar me-3 m-0"><img
                                src={userData?.profileImage || defaultProfile}
                                alt="avatar"
                                className="shadow-sm rounded-circle w45"
                                style={{ width: '45px', height: '45px', objectFit: 'cover' }}
                            /></figure>
                            <input type="text" name='comment-input' className='form-control' defaultValue="" id='comment-input' placeholder='Write a comment' ref={textInputRef} />
                        </div>
                        {showIsImage && (
                            <div className='comment-image-container mt-3'>
                                <img src={showIsImage} aria-hidden alt="comment-image" className='comment-image' />
                                <i
                                    className='bi bi-x-circle'
                                    onClick={handleImageDelete}
                                ></i>
                            </div>
                        )}
                    </div>
                    <div className='d-flex align-items-center w-100'>
                        {!showIsImage && (
                            <div className='add-photo-btn-container'>
                                <ReactSVG src="assets/images/dooNineIconV2/others/create_Post_Add_Photo.svg" className='create-post-add-photo-btn ms-4 w40' onClick={() => document.getElementById('image-input').click()} />
                                <input type="file" name="image-input" id="image-input" accept="image/*, .heic, .heif, image/heic, image/heif" className='d-none' onChange={handleImageSelect} />
                            </div>
                        )}
                    </div>
                    <div className='create-post-action-btn ms-auto d-inline-flex gap-2'>
                        <SelectedPrivBtn handleSelectPriv={handleSelectPriv} isComment={true} />
                        <button className='btn bg-current post-btn' onClick={handleCommentPostBtn}>
                            {!loading ? (
                                <h5 className='m-0 fw-600 text-white'>{t(textConvert('Comment'))}</h5>
                            ) : (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default Comment;
