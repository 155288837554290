import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  onSnapshot,
  doc,
  getDoc,
} from "firebase/firestore";
import { firestore } from "../../../../../components/firebase/FirebaseConfig";
import { epochToDateTime } from "../../../component/wizard/module/covertTime";

// export const getDataUpcomingRound = async (
//   userData,
//   setListRoundTest,
//   setLoading,
//   lastVisibleDoc = null, // ใช้สำหรับ pagination
//   append = false // ระบุว่าควรเพิ่มข้อมูลใหม่หรือแทนที่ข้อมูลเดิม
// ) => {
//   if (!userData) throw new Error("userData is required");

//   setLoading(true);

//   let nextVisibleDoc = lastVisibleDoc;
//   let filteredRound = [];

//   try {
//     // Loop เพื่อดึงข้อมูลจนได้รอบที่ต้องการ (ขั้นต่ำ 5 รอบ)
//     while (filteredRound.length < 5) {
//       const roundActivityQuery = query(
//         collection(firestore, "roundActivity"),
//         where("userList", "array-contains", userData.uid),
//         limit(5), // ดึงข้อมูล 5 รายการในแต่ละรอบ
//         ...(nextVisibleDoc ? [startAfter(nextVisibleDoc)] : [])
//       );

//       const roundActivitySnapshot = await getDocs(roundActivityQuery);

//       if (roundActivitySnapshot.empty) {
//         // หากไม่มีข้อมูลเพิ่ม ให้หยุด loop
//         break;
//       }

//       const roundId = roundActivitySnapshot.docs.map((doc) => doc.data().roundId);

//       // Handle กรณีที่ roundId เกิน 10
//       const batchQueries = [];
//       for (let i = 0; i < roundId.length; i += 10) {
//         const batch = roundId.slice(i, i + 10);
//         batchQueries.push(
//           getDocs(
//             query(
//               collection(firestore, "round"),
//               where("roundId", "in", batch),
//               where("teeTime", "==", "schedule")
//             )
//           )
//         );
//       }

//       const batchResults = await Promise.all(batchQueries);
//       const roundSnapshot = batchResults.flatMap((batch) => batch.docs);

//       const roundData = roundSnapshot
//         .map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }))
//         .filter((round) => round.status !== "deleted"); // ตัดรอบที่สถานะเป็น "deleted" ออก

//       const roundsWithDetails = await addCourseAndHolesData(roundData);
//       const roundIsPlaying = await fetchUserDetails(roundsWithDetails);

//       const validRounds = roundIsPlaying.filter(
//         (round) =>
//           Array.isArray(round.userData) &&
//           round.userData.some(
//             (user) => user.uid === userData.uid && user.isJoin === true
//           )
//       );

//       // เพิ่ม validRounds ลงใน filteredRound
//       filteredRound = [...filteredRound, ...validRounds];

//       // อัปเดต nextVisibleDoc สำหรับ pagination
//       nextVisibleDoc =
//         roundActivitySnapshot.docs[roundActivitySnapshot.docs.length - 1];

//       // หากได้รอบครบ 5 รอบแล้วให้หยุด
//       if (filteredRound.length >= 5) break;
//     }

//     // กรณีไม่มีข้อมูลเพิ่ม
//     if (filteredRound.length === 0 && nextVisibleDoc === null) {
//       setListRoundTest([]);
//       setLoading(false);
//       return null;
//     }

//     // จัดเรียงข้อมูลตาม scheduleTime
//     const sortedRounds = filteredRound.sort((a, b) =>
//       new Date(b.scheduleTime || 0) - new Date(a.scheduleTime || 0)
//     );

//     // อัปเดต state ด้วยรอบที่จัดเรียงแล้ว
//     setListRoundTest((prevList) =>
//       append ? [...prevList, ...sortedRounds] : sortedRounds
//     );
//   } catch (error) {
//     console.error("Error fetching upcoming rounds:", error);
//   } finally {
//     setLoading(false);
//   }

//   return nextVisibleDoc; // คืนค่าข้อมูลเอกสารล่าสุดสำหรับ pagination
// };


export const getDataUpcomingRound = async (
  userData,
  setListRoundTest,
  setLoading,
  lastVisibleDoc = null,
  append = false
) => {
  if (!userData) throw new Error("userData is required");

  setLoading(true);

  let nextVisibleDoc = lastVisibleDoc;
  let filteredRound = [];
  const loadedRoundIds = new Set();

  try {
    let fetchCount = 0;
    const maxFetchCount = 5;

    while (filteredRound.length < 5 && fetchCount < maxFetchCount) {
      fetchCount++;

      const roundActivityQuery = query(
        collection(firestore, "roundActivity"),
        where("userList", "array-contains", userData.uid),
        limit(5),
        ...(nextVisibleDoc ? [startAfter(nextVisibleDoc)] : [])
      );

      const roundActivitySnapshot = await getDocs(roundActivityQuery);

      if (roundActivitySnapshot.empty) break;

      const roundId = roundActivitySnapshot.docs.map((doc) => doc.data().roundId);

      const batchQueries = [];
      for (let i = 0; i < roundId.length; i += 10) {
        const batch = roundId.slice(i, i + 10);
        batchQueries.push(
          getDocs(
            query(
              collection(firestore, "round"),
              where("roundId", "in", batch),
              where("teeTime", "==", "schedule")
            )
          )
        );
      }

      const batchResults = await Promise.all(batchQueries);
      const roundSnapshot = batchResults.flatMap((batch) => batch.docs);

      const roundData = roundSnapshot
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((round) => round.status !== "deleted" && !loadedRoundIds.has(round.id));

      roundData.forEach((round) => loadedRoundIds.add(round.id));

      const roundsWithDetails = await addCourseAndHolesData(roundData);
      const roundIsPlaying = await fetchUserDetails(roundsWithDetails);

      const validRounds = roundIsPlaying.filter(
        (round) =>
          Array.isArray(round.userData) &&
          round.userData.some(
            (user) => user.uid === userData.uid && user.isJoin === true
          )
      );

      filteredRound = [...filteredRound, ...validRounds];

      nextVisibleDoc =
        roundActivitySnapshot.docs[roundActivitySnapshot.docs.length - 1];

      if (filteredRound.length >= 5) break;
    }

    if (filteredRound.length === 0 && nextVisibleDoc === null) {
      setListRoundTest([]);
      setLoading(false);
      return null;
    }

    const sortedRounds = filteredRound.sort((a, b) => (b.scheduleTime || 0) - (a.scheduleTime || 0));

    setListRoundTest((prevList) => (append ? [...prevList, ...sortedRounds] : sortedRounds));
  } catch (error) {
    console.error("Error fetching upcoming rounds:", error);
    setListRoundTest((prevList) => (append ? [...prevList] : []));
  } finally {
    setLoading(false);
  }

  return nextVisibleDoc;
};

// export const getDataUpcomingRound = async (
//   userData,
//   setListRoundTest,
//   setLoading,
//   lastVisibleDoc = null,
//   append = false
// ) => {
//   if (!userData) throw new Error("userData is required");

//   setLoading(true);

//   let nextVisibleDoc = lastVisibleDoc;
//   let filteredRound = [];
//   const loadedRoundIds = new Set();
//   const buffer = [];  // Buffer สำหรับสะสมข้อมูลที่ผ่านเงื่อนไข

//   try {
//     let fetchCount = 0;
//     const maxFetchCount = 5;

//     // ฟังก์ชันที่จะทำการดึงข้อมูลต่อเนื่องจนกว่าจะได้ 5 รายการ
//     const fetchRounds = async () => {
//       if (filteredRound.length >= 5) return; // ถ้าได้ข้อมูลครบแล้วไม่ต้องดึงเพิ่ม

//       const roundActivityQuery = query(
//         collection(firestore, "roundActivity"),
//         where("userList", "array-contains", userData.uid),
//         limit(5),
//         ...(nextVisibleDoc ? [startAfter(nextVisibleDoc)] : [])
//       );

//       const roundActivitySnapshot = await getDocs(roundActivityQuery);

//       if (roundActivitySnapshot.empty) return; // ถ้าไม่มีข้อมูลให้หยุด

//       const roundId = roundActivitySnapshot.docs.map((doc) => doc.data().roundId);

//       const batchQueries = [];
//       for (let i = 0; i < roundId.length; i += 10) {
//         const batch = roundId.slice(i, i + 10);
//         batchQueries.push(
//           getDocs(
//             query(
//               collection(firestore, "round"),
//               where("roundId", "in", batch),
//               where("teeTime", "==", "schedule")
//             )
//           )
//         );
//       }

//       const batchResults = await Promise.all(batchQueries);
//       const roundSnapshot = batchResults.flatMap((batch) => batch.docs);

//       const roundData = roundSnapshot
//         .map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }))
//         .filter((round) => round.status !== "deleted" && !loadedRoundIds.has(round.id));

//       roundData.forEach((round) => loadedRoundIds.add(round.id));

//       const roundsWithDetails = await addCourseAndHolesData(roundData);
//       const roundIsPlaying = await fetchUserDetails(roundsWithDetails);

//       const validRounds = roundIsPlaying.filter(
//         (round) =>
//           Array.isArray(round.userData) &&
//           round.userData.some(
//             (user) => user.uid === userData.uid && user.isJoin === true
//           )
//       );

//       buffer.push(...validRounds);  // เพิ่มข้อมูลที่ผ่านเงื่อนไขลงใน buffer

//       if (buffer.length < 5) {
//         nextVisibleDoc = roundActivitySnapshot.docs[roundActivitySnapshot.docs.length - 1];
//         await new Promise((resolve) => setTimeout(resolve, 1000)); // ใช้ setTimeout เพื่อป้องกัน rate limit
//         await fetchRounds(); // ทำการดึงข้อมูลเพิ่มหากยังไม่ครบ 5 ตัว
//       } else {
//         filteredRound = buffer.slice(0, 5);  // เก็บแค่ 5 รายการแรกที่ผ่านเงื่อนไข
//       }
//     };

//     await fetchRounds();  // เริ่มต้นการดึงข้อมูล

//     if (filteredRound.length === 0 && nextVisibleDoc === null) {
//       setListRoundTest([]); // ถ้าไม่มีข้อมูลเลย
//       setLoading(false);
//       return null;
//     }

//     const sortedRounds = filteredRound.sort((a, b) => (b.scheduleTime || 0) - (a.scheduleTime || 0));

//     setListRoundTest((prevList) => (append ? [...prevList, ...sortedRounds] : sortedRounds));
//   } catch (error) {
//     console.error("Error fetching upcoming rounds:", error);
//     setListRoundTest((prevList) => (append ? [...prevList] : []));
//   } finally {
//     setLoading(false);
//   }

//   return nextVisibleDoc;
// };

const fetchUserDetails = async (checkFinishedRound) => {
  const promises = checkFinishedRound.map(async (round) => {
    const userDetails = [];

    round.userList.forEach((slot) => {
      if (slot?.player?.uid) {
        userDetails.push({
          uid: slot.player.uid,
          isCreator: slot.player.isCreator || false,
          isJoin: slot.player.isJoin || false,
        });
      }
      if (slot?.caddie?.uid) {
        userDetails.push({
          uid: slot.caddie.uid,
          isCreator: slot.caddie.isCreator || false,
          isJoin: slot.caddie.isJoin || false,
        });
      }
    });

    // Remove duplicates based on UID
    const uniqueUserDetails = [
      ...new Map(userDetails.map((user) => [user.uid, user])).values(),
    ];

    const userDataPromises = uniqueUserDetails.map(async (userDetail) => {
      const userQuery = query(
        collection(firestore, "users"),
        where("uid", "==", userDetail.uid)
      );

      const userSnapshot = await getDocs(userQuery);

      if (!userSnapshot.empty) {
        const user = userSnapshot.docs[0].data();
        // Merge both isCreator, isJoin and the user data
        return { ...user, ...userDetail };
      }
      return null;
    });

    const userData = (await Promise.all(userDataPromises)).filter(
      (user) => user !== null
    );

    return { ...round, userData };
  });

  const roundsWithUserData = await Promise.all(promises);
  return roundsWithUserData;
};

const addCourseAndHolesData = async (rounds) => {
  const promises = rounds.map(async (round) => {
    let holesCourse = "";

    if (round?.holesCourse) {
      const courseHolesRef = doc(firestore, "courseHoles", round.holesCourse);
      const courseHolesSnapshot = await getDoc(courseHolesRef);
      holesCourse = courseHolesSnapshot.exists()
        ? courseHolesSnapshot.data().courseHoleName
        : "";
    }

    const courseSnapshot = await getDocs(
      query(collection(firestore, "courses"), where("id", "==", round.course))
    );

    const courseData = courseSnapshot.empty
      ? null
      : courseSnapshot.docs[0].data();

    const { time, day, month } = epochToDateTime(round.scheduleTime);

    return {
      ...round,
      holesCourse,
      courseInfo: courseData,
      time,
      day,
      month,
    };
  });

  return Promise.all(promises);
};
