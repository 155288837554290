import axios from "axios";
import { Dropdown } from "react-bootstrap";

import { UnFriend } from "../../../components/friend/UnFriend";
import { blockFriend } from "../../../components/friend/BlockFriend";
import { favFriend, unfavFriend, favFriendCaddie, unfavFriendCaddie } from "../../../components/friend/FavFriend";

import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Label from "../../createlabel/Label";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../../components/language/dropdown/textconvert";

const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN

export const FriendChatClick = async (userData, dooNineId) => {
    try {
        // Fetching group channels where the members exactly match userData.dooNineId and dooNineId
        const response = await axios.get(`https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels?members_exactly_in=${userData.dooNineId}&show_member=false&members_exactly_in=${dooNineId}`, {
            headers: {
                'Content-Type': 'application/json; charset=utf8',
                'Api-Token': sendBird_api_token
            }
        });

        // Check if any channels exist with the exact members
        if (response.data.channels.length > 0) {
            const channelUrl = response.data.channels[0].channel_url;
            return channelUrl;
        } else {
            // No existing channel, create a new one
            const response2 = await axios.post('https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels', {
                user_ids: [userData.dooNineId, dooNineId]
            }, {
                headers: {
                    'Content-Type': 'application/json; charset=utf8',
                    'Api-Token': sendBird_api_token
                }
            });

            const newChannelUrl = response2.data.channel_url;
            return newChannelUrl;
        }

    } catch (error) {
        console.log(error);
    }
};

export const FriendChatClickV2 = async (userData, dooNineId) => {

    try {

        const response = await axios.get(
            `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels?members_exactly_in=${userData.dooNineId}&show_member=false&members_exactly_in=${dooNineId}`,
            {
                headers: {
                    "Content-Type": "application/json; charset=utf8",
                    "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
                },
            }
        );

        if (response.data.channels.length > 0) {
            return response.data.channels
        } else {
            const response2 = await axios.post(
                "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels",
                {
                    user_ids: [userData.dooNineId, dooNineId],
                    is_distinct: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json; charset=utf8",
                        "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
                    },
                }
            );
            return [response2.data]

        }

    } catch (error) {
        return error
    }


    // try {
    //     // Fetching group channels where the members exactly match userData.dooNineId and dooNineId
    //     const response = await axios.get(`https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels?members_exactly_in=${userData.dooNineId}&show_member=false&members_exactly_in=${dooNineId}`, {
    //         headers: {
    //             'Content-Type': 'application/json; charset=utf8',
    //             'Api-Token': sendBird_api_token
    //         }
    //     });

    //     // Check if any channels exist with the exact members
    //     if (response.data.channels.length > 0) {
    //         const channelUrl = response.data.channels;
    //         return channelUrl;
    //     } else {
    //         // No existing channel, create a new one
    //         const response2 = await axios.post('https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels', {
    //             user_ids: [userData.dooNineId, dooNineId]
    //         }, {
    //             headers: {
    //                 'Content-Type': 'application/json; charset=utf8',
    //                 'Api-Token': sendBird_api_token
    //             }
    //         });

    //         const newChannelUrl = response2.data.channel_url;
    //         return newChannelUrl;
    //     }

    // } catch (error) {
    //     console.log(error);
    // }
};


export default function FriendProfileMoreBtn({ isFriend, userId, friendId, isUnFriendComplete, isFavorite, isCaddie, setTriggerGetLabel, userLabels, triggerGetLabel, memberList }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isFav, setIsFav] = useState(isFavorite || false);
    const [triggerFriendList, setTriggerFriendList] = useState(true);
    const [showLabelModal, setShowLabelModal] = useState(false);
    const [selectedUserLabel, setSelectedUserLabel] = useState(null);

    const handleUnfriend = useCallback(async (userId, friendId) => {
        if (userId && friendId) {
            const isUnFriend = await UnFriend(userId, friendId);
            if (isUnFriend) {
                isUnFriendComplete(isUnFriend)
            }
        }
    }, [isUnFriendComplete])


    const handleBlock = useCallback(async (userId, friendId) => {
        if (userId && friendId) {
            const isBlocked = await blockFriend(userId, friendId);
            if (isBlocked) {
                const isUnFriend = await UnFriend(userId, friendId);
                if (isUnFriend) {
                    handleFav(userId, friendId, 'unfav');
                    navigate('/home');
                }
            }
        }
    }, [])

    const handleFav = useCallback(async (userId, friendId, favType) => {
        if (userId && friendId) {
            if (favType === 'fav') {
                if (isCaddie) {
                    const isFav = await favFriendCaddie(userId, friendId);
                    if (isFav) {
                        setIsFav(true);
                    }
                } else {
                    const isFav = await favFriend(userId, friendId);
                    if (isFav) {
                        setIsFav(true);
                    }
                }
            } else {
                if (isCaddie) {
                    const isUnFav = await favFriendCaddie(userId, friendId);
                    if (isUnFav) {
                        setIsFav(false);
                    }
                } else {
                    const isUnFav = await favFriend(userId, friendId);
                    if (isUnFav) {
                        setIsFav(false);
                    }
                }

            }
        }
    }, [isCaddie])

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle className="p-0 m-0 bg-transparent border border-0">
                    <i className="ti-more-alt text-grey-900 btn-round-sm bg-greylight font-xsss pointer"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu className="border border-0 shadow-xss p-0" style={{ borderRadius: '15px', overflow: 'hidden' }}>
                    {memberList.map((value) => (
                        <Dropdown.Item key={value.uid} className="d-flex align-items-center" onClick={() => { setSelectedUserLabel(value); setShowLabelModal(true); }}>
                            <ReactSVG src="assets/images/dooNineIconV3/my-performance/profile-label.svg" wrapper="svg" width={30} height={30} />
                            <span className="fs-5 fw-bold">{t(textConvert('label'))}</span>
                        </Dropdown.Item>
                    ))}

                    {!isFav ?
                        <Dropdown.Item className="d-flex align-items-center" onClick={() => handleFav(userId, friendId, 'fav')}>
                            {/* <i className="bi bi-heart-fill me-2 fs-2"></i> */}
                            <ReactSVG src="assets/images/dooNineIconV3/my-performance/profile-unfav.svg" wrapper="svg" width={30} height={30} />
                            <span className="fs-5 fw-bold">{t(textConvert('Favorite'))}</span>
                        </Dropdown.Item>
                        :
                        <Dropdown.Item className="d-flex align-items-center" onClick={() => handleFav(userId, friendId, 'unfav')}>
                            {/* <i className="bi bi-heart me-2 fs-2"></i> */}
                            <ReactSVG src="assets/images/dooNineIconV3/my-performance/profile-fav.svg" wrapper="svg" width={30} height={30} />
                            <span className="fs-5 fw-bold">{t(textConvert('Favorite'))}</span>
                        </Dropdown.Item>}

                    <Dropdown.Divider />

                    <Dropdown.Item className="d-flex align-items-center" disabled={!isFriend ? true : false} onClick={() => handleUnfriend(userId, friendId)}>
                        {/* <i className="bi bi-person-dash me-2 fs-2"></i> */}
                        <ReactSVG src="assets/images/dooNineIconV3/my-performance/profile-unfriend.svg" wrapper="svg" width={30} height={30} />
                        <span className="fs-5 fw-bold">{t(textConvert('Unfriend'))}</span>
                    </Dropdown.Item>

                    <Dropdown.Item className="d-flex align-items-center" onClick={() => handleBlock(userId, friendId)}>
                        {/* <i className="bi bi-person-slash me-2 fs-2"></i> */}
                        <ReactSVG src="assets/images/dooNineIconV3/my-performance/profile-block.svg" wrapper="svg" width={30} height={30} />
                        <span className="fs-5 fw-bold">{t(textConvert('Block'))}</span>
                    </Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>

            <Label
                triggerFriendList={triggerFriendList}
                setTriggerFriendList={setTriggerFriendList}
                showLabelModal={showLabelModal}
                setShowLabelModal={setShowLabelModal}
                setSelectedUserLabel={setSelectedUserLabel}
                selectedUserLabel={selectedUserLabel}
                userLabels={userLabels}
                triggerGetLabel={triggerGetLabel}
                setTriggerGetLabel={setTriggerGetLabel}
            />

        </>
    )
}