import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import Picker from 'react-mobile-picker'
import { useScore } from '../ScoreContext/ScoreContext'
import { firestore } from '../../../components/firebase/FirebaseConfig'
import { doc, updateDoc } from 'firebase/firestore'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import { textConvert } from '../../../components/language/dropdown/textconvert'

const Scorebox = ({ selectedHole, isModalOpen, openModal, handleClose, holeData }) => {
    const { t } = useTranslation();
    const userData = useSelector((state) => state.user.data);
    const { courseHoles, score } = useScore();

    const header = ['Score', 'Putt', 'Fairway Hit', 'Bunker', 'Penalties']
    const scoreNum = Array.from({ length: 29 }, (_, i) => (i + 1).toString());

    const [pickerValue, setPickerValue] = useState({
        score: "-",
        putt: "-",
        fairwayHit: "-",
        bunkerHit: "-",
        penalties: "-",
    })

    const [scoreState, setScoreState] = useState({
        score: ["-", ...scoreNum],
        putt: ["-", "0"],
        fairwayHit: ["-", "↑", "↖", "↗"],
        bunkerHit: ["-", "Yes", "No"],
        penalties: ["-"],
    });
    const [driving, setDriving] = useState('')

    const prevScoreDataRef = useRef(null);

    useEffect(() => {
        if (score.length && userData) {
            const foundUser = score.find(item => (item?.userId === userData.uid) || (item?.caddieId === userData.uid));
            if (foundUser) {
                if (foundUser.status !== 'playing') {
                    handleClose();
                    return;
                }
            }
        }
    }, [score, userData])


    useEffect(() => {
        if (isModalOpen) {
            sessionStorage.setItem(`inputScore-${selectedHole?.holeNumber}`, JSON.stringify({ ...pickerValue, drivingRange: driving }));
        }
        return () => {
            sessionStorage.removeItem(`inputScore-${selectedHole?.holeNumber}`)
        }
    }, [isModalOpen, pickerValue, driving])


    function convertFairwayHit(fairway = "-") {
        switch (fairway) {
            case 'center':
                return "↑"
            case 'left':
                return "↖"
            case 'right':
                return "↗"

            case "↑":
                return 'center'
            case "↖":
                return 'left'
            case "↗":
                return 'right'

            default:
                return "-"
        }
    }
    const scoreChange = (e) => {
        let scoreData = e;
        const scoreNum = Number(scoreData.score);
        const puttNum = Number(scoreData.putt);

        // putt state calculation -------------------------------------------------
        let putt = ["-", "0"];

        if (!isNaN(scoreNum)) {
            // Create an array of strings from 1 to scoreNum - 1
            const puttNumArr = Array.from({ length: scoreNum - 1 }, (_, i) => (i + 1).toString());
            putt = ["-", "0", ...puttNumArr];

            // Check if the previous score exists and is a valid number
            if (prevScoreDataRef.current && !isNaN(Number(prevScoreDataRef.current.score))) {
                // If the current score is less than the previous score
                if (scoreNum < Number(prevScoreDataRef.current.score)) {
                    // Filter out non-numeric values and convert to numbers
                    const numericPutt = putt.filter(value => value !== "-").map(Number);
                    let maxPuttNum = prevScoreDataRef.current.putt;

                    // if putt is equal or more than score reset to max putt (score - 1)
                    if (Number(prevScoreDataRef.current.putt) >= scoreNum) {
                        maxPuttNum = numericPutt.length > 0 ? Math.max(...numericPutt).toString() : "-";
                    }
                    // Set putt to the maximum of the valid numeric values
                    scoreData = {
                        ...scoreData,
                        putt: maxPuttNum,
                    };
                }
            }
        } else {
            scoreData = {
                ...scoreData,
                putt: "-",
            };
            putt = ["-", "0"];
        }

        // Penalties state calculation -------------------------------------------------
        let penalties = ["-"];
        if (!isNaN(scoreNum) && !isNaN(puttNum)) {
            // Generate array of possible penalty numbers based on score and putt difference
            const penaltieNumArr = Array.from({ length: scoreNum - puttNum - 2 }, (_, i) => (i + 1).toString());

            if (penaltieNumArr.length > 0) {
                penalties = ["-", ...penaltieNumArr];
            }

            const penaltiesNum = Number(scoreData.penalties);

            if (!isNaN(penaltiesNum)) {

                if (prevScoreDataRef.current && !isNaN(Number(prevScoreDataRef.current.penalties))) {
                    let maxPenaltiesNum = prevScoreDataRef.current.penalties;

                    if (scoreNum - puttNum - 2 < Number(prevScoreDataRef.current.penalties)) {
                        const numericPenalties = penalties.filter(value => value !== "-").map(Number);
                        maxPenaltiesNum = numericPenalties.length > 0 ? Math.max(...numericPenalties).toString() : "-";

                        scoreData = {
                            ...scoreData,
                            penalties: maxPenaltiesNum,
                        };
                    }
                }
            }
        } else {
            scoreData = {
                ...scoreData,
                penalties: "-",
            };
            penalties = ["-"];
        }

        // console.log(scoreData);

        const updateScoreState = {
            ...scoreState,
            putt: putt,
            penalties: penalties
        }

        setPickerValue(scoreData);
        setScoreState(updateScoreState);

        prevScoreDataRef.current = scoreData;
    }

    useEffect(() => {
        const scoreTmp = JSON.parse(sessionStorage.getItem(`inputScore-${selectedHole?.holeNumber}`));

        if (selectedHole) {
            if (!scoreTmp) {
                scoreChange({
                    score: selectedHole.score === "-" ? holeData.par.toString() : selectedHole.score || "-",
                    putt: selectedHole.putts || "-",
                    fairwayHit: convertFairwayHit(selectedHole.fairwayHit),
                    bunkerHit: selectedHole.bunkerHit || "-",
                    penalties: selectedHole.penalties || "-",
                })
                setDriving(selectedHole.drivingRange || '')
            } else {
                scoreChange({
                    score: scoreTmp.score === "-" ? holeData.par.toString() : scoreTmp.score || "-",
                    putt: scoreTmp.putt || "-",
                    fairwayHit: scoreTmp.fairwayHit || "-",
                    bunkerHit: scoreTmp.bunkerHit || "-",
                    penalties: scoreTmp.penalties || "-",
                })
                setDriving(scoreTmp.drivingRange || '')
            }
        }
    }, [selectedHole])


    const clearScoreData = () => {
        setPickerValue({
            score: "-",
            putt: "-",
            fairwayHit: "-",
            bunkerHit: "-",
            penalties: "-",
        })
        setDriving('')
        prevScoreDataRef.current = null;
    }

    const submitScoreData = async () => {
        if (driving > 1000 || driving < 0) {
            Swal.fire({
                text: t(textConvert('Invalid driving range maximum is 1000 yd')),
                icon: 'error',
                confirmButtonColor: '#ee3d7f'
            })
            return;
        }
        const holeField = `hole${selectedHole.holeNumber}`;

        const updateData = {};

        updateData[`${holeField}.bunkerHit`] =
            pickerValue.bunkerHit === "NO"
                ? false
                : pickerValue.bunkerHit === "YES"
                    ? true
                    : pickerValue.bunkerHit;

        updateData[`${holeField}.fairwayHit`] = convertFairwayHit(pickerValue.fairwayHit);

        updateData[`${holeField}.drivingRange`] = driving.toString();
        // updateData[`${holeField}.fairwayHit`] = pickerValue.fairwayHit;
        updateData[`${holeField}.penalties`] = pickerValue.penalties;
        updateData[`${holeField}.putts`] = pickerValue.putt;
        updateData[`${holeField}.score`] = pickerValue.score;

        const ref = doc(firestore, "scoreCards", selectedHole.docId);
        await updateDoc(ref, updateData);
        handleClose();
    }

    return (
        <>
            {/* bug ok button not show modal after press */}
            <Modal show={isModalOpen} onHide={() => { handleClose(); }} centered animation={true} >
                <Modal.Header className='bg-light theme-light-bg'>
                    <h3 className='fw-600'>{courseHoles.courseHoleName}</h3>
                    <div className='d-flex align-items-center'>
                        <span className='fs-2 fw-600 ms-2 text-current mx-2'>{selectedHole?.holeNumber}</span>
                        <div className='d-flex flex-column justify-content-between align-items-center'>
                            <h5 className='fw-600'>{holeData.par}</h5>
                            <h5 className='fw-600'>{holeData.index}</h5>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='overflow-hidden'>
                    <div className='d-flex justify-content-between w-100'>
                        {header.map((header, idx) =>
                            <React.Fragment key={idx}>
                                {/* desktop */}
                                <h4 className='text-center fw-600 d-sm-block d-none' style={{ flex: 1 }}>{t(textConvert(header))}</h4>
                                {/* mobile */}
                                <h6 className='text-center fw-600 d-sm-none d-block' style={{ flex: 1 }}>{t(textConvert(header))}</h6>
                            </React.Fragment>
                        )}
                    </div>
                    <Picker value={pickerValue} onTouchMove={(e) => e.stopPropagation()} onChange={(e) => scoreChange(e)} wheelMode='normal' height={160}>
                        {Object.keys(scoreState).map(name => (
                            <Picker.Column key={name} name={name}>
                                {scoreState[name].map(option => (
                                    <Picker.Item key={option} value={option}>
                                        {(() => {
                                            // assume yes or no is bunker
                                            switch (option) {
                                                case 'Yes':
                                                    return <h5>{t(textConvert('bunker_yes'))}</h5>

                                                case 'No':
                                                    return <h5>{t(textConvert('bunker_no'))}</h5>

                                                default:
                                                    return <h5>{option}</h5>
                                            }
                                        })()}
                                    </Picker.Item>
                                ))}
                            </Picker.Column>
                        ))}
                    </Picker>

                    <div className='row'>
                        <label htmlFor="driving-input" className='col-3 my-auto'>
                            <h5 className='fw-600'>{t(textConvert('Driving'))}</h5>
                        </label>
                        <div className='col-6 p-0'>
                            <input type="number" max={1000} min={0}
                                className='form-control form-control-sm'
                                id='driving-input' placeholder='-' value={driving} onChange={(e) => setDriving(e.target.value)} />
                        </div>
                        <h5 className='fw-600 col-3 my-auto'>{t(textConvert('yd'))}</h5>
                    </div>
                </Modal.Body>
                <Modal.Footer className='justify-content-between'>
                    <button className='btn bg-light theme-light-bg p-3' onClick={() => clearScoreData()}>
                        <h4 className='fw-600'>{t(textConvert('Clear'))}</h4>
                    </button>
                    <div className='d-flex gap-2'>
                        {/* <button className='btn bg-current p-3'
                            // disabled={currentHole && Number(currentHole.holeNumber) >= courseHoles.holeDetails.length ? true : false}
                            disabled
                            onClick={() => { nextHole(); }}>
                            <h4 className='fw-600 text-white'>Next</h4>
                        </button> */}
                        <button className='btn bg-current p-3' onClick={() => submitScoreData()}>
                            <h4 className='fw-600 text-white'>{t(textConvert('OK'))}</h4>
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Scorebox