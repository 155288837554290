import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";

export async function getFavCaddie(userData, caddieIds) {
    if (!userData || !caddieIds.length) return [];

    try {
        const caddieQuery = query(
            collection(firestore, "favoriteFriends"),
            where("userId", "==", userData.uid),
            where("status", "==", "active"),
            where("caddieId", 'in', caddieIds)
        );

        const favCaddieSnapshot = await getDocs(caddieQuery);
        let favCaddieArr = []
        if (!favCaddieSnapshot.empty) {
            favCaddieArr = new Set(favCaddieSnapshot.docs.map(item => item.data().caddieId))
        }
        return [...favCaddieArr]
    } catch (error) {
        console.error('Failed to get favorite caddie', error);
        return []
    }
}

export async function getFavPlayer(userData, playerIds) {
    if (!userData || !playerIds.length) return [];

    try {
        const playerQuery = query(
            collection(firestore, "favoritePlayers"),
            where("userId", "==", userData.uid),
            where("status", "==", "active"),
            where("playerId", 'in', playerIds)
        );

        const favPlayerSnapshot = await getDocs(playerQuery);
        let favPlayerArr = []
        if (!favPlayerSnapshot.empty) {
            favPlayerArr = new Set(favPlayerSnapshot.docs.map(item => item.data().playerId))
        }
        return [...favPlayerArr];
    } catch (error) {
        console.error('Failed to get favorite player', error);
        return []
    }
}