import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  getDocs,
  onSnapshot,
  doc,
  getDoc,
} from "firebase/firestore";
import { firestore } from "../../../../../components/firebase/FirebaseConfig";
import { epochToDateTime } from "../../../component/wizard/module/covertTime";

// export const getDataRequestRound = async (
//   userData,
//   setListRoundTest,
//   setLoading,
//   lastVisibleDoc = null,
//   append = false
// ) => {
//   if (!userData) throw new Error("userData is required");

//   setLoading(true);

//   let nextVisibleDoc = lastVisibleDoc;
//   let filteredRoundStart = [];
//   const loadedRoundIds = new Set();

//   try {
//     let fetchCount = 0;
//     const maxFetchCount = 5;

//     while (filteredRoundStart.length < 5 && fetchCount < maxFetchCount) {
//       fetchCount++;

//       const roundActivityQuery = query(
//         collection(firestore, "roundActivity"),
//         where("userList", "array-contains", userData.uid),
//         where("creator", "!=", userData.uid),
//         orderBy("created", "desc"),
//         limit(5),
//         ...(nextVisibleDoc ? [startAfter(nextVisibleDoc)] : [])
//       );

//       const roundActivitySnapshot = await getDocs(roundActivityQuery);
//       if (roundActivitySnapshot.empty) break;

//       const roundId = roundActivitySnapshot.docs.map(
//         (doc) => doc.data().roundId
//       );

//       const batchQueries = [];
//       for (let i = 0; i < roundId.length; i += 10) {
//         const batch = roundId.slice(i, i + 10);
//         batchQueries.push(
//           getDocs(
//             query(
//               collection(firestore, "round"),
//               where("roundId", "in", batch),
//               orderBy("scheduleTime", "desc")
//             )
//           )
//         );
//       }

//       const batchResults = await Promise.all(batchQueries);
//       const roundSnapshot = batchResults.flatMap((batch) => batch.docs);

//       const roundData = roundSnapshot
//         .map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }))
//         .filter((round) => round.status !== "deleted")
//         .sort((a, b) => (b.scheduleTime || 0) - (a.scheduleTime || 0));

//       roundData.forEach((round) => loadedRoundIds.add(round.id));

//       const roundsWithDetails = await addCourseAndHolesData(roundData);
//       const roundsWithUsers = await fetchUserDetails(roundsWithDetails);

//       const filteredRoundIsPlaying = roundsWithUsers.filter((round) =>
//         round.userData.some(
//           (user) =>
//             user.uid === userData.uid &&
//             ((user.role === "player" && user.isJoin === false) ||
//               (user.role === "caddie" &&
//                 user.isJoin === false &&
//                 user.playerConfirm === true))
//         )
//       );

//       const checkRoundStart = await checkPlayingRounds(filteredRoundIsPlaying);

//       const validRounds = checkRoundStart.filter((user) => {
//         return user.userList.some((userItem) => {
//           const isPlayerMatch =
//             userItem.player?.uid === userData.uid &&
//             (userItem.player?.status === "playing" ||
//               userItem.player?.status === null);

//           const isCaddieMatch =
//             userItem.caddie?.uid === userData.uid &&
//             (userItem.caddie?.status === "playing" ||
//               userItem.caddie?.status === null);

//           return isPlayerMatch || isCaddieMatch;
//         });
//       });

//       const roundsRecent = await filterRecentRounds(validRounds);


//       filteredRoundStart = [...filteredRoundStart, ...roundsRecent];

//       nextVisibleDoc =
//         roundActivitySnapshot.docs[roundActivitySnapshot.docs.length - 1];

//       if (filteredRoundStart.length >= 5) break;
//     }

//     if (filteredRoundStart.length === 0 && nextVisibleDoc === null) {
//       setListRoundTest([]);
//       setLoading(false);
//       return null;
//     }

//     const sortedRounds = filteredRoundStart.sort(
//       (a, b) => (b.scheduleTime || 0) - (a.scheduleTime || 0)
//     );

//     setListRoundTest((prevList) =>
//       append ? [...prevList, ...sortedRounds] : sortedRounds
//     );
//   } catch (error) {
//     console.error("Error fetching request rounds:", error);
//     setListRoundTest((prevList) => (append ? [...prevList] : []));
//   } finally {
//     setLoading(false);
//   }

//   return nextVisibleDoc;
// };


// export const getDataRequestRound = (
//   userData,
//   setListRoundTest,
//   setLoading,
//   setLastVisibleDoc,
//   lastVisibleDoc = null,
//   append = false
// ) => {
//   if (!userData) throw new Error("userData is required");

//   setLoading(true);

//   const roundActivityQuery = query(
//     collection(firestore, "roundActivity"),
//     where("userList", "array-contains", userData.uid),
//     where("creator", "!=", userData.uid),
//     orderBy("created", "desc"),
//     limit(5),
//     ...(lastVisibleDoc ? [startAfter(lastVisibleDoc)] : [])
//   );

//   // ตั้งค่า Listener สำหรับ onSnapshot
//   const unsubscribe = onSnapshot(
//     roundActivityQuery,
//     async (snapshot) => {
//       if (snapshot.empty) {
//         setLoading(false);
//         return;
//       }

//       const roundIds = snapshot.docs.map((doc) => doc.data().roundId);
//       const batchQueries = [];
//       for (let i = 0; i < roundIds.length; i += 10) {
//         const batch = roundIds.slice(i, i + 10);
//         batchQueries.push(
//           getDocs(
//             query(
//               collection(firestore, "round"),
//               where("roundId", "in", batch),
//               orderBy("scheduleTime", "desc")
//             )
//           )
//         );
//       }

//       const batchResults = await Promise.all(batchQueries);
//       const roundSnapshot = batchResults.flatMap((batch) => batch.docs);

//       const roundData = roundSnapshot
//         .map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }))
//         .filter((round) => round.status !== "deleted")
//         .sort((a, b) => (b.scheduleTime || 0) - (a.scheduleTime || 0));

//       const roundsWithDetails = await addCourseAndHolesData(roundData);
//       const roundsWithUsers = await fetchUserDetails(roundsWithDetails);

//       const filteredRoundIsPlaying = roundsWithUsers.filter((round) =>
//         round.userData.some(
//           (user) =>
//             user.uid === userData.uid &&
//             ((user.role === "player" && user.isJoin === false) ||
//               (user.role === "caddie" &&
//                 user.isJoin === false &&
//                 user.playerConfirm === true))
//         )
//       );

//       const checkRoundStart = await checkPlayingRounds(filteredRoundIsPlaying);

//       const validRounds = checkRoundStart.filter((user) => {
//         return user.userList.some((userItem) => {
//           const isPlayerMatch =
//             userItem.player?.uid === userData.uid &&
//             (userItem.player?.status === "playing" ||
//               userItem.player?.status === null);

//           const isCaddieMatch =
//             userItem.caddie?.uid === userData.uid &&
//             (userItem.caddie?.status === "playing" ||
//               userItem.caddie?.status === null);

//           return isPlayerMatch || isCaddieMatch;
//         });
//       });

//       const roundsRecent = await filterRecentRounds(validRounds);

//       // อัปเดตรายการรอบ
//       setListRoundTest((prevList) =>
//         append ? [...prevList, ...roundsRecent] : roundsRecent
//       );

//       // อัปเดต lastVisibleDoc สำหรับ pagination
//       setLastVisibleDoc(snapshot.docs[snapshot.docs.length - 1]);

//       setLoading(false);
//     },
//     (error) => {
//       console.error("Error fetching request rounds:", error);
//       setLoading(false);
//     }
//   );

//   return unsubscribe;
// };

// loop ให้ครบ 5 รอบ
export const getDataRequestRound = (
  userData,
  setListRoundTest,
  setLoading,
  setLastVisibleDoc,
  lastVisibleDoc = null,
  append = false,
  pageSize = 5
) => {
  if (!userData) throw new Error("userData is required");

  setLoading(true);
  let roundsBuffer = [];
  let lastDoc = lastVisibleDoc;

  const fetchRounds = () => {
    const roundActivityQuery = query(
      collection(firestore, "roundActivity"),
      where("userList", "array-contains", userData.uid),
      where("creator", "!=", userData.uid),
      orderBy("created", "desc"),
      limit(pageSize),
      ...(lastDoc ? [startAfter(lastDoc)] : [])
    );

    // ✅ Return `unsubscribe`
    const unsubscribe = onSnapshot(roundActivityQuery, async (snapshot) => {
      if (snapshot.empty) {
        setLoading(false);
        return;
      }

      lastDoc = snapshot.docs[snapshot.docs.length - 1];

      const roundIds = snapshot.docs.map((doc) => doc.data().roundId);
      const batchQueries = [];
      for (let i = 0; i < roundIds.length; i += 10) {
        const batch = roundIds.slice(i, i + 10);
        batchQueries.push(
          getDocs(
            query(
              collection(firestore, "round"),
              where("roundId", "in", batch),
              orderBy("scheduleTime", "desc")
            )
          )
        );
      }

      const batchResults = await Promise.all(batchQueries);
      const roundSnapshot = batchResults.flatMap((batch) => batch.docs);

      const roundData = roundSnapshot
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((round) => round.status !== "deleted")
        .sort((a, b) => (b.scheduleTime || 0) - (a.scheduleTime || 0));

      const roundsWithDetails = await addCourseAndHolesData(roundData);
      const roundsWithUsers = await fetchUserDetails(roundsWithDetails);

      const filteredRoundIsPlaying = roundsWithUsers.filter((round) =>
        round.userData.some(
          (user) =>
            user.uid === userData.uid &&
            ((user.role === "player" && user.isJoin === false) ||
              (user.role === "caddie" &&
                user.isJoin === false &&
                user.playerConfirm === true))
        )
      );

      const checkRoundStart = await checkPlayingRounds(filteredRoundIsPlaying);

      const validRounds = checkRoundStart.filter((user) => {
        return user.userList.some((userItem) => {
          const isPlayerMatch =
            userItem.player?.uid === userData.uid &&
            (userItem.player?.status === "playing" ||
              userItem.player?.status === null);

          const isCaddieMatch =
            userItem.caddie?.uid === userData.uid &&
            (userItem.caddie?.status === "playing" ||
              userItem.caddie?.status === null);

          return isPlayerMatch || isCaddieMatch;
        });
      });

      const roundsRecent = await filterRecentRounds(validRounds);

      roundsBuffer = [...roundsBuffer, ...roundsRecent];

      setListRoundTest((prevList) =>
        append ? [...prevList, ...roundsBuffer] : roundsBuffer
      );

      setLastVisibleDoc(lastDoc);
      setLoading(false);
    });

    return unsubscribe; // ✅ Return unsubscribe
  };

  return fetchRounds();
};


const checkPlayingRounds = async (filteredRounds) => {
  const promises = filteredRounds.map(async (round) => {
    try {
      // อัปเดต userList โดยเพิ่ม status ของ player และ caddie
      const updatedUserList = await Promise.all(
        round.userList.map(async (user) => {
          const queries = [];
          let playerStatus = null;
          let caddieStatus = null;

          // สร้าง query สำหรับ caddie ถ้ามี caddieId
          if (user.caddie?.uid) {
            const caddieQuery = query(
              collection(firestore, "scoreCards"),
              where("caddieId", "==", user.caddie.uid),
              where("roundId", "==", round.roundId)
            );
            const caddieSnapshot = await getDocs(caddieQuery);
            caddieStatus =
              caddieSnapshot.docs.length > 0
                ? caddieSnapshot.docs[0].data().status
                : null;
          }

          // สร้าง query สำหรับ player ถ้ามี userId
          if (user.player?.uid) {
            const playerQuery = query(
              collection(firestore, "scoreCards"),
              where("userId", "==", user.player.uid),
              where("roundId", "==", round.roundId)
            );
            const playerSnapshot = await getDocs(playerQuery);
            playerStatus =
              playerSnapshot.docs.length > 0
                ? playerSnapshot.docs[0].data().status
                : null;
          }

          // ถ้าไม่มี status ของ player แต่มี caddieStatus ให้ใช้ caddieStatus
          if (playerStatus === null && caddieStatus !== null) {
            playerStatus = caddieStatus;
          }

          // อัปเดตข้อมูล user
          const updatedUser = {
            ...user,
            caddie: user.caddie
              ? {
                  ...user.caddie,
                  status: caddieStatus,
                }
              : null,
            player: user.player
              ? {
                  ...user.player,
                  status: playerStatus,
                }
              : null,
          };

          return updatedUser;
        })
      );

      // อัปเดต userList ใน round
      return { ...round, userList: updatedUserList };
    } catch (error) {
      console.error(`Error checking round ${round.roundId}:`, error);
      return round; // ส่ง round เดิมกลับถ้าเกิดข้อผิดพลาด
    }
  });

  const results = await Promise.all(promises);
  return results;
};

const fetchUserDetails = async (checkFinishedRound) => {
  const promises = checkFinishedRound.map(async (round) => {
    const userDetails = [];

    round.userList.forEach((slot) => {
      if (slot?.player?.uid) {
        userDetails.push({
          uid: slot.player.uid,
          isCreator: slot.player.isCreator || false,
          isJoin: slot.player.isJoin || false,
        });
      }
      if (slot?.caddie?.uid) {
        userDetails.push({
          uid: slot.caddie.uid,
          isCreator: slot.caddie.isCreator || false,
          isJoin: slot.caddie.isJoin || false,
          playerConfirm: slot.caddie.playerConfirm || false,
        });
      }
    });

    // Remove duplicates based on UID
    const uniqueUserDetails = [
      ...new Map(userDetails.map((user) => [user.uid, user])).values(),
    ];

    const userDataPromises = uniqueUserDetails.map(async (userDetail) => {
      const userQuery = query(
        collection(firestore, "users"),
        where("uid", "==", userDetail.uid)
      );

      const userSnapshot = await getDocs(userQuery);

      if (!userSnapshot.empty) {
        const user = userSnapshot.docs[0].data();
        // Merge both isCreator, isJoin and the user data
        return { ...user, ...userDetail };
      }
      return null;
    });

    const userData = (await Promise.all(userDataPromises)).filter(
      (user) => user !== null
    );

    return { ...round, userData };
  });

  const roundsWithUserData = await Promise.all(promises);
  return roundsWithUserData;
};

const addCourseAndHolesData = async (rounds) => {
  const promises = rounds.map(async (round) => {
    let holesCourse = "";

    if (round?.holesCourse) {
      const courseHolesRef = doc(firestore, "courseHoles", round.holesCourse);
      const courseHolesSnapshot = await getDoc(courseHolesRef);
      holesCourse = courseHolesSnapshot.exists()
        ? courseHolesSnapshot.data().courseHoleName
        : "";
    }

    const courseSnapshot = await getDocs(
      query(collection(firestore, "courses"), where("id", "==", round.course))
    );

    const courseData = courseSnapshot.empty
      ? null
      : courseSnapshot.docs[0].data();

    const { time, day, month } = epochToDateTime(round.scheduleTime);

    return {
      ...round,
      holesCourse,
      courseInfo: courseData,
      time,
      day,
      month,
    };
  });

  return Promise.all(promises);
};

function filterRecentRounds(rounds) {
  // เวลาปัจจุบันในหน่วยวินาที
  const now = Math.floor(Date.now() / 1000);

  // กรองข้อมูลที่ scheduleTime ยังไม่เกิน 24 ชั่วโมง
  return rounds.filter((round) => {
    const scheduleTime = round.scheduleTime;
    // เช็คว่าเวลาปัจจุบัน - scheduleTime น้อยกว่า 24 ชั่วโมง (86400 วินาที)
    return now - scheduleTime <= 86400;
  });
}
