import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useWizard } from '../wizardContext';
import { Card, Dropdown } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import CaddieModal from '../CaddieModal';
import PlayerModal from '../PlayerModal';
import { handleAddRow, handleRemoveRow, handleRemoveBoth, handleRemovePlayer, handleRemoveCaddie } from '../module/stepTwoDropdown';
import SwapCaddie from '../SwapCaddie';
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../../../components/language/dropdown/textconvert';

const EditStepTwoByCaddie = () => {
  const { t } = useTranslation();
  const { stepData, updateStepData } = useWizard();
  const data = stepData.stepTwo;
  const userData = useSelector((state) => state.user.data);
  const selectedCaddieIndex = useRef(null);
  const selectedPlayerIndex = useRef(null);
  const swapCaddieIndex = useRef(null);

  const inServiceCaddieRef = useRef(data.row.length > 0 ? data.row.map((user) => user?.caddie?.uid) : [])

  const selectedPlayerRef = useRef(data.row.length > 0 ? data.row.map((user) => user?.player?.uid) : [])

  // console.log(data);


  const [cardRow, setCardRow] = useState(data.row.length > 0 ? data.row : [{
    player: userData,
    caddie: null,
    row: 0
  }])

  const [selectCaddieModalOpen, setSelectCaddieModal] = useState(false);
  const [selectPlayerModalOpen, setSelectPlayerModalOpen] = useState(false);
  const [swapCaddieModal, setSwapCaddieModal] = useState(false);

  const handleOpenCaddieModal = (row) => {
    setSelectCaddieModal(true);
    selectedCaddieIndex.current = row;
  };
  const handleOpenPlayerModal = (row) => {
    setSelectPlayerModalOpen(true);
    selectedPlayerIndex.current = row;
  };
  const handleOpenSwapModal = (row) => {
    setSwapCaddieModal(true);
    swapCaddieIndex.current = row
  };


  const handleCloseCaddieModal = () => setSelectCaddieModal(false);
  const handleClosePlayereModal = () => setSelectPlayerModalOpen(false);
  const handleCloseSwapModal = () => setSwapCaddieModal(false);

  const handleSelectedCaddie = (caddie, rowNum = selectedCaddieIndex.current) => {
    inServiceCaddieRef.current.push(caddie.uid)
    setCardRow((prev) => {
      const updatedCardRow = prev.map((row) =>
        row.row === rowNum ? { ...row, caddie: caddie } : row
      );

      updateStepData('stepTwo', { row: updatedCardRow });

      return updatedCardRow;
    });
  };

  const handleSelectedPlayer = (player, rowNum = selectedPlayerIndex.current) => {
    selectedPlayerRef.current.push(player.uid)
    setCardRow((prev) => {
      const updatedCardRow = prev.map((row) =>
        row.row === rowNum ? { ...row, player: player } : row
      );

      updateStepData('stepTwo', { row: updatedCardRow });

      return updatedCardRow;
    });
  };

  const addRow = () => {
    const updatedData = handleAddRow(cardRow)
    setCardRow(updatedData);
    updateStepData('stepTwo', { row: updatedData })
  };

  const removeRow = (rowNum) => {
    const reIndexedRows = handleRemoveRow(cardRow, rowNum);
    setCardRow(reIndexedRows);

    selectedPlayerRef.current = reIndexedRows.map((user) => user?.player?.uid);
    inServiceCaddieRef.current = reIndexedRows.map((user) => user?.caddie?.uid);

    updateStepData('stepTwo', { row: reIndexedRows });

  };

  const removeBoth = (rowNum) => {
    const updatedCardRow = handleRemoveBoth(cardRow, rowNum)

    setCardRow(updatedCardRow)

    selectedPlayerRef.current = updatedCardRow.map((user) => user?.player?.uid);
    inServiceCaddieRef.current = updatedCardRow.map((user) => user?.caddie?.uid);

    updateStepData('stepTwo', { row: updatedCardRow });

  };

  const removePlayer = (rowNum) => {
    const updatedCardRow = handleRemovePlayer(cardRow, rowNum)

    setCardRow(updatedCardRow)

    selectedPlayerRef.current = updatedCardRow.map((user) => user?.player?.uid);
    inServiceCaddieRef.current = updatedCardRow.map((user) => user?.caddie?.uid);

    updateStepData('stepTwo', { row: updatedCardRow });

  };

  const removeCaddie = (rowNum) => {

    const updatedCardRow = handleRemoveCaddie(cardRow, rowNum)

    setCardRow(updatedCardRow)

    selectedPlayerRef.current = updatedCardRow.map((user) => user?.player?.uid);
    inServiceCaddieRef.current = updatedCardRow.map((user) => user?.caddie?.uid);

    updateStepData('stepTwo', { row: updatedCardRow });

  };



  return (
    <>
      <h4 className='fw-600 mb-1'>{t(textConvert('Players and caddies in this round'))} {`(${cardRow.length}/6)`}</h4>
      <Card className='mb-3 border-0 main-step-two-card'>
        {cardRow.map((row, index) =>
          <Card.Body key={index} className='main-step-two-card-body bg-white d-block d-sm-grid theme-dark-bg my-1'>

            {/* player */}
            {row.player ?
              <div className='player-container theme-light-bg d-flex align-items-center flex-wrap p-1'>
                <figure className='d-flex align-items-center mb-0'>
                  <img src={row?.player?.profileImage} alt="user" className='user-img player-color-outline' />
                  <h5 className='fw-600 ms-1'>{`${row?.player?.firstName} ${row?.player?.lastName}`}</h5>
                </figure>
              </div>
              :
              <>
                {index > 0 ?
                  <div className='player-container-none theme-light-bg'>
                    <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addPlayer_w.svg'
                      wrapper='svg'
                      width={50}
                      height={50}
                      className='pointer'
                      onClick={() => handleOpenPlayerModal(row.row)}
                    />
                  </div>
                  :
                  <div className='player-container-none theme-light-bg'>
                    <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addPlayer_p.svg'
                      wrapper='svg'
                      width={50}
                      height={50}
                      className='pointer'
                      onClick={() => handleOpenPlayerModal(row.row)}
                    />
                  </div>
                }
              </>
            }

            {/* caddie */}
            {row.caddie ?
              <>
                {index > 0 ?
                  <>
                    <div className='caddie-container theme-light-bg d-flex align-items-center flex-wrap p-1 position-relative'>
                      <figure className='d-flex align-items-center mb-0'>
                        <img src={row.caddie.profileImage} alt="user" className='user-img caddie-color-outline' />
                        <h5 className='fw-600 ms-1'>{`${row.caddie.firstName} ${row.caddie.lastName}`}</h5>
                      </figure>
                      <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-change.svg'
                        wrapper='svg'
                        width={30}
                        height={30}
                        className='position-absolute pointer' style={{ bottom: 0, right: 0 }}
                        onClick={() => handleOpenSwapModal(row.row)}
                      />
                    </div>
                  </>
                  :
                  <div className='caddie-container d-flex align-items-center flex-wrap p-1'>
                    <figure className='d-flex align-items-center mb-sm-0'>
                      <img src={row.caddie.profileImage} alt="user" className='user-img caddie-color-outline' />
                      <h5 className='fw-600 ms-1'>{`${row.caddie.firstName} ${row.caddie.lastName}`}
                        <span><h6 className='fw-600 text-muted'>{t(textConvert('Round Creator'))}</h6></span>
                      </h5>
                    </figure>
                  </div>
                }
              </>
              :
              <>
                {index > 0 ?
                  <div className='caddie-container-none theme-light-bg'>
                    <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addCaddie_w.svg'
                      wrapper='svg'
                      width={50}
                      height={50}
                      className='pointer'
                      onClick={() => handleOpenCaddieModal(row.row)}
                    />
                  </div>
                  :
                  <div className='caddie-container-none theme-light-bg'>
                    <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-addCaddie_p.svg'
                      wrapper='svg'
                      width={50}
                      height={50}
                      className='pointer'
                      onClick={() => handleOpenCaddieModal(row.row)}
                    />
                  </div>
                }
              </>
            }

            <Dropdown className='step-two-card-more-dropdown' >
              <Dropdown.Toggle variant='light'>
                <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-menu.svg' wrapper='svg' className='step-two-card-more-btn wizrd-more-btn' />
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ borderRadius: 15 }} className='theme-dark-bg'>
                <Dropdown.Item onClick={() => handleOpenSwapModal(row.row)}><h4 className='fw-600 p-2'>{t(textConvert('Swap Caddie'))}</h4></Dropdown.Item>
                <Dropdown.Item onClick={() => removeCaddie(row.row)} disabled={index > 0 ? false : true}>{!index > 0 ? (<span className='fw-600 p-2 text-muted'>{t(textConvert('Remove Caddie'))}</span>) : (<h4 className='fw-600 p-2'>{t(textConvert('Remove Caddie'))}</h4>)}</Dropdown.Item>
                <Dropdown.Item onClick={() => removePlayer(row.row)}><h4 className='fw-600 p-2'>{t(textConvert('Remove Player'))}</h4></Dropdown.Item>
                <Dropdown.Item onClick={() => removeBoth(row.row)} disabled={index > 0 ? false : true}>{!index > 0 ? (<span className='fw-600 p-2 text-muted'>{t(textConvert('Remove Both'))}</span>) : (<h4 className='fw-600 p-2'>{t(textConvert('Remove Both'))}</h4>)}</Dropdown.Item>
                <Dropdown.Item onClick={() => removeRow(row.row)} disabled={index > 0 ? false : true}>{!index > 0 ? (<span className='fw-600 p-2 text-muted'>{t(textConvert('Remove Slot'))}</span>) : (<h4 className='fw-600 p-2'>{t(textConvert('Remove Slot'))}</h4>)}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Card.Body>
        )}

        {cardRow.length === 6 ?
          null
          :
          <Card.Body className='bg-white d-flex align-items-center justify-content-center col-6 text-black-50 theme-light-bg pointer my-1'
            style={{ height: 80, fontSize: 30 }}
            onClick={() => addRow()}
          >
            <i className='bi bi-plus-circle'></i>
          </Card.Body>
        }
      </Card>

      <CaddieModal
        isOpen={selectCaddieModalOpen}
        handleClose={handleCloseCaddieModal}
        selectedCaddie={handleSelectedCaddie}
        inServiceCaddie={inServiceCaddieRef.current}
      />

      <PlayerModal
        isOpen={selectPlayerModalOpen}
        handleClose={handleClosePlayereModal}
        selectedPlayer={handleSelectedPlayer}
        inplayPlayer={selectedPlayerRef.current}
      />

      <SwapCaddie
        isOpen={swapCaddieModal}
        handleClose={handleCloseSwapModal}
        rowNum={swapCaddieIndex.current}
        data={data}
        updateStepData={updateStepData}
      />
    </>
  )
}

export default EditStepTwoByCaddie