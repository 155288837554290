import { Card } from "react-bootstrap";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { ReactSVG } from "react-svg";
import Dropdown from "react-bootstrap/Dropdown";
import clsx from "clsx";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import { deleteRoundForCreator } from "../module/deleteRound";
import { updateStartRound } from "../module/startRound";
import { Link, useNavigate } from "react-router-dom";
import { leaveRound } from "../module/leaveRound";

import { getDataStartRound } from "../startround/module/getData";
// import { getDataUpcomingRound } from "./module/getData";
import { getDataUpcomingRound } from "./module/getDataPaging";

import { ListGroup, Modal } from "react-bootstrap";
import ModalPlayerCaddie from "../PlayerCaddieModal";
import Load from '../../../../components/Load';
import { useTranslation } from "react-i18next";
import { textConvert } from "../../../../components/language/dropdown/textconvert";

function UpcomingRound() {
  const { t, i18n } = useTranslation();
  const [listRoundStart, setListRoundStart] = useState([]);
  const [loading, setLoading] = useState(true);
  const userData = useSelector((state) => state.user.data);
  const maxUserList = { desktop: 7, mobile: 5 };
  const [listRoundTest, setListRoundTest] = useState([]);
  const [modalPlayerCaddie, setModalPlayerCaddie] = useState(false);
  const [listUserData, setListUserData] = useState([]);

  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [noMoreData, setNoMoreData] = useState(false);

  // useEffect(() => {
  //   if (!userData) return;

  //   // เรียกข้อมูลเมื่อ component mount
  //   getDataUpcomingRound(userData, setListRoundTest, setLoading).then(
  //     (lastDoc) => setLastVisibleDoc(lastDoc)
  //   );
  //   getDataStartRound(userData, setListRoundStart, setLoading);
  // }, [userData]);

  // const handleLoadMore = () => {
  //   if (!lastVisibleDoc || loading || noMoreData) return; // ถ้า noMoreData เป็น true ให้หยุด

  //   setLoading(true);

  //   getDataUpcomingRound(
  //     userData,
  //     setListRoundTest,
  //     setLoading,
  //     lastVisibleDoc,
  //     true
  //   )
  //     .then((lastDoc) => {
  //       if (lastDoc) {
  //         setLastVisibleDoc(lastDoc);
  //       } else {
  //         setLastVisibleDoc(null);
  //         setNoMoreData(true); // ไม่มีข้อมูลเพิ่มเติม
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error loading more rounds:", error);
  //       setLoading(false);
  //     });
  // };

  // const handleScroll = debounce(() => {
  //   const isBottom =
  //     window.innerHeight + document.documentElement.scrollTop >=
  //     document.documentElement.scrollHeight - 200;

  //   if (isBottom && !loading) {
  //     handleLoadMore();
  //   }
  // }, 300);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [loading, lastVisibleDoc]);

  const handleLoadMore = useCallback(() => {
    if (loading || noMoreData || !userData) return;

    setLoading(true);

    getDataUpcomingRound(userData, setListRoundTest, setLoading, lastVisibleDoc, true)
      .then((lastDoc) => {
        if (lastDoc) {
          setLastVisibleDoc(lastDoc);
        } else {
          setNoMoreData(true);
        }
      })
      .catch((error) => {
        console.error("Error loading more rounds:", error);
        setLoading(false);
      });
  }, [loading, noMoreData, lastVisibleDoc, userData]);

  const handleScroll = useCallback(
    debounce(() => {
      if (loading || noMoreData) return;

      const scrollPosition = window.scrollY + window.innerHeight;
      const documentHeight = document.documentElement.offsetHeight;

      if (scrollPosition >= documentHeight - 200) {
        handleLoadMore();
      }
    }, 200),
    [loading, noMoreData]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (!userData) return;

    setLoading(true);

    getDataUpcomingRound(userData, setListRoundTest, setLoading)
      .then((lastDoc) => setLastVisibleDoc(lastDoc))
      .catch(() => setLoading(false));
  }, [userData]);

  const navigate = useNavigate();

  const handleDeleteRound = async (roundId) => {
    try {
      await deleteRoundForCreator(roundId);
    } catch (error) {
      console.error("Error deleted round:", error);
    }
  };

  const handleUpdateStartRound = async (roundId) => {
    try {
      if (listRoundStart.length > 0) {
        Swal.fire({
          title: "Round In Progress",
          text: "You still have a round in progress. Please finish that round first.",
          icon: "warning",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });
      } else {
        await updateStartRound(roundId);
      }
    } catch (error) {
      console.error("Error denying round:", error);
    }
  };

  const handleLinkToCourse = (courseId) => {
    navigate(`/course-detail?courseId=${courseId}`);
  };

  const isSameDay = (timestamp) => {
    const scheduledDate = new Date(timestamp * 1000); // Convert to milliseconds
    const currentDate = new Date();

    return (
      scheduledDate.getFullYear() < currentDate.getFullYear() ||
      (scheduledDate.getFullYear() === currentDate.getFullYear() &&
        (scheduledDate.getMonth() < currentDate.getMonth() ||
          (scheduledDate.getMonth() === currentDate.getMonth() &&
            scheduledDate.getDate() <= currentDate.getDate())))
    );
  };

  const handleChat = async (channelUrl) => {
    navigate("/chat-room?channelId=" + channelUrl);
  };

  const handleLeaveRound = async (roundId, userId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to leave this round",
        icon: "warning",
        confirmButtonColor: "#ee3c7f",
        confirmButtonText: "OK",
        showCancelButton: true, // เพิ่มปุ่ม Cancel เพื่อให้ผู้ใช้เลือกยกเลิกได้
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        await leaveRound(roundId, userId);
      }
    } catch (error) {
      console.error("Error denying round:", error);
    }
  };

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  const handleOpenPlayerCaddieModal = (userData) => {
    setModalPlayerCaddie(true);
    setListUserData(userData);
  };

  const handleClosePlayerCaddieModal = () => setModalPlayerCaddie(false);

  // console.log(listRoundTest);
  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl w-100">
        <Card.Body
          className="main-player-stat"
          style={{ padding: "0.5rem 0.5rem" }}
        >
          <>
            {listRoundTest.length > 0 ? (
              listRoundTest.map((round, index) => (
                <div
                  key={index}
                  className="create-round-req-main"
                  style={{ border: "1px solid #e1e1f0 " }}
                >
                  <section className="create-round-req-head">
                    <div className="pointer">
                      {round.userData.some(
                        (user) =>
                          user.uid === userData.uid && user.isCreator === true
                      ) ? (
                        <a href={`/create-round?edit=${round.roundId}`}>
                          <h4 className="fw-600 mb-1">{round.roundName}</h4>
                          <h6 className="fw-600 text-muted">
                            {`${round.holes} (${round.holesCourse}), ${round.gameMode} (${round.scoring})`}
                          </h6>
                        </a>
                      ) : (
                        <div>
                          <h4 className="fw-600 mb-1">{round.roundName}</h4>
                          <h6 className="fw-600 text-muted">
                            {`${round.holes} (${round.holesCourse}), ${round.gameMode} (${round.scoring})`}
                          </h6>
                        </div>
                      )}
                    </div>

                    <div className="d-flex flex-wrap-reverse justify-content-end align-items-end">
                      <ReactSVG
                        src="assets/images/dooNineIconV2/chat-SL.svg"
                        wrapper="svg"
                        style={{ height: 40, width: 40 }}
                        className={clsx("pointer", {
                          "d-none": round.groupChat !== "create",
                        })}
                        onClick={() => handleChat(round.channelUrl)}
                      />
                      <Dropdown className="drodownFriend" drop="down">
                        <Dropdown.Toggle
                          as="span" // Use a span element for the wrapper
                          id="dropdown-basic"
                          style={{ cursor: "pointer", zIndex: 1000 }}
                        >
                          <ReactSVG
                            src="assets/images/dooNineIconV3/create-round/createRound-menu.svg"
                            wrapper="svg"
                            className="wizrd-more-btn"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu as={CustomDropdown}>
                          <Dropdown.Item
                            disabled={
                              !isSameDay(round.scheduleTime) || // Check if it's the same day
                              !round.userData.some(
                                (user) =>
                                  user.uid === userData.uid &&
                                  user.isCreator === true // Check if the user can start the round
                              )
                            }
                            onClick={() =>
                              handleUpdateStartRound(round.roundId, userData)
                            }
                          >
                            {t(textConvert('Start Round'))}
                          </Dropdown.Item>
                          <Dropdown.Item href="#/leader-board" disabled>
                            {t(textConvert('Leader Board'))}
                          </Dropdown.Item>
                          <Dropdown.Item href="#/game-mode" disabled>
                            {t(textConvert('Game mode'))}
                          </Dropdown.Item>
                          <Dropdown.Item href="#/competitor" disabled>
                            {t(textConvert('Competitor'))}
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            href={`/p-and-c-round?round=${round?.roundId}`}
                          >
                            {t(textConvert('Player and Caddie'))}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleLinkToCourse(round.course)}
                          >
                            {t(textConvert('Course'))}
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            disabled={
                              !round.userData.some(
                                (user) =>
                                  user.uid === userData.uid &&
                                  user.isCreator === true
                              )
                            }
                            href={`/create-round?edit=${round.roundId}`}
                          >
                            {t(textConvert('Edit Round'))}
                          </Dropdown.Item>

                          {/* Invitation Link */}
                          <Dropdown.Item
                            // disabled={
                            //   !round.userData.some(
                            //     (user) =>
                            //       user.uid === userData.uid &&
                            //       user.isCreator === true
                            //   )
                            // }
                            disabled
                          >
                            {t(textConvert('Invitation Link'))}
                          </Dropdown.Item>

                          {round.userData.some(
                            (user) =>
                              user.uid === userData.uid &&
                              user.isCreator === true
                          ) ? (
                            <Dropdown.Item
                              onClick={() => handleDeleteRound(round.roundId)}
                            >
                              {t(textConvert('Delete Round'))}
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              onClick={() =>
                                handleLeaveRound(round.roundId, userData.uid)
                              }
                            >
                              {t(textConvert('Leave Round'))}
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </section>
                  <section
                    className="create-round-req-body p-1 pointer"
                    //   onClick={() => handleChatandDateClick()}
                    style={{
                      backgroundImage: round.coverImage
                        ? typeof round.coverImage === "string"
                          ? `url(${round.coverImage})` // ถ้าเป็น URL ให้ใช้โดยตรง
                          : `url(${URL.createObjectURL(round.coverImage)})` // ถ้าเป็นไฟล์ ให้ใช้ createObjectURL
                        : "none",
                      height: round.coverImage ? "" : "inherit",
                    }}
                  >
                    <div
                      className="req-course-data px-0 row"
                      style={{
                        backgroundColor: round.coverImage ? "" : "#fff",
                      }}
                    >
                      <div
                        className="col-sm-2 col-3 pointer p-0 d-flex justify-content-center align-items-center"
                        onClick={() => handleLinkToCourse(round.course)}
                      >
                        <img
                          src={round.courseInfo.profileImage}
                          alt="course-profile"
                          width={70}
                          height={70}
                          className="rounded-circle my-2 d-sm-block d-none"
                        />
                        {/* mobile */}
                        <img
                          src={round.courseInfo.profileImage}
                          alt="course-profile"
                          width={50}
                          height={50}
                          className="rounded-circle my-2 d-sm-none d-block"
                        />
                      </div>
                      <div
                        className="col-sm-8 col-6 pointer p-0 d-flex justify-content-center align-items-center"
                        onClick={() => handleLinkToCourse(round.course)}
                      >
                        <div>
                          {/* desktop */}
                          <span className="fw-600 fs-5 d-sm-block d-none">
                            {i18n.language === 'th' ?
                              `${round.courseInfo?.courseNameThai}`
                              :
                              `${round.courseInfo?.courseName}`
                            }
                          </span>
                          {/* mobile */}
                          <span className="fw-600 fs-6 d-sm-none d-block">
                            {i18n.language === 'th' ?
                              `${round.courseInfo?.courseNameThai}`
                              :
                              `${round.courseInfo?.courseName}`
                            }
                          </span>
                          {i18n.language === 'th' ?
                            <>
                              {/* mobile */}
                              <span span className="fw-600 fs-6 d-sm-none d-block text-muted">{`${round.courseInfo.location.province}`}</span>
                              {/* desktop */}
                              <span className="fw-600 fs-5 d-sm-block d-none text-muted">{`${round.courseInfo.location.province}`}</span>
                            </>
                            :
                            <>
                              {/* mobile */}
                              <span className="fw-600 fs-6 d-sm-none d-block text-muted">{`${round.courseInfo.location.provinceEng}`}</span>
                              {/* desktop */}
                              <span className="fw-600 fs-5 d-sm-block d-none text-muted">{`${round.courseInfo.location.provinceEng}`}</span>
                            </>
                          }
                        </div>
                      </div>
                      <div className="col-sm-2 col-3 px-sm-20">
                        <div
                          className="pointer rounded-3 h-100 d-flex justify-content-center align-items-center flex-column"
                          style={{ backgroundColor: "#E59E49" }}
                        // onClick={() => handleChatandDateClick()}
                        >
                          {round.teeTime === "schedule" ? (
                            <>
                              <h5 className="fw-600 text-white text-center">
                                {round.month}
                              </h5>
                              <h5 className="fw-700 text-white text-center">
                                {round.day}
                              </h5>
                              <div className="bg-white text-center w-100">
                                <span
                                  className="fw-600 fs-6"
                                  style={{ color: "#E59E49" }}
                                >
                                  {round.time}
                                </span>
                              </div>
                            </>
                          ) : (
                            <div className="bg-white text-center w-100">
                              <span
                                className="fw-600 fs-5"
                                style={{ color: "#E59E49" }}
                              >

                                {t(textConvert('Start Now'))}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>

                  <section>
                    {/* desktop display 7 limit */}
                    <div
                      className="d-none d-sm-flex align-items-center justify-content-between pointer"
                      style={{ padding: "0.6rem 1rem 0.7rem" }}
                    >
                      {/* Left side: User profile images */}
                      <div className="d-flex align-items-center">
                        {round.userData
                          .slice(0, maxUserList.desktop)
                          .map((user, idx) =>
                            user.isCreator ? (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={50}
                                    height={50}
                                    className="rounded-circle player-color-outline me-4"
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={50}
                                    height={50}
                                    className="rounded-circle caddie-color-outline me-4"
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={50}
                                    height={50}
                                    className="rounded-circle player-color-outline"
                                    style={{
                                      marginLeft: "0px",
                                      zIndex: maxUserList.desktop - idx,
                                    }}
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={50}
                                    height={50}
                                    className="rounded-circle caddie-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.desktop - idx,
                                    }}
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                        {round.userData.length > maxUserList.desktop ? (
                          <div
                            className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                            style={{
                              width: 50,
                              height: 50,
                              opacity: 0.6,
                            }}
                          >
                            <h4
                              className="text-center"
                              onClick={() =>
                                handleOpenPlayerCaddieModal(round.userData)
                              }
                            >
                              +{round.userData.length - maxUserList.desktop}
                            </h4>
                          </div>
                        ) : null}
                      </div>

                      {/* Right side: Button */}
                      <div>
                        {isSameDay(round.scheduleTime) && (
                          <button
                            className={clsx(
                              "p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0",
                              {
                                "bg-current text-white":
                                  round.creator === userData.uid, // Active button style
                                "bg-light text-muted":
                                  round.creator !== userData.uid, // Disabled button style
                              }
                            )}
                            onClick={() =>
                              handleUpdateStartRound(round.roundId, userData)
                            }
                            disabled={round.creator !== userData.uid} // Disable button if user is not the creator
                          >
                            {t(textConvert('Start'))}
                          </button>
                        )}
                      </div>
                    </div>

                    {/* mobile display 5 limit */}
                    <div
                      className="d-sm-none d-flex align-items-center justify-content-between pointer"
                      style={{ padding: "0.5rem 1rem 0.6rem" }}
                    >
                      {/* Left side: User profile images */}
                      <div className="d-flex align-items-center">
                        {round.userData
                          .slice(0, maxUserList.mobile)
                          .map((user, idx) =>
                            user.isCreator ? (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={40}
                                    height={40}
                                    className="rounded-circle player-color-outline me-4"
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={40}
                                    height={40}
                                    className="rounded-circle caddie-color-outline me-4"
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                )}
                              </React.Fragment>
                            ) : (
                              <React.Fragment key={idx}>
                                {user.role === "player" ? (
                                  <img
                                    src={user.profileImage}
                                    alt="player"
                                    width={40}
                                    height={40}
                                    className="rounded-circle player-color-outline"
                                    style={{
                                      marginLeft: "0px",
                                      zIndex: maxUserList.mobile - idx,
                                    }}
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                ) : (
                                  <img
                                    src={user.profileImage}
                                    alt="caddie"
                                    width={40}
                                    height={40}
                                    className="rounded-circle caddie-color-outline"
                                    style={{
                                      marginLeft: "-10px",
                                      zIndex: maxUserList.mobile - idx,
                                    }}
                                    onClick={() =>
                                      handleProfileClick(user?.uid)
                                    }
                                  />
                                )}
                              </React.Fragment>
                            )
                          )}
                        {round.userData.length > maxUserList.mobile ? (
                          <div
                            className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                            style={{
                              width: 40,
                              height: 40,
                            }}
                          >
                            <h4
                              className="text-center"
                              onClick={() =>
                                handleOpenPlayerCaddieModal(round.userData)
                              }
                            >
                              +{round.userData.length - maxUserList.mobile}
                            </h4>
                          </div>
                        ) : null}
                      </div>

                      {/* Right side: Button */}
                      <div>
                        {isSameDay(round.scheduleTime) && (
                          <button
                            className={clsx(
                              "p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0",
                              {
                                "bg-current text-white":
                                  round.creator === userData.uid, // Active button style
                                "bg-light text-muted":
                                  round.creator !== userData.uid, // Disabled button style
                              }
                            )}
                            onClick={() =>
                              handleUpdateStartRound(round.roundId, userData)
                            }
                            disabled={round.creator !== userData.uid} // Disable button if user is not the creator
                          >
                            {t(textConvert('Start'))}
                          </button>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              ))
            ) : !loading ? (
              <h5 className="text-center p-2">{t(textConvert('No rounds upcoming available'))}</h5>
            ) : null}

            {/* แสดง Spinner โหลดข้อมูลด้านล่าง */}
            {loading && (
              <div className="d-flex justify-content-center">
                <Load />
              </div>
            )}
            {/* กรณีไม่มีข้อมูลเพิ่มเติม */}
            {/* {!lastVisibleDoc && (
              <div className="d-flex justify-content-center mt-3">
                <p>No more data available</p>
              </div>
            )} */}
          </>
        </Card.Body>
      </Card>

      <ModalPlayerCaddie
        isOpen={modalPlayerCaddie}
        handleClose={handleClosePlayerCaddieModal}
        listUserData={listUserData}
      />
    </>
  );
}
export default UpcomingRound;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}

function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
}
