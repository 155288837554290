
export function textConvert(input) {
    if (input === undefined) return '';

    if (typeof input !== 'string') {
        console.error('Argument must be string only :', typeof input);
        return '';
    } else {
        return input.toLowerCase().replace(/[ \-]/g, '_');
    }
}

export function extractMonth(dateString) {
    const [month] = dateString.split(' '); // Split the string by space and take the first part
    return month.toLowerCase(); // Return the month in lowercase
}

export function extractYear(dateString, lang) {
    const [, year] = dateString.split(' '); // Split the string and take the second part
    if (lang === 'th') {
        return (parseInt(year) + 543).toString(); // Convert to Thai Buddhist year
    } else {
        return year; // Return the Gregorian year
    }
}

export function extractClubNames(clubs) {
    if (!clubs) return clubs;
    return clubs.replace(/[\d.°]+/g, '').trim();
}

export function formatClubName(club, translatedClub) {
    const clubname = club.replace(/[\d.°]+/g, '').trim(); // Extract the club name
    const match = club.match(/[\d.°]+/g); // Extract the number or degree

    if (match) {
        const numberPart = match.join(' ');
        switch (clubname) {
            case 'Driver':
            case 'Pitching Wedge':
            case 'Sand Wedge':
            case 'Lob Wedge':
                return `${translatedClub} ${numberPart}`;

            case 'Iron':
            case 'Wood':
            case 'Hybrid':
                return `${numberPart} ${translatedClub}`;

            default:
                return `${club}`;

        }
    }

    return club;
}

export function holeConvert(hole) {
    if (hole === undefined) return '';

    switch (hole) {
        case '9hole':
        case '9holes':
        case '9 holes':
        case '9 Holes':
        case '9 Hole':
        case '9 hole':

            return 'nine_holes';

        case '18hole':
        case '18holes':
        case '18 holes':
        case '18 Holes':
        case '18 Hole':
        case '18 hole':

            return 'eighteen_holes';

        default:
            console.error('wrong hole name');
            break;
    }
}

export function convertScoring(scoring = '') {
    switch (scoring) {
        case 'Gross-S36':
            return 'gross_s_thirtysix';
        case 'To Par-S36':
            return 'to_par_s_thirtysix';

        default:
            return scoring;
    }
}