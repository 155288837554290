import { stablefordCalculation, system36Calculation } from "../../component/scoreInput/scoreCalculation";

export function mapUser(mappedScore, userList) {
    // Early return if mappedScore is empty
    if (!mappedScore.length) {
        return userList.length ? userList : [];
    }

    const userArr = mappedScore[0]?.scores;

    // Create a Map for quick lookup of userId to player
    const playerMap = new Map();
    const nullPlayers = []; // To store users with null player

    // Organize userList by uid for quick lookup and separate null player entries
    userList.forEach(item => {
        if (item.player?.uid != null) {
            playerMap.set(item.player.uid, item); // Store player by uid
        } else {
            nullPlayers.push(item); // Store items with null player separately
        }
    });

    const sortedUser = [];

    userArr.forEach(userScore => {
        if (userScore.userId != null) {
            // If userId is non-null, find the player in the playerMap
            const foundPlayer = playerMap.get(userScore.userId);
            if (foundPlayer) {
                sortedUser.push(foundPlayer);
            }
        } else {
            // If userId is null, take one from nullPlayers list (if available)
            const foundNull = nullPlayers.shift();
            if (foundNull) {
                sortedUser.push(foundNull);
            }
        }
    });

    return sortedUser;
}

export function mapScores(courseDet, score, userList, userId) {
    // Deep copy of courseDet to prevent mutations
    const courseDetailsCopy = courseDet ? JSON.parse(JSON.stringify(courseDet.sort((a, b) => a.holeNumber - b.holeNumber))) : [];

    // Create a variable to hold the mapped results
    const mappedScores = courseDetailsCopy.map(hole => {
        const holeIndex = hole.index; // Hole difficulty index from courseDet

        // Map over userList to generate scores and handicap strokes for each hole
        const scores = userList.map(user => {
            const userIdValue = user.player?.uid || null;
            const caddieIdValue = user.caddie?.uid || null;
            const userScore = score.find(u => u.userId === userIdValue);
            const handicap = user?.player?.handicap || 0; // Get user's handicap, default to 0 if not present

            // Calculate how many strokes this user gets for this hole based on the index
            const handicapStrokes = handicap >= holeIndex
                ? Math.floor(handicap / courseDet.length) + (handicap % courseDet.length >= holeIndex ? 1 : 0)
                : 0;

            return {
                holeDetail: hole,
                holeScore: userScore ? {
                    ...userScore[`hole${hole.holeNumber}`],
                    docId: userScore.docId,
                    handicapStrokes: courseDet.length > 9 ? handicapStrokes : handicapStrokes > 3 ? 3 : handicapStrokes, // Add field for how many strokes user gets for this hole
                } : null,
                userId: userIdValue || null,
                caddieId: caddieIdValue || (userScore ? userScore.caddieId : null),
                rival: user?.player?.rival || null
            };
        });

        // Filter out entries where holeScore is null
        const scoresWithUserScores = scores.filter(score => score.holeScore !== null);

        // Find the lowest score (ignoring handicap for now)
        let minScore = Infinity;

        scoresWithUserScores.forEach(score => {
            const scoreValue = parseFloat(score.holeScore?.score);
            if (!isNaN(scoreValue) && scoreValue < minScore) {
                minScore = scoreValue;
            }
        });

        // Count how many users have the minimum score
        const countMinScoreUsers = scoresWithUserScores.filter(score => parseFloat(score.holeScore?.score) === minScore).length;

        // Compare each user score and add a `winner` field in holeScore
        scoresWithUserScores.forEach((scoreA, indexA) => {
            const scoreAValue = parseFloat(scoreA.holeScore?.score);

            // Initialize comparison result for each user
            scoreA.holeScore.comparisonResult = [];
            scoreA.holeScore.winner = false; // Default value for winner

            scoresWithUserScores.forEach((scoreB, indexB) => {
                if (indexA === indexB) return; // Skip self-comparison

                const scoreBValue = parseFloat(scoreB.holeScore?.score);

                if (scoreAValue < scoreBValue) {
                    scoreA.holeScore.comparisonResult.push({
                        comparedTo: scoreB.userId,
                        result: "lower"
                    });
                } else if (scoreAValue > scoreBValue) {
                    scoreA.holeScore.comparisonResult.push({
                        comparedTo: scoreB.userId,
                        result: "higher"
                    });
                } else {
                    scoreA.holeScore.comparisonResult.push({
                        comparedTo: scoreB.userId,
                        result: "equal"
                    });
                }
            });

            // Set the winner field if the score matches the minScore and only one user has it
            if (scoreAValue === minScore && countMinScoreUsers === 1) {
                scoreA.holeScore.winner = true; // Mark as winner if it matches the min score and is unique
            }
        });

        // Sort the scores array to have the matched userId or caddieId first
        scores.sort((a, b) => {
            const aMatches = a.userId === userId || a.caddieId === userId;
            const bMatches = b.userId === userId || b.caddieId === userId;

            // Handle null userId by moving it to the end
            if (a.userId === null) return 1; // a goes last
            if (b.userId === null) return -1; // b goes last

            // First priority: User's score comes first
            if (aMatches && !bMatches) {
                return -1; // a comes first
            } else if (!aMatches && bMatches) {
                return 1; // b comes first
            }

            // Second priority: Scores where userId matches a rival
            const userScore = scores.find(score => score.userId === userId);
            const aIsRival = a.userId === userScore?.rival;
            const bIsRival = b.userId === userScore?.rival;

            if (aIsRival && !bIsRival) {
                return -1; // a comes first
            } else if (!aIsRival && bIsRival) {
                return 1; // b comes first
            }

            // No preference, keep original order
            return 0;
        });

        // Return the hole detail along with the sorted scores
        return {
            holeDetail: hole,
            scores: scores
        };
    });

    return mappedScores;
}

export function mapScoresWHS(courseDet, score, userList, userId) {
    // Deep copy of courseDet to prevent mutations
    const courseDetailsCopy = courseDet ? JSON.parse(JSON.stringify(courseDet.sort((a, b) => a.holeNumber - b.holeNumber))) : [];

    // Create a variable to hold the mapped results
    const mappedScores = courseDetailsCopy.map(hole => {

        const scores = userList.map(user => {
            const userIdValue = user.player?.uid || null;
            const caddieIdValue = user.caddie?.uid || null;
            const userScore = score.find(u => u.userId === userIdValue);
            const handicap = user?.player?.handicap || 0; // Get user's handicap, default to 0 if not present
            const isNegativeIndex = user?.player?.isNegativeIndex || false;

            // Function to handle assigning handicap strokes
            const assignHandicap = (course, handicap, totalHoles) => {
                let handicapFloat = parseFloat(handicap);
                // let absHandicap = Math.round(handicap);
                if (totalHoles <= 9) {
                    handicapFloat = handicapFloat / 2;
                }
                const absHandicap = Math.round(handicapFloat);
                // Initialize the `handicap` field for each hole to 0
                course.forEach(hole => hole.handicap = 0);

                for (let i = 0; i < absHandicap; i++) {
                    const modded = i % totalHoles; // Cycle through holes
                    if (course[modded]) { // Ensure the index exists
                        // Prevent assigning more than 3 strokes to a hole
                        if (course[modded].handicap < 3) {
                            course[modded].handicap += 1;
                        }
                    }
                }

                return course;
            };


            if (!user.sortedCourse) {
                const totalHoles = courseDetailsCopy.length;
                let sortedCourse;

                if (isNegativeIndex) {
                    sortedCourse = JSON.parse(JSON.stringify(courseDetailsCopy)).sort((a, b) => b.index - a.index);
                } else {
                    sortedCourse = JSON.parse(JSON.stringify(courseDetailsCopy)).sort((a, b) => a.index - b.index);
                }
                sortedCourse = assignHandicap(sortedCourse, handicap, totalHoles);
                user.sortedCourse = sortedCourse;
            }

            return {
                holeDetail: hole,
                holeScore: userScore ? {
                    ...userScore[`hole${hole.holeNumber}`],
                    docId: userScore.docId,
                    handicapStrokes: (() => {
                        // Find the hole once and store it for reuse
                        const foundHole = user.sortedCourse.find(item => item.holeNumber === Number(hole.holeNumber));
                        return foundHole ? foundHole.handicap : 0;
                    })(),
                    scoreHandicap: (() => {
                        const scoreNum = Number(userScore[`hole${hole.holeNumber}`]?.score);
                        // Only proceed if the score is a valid number
                        if (!isNaN(scoreNum)) {
                            const foundHole = user.sortedCourse.find(item => item.holeNumber === Number(hole.holeNumber));
                            if (foundHole) {
                                const handicapAdjustment = isNegativeIndex ? foundHole.handicap : -foundHole.handicap;
                                return (scoreNum + handicapAdjustment).toString();
                            }
                        }
                        // Return the default score if it's not a valid number
                        return userScore[`hole${hole.holeNumber}`]?.score;
                    })(),
                    isNegativeIndex: isNegativeIndex
                } : null,
                userId: userIdValue || null,
                caddieId: caddieIdValue || userScore?.caddieId || null,
                rival: user?.player?.rival || null
            };

        });

        // console.log(scores);


        // Filter out entries where holeScore is null
        const scoresWithUserScores = scores.filter(score => score.holeScore !== null);

        // Find the lowest score (ignoring handicap for now)
        let minScore = Infinity;

        scoresWithUserScores.forEach(score => {
            const scoreValueNum = parseFloat(score.holeScore?.score);
            const handicap = score.holeScore?.handicapStrokes;
            let scoreValue = 0;
            if (score.holeScore?.isNegativeIndex) {
                scoreValue = scoreValueNum + handicap;
            } else {
                scoreValue = scoreValueNum - handicap;
            }
            if (!isNaN(scoreValue) && scoreValue < minScore) {
                minScore = scoreValue;
            }
        });

        // Count how many users have the minimum score
        const countMinScoreUsers = scoresWithUserScores.filter(score => {
            const scoreNum = parseFloat(score.holeScore?.score);
            const handicap = score.holeScore?.handicapStrokes;
            let scoreValue = 0;

            // Adjust score based on whether the handicap is a negative index
            if (score.holeScore?.isNegativeIndex) {
                scoreValue = scoreNum + handicap;
            } else {
                scoreValue = scoreNum - handicap;
            }

            return !isNaN(scoreValue) && scoreValue === minScore;
        }).length;


        // Compare each user score and add a `winner` field in holeScore
        scoresWithUserScores.forEach((scoreA, indexA) => {
            const scoreNumA = parseFloat(scoreA.holeScore?.score);
            let scoreAValue = 0;

            // Adjust for negative index (plus handicap)
            if (scoreA.holeScore?.isNegativeIndex) {
                scoreAValue = scoreNumA + scoreA.holeScore.handicapStrokes;
            } else {
                scoreAValue = scoreNumA - scoreA.holeScore.handicapStrokes;
            }

            // Initialize comparison result for each user
            scoreA.holeScore.comparisonResult = [];
            scoreA.holeScore.winner = false; // Default value for winner

            scoresWithUserScores.forEach((scoreB, indexB) => {
                if (indexA === indexB) return; // Skip self-comparison

                const scoreNumB = parseFloat(scoreB.holeScore?.score);
                let scoreBValue = 0;

                // Adjust for negative index (plus handicap)
                if (scoreB.holeScore?.isNegativeIndex) {
                    scoreBValue = scoreNumB + scoreB.holeScore.handicapStrokes;
                } else {
                    scoreBValue = scoreNumB - scoreB.holeScore.handicapStrokes;
                }

                if (scoreAValue < scoreBValue) {
                    scoreA.holeScore.comparisonResult.push({
                        comparedTo: scoreB.userId,
                        result: "lower"
                    });
                } else if (scoreAValue > scoreBValue) {
                    scoreA.holeScore.comparisonResult.push({
                        comparedTo: scoreB.userId,
                        result: "higher"
                    });
                } else {
                    scoreA.holeScore.comparisonResult.push({
                        comparedTo: scoreB.userId,
                        result: "equal"
                    });
                }
            });

            // Set the winner field if the score matches the minScore and only one user has it
            if (scoreAValue === minScore && countMinScoreUsers === 1) {
                scoreA.holeScore.winner = true; // Mark as winner if it matches the min score and is unique
            }
        });


        // Sort the scores array to have the matched userId or caddieId first
        scores.sort((a, b) => {
            const aMatches = a.userId === userId || a.caddieId === userId;
            const bMatches = b.userId === userId || b.caddieId === userId;

            // Handle null userId by moving it to the end
            if (a.userId === null) return 1; // a goes last
            if (b.userId === null) return -1; // b goes last

            // First priority: User's score comes first
            if (aMatches && !bMatches) {
                return -1; // a comes first
            } else if (!aMatches && bMatches) {
                return 1; // b comes first
            }

            // Second priority: Scores where userId matches a rival
            const userScore = scores.find(score => score.userId === userId);
            const aIsRival = a.userId === userScore?.rival;
            const bIsRival = b.userId === userScore?.rival;

            if (aIsRival && !bIsRival) {
                return -1; // a comes first
            } else if (!aIsRival && bIsRival) {
                return 1; // b comes first
            }

            // No preference, keep original order
            return 0;
        });

        // Return the hole detail along with the sorted scores
        return {
            holeDetail: hole,
            scores: scores
        };

    });
    return mappedScores;
}



export function sumScores(player) {
    if (!player) return;

    let sumScore = 0;

    const holeFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));

    const holeValues = holeFiltered.map(key => player[key]);

    // Check if all hole scores are "-"
    const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

    if (allScoresAreDash) {
        return "-";
    }

    // Sum valid numeric scores
    holeValues.forEach(hole => {
        const holescore = hole?.score;
        if (holescore) {
            const numHolescore = Number(holescore);
            if (!isNaN(numHolescore)) {
                sumScore += numHolescore;
            }
        }
    });

    return sumScore;
}

export const sumScoresWHS = (player, mappedScore) => {
    if (!player || !mappedScore) return;

    let sumScore = 0;

    let playerScoreList = [];
    for (let i = 0; i < mappedScore.length; i++) {
        for (let j = 0; j < mappedScore[i].scores.length; j++) {
            if (mappedScore[i].scores[j].userId === player.userId) {
                playerScoreList.push(mappedScore[i].scores[j].holeScore);
            }
        }
    }

    // Extracting hole numbers that belong to the player
    const holeFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));

    // Early return if no hole data exists for the player
    if (!holeFiltered.length) return "-";

    // Process player hole values and find relevant scores in playerScoreList
    holeFiltered.forEach(holeKey => {
        const hole = player[holeKey];
        const foundHole = playerScoreList.find(item => item?.holeNumber === hole?.holeNumber);

        // Skip if hole score is invalid or handicap is missing
        if (hole?.score === "-" || !foundHole) return;

        const score = Number(hole.score);
        const handicap = foundHole.handicapStrokes || 0;
        const isNegative = foundHole.isNegativeIndex;

        // Sum valid numeric scores after applying the handicap
        if (!isNaN(score)) {
            let adjustScore = 0;
            if (isNegative) {
                adjustScore = score + handicap;
            } else {
                adjustScore = score - handicap;
            }
            sumScore += adjustScore;
        }
    });

    return sumScore || "-";
};

export function sumScoresS36(player, courseHoles) {
    if (!player || !courseHoles) return;
    let sumScore = 0;
    const holeDet = courseHoles.holeDetails;
    const playerHoleFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));

    const holeValues = playerHoleFiltered.map(key => player[key]);

    // Check if all hole scores are "-"
    const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

    if (allScoresAreDash) {
        return "-";
    }

    // calculate total s36 score
    let s36Sum = 0;
    // if (checkScore(player)) {

    //     holeValues.forEach(hole => {
    //         const playerHoleNumber = Number(hole?.holeNumber);
    //         const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);
    //         if (holeDetail) {
    //             const par = Number(holeDetail?.par);
    //             const score = Number(hole?.score);
    //             // Only compute if both score and par exist and score is a valid number
    //             if (!isNaN(score) && !isNaN(par)) {
    //                 const s36Score = system36Calculation(score, par);
    //                 s36Sum += s36Score
    //             }
    //         }
    //     });

    //     if (holeDet.length > 9) {
    //         s36Sum = 36 - s36Sum;
    //     } else {
    //         s36Sum = 18 - s36Sum;
    //     }
    // }

    // Calculate the total score relative to par

    holeValues.forEach((holeValue, index) => {
        const playerHoleNumber = Number(holeValue?.holeNumber);
        const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

        if (holeDetail) {
            const par = Number(holeDetail?.par);
            const score = Number(holeValue?.score);

            // Only compute if both score and par exist and score is a valid number
            if (!isNaN(score) && !isNaN(par)) {
                let adjustScore = 0;
                const s36Score = system36Calculation(score, par);
                adjustScore = (36 / 18) - s36Score;
                adjustScore = score - adjustScore
                // Calculate the score relative to par and add it to the sumScore
                sumScore += adjustScore;
            }
        }
    });

    return sumScore
}


export function sumScoresToPar(player, courseHoles) {
    if (!player || !courseHoles) return;
    let sumScore = 0;
    const holeDet = courseHoles.holeDetails;


    const playerHoleFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));

    const holeValues = playerHoleFiltered.map(key => player[key]);

    // Check if all hole scores are "-"
    const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

    if (allScoresAreDash) {
        return "-";
    }

    // Calculate the total score relative to par
    holeValues.forEach((holeValue, index) => {
        const playerHoleNumber = Number(holeValue?.holeNumber);
        const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

        if (holeDetail) {
            const par = Number(holeDetail?.par);
            const score = Number(holeValue?.score);

            // Only compute if both score and par exist and score is a valid number
            if (!isNaN(score) && !isNaN(par)) {
                // Calculate the score relative to par and add it to the sumScore
                sumScore += (score - par);
            }
        }
    });

    return sumScore;
}

export function sumScoresToParWHS(player, courseHoles, mappedScore) {
    if (!player || !courseHoles) return;

    let playerScoreList = [];
    for (let i = 0; i < mappedScore.length; i++) {
        for (let j = 0; j < mappedScore[i].scores.length; j++) {
            if (mappedScore[i].scores[j].userId === player.userId) {
                playerScoreList.push(mappedScore[i].scores[j].holeScore);
            }
        }
    }

    let sumScore = 0;
    const holeDet = courseHoles.holeDetails;


    const playerHoleFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));

    const holeValues = playerHoleFiltered.map(key => player[key]);

    // Check if all hole scores are "-"
    const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

    if (allScoresAreDash) {
        return "-";
    }

    // Calculate the total score relative to par
    holeValues.forEach((holeValue, index) => {
        const playerHoleNumber = Number(holeValue?.holeNumber);
        const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);
        const foundHole = playerScoreList.find(item => item.holeNumber === holeValue.holeNumber);

        if (holeDetail && foundHole) {
            const par = Number(holeDetail?.par);
            const score = Number(holeValue?.score);
            const handicap = foundHole.handicapStrokes || 0;
            const isNegative = foundHole.isNegativeIndex;

            // Only compute if both score and par exist and score is a valid number
            if (!isNaN(score) && !isNaN(par)) {
                // Calculate the score relative to par and add it to the sumScore
                let adjustScore = 0;
                if (isNegative) {
                    adjustScore = (score + handicap) - par;
                } else {
                    adjustScore = (score - handicap) - par;
                }
                sumScore += adjustScore;
            }
        }
    });

    return sumScore;
}

export function sumScoresToParS36(player, courseHoles) {
    if (!player || !courseHoles) return;
    let sumScore = 0;
    const holeDet = courseHoles.holeDetails;


    const playerHoleFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));

    const holeValues = playerHoleFiltered.map(key => player[key]);

    // Check if all hole scores are "-"
    const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

    if (allScoresAreDash) {
        return "-";
    }

    // calculate total s36 score
    let s36Sum = 0;
    // if (checkScore(player)) {

    //     holeValues.forEach(hole => {
    //         const playerHoleNumber = Number(hole?.holeNumber);
    //         const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);
    //         if (holeDetail) {
    //             const par = Number(holeDetail?.par);
    //             const score = Number(hole?.score);
    //             // Only compute if both score and par exist and score is a valid number
    //             if (!isNaN(score) && !isNaN(par)) {
    //                 const s36Score = system36Calculation(score, par);
    //                 s36Sum += s36Score
    //             }
    //         }
    //     });

    //     if (holeDet.length > 9) {
    //         s36Sum = 36 - s36Sum;
    //     } else {
    //         s36Sum = 18 - s36Sum;
    //     }
    // }

    // Calculate the total score relative to par
    holeValues.forEach((holeValue, index) => {
        const playerHoleNumber = Number(holeValue?.holeNumber);
        const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

        if (holeDetail) {
            const par = Number(holeDetail?.par);
            const score = Number(holeValue?.score);

            // Only compute if both score and par exist and score is a valid number
            if (!isNaN(score) && !isNaN(par)) {
                let adjustScore = 0;
                const s36Score = system36Calculation(score, par);
                adjustScore = (36 / 18) - s36Score;
                adjustScore = score - adjustScore
                const s36ToPar = adjustScore - par;
                // Calculate the score relative to par and add it to the sumScore
                sumScore += s36ToPar;
            }
        }
    });

    return sumScore;
}

export function sumScoreStableford(player, courseHoles) {
    if (!player || !courseHoles) return;
    let sumScore = 0;
    const holeDet = courseHoles.holeDetails;

    const playerHoleFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));
    const holeValues = playerHoleFiltered.map(key => player[key]);

    // Check if all hole scores are "-"
    const allScoresAreDash = holeValues.every(hole => hole?.score === "-");
    if (allScoresAreDash) {
        return "-";
    }

    // Calculate the total Stableford points
    holeValues.forEach((holeValue) => {
        const playerHoleNumber = Number(holeValue?.holeNumber);
        const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

        if (holeDetail) {
            const par = Number(holeDetail?.par);
            const score = Number(holeValue?.score);

            // Only compute if both score and par exist and score is a valid number
            if (!isNaN(score) && !isNaN(par)) {
                // Calculate the score difference
                const scoreDifference = score - par;
                // Get Stableford points based on the score difference
                const stablefordPoints = stablefordCalculation(scoreDifference);
                // Accumulate Stableford points
                sumScore += stablefordPoints;
            }
        }
    });

    return sumScore;
}

export function sumScoreStablefordWHS(player, courseHoles, mappedScore) {
    if (!player || !courseHoles || !mappedScore) return;

    let playerScoreList = [];
    for (let i = 0; i < mappedScore.length; i++) {
        for (let j = 0; j < mappedScore[i].scores.length; j++) {
            if (mappedScore[i].scores[j].userId === player.userId) {
                playerScoreList.push(mappedScore[i].scores[j].holeScore);
            }
        }
    }

    let sumScore = 0;
    const holeDet = courseHoles.holeDetails;

    const playerHoleFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));
    const holeValues = playerHoleFiltered.map(key => player[key]);

    // Check if all hole scores are "-"
    const allScoresAreDash = holeValues.every(hole => hole?.score === "-");
    if (allScoresAreDash) {
        return "-";
    }

    // Calculate the total Stableford points
    holeValues.forEach((holeValue) => {
        const playerHoleNumber = Number(holeValue?.holeNumber);
        const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);
        const foundHole = playerScoreList.find(item => item.holeNumber === holeValue.holeNumber);

        if (holeDetail && foundHole) {
            const par = Number(holeDetail?.par);
            const score = Number(holeValue?.score);
            const handicap = foundHole.handicapStrokes || 0;
            const isNegative = foundHole.isNegativeIndex;

            // Only compute if both score and par exist and score is a valid number
            if (!isNaN(score) && !isNaN(par)) {
                // Calculate the score difference
                let scoreDifference = 0;
                if (isNegative) {
                    scoreDifference = (score + handicap) - par;
                } else {
                    scoreDifference = (score - handicap) - par;
                }

                // Get Stableford points based on the score difference
                const stablefordPoints = stablefordCalculation(scoreDifference);
                // Accumulate Stableford points
                sumScore += stablefordPoints;
            }
        }
    });

    return sumScore;
}

export function sumScoreStablefordS36(player, courseHoles) {
    if (!player || !courseHoles) return;
    let sumScore = 0;
    const holeDet = courseHoles.holeDetails;

    const playerHoleFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));
    const holeValues = playerHoleFiltered.map(key => player[key]);

    // Check if all hole scores are "-"
    const allScoresAreDash = holeValues.every(hole => hole?.score === "-");
    if (allScoresAreDash) {
        return "-";
    }

    // calculate total s36 score
    // let s36Sum = 0;
    // if (checkScore(player)) {

    //     holeValues.forEach(hole => {
    //         const playerHoleNumber = Number(hole?.holeNumber);
    //         const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);
    //         if (holeDetail) {
    //             const par = Number(holeDetail?.par);
    //             const score = Number(hole?.score);
    //             // Only compute if both score and par exist and score is a valid number
    //             if (!isNaN(score) && !isNaN(par)) {
    //                 const s36Score = system36Calculation(score, par);
    //                 s36Sum += s36Score
    //             }
    //         }
    //     });

    //     if (holeDet.length > 9) {
    //         s36Sum = 36 - s36Sum;
    //     } else {
    //         s36Sum = 18 - s36Sum;
    //     }
    // }

    // Calculate the total Stableford points
    holeValues.forEach((holeValue) => {
        const playerHoleNumber = Number(holeValue?.holeNumber);
        const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

        if (holeDetail) {
            const par = Number(holeDetail?.par);
            const score = Number(holeValue?.score);

            // Only compute if both score and par exist and score is a valid number
            if (!isNaN(score) && !isNaN(par)) {
                // Calculate the score difference
                let adjustScore = 0;
                const s36Score = system36Calculation(score, par);
                adjustScore = (36 / 18) - s36Score;
                adjustScore = score - adjustScore
                const scoreDifference = adjustScore - par;
                // Get Stableford points based on the score difference
                const stablefordPoints = stablefordCalculation(scoreDifference);
                // Accumulate Stableford points
                sumScore += stablefordPoints;
            }
        }
    });

    return sumScore
}


export function sumPenalties(player) {
    if (!player) return;

    let sumPenalties = 0

    const holeFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));

    const holeValues = holeFiltered.map(key => player[key]);

    holeValues.forEach(hole => {
        const holePenalties = hole?.penalties;
        if (holePenalties) {
            const numHolePenalties = Number(holePenalties);
            if (!isNaN(numHolePenalties)) {
                sumPenalties += numHolePenalties
            }
        }
    });
    return sumPenalties
}

export function sumPutts(player) {
    if (!player) return;

    let sumPutts = 0

    const holeFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));

    const holeValues = holeFiltered.map(key => player[key]);

    holeValues.forEach(hole => {
        const holePutts = hole?.putts;
        if (holePutts) {
            const numHolePutts = Number(holePutts);
            if (!isNaN(numHolePutts)) {
                sumPutts += numHolePutts
            }
        }
    });
    return sumPutts
}

export function sumPar(courseHole) {
    if (!courseHole) return;

    let sumPar = 0
    const holeDetail = courseHole.holeDetails;

    holeDetail.forEach(hole => {
        const holePar = hole?.par;
        if (holePar) {
            const numHolePar = Number(holePar);
            if (!isNaN(numHolePar)) {
                sumPar += numHolePar
            }
        }
    });
    return sumPar
}

export function checkScore(player) {
    if (!player) return false;

    const holeFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));
    
    const holeValues = holeFiltered.map(key => player[key]);

    // Check if every hole score is a valid number and not "-"
    const allScoresAreValidNumbers = holeValues.every(hole => {
        const holescore = hole?.score;
        return holescore !== "-" && !isNaN(Number(holescore));
    });

    return allScoresAreValidNumbers;
}

export function matchplayScore(player, rival) {

    if (!rival) {
        return false;
    }

    let playerWins = 0;  // Tracks how many holes the player has won
    let rivalWins = 0;   // Tracks how many holes the rival has won
    let tie = 0;         // Tracks how many holes tied
    let playedHole = 0;  // Tracks how many holes have been played
    let result = "";     // Holds the result string (e.g., "6&4", "Tied")
    let dormiePlayer = false;  // Flag to indicate dormie condition
    let dormieRival = false;  // Flag to indicate dormie condition
    let winner = null;   // Stores the winner ("player" or "rival")
    let playerUp = false; // Flag to indicate if the player is up
    let rivalUp = false;  // Flag to indicate if the rival is up
    let winRemainingHole = 0;

    // Regular expression to identify fields matching "hole<number>"
    const holeRegex = /^hole\d+$/;

    // Extract only hole-related keys from player and rival
    const playerHoles = Object.keys(player).filter(key => holeRegex.test(key));
    const rivalHoles = Object.keys(rival).filter(key => holeRegex.test(key));

    // Check for missing hole data
    if (playerHoles.length !== rivalHoles.length) {
        console.warn('hole missing from player or competitor', playerHoles.length, rivalHoles.length);
        return false;
    }

    // Sort hole keys for consistent processing
    const sortedHoles = playerHoles.sort();

    // Loop to calculate scores
    for (const hole of sortedHoles) {
        const playerScore = Number(player[hole].score);
        const rivalScore = Number(rival[hole].score);

        if (!isNaN(playerScore) && !isNaN(rivalScore)) {
            if (playerScore < rivalScore) {
                playerWins++;
            } else if (playerScore > rivalScore) {
                rivalWins++;
            } else {
                tie++;
            }
            playedHole++;

            // Calculate remaining holes
            const remainingHoles = sortedHoles.length - playedHole;

            // Check if the result is determined
            if (!winner) {
                if (playerWins - rivalWins > remainingHoles) {
                    result = `${playerWins - rivalWins}&${remainingHoles}`;
                    winner = "player";
                    winRemainingHole = remainingHoles;
                } else if (rivalWins - playerWins > remainingHoles) {
                    result = `${rivalWins - playerWins}&${remainingHoles}`;
                    winner = "rival";
                    winRemainingHole = remainingHoles;
                }
            }

            // Dormie logic
            if (!dormiePlayer && (playerWins > rivalWins) && (playerWins - rivalWins >= remainingHoles)) {
                dormiePlayer = true;
            } else if (!dormieRival && (rivalWins > playerWins) && (rivalWins - playerWins >= remainingHoles)) {
                dormieRival = true;
            }
        }
    }

    // Final result if no early winner
    if (!winner) {
        if (playerWins > rivalWins) {
            result = `${playerWins - rivalWins} up`;
            playerUp = true;
        } else if (playerWins < rivalWins) {
            result = `${rivalWins - playerWins} up`;
            rivalUp = true;
        } else {
            result = 'AS';
        }
    }

    return {
        winner: winner,
        playerWins: playerWins,
        rivalWins: rivalWins,
        tie: tie,
        result: result,
        dormiePlayer: dormiePlayer,
        dormieRival: dormieRival,
        playerUp: playerUp,
        rivalUp: rivalUp,
        winRemainingHole: winRemainingHole
    };
}

export function matchplayScoreWHS(player, rival, mappedScore) {

    if (!rival) {
        return false;
    }

    let playerScoreList = [];
    let rivalScoreList = [];

    for (let i = 0; i < mappedScore.length; i++) {
        for (let j = 0; j < mappedScore[i].scores.length; j++) {
            if (mappedScore[i].scores[j].userId === player.userId) {
                playerScoreList.push(mappedScore[i].scores[j].holeScore);
            }
        }
    }
    if (rival) {
        for (let i = 0; i < mappedScore.length; i++) {
            for (let j = 0; j < mappedScore[i].scores.length; j++) {
                if (mappedScore[i].scores[j].userId === rival.userId) {
                    rivalScoreList.push(mappedScore[i].scores[j].holeScore);
                }
            }
        }
    }
    // console.log(playerScoreList);
    // console.log(rivalScoreList);


    let playerWins = 0;  // Tracks how many holes the player has won
    let rivalWins = 0;   // Tracks how many holes the rival has won
    let tie = 0;         // Tracks how many holes tied
    let playedHole = 0;  // Tracks how many holes have been played
    let result = "";     // Holds the result string (e.g., "6&4", "Tied")
    let dormiePlayer = false;  // Flag to indicate dormie condition
    let dormieRival = false;  // Flag to indicate dormie condition
    let winner = null;   // Stores the winner ("player" or "rival")
    let playerUp = false; // Flag to indicate if the player is up
    let rivalUp = false;  // Flag to indicate if the rival is up
    let winRemainingHole = 0;

    // Regular expression to identify fields matching "hole<number>"
    const holeRegex = /^hole\d+$/;

    // Extract only hole-related keys from player and rival
    const playerHoles = Object.keys(player).filter(key => holeRegex.test(key));
    const rivalHoles = Object.keys(rival).filter(key => holeRegex.test(key));

    // Check for missing hole data
    if (playerHoles.length !== rivalHoles.length) {
        console.warn('hole missing from player or competitor', playerHoles.length, rivalHoles.length);
        return false;
    }

    // Sort hole keys for consistent processing
    const sortedHoles = playerHoles.sort();

    // Loop to calculate scores
    for (const hole of sortedHoles) {
        const playerScore = Number(player[hole].score);
        const rivalScore = Number(rival[hole].score);

        const removedHoleString = hole.replace("hole", "");

        const foundPlayerScore = playerScoreList.find(item => item?.holeNumber === removedHoleString);
        const foundRivalScore = rivalScoreList.find(item => item?.holeNumber === removedHoleString);

        if (foundPlayerScore && foundRivalScore) {
            const isNegativePlayer = foundPlayerScore.isNegativeIndex;
            const isNegativeRival = foundRivalScore.isNegativeIndex;

            if (!isNaN(playerScore) && !isNaN(rivalScore)) {

                let AdjustRivalScore = 0, AdjustPlayerScore = 0;

                // adjust score handicap
                if (isNegativePlayer) {
                    AdjustPlayerScore = playerScore + foundPlayerScore.handicapStrokes
                } else {
                    AdjustPlayerScore = playerScore - foundPlayerScore.handicapStrokes
                }

                if (isNegativeRival) {
                    AdjustRivalScore = rivalScore + foundRivalScore.handicapStrokes
                } else {
                    AdjustRivalScore = rivalScore - foundRivalScore.handicapStrokes
                }

                if (AdjustPlayerScore < AdjustRivalScore) {
                    playerWins++;
                } else if (AdjustPlayerScore > AdjustRivalScore) {
                    rivalWins++;
                } else {
                    tie++;
                }
                playedHole++;

                // Calculate remaining holes
                const remainingHoles = sortedHoles.length - playedHole;

                // Check if the result is determined
                if (!winner) {
                    if (playerWins - rivalWins > remainingHoles) {
                        result = `${playerWins - rivalWins}&${remainingHoles}`;
                        winner = "player";
                        winRemainingHole = remainingHoles;
                    } else if (rivalWins - playerWins > remainingHoles) {
                        result = `${rivalWins - playerWins}&${remainingHoles}`;
                        winner = "rival";
                        winRemainingHole = remainingHoles;
                    }
                }

                // dormie logic
                if (!dormiePlayer && (playerWins > rivalWins) && (playerWins - rivalWins >= remainingHoles)) {
                    dormiePlayer = true;
                } else if (!dormieRival && (rivalWins > playerWins) && (rivalWins - playerWins >= remainingHoles)) {
                    dormieRival = true;
                }
            }
        }
    }

    // Final result if no early winner
    if (!winner) {
        if (playerWins > rivalWins) {
            result = `${playerWins - rivalWins} up`;
            playerUp = true;
        } else if (playerWins < rivalWins) {
            result = `${rivalWins - playerWins} up`;
            rivalUp = true;
        } else {
            result = 'AS';
        }
    }

    return {
        winner: winner,
        playerWins: playerWins,
        rivalWins: rivalWins,
        tie: tie,
        result: result,
        dormiePlayer: dormiePlayer,
        dormieRival: dormieRival,
        playerUp: playerUp,
        rivalUp: rivalUp,
        winRemainingHole: winRemainingHole
    };
}

export function matchplayScoreSystem36(player, rival, mappedScore) {


    if (!rival) {
        return false;
    }

    let playerWins = 0;  // Tracks how many holes the player has won
    let rivalWins = 0;   // Tracks how many holes the rival has won
    let tie = 0;         // Tracks how many holes tied
    let playedHole = 0;  // Tracks how many holes have been played
    let result = "";     // Holds the result string (e.g., "6&4", "Tied")
    let dormiePlayer = false;  // Flag to indicate dormie condition
    let dormieRival = false;  // Flag to indicate dormie condition
    let winner = null;   // Stores the winner ("player" or "rival")
    let playerUp = false; // Flag to indicate if the player is up
    let rivalUp = false;  // Flag to indicate if the rival is up
    let winRemainingHole = 0;

    // Regular expression to identify fields matching "hole<number>"
    const holeRegex = /^hole\d+$/;

    // Extract only hole-related keys from player and rival
    const playerHoles = Object.keys(player).filter(key => holeRegex.test(key));
    const rivalHoles = Object.keys(rival).filter(key => holeRegex.test(key));

    // Check for missing hole data
    if (playerHoles.length !== rivalHoles.length) {
        console.warn('hole missing from player or competitor', playerHoles.length, rivalHoles.length);
        return false;
    }

    // Sort hole keys for consistent processing
    const sortedHoles = playerHoles.sort();

    // Loop to calculate scores
    for (const hole of sortedHoles) {
        const playerScore = Number(player[hole].score);
        const rivalScore = Number(rival[hole].score);

        const removedHoleString = hole.replace("hole", "");

        const holeDet = mappedScore[Number(removedHoleString) - 1];
        // console.log(holeDet);

        const playerS36Score = system36Calculation(playerScore, holeDet?.holeDetail?.par);
        const rivalS36Score = system36Calculation(rivalScore, holeDet?.holeDetail?.par);

        // console.log(`${hole} par:${holeDet?.holeDetail?.par}`);
        // console.log(`s36 player ${playerS36Score}, gross : ${playerScore}`);
        // console.log(`s36 rival ${rivalS36Score}, gross : ${rivalScore}`);
        // console.log('====================================================');

        if (!isNaN(playerS36Score) && !isNaN(rivalS36Score)) {
            if (playerS36Score > rivalS36Score) {
                playerWins++;
            } else if (playerS36Score < rivalS36Score) {
                rivalWins++;
            } else {
                tie++;
            }
            playedHole++;

            // Calculate remaining holes
            const remainingHoles = sortedHoles.length - playedHole;

            // Check if the result is determined
            if (!winner) {
                if (playerWins - rivalWins > remainingHoles) {
                    result = `${playerWins - rivalWins}&${remainingHoles}`;
                    winner = "player";
                    winRemainingHole = remainingHoles;
                } else if (rivalWins - playerWins > remainingHoles) {
                    result = `${rivalWins - playerWins}&${remainingHoles}`;
                    winner = "rival";
                    winRemainingHole = remainingHoles;
                }
            }

            // dormie logic
            if (!dormiePlayer && (playerWins > rivalWins) && (playerWins - rivalWins >= remainingHoles)) {
                dormiePlayer = true;
            } else if (!dormieRival && (rivalWins > playerWins) && (rivalWins - playerWins >= remainingHoles)) {
                dormieRival = true;
            }
        }
    }

    // Final result if no early winner
    if (!winner) {
        if (playerWins > rivalWins) {
            result = `${playerWins - rivalWins} up`;
            playerUp = true;
        } else if (playerWins < rivalWins) {
            result = `${rivalWins - playerWins} up`;
            rivalUp = true;
        } else {
            result = 'AS';
        }
    }

    return {
        winner: winner,
        playerWins: playerWins,
        rivalWins: rivalWins,
        tie: tie,
        result: result,
        dormiePlayer: dormiePlayer,
        dormieRival: dormieRival,
        playerUp: playerUp,
        rivalUp: rivalUp,
        winRemainingHole: winRemainingHole
    };
}




// Function to calculate score difference
export function calculateScoreDifference(player, rival) {
    let totalDifference = 0;

    // Regular expression to match keys starting with "hole" followed by a number
    const holeRegex = /^hole\d+$/;

    // Filter keys that match the "hole<number>" pattern in the player object
    const playerHoleKeys = Object.keys(player).filter(key => holeRegex.test(key));

    // Iterate over filtered keys
    playerHoleKeys.forEach(holeKey => {
        if (rival.hasOwnProperty(holeKey)) {
            const playerScore = player[holeKey].score === "-" ? 0 : parseInt(player[holeKey].score, 10);
            const rivalScore = rival[holeKey].score === "-" ? 0 : parseInt(rival[holeKey].score, 10);

            // Add the difference to the total
            totalDifference += (playerScore - rivalScore);
        }
    });

    return totalDifference;
}










