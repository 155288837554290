import { and, collection, getDocs, or, query, where } from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";

export const getFriendList = async (userData) => {
    const friendsList = [userData.uid]
    const friendRef = collection(firestore, "friends");
    const q = query(friendRef,
        and(
            or(
                where("friendId", "==", userData.uid),
                where("userId", "==", userData.uid)
            ),
            where("status", "==", 'active')
        )
    );
    const friendsDoc = await getDocs(q);

    friendsDoc.forEach(doc => {
        friendsList.push(doc.data().friendId === userData.uid ? doc.data().userId : doc.data().friendId);
    });

    return friendsList;
}

export async function getBlockedUserList(userData) {
    const userIds = [];

    const q1 = query(collection(firestore, "blockedUsers"), where("userId", "==", userData.uid))
    const querySnapshot1 = await getDocs(q1);

    const q2 = query(collection(firestore, "blockedUsers"), where("blockedUserId", "==", userData.uid))
    const querySnapshot2 = await getDocs(q2);

    querySnapshot1.forEach((doc) => {
        const data = doc.data();
        if (data.blockedUserId) {
            userIds.push(data.blockedUserId)
        }
    });

    querySnapshot2.forEach((doc) => {
        const data = doc.data();
        if (data.userId) {
            userIds.push(data.userId)
        }
    });

    return userIds;
}