import React from 'react';
import moment from 'moment';
import 'moment/locale/th'; // Import Thai locale
import { useTranslation } from 'react-i18next';

const TimeAgo = ({ timestamp }) => {
  const { i18n } = useTranslation(); // Access i18n instance
  const storedLocale = i18n.language || 'en'; // Get the current language from i18n

  moment.locale(storedLocale); // Set moment locale when language changes

  return (
    <span className="d-flex font-xssss fw-500 lh-3 text-grey-500">
      {moment(timestamp * 1000).calendar()}
    </span>
  );
};

export default TimeAgo;
