import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { textConvert } from '../../../../../components/language/dropdown/textconvert';

const CaddieReq = ({ roundData }) => {
    const { t } = useTranslation();
    return (
        <section className='caddie-req-sect'>
            <h4 className='fw-600'>{t(textConvert('Caddie Request'))}</h4>
            <h6 className='fw-600 text-muted mb-1'>{t(textConvert('Would you like the caddies at the course to see and ask to join the round'))}</h6>
            <div className='d-flex align-items-center flex-wrap gap-2 mb-3'>
                <div className={clsx('col-sm-3 col-12 step-three-radio-card p-3 p-sm-4', { 'active': roundData.caddieReq === 'all' })}
                >
                    <h6 className='fw-600'>{t(textConvert('All Caddie'))}</h6>
                    <i className="bi bi-check-circle-fill text-current step-three-checked-icon"></i>
                </div>
                <div className={clsx('col-sm-3 col-12 step-three-radio-card p-3 p-sm-4', { 'active': roundData.caddieReq === 'favorite' })}
                >
                    <h6 className='fw-600'>{t(textConvert('Favorite'))}</h6>
                    <i className="bi bi-check-circle-fill text-current step-three-checked-icon"></i>
                </div>
                <div className={clsx('col-sm-3 col-12 step-three-radio-card p-3 p-sm-4', { 'active': roundData.caddieReq === 'disable' })}
                >
                    <h6 className='fw-600'>{t(textConvert('Disable'))}</h6>
                    <i className="bi bi-check-circle-fill text-current step-three-checked-icon"></i>
                </div>
            </div>
        </section>
    )
}

export default CaddieReq