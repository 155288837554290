/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState, useEffect, useRef } from 'react'
import { firestore } from '../../../../../components/firebase/FirebaseConfig';
import { collection, query, where, getDocs, updateDoc, addDoc, Timestamp } from 'firebase/firestore';

import { Accordion, ListGroup, useAccordionButton, ToggleButton } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { useWizard } from '../wizardContext';
import clsx from 'clsx';
import moment from 'moment';
import { scoringDef } from '../module/holeDef';
import FilterCourse, { handleSearch } from '../module/searchCourse';
import CourseDetail from '../module/CourseDetail';
import LoadCircle from '../../../../../components/LoadCircle';
import { useTranslation } from 'react-i18next';
import { convertScoring, holeConvert, textConvert } from '../../../../../components/language/dropdown/textconvert';

const StepOneByPlayer = ({ preSelectCourse }) => {
    const { t, i18n } = useTranslation();
    const userData = useSelector((state) => state.user.data);

    const { stepData, updateStepData } = useWizard();
    const data = stepData.stepOne;

    const courseListRef = useRef([])
    const courseDetailRef = useRef(null)
    const [courseLoading, setCourseLoading] = useState(true);
    const [holesLoading, setHolesLoading] = useState(true);
    const [selectedCourse, setSelectedCourse] = useState(data.course);
    const [selectCourseisOpen, setSelectCourseIsOpen] = useState(false);

    const [nineHolesData, setNineHolesData] = useState([]);
    const [eighteenHolesData, seteighteenHolesData] = useState([]);
    const [holeButton, setHoleButton] = useState([]);
    const [holesValue, setHolesValue] = useState(data.holes || "");
    const [holesCourse, setHolesCourse] = useState([]);
    const [selectedHoleCourse, setSelectedHoleCourse] = useState(data.holesCourse || "");

    const [handicapSelect, setHandicapSelect] = useState(data.handicap);
    const [scoringSelect, setScoringSelect] = useState(data.scoring);
    const [scoringType, setScoringType] = useState(data.scoringType);

    const [gameModeVal, setGameModeVal] = useState(data.gameMode);
    const gameModeButton = [
        {
            name: 'Stroke Play',
            value: 'str',
        },
        {
            name: 'Stableford',
            value: 'stb'
        },
        {
            name: 'Match Play',
            value: 'mp'
        }
    ]

    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCourse, setFilteredCourse] = useState([]);
    const [originCourseList, setOriginCourseList] = useState([]);

    const [filterModal, setFilterModal] = useState(false);
    const [courseDetailModal, setCourseDetailModal] = useState(false);
    const [filterActive, setFilterActive] = useState(false);

    const handleOpenFilterModal = () => {
        setFilterModal(true)
    }
    const handleCloseFilterModal = () => {
        setFilterModal(false)
    }

    const handleOpenCourseDetailModal = (e, course) => {
        e.stopPropagation();
        courseDetailRef.current = course;
        setCourseDetailModal(true);
    }

    const handleCloseCourseDetailModal = () => {
        setCourseDetailModal(false)
    }

    const toggleAccordion = useAccordionButton("0", () => setSelectCourseIsOpen(!selectCourseisOpen));

    useEffect(() => {
        async function getFavCourse() {
            const q = query(
                collection(firestore, "favoriteCourses"),
                where("userId", "==", userData.uid),
                where('status', '==', 'active')
            );
            const favCourseSnapshot = await getDocs(q);
            const favCourseArr = []
            if (!favCourseSnapshot.empty) {
                favCourseSnapshot.forEach((doc) => {
                    favCourseArr.push(doc.data().courseId);
                })
            }
            return favCourseArr;
        }
        async function getCourse() {
            try {
                // Fetch courses
                const querySnapshot = await getDocs(collection(firestore, "courses"));
                const arrayData = querySnapshot.docs.map(doc => ({
                    courseId: doc.id,
                    ...doc.data(),
                }));

                // Extract courseIds directly from arrayData
                const courseIds = arrayData.map(item => item.courseId); // Use courseId instead of item.id

                // Fetch course holes for the courses
                const holesCollection = collection(firestore, 'courseHoles');
                const q = query(holesCollection, where('courseId', 'in', courseIds));
                const holesSnapshot = await getDocs(q);

                // Create a Set for courseIds with holes for faster lookup
                const courseIdsWithHoles = new Set(holesSnapshot.docs.map(doc => doc.data().courseId));

                // Filter out courses that don't have holes
                const courseArr = arrayData.filter(course => courseIdsWithHoles.has(course.courseId));

                // Fetch favorite courses
                const favCourseList = await getFavCourse();
                const favCourseSet = new Set(favCourseList);

                // Add 'isFavorite' to each course and sort by favorite status and course name
                courseArr.forEach(course => {
                    course.isFavorite = favCourseSet.has(course.courseId);
                });

                courseArr.sort((a, b) => {
                    const isFavA = a.isFavorite;
                    const isFavB = b.isFavorite;

                    if (isFavA && !isFavB) return -1;
                    if (!isFavA && isFavB) return 1;

                    const nameA = a.courseName || "";
                    const nameB = b.courseName || "";
                    return nameA.localeCompare(nameB);
                });

                // Update state and references
                setFilteredCourse(courseArr);
                setOriginCourseList(courseArr);
                courseListRef.current = courseArr;
                setCourseLoading(false);

            } catch (error) {
                setCourseLoading(false);
                console.error('Failed to load courses:', error);
            }
        }

        if (userData) {
            getCourse()
        }
    }, [userData])

    useEffect(() => {
        if (data.course) {
            getHole(data.course.courseId);
            updateStepData('stepOne', {
                roundName: data.roundName === "" ? `${userData.firstName}'s Round-${moment().format('YYMMDD')}` : data.roundName
            });
        } else if (preSelectCourse) {
            handleSelectCourse(preSelectCourse);
        } else {
            return;
        }
    }, [data.course])



    const handleSelectCourse = async (course) => {

        if (course?.courseId === selectedCourse?.courseId) {
            setSelectCourseIsOpen(false);
            return;
        }
        setSelectCourseIsOpen(false);
        setSelectedCourse(course);

        const latLong = course.location.latLong;
        const lat = latLong._lat;
        const long = latLong._long;

        course.location.latLong = {};
        course.location.latLong.lat = lat;
        course.location.latLong.long = long;

        updateStepData('stepOne', {
            course: course,
            holes: "",
            holesCourse: "",
            roundName: `${userData.firstName}'s Round-${moment().format('YYMMDD')}`
        });
        updateStepData('stepTwo', {
            row: []
        });

        await getHole(course.courseId);
    }

    const getHole = async (courseId) => {

        setNineHolesData([]);
        seteighteenHolesData([]);
        setHoleButton([]);
        setHolesValue("");
        try {

            const holesCollection = collection(firestore, "courseHoles");
            const q = query(holesCollection, where('courseId', '==', courseId), where('status', '==', 'inService'));
            const querySnapshot = await getDocs(q);

            const nineHolesArray = [];
            const eighteenHolesArray = [];
            const buttonArray = [];

            querySnapshot.forEach((doc) => {

                if (doc.data().numberOfHole === "9holes") {
                    nineHolesArray.push({ ...doc.data(), id: doc.id })
                } else if (doc.data().numberOfHole === "18holes") {
                    eighteenHolesArray.push({ ...doc.data(), id: doc.id })
                } else {
                    console.warn('Not found hole', doc.numberOfHole);
                }
            });

            if (nineHolesArray.length) {
                buttonArray.push({ name: '9 Holes', value: "1" })
                updateStepData('stepOne', { holes: data.holes ? data.holes : "1" });
                setHolesValue(data.holes ? data.holes : "1");
            }
            if (eighteenHolesArray.length) {
                buttonArray.push({ name: '18 Holes', value: "2" })
                updateStepData('stepOne', { holes: data.holes ? data.holes : "2" });
                setHolesValue(data.holes ? data.holes : "2");
            }
            setHoleButton(buttonArray);
            setNineHolesData(nineHolesArray);
            seteighteenHolesData(eighteenHolesArray);

            // setHolesValue(data.holes);
            if (data.holes === "1") {
                setHolesCourse(nineHolesArray);
            } else if (holesValue === "2") {
                setHolesCourse(eighteenHolesArray);
            } else {
                setHolesCourse([]);
                // console.error('Hole value not define :', data.holes);
            }

            setHolesLoading(false);

        } catch (error) {
            setHolesLoading(false)
            console.error('fail to load course', error);
        }
    }

    const handleHolesChange = (e) => {
        const eholeValue = e.target.value;

        setHolesValue(eholeValue);
        setSelectedHoleCourse("")


        updateStepData('stepOne', { holes: e.target.value });

        if (eholeValue === "1") {
            setHolesCourse(nineHolesData);
            // setSelectedHoleCourse(nineHolesData[0].courseHoleName);
            updateStepData('stepOne', { holesCourse: "" })
        } else if (eholeValue === "2") {
            setHolesCourse(eighteenHolesData);
            // setSelectedHoleCourse(eighteenHolesData[0].courseHoleName);
            updateStepData('stepOne', { holesCourse: "" })
        } else {
            setHolesCourse([]);
            console.error('Hole value not define :', eholeValue);
        }

    }

    const handleHoleCourseChange = (e, id) => {
        let holesCourseId = ""
        if (id) {
            holesCourseId = id
        } else {
            holesCourseId = e.target.value
        }
        setSelectedHoleCourse(holesCourseId)
        updateStepData('stepOne', { holesCourse: holesCourseId })
    }

    const handleGameModeChange = (e) => {
        const mode = e.target.value;
        setGameModeVal(mode)
        const scoringList = scoringDef(mode, handicapSelect);

        updateStepData('stepOne', {
            gameMode: mode,
            scoring: scoringList[0],
            scoringType: scoringList
        });
        setScoringType(scoringList);
        setScoringSelect(scoringList[0])
    }

    const handleChangeHandicap = (e) => {
        const handicap = e.target.value;
        setHandicapSelect(handicap);
        const scoringList = scoringDef(gameModeVal, handicap);
        updateStepData('stepOne', {
            handicap: handicap,
            scoring: scoringList[0],
            scoringType: scoringList
        });

        setScoringType(scoringList);
        setScoringSelect(scoringList[0])
    }

    const handleScoringChange = (e) => {
        const scoring = e.target.value;
        setScoringSelect(scoring);
        updateStepData('stepOne', { scoring: scoring });
    }

    const handleCourseFav = async (favMode, course) => {

        if (favMode === 'fav') {
            course.isFavorite = true;
        } else {
            course.isFavorite = false;
        }

        // Update the course in local state
        setFilteredCourse(prevCourses =>
            prevCourses.map(c =>
                c.courseId === course.courseId ? { ...c, isFavorite: course.isFavorite } : c
            )
        );

        try {
            const favCourseCollection = collection(firestore, 'favoriteCourses');
            const q = query(
                favCourseCollection,
                where('courseId', '==', course.courseId),
                where('userId', '==', userData.uid)
            );
            const favCourseSnapshot = await getDocs(q);

            if (!favCourseSnapshot.empty) {
                // If the course is already in favorites
                const docRef = favCourseSnapshot.docs[0].ref;

                if (favMode === 'fav') {
                    // Mark as favorite
                    await updateDoc(docRef, { status: 'active' });
                } else {
                    // Mark as not favorite
                    await updateDoc(docRef, { status: 'inactive' });
                }
            } else {
                if (favMode === 'fav') {
                    // Add a new favorite course if not found
                    await addDoc(favCourseCollection, {
                        courseId: course.courseId,
                        status: 'active',
                        userId: userData.uid,
                        timestamp: Timestamp.now().seconds
                    });
                } else {
                    // Add a new favorite course if not found
                    await addDoc(favCourseCollection, {
                        courseId: course.courseId,
                        status: 'inactive',
                        userId: userData.uid,
                        timestamp: Timestamp.now().seconds
                    });
                }

            }
        } catch (error) {
            console.error('Failed to update favorite status:', error);
        }
    };


    return (
        <>
            <Accordion activeKey={selectCourseisOpen ? "0" : "1"} flush>
                <h5 className='col-sm-4 mb-1 fw-600'>{t(textConvert('Select Golf Course'))}</h5>

                <button className='btn p-3 w-100 text-center rounded-3 bg-light theme-dark-bg border shadow-sm'
                    onClick={toggleAccordion}>
                    {selectedCourse ?
                        <div className='d-flex justify-content-center align-items-center'>
                            <img src={selectedCourse.profileImage} alt="img" width={60} height={60} className='me-2 rounded-circle' />
                            <div>
                                <h4 className='fw-600'>
                                    {i18n.language === 'th' ?
                                        `${selectedCourse?.courseNameThai}`
                                        :
                                        `${selectedCourse?.courseName}`
                                    }
                                </h4>
                                <h6 className='fw-600 text-black-50'>
                                    {i18n.language === 'th' ?
                                        `${selectedCourse?.location?.district || ""}, ${selectedCourse?.location?.province || ""}`
                                        :
                                        `${selectedCourse?.location?.districtEng || ""}, ${selectedCourse?.location?.provinceEng || ""}`
                                    }
                                </h6>
                            </div>
                        </div>
                        :
                        <>
                            {userData ?
                                <h4 className='fw-600'>{t(textConvert('Select Course'))}</h4>
                                :
                                <LoadCircle />
                            }
                        </>
                    }
                </button>

                <Accordion.Collapse eventKey='0' className='p-0'>
                    <>
                        <section className='d-sm-flex justify-content-between align-items-center mb-3 mt-2'>
                            <h3 className='col-sm-4 fw-600'>{t(textConvert('Golf Course'))}</h3>
                            <div className='d-flex align-items-center gap-2 col-sm-6'>
                                <div className="search-form-2 flex-grow-1">
                                    <i className="ti-search font-xss"></i>
                                    <input
                                        type="text"
                                        className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
                                        placeholder={t(textConvert("Search here"))}
                                        value={searchTerm}
                                        onChange={(e) => handleSearch(e, setSearchTerm, setFilteredCourse, originCourseList)}
                                    />
                                </div>
                                <button className='btn btn-light theme-dark-bg' onClick={() => handleOpenFilterModal()}>
                                    <i className={clsx('fs-2 feather-filter text-current', { 'text-muted': !filterActive })}></i>
                                </button>
                            </div>
                        </section>
                        <hr />
                        {!courseLoading ?
                            <section className='right-scroll-bar' style={{ maxHeight: 350, overflow: 'auto', }}>
                                <ListGroup variant='flush' >
                                    {
                                        (() => {
                                            const renderCourseItem = (course, index) => (
                                                <ListGroup.Item
                                                    key={index}
                                                    className="position-relative course-list-items"
                                                    style={{ cursor: 'default' }}
                                                    onClick={() => handleSelectCourse(course)}
                                                >
                                                    <figure className="d-flex align-items-center me-2">
                                                        <img
                                                            src={course?.profileImage}
                                                            alt="profile_img"
                                                            width={50}
                                                            height={50}
                                                            className="rounded-circle"
                                                        />
                                                        <div className="ms-2 text-truncate">
                                                            <h5 className="fw-600 text-wrap">
                                                                {i18n.language === 'th' ?
                                                                    `${course?.courseNameThai}`
                                                                    :
                                                                    `${course?.courseName}`
                                                                }
                                                                {course.isFavorite ? (
                                                                    <i
                                                                        className="bi bi-bookmark-fill ms-2 fs-4 pointer"
                                                                        style={{ color: 'rgb(229, 158, 73)' }}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleCourseFav('unfav', course);
                                                                        }}
                                                                    ></i>
                                                                ) : (
                                                                    <i
                                                                        className="bi bi-bookmark ms-2 fs-4 pointer"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleCourseFav('fav', course);
                                                                        }}
                                                                    ></i>
                                                                )}
                                                            </h5>
                                                            <h6 className="fw-600 text-black-50">
                                                                {i18n.language === 'th' ?
                                                                    `${course?.location?.subDistrict || ""}, ${course?.location?.province || ""}`
                                                                    :
                                                                    `${course?.location?.subDistrictEng || ""}, ${course?.location?.provinceEng || ""}`
                                                                }
                                                            </h6>
                                                        </div>
                                                    </figure>
                                                    <h2
                                                        className="feather-info position-absolute pointer"
                                                        style={{ top: 5, right: 5 }}
                                                        onClick={(e) => handleOpenCourseDetailModal(e, course)}
                                                    ></h2>
                                                </ListGroup.Item>
                                            );

                                            return (
                                                <>
                                                    {(searchTerm.trim() !== "" ? filteredCourse : originCourseList).map(renderCourseItem)}
                                                </>
                                            );
                                        })()
                                    }
                                </ListGroup>
                            </section>
                            :
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only"></span>
                            </div>
                        }
                    </>
                </Accordion.Collapse>
                <Accordion.Collapse eventKey='1' className='p-0'>
                    <>
                        <h5 className='mt-4 mb-1 fw-600'>{t(textConvert('Round Name'))}</h5>
                        <input type="text" name="round-name" id="round-name"
                            className='form-control'
                            value={data.roundName}
                            onChange={(e) => updateStepData('stepOne', { roundName: e.target.value })}
                        />

                        <h5 className='mt-4 mb-1 fw-600'>{t(textConvert('Playing Holes'))}</h5>
                        {!holesLoading && holeButton.length ?
                            <>
                                <div className='d-flex align-items-center gap-2 mb-2' >
                                    {holeButton
                                        .slice() // To avoid mutating the original array
                                        .reverse() // Reverse the array
                                        .map((radio, idx) => (
                                            <div key={idx} className='radio-container col-sm-3'>
                                                <ToggleButton
                                                    id={`hole-${idx}`}
                                                    type="radio"
                                                    name="hole"
                                                    className={clsx("inactive w-100 theme-dark-bg", {
                                                        "active": holesValue === radio.value,
                                                    })}
                                                    variant='light'
                                                    value={radio.value}
                                                    checked={holesValue === radio.value}
                                                    onChange={(e) => handleHolesChange(e)}
                                                >
                                                    <h6 className='fw-600'>{t(holeConvert(radio.name))}</h6>
                                                </ToggleButton>
                                                <i className={clsx("bi bi-check-circle-fill text-current checked-icon",
                                                    { "d-none": holesValue !== radio.value })}></i>
                                            </div>
                                        ))}

                                </div>
                                {/* <div className={clsx('mb-2', { 'd-none': !holesCourse.length })}>
                                    <select name="holeCourseSelect" id="holeCourseSelect" className='form-select' value={selectedHoleCourse} onChange={handleHoleCourseChange}>
                                        <option value=''>No Select</option>
                                        {holesCourse.map((hole, index) => (
                                            <option key={index} value={hole.id}>{hole.courseHoleName}</option>
                                        ))}
                                    </select>
                                </div> */}
                            </> :
                            <h5 className='text-muted mb-1'>No data</h5>
                        }
                        {/* <h5 className='mt-4 mb-1 fw-600'>Game Mode</h5>
                        <div className='d-flex align-items-center gap-2 mb-2 flex-wrap' >
                            {gameModeButton.map((radio, idx) => (
                                <div key={idx} className='radio-container col-sm-3'>
                                    <ToggleButton
                                        id={`mode-${idx}`}
                                        type="radio"
                                        className={clsx("inactive w-100 theme-dark-bg", {
                                            "active": gameModeVal === radio.value,
                                        })}
                                        variant='light'
                                        name="mode"
                                        value={radio.value}
                                        checked={gameModeVal === radio.value}
                                        onChange={(e) => handleGameModeChange(e)}
                                    >
                                        <h6 className='fw-600'>{radio.name}</h6>
                                    </ToggleButton>
                                    <i className={clsx("bi bi-check-circle-fill text-current checked-icon",
                                        { "d-none": gameModeVal !== radio.value })}></i>
                                </div>
                            ))}
                        </div> */}

                        <div className='d-sm-flex align-items-center gap-2 mb-2'>
                            <label htmlFor="game-mode" className='col-4'><h5 className='fw-600'>{t(textConvert('Game Mode'))}</h5></label>
                            <select name="game-mode" id="game-mode" className='form-select form-select-sm' value={gameModeVal} onChange={(e) => handleGameModeChange(e)}>
                                {gameModeButton.map(mode =>
                                    <option key={mode.value} value={mode.value}>{t(textConvert(mode.name))}</option>
                                )}
                            </select>
                        </div>

                        <div className='d-sm-flex align-items-center gap-2 mb-2'>
                            <label htmlFor="handicap" className='col-4'><h5 className='fw-600'>{t(textConvert('Handicap'))}</h5></label>
                            <select name="handicap" id="handicap" className='form-select form-select-sm' value={handicapSelect} onChange={handleChangeHandicap}>
                                <option value="scratch">{t(textConvert('scratch_no_handicap_applied'))}</option>
                                <option value="whs">{t(textConvert('whs_world_handicap_system'))}</option>
                                <option value="s36">{t(textConvert('s_thirtysix_system_thirtysix'))}</option>
                            </select>
                        </div>

                        <div className='d-sm-flex align-items-center gap-2 mb-2'>
                            <label htmlFor="scoring" className='col-4'><h5 className='fw-600'>{t(textConvert('Scoring'))}</h5></label>
                            <select name="scoring" id="scoring" className='form-select form-select-sm' value={scoringSelect} onChange={handleScoringChange}>
                                {scoringType.map((type, index) => (
                                    <option key={index} value={type}>{t(textConvert(convertScoring(type)))}</option>
                                ))}
                            </select>
                        </div>
                    </>
                </Accordion.Collapse>
            </Accordion >

            <FilterCourse
                isOpen={filterModal}
                handleClose={handleCloseFilterModal}
                filteredCourse={(courseList) => setOriginCourseList(courseList)}
                courseList={courseListRef.current}
                isActive={(active) => setFilterActive(active)}
            />

            <CourseDetail
                isOpen={courseDetailModal}
                handleClose={handleCloseCourseDetailModal}
                course={courseDetailRef.current}
                handleSelectCourse={(course) => handleSelectCourse(course)}
                handleHoleCourseChange={(id, holeVal) => { handleHoleCourseChange(null, id); setHolesValue(holeVal); }}
                handleCourseFav={handleCourseFav}
            />

        </>
    )
}

export default StepOneByPlayer