import React, { useState, useReducer, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { ReactSwitchComp } from '../../../components/ReactSwitch'
import clsx from 'clsx';
import { useScore } from '../ScoreContext/ScoreContext';
import { saveTeebox } from '../module/saveTeebox';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { convertScoring, textConvert } from '../../../components/language/dropdown/textconvert';

const Gamemode = ({ isOpen, closeModal }) => {
    const { t } = useTranslation();
    const { roundData, courseHoles, setPlayerGameMode, playerGameMode, playerStat, score } = useScore();
    const userData = useSelector((state) => state.user.data);
    const [scoring, setScoring] = useState([]);
    const [teeOptions, setTeeOptions] = useState([]);

    let defaultTeebox = 'white';

    const foundScore = score.find(item => item.userId === userData.uid);
    if (foundScore && foundScore.teebox && foundScore.teebox.color !== 'not_set') {
        defaultTeebox = foundScore.teebox.color;
    } else {
        const foundDefaultteebox = playerStat.find(item => item.userId === userData.uid);
        if (foundDefaultteebox && foundDefaultteebox.defaultTeebox) {
            defaultTeebox = (foundDefaultteebox.defaultTeebox).toLowerCase();
        }
    }

    const initialState = {
        defaultSw: playerGameMode ? false : true,
        gameMode: playerGameMode?.gameMode || roundData.gameMode,
        handicap: playerGameMode?.handicap || roundData.handicap,
        scoring: playerGameMode?.scoring || roundData.scoring,
        teebox: defaultTeebox
    }

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (!roundData) return;

        scoringDef(state.gameMode, state.handicap);

        if (courseHoles) {
            getTee(courseHoles);
        }

    }, [courseHoles, roundData, state.gameMode, state.handicap])

    // console.log(courseHoles);


    function reducer(state, action) {
        switch (action.type) {
            case 'SWITCH':
                return { ...state, defaultSw: !state.defaultSw };

            case 'GAMEMODE':
                if (action.payload === 'Stableford') {
                    state.handicap = 'scratch'
                    state.scoring = 'Gross'
                }// reset to gross if stableford

                return { ...state, gameMode: action.payload };

            case 'HANDICAP':
                // console.log(action.payload);

                switch (action.payload) {
                    case 'scratch':
                        state.scoring = 'Gross'
                        break;
                    case 'world_handicap_system':
                        state.scoring = 'Gross-WHS'
                        break;
                    case 'system36':
                        state.scoring = 'Gross-S36'
                        break;

                    default:
                        state.scoring = 'Gross'
                        break;
                }
                return { ...state, handicap: action.payload };

            case 'SCORING':
                return { ...state, scoring: action.payload };

            case 'TEEBOX':
                return { ...state, teebox: action.payload };

            default:
                return state;
        }
    }

    function scoringDef(gameMode, handicap) {
        let scoringOptions = [];
        switch (gameMode) {

            case 'Stroke Play':
            case 'Match Play':
                switch (handicap) {
                    case 'scratch':
                        scoringOptions = [{ value: 'Gross', name: 'Gross' }, { value: 'To Par', name: 'To Par' }]
                        break;

                    case 'world_handicap_system':
                    case 'whs':
                        scoringOptions = [{ value: 'Gross-WHS', name: 'Gross-WHS' }, { value: 'To Par-WHS', name: 'To Par-WHS' }]
                        break;

                    case 'system36':
                    case 's36':
                        scoringOptions = [{ value: 'Gross-S36', name: 'Gross-S36' }, { value: 'To Par-S36', name: 'To Par-S36' }]
                        break;

                    default:
                        console.warn('Incorrect handicap >>', handicap);
                        break;
                }
                break;

            case 'Stableford':
                switch (handicap) {
                    case 'scratch':
                        scoringOptions = [{ value: 'Gross', name: 'Gross' }]
                        break;

                    case 'world_handicap_system':
                    case 'whs':
                        scoringOptions = [{ value: 'Gross', name: 'Gross-WHS' }]
                        break;

                    case 'system36':
                    case 's36':
                        scoringOptions = [{ value: 'Gross', name: 'Gross-S36' }]
                        break;

                    default:
                        console.warn('Incorrect handicap >>', handicap);
                        break;
                }

                break;

            default:
                console.warn('Incorrect gamemode >>', gameMode);
                break;
        }
        setScoring(scoringOptions)
    }

    function getTee(courseHoles) {
        const keyToFind = ['black', 'blue', 'red', 'white', 'yellow'];
        const foundKeys = [];
        keyToFind.forEach(key => {
            const keyDet = `${key}TeeDetails`;

            if (Object.keys(courseHoles).includes(keyDet)) {  // Check if key exists in courseHoles
                foundKeys.push({ ...courseHoles[keyDet], color: key });  // Store the key-value pair
            } else {
                console.warn(`"${key}" does not exist`);
            }
        });
        setTeeOptions(foundKeys);
    }


    // console.log(state);

    const handleSubmit = () => {
        if (state.defaultSw) {
            setPlayerGameMode(null);
            sessionStorage.removeItem(`playerGameMode_${roundData.roundId}`);
        } else {
            // console.log(state);
            setPlayerGameMode(state);
            sessionStorage.setItem(`playerGameMode_${roundData.roundId}`, JSON.stringify(state));

            const seletedTee = teeOptions.find(tee => tee.color === state.teebox);
            const tee = {
                color: seletedTee.color,
                courseRating: seletedTee.courseRating,
                slopeRating: seletedTee.slopeRating
            }
            saveTeebox(userData.uid, tee, roundData.roundId);

        }
        closeModal()
    }



    return (
        <Modal show={isOpen} onHide={closeModal} centered animation={false}>
            <Modal.Body>
                <h4 className='fw-600'>{t(textConvert('Game Mode'))}</h4>
            </Modal.Body>

            <Modal.Body>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <h5 className='fw-600'>{t(textConvert('Default'))}</h5>
                    <ReactSwitchComp
                        isChecked={state.defaultSw}
                        handleChecked={() => dispatch({ type: 'SWITCH' })}
                    />
                </div>

                <div className='d-flex justify-content-between align-items-center gap-1 mb-3' style={{ pointerEvents: state.defaultSw ? 'none' : 'auto' }}>
                    <div
                        className='px-1 px-sm-3 rounded-3 position-relative'
                        style={{ ...(state.gameMode === "Stroke Play" ? (state.defaultSw ? disableSelectedModeCardStyle : selectedModeCardStyle) : unselectedModeCardStyle), ...modeCardStyle }}
                        onClick={() => dispatch({ type: 'GAMEMODE', payload: 'Stroke Play' })}
                    >
                        <h5 className='fw-600'>{t(textConvert('Stroke Play'))}</h5>
                        <i className={clsx('bi bi-check-circle-fill position-absolute', {
                            'd-none': state.gameMode !== 'Stroke Play'
                        })} style={{ top: 0, right: 5 }}></i>

                    </div>

                    <div
                        className='px-1 px-sm-3 rounded-3 position-relative'
                        style={{ ...(state.gameMode === "Stableford" ? (state.defaultSw ? disableSelectedModeCardStyle : selectedModeCardStyle) : unselectedModeCardStyle), ...modeCardStyle }}
                        onClick={() => dispatch({ type: 'GAMEMODE', payload: 'Stableford' })}
                    >
                        <h5 className='fw-600'>{t(textConvert('Stableford'))}</h5>
                        <i className={clsx('bi bi-check-circle-fill position-absolute', {
                            'd-none': state.gameMode !== 'Stableford'
                        })} style={{ top: 0, right: 5 }}></i>

                    </div>
                    <div
                        className='px-1 px-sm-3 rounded-3 position-relative'
                        style={{ ...(state.gameMode === "Match Play" ? (state.defaultSw ? disableSelectedModeCardStyle : selectedModeCardStyle) : unselectedModeCardStyle), ...modeCardStyle }}
                        onClick={() => dispatch({ type: 'GAMEMODE', payload: 'Match Play' })}
                    >
                        <h5 className='fw-600'>{t(textConvert('Match Play'))}</h5>
                        <i className={clsx('bi bi-check-circle-fill position-absolute', {
                            'd-none': state.gameMode !== 'Match Play'
                        })} style={{ top: 0, right: 5 }}></i>

                    </div>
                </div>

                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <h5 className='fw-600 col-3 me-2'>{t(textConvert('Handicap'))}</h5>
                    <select name="handicap" id="handicap" className='form-select form-select-sm flex-grow-1' disabled={state.defaultSw ? true : false}
                        onChange={(e) => dispatch({ type: 'HANDICAP', payload: e.target.value })}
                        value={state.handicap}>
                        <option value="scratch">{t(textConvert('scratch_no_handicap_applied'))}</option>
                        <option value="world_handicap_system">{t(textConvert('whs_world_handicap_system'))}</option>
                        <option value="system36">{t(textConvert('s_thirtysix_system_thirtysix'))}</option>
                    </select>
                </div>

                <div className='d-flex justify-content-between align-items-center mb-2'>
                    <h5 className='fw-600 col-3 me-2'>{t(textConvert('Scoring'))}</h5>
                    <select name="handicap" id="handicap" className='form-select form-select-sm flex-grow-1' disabled={state.defaultSw ? true : false}
                        onChange={(e) => dispatch({ type: 'SCORING', payload: e.target.value })}
                        value={state.scoring}>
                        {scoring.map((score, idx) =>
                            <option key={idx} value={score.value}>{t(textConvert(convertScoring(score.name)))}</option>
                        )}
                    </select>
                </div>

                <div className='mb-2'>
                    <h5 className='fw-600 col-3 mb-1'>{t(textConvert('Teebox'))}</h5>
                    <select name="handicap" id="handicap" className='form-select form-select-sm text-capitalize' disabled={state.defaultSw ? true : false}
                        onChange={(e) => { dispatch({ type: 'TEEBOX', payload: e.target.value }); }}
                        value={state.teebox}>
                        {teeOptions.map((tee, idx) =>
                            <option key={idx} value={tee.color} className='text-capitalize'>{`${t(textConvert(tee.color))} ${tee.courseRating}/${tee.slopeRating}`}</option>
                        )}

                    </select>
                </div>

            </Modal.Body>

            <Modal.Footer>
                <div className='d-block w-100'>
                    <h6 className='text-muted fw-600'>* {t(textConvert('This will not apply to other player'))}</h6>
                </div>
                <button className='btn bg-current' onClick={() => handleSubmit()}>
                    <h4 className='fw-600 text-white'>{t(textConvert('OK'))}</h4>
                </button>
                <button className='btn btn-outline-light theme-dark-bg' onClick={() => closeModal()}>
                    <h4 className='fw-600'>{t(textConvert('close'))}</h4>
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default Gamemode

const modeCardStyle = {
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

}
const selectedModeCardStyle = {
    border: '2px solid #EE3C7F',
    backgroundColor: '#FCD8E580',
    color: '#EE3C7F'
}
const disableSelectedModeCardStyle = {
    border: '2px solid #000000',
    backgroundColor: '#D9D9D9'
}
const unselectedModeCardStyle = {
    border: '2px solid #888888',

}
