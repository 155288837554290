import React, { useReducer } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { textConvert } from '../../../components/language/dropdown/textconvert';
import { CheckboxComp, ReactSwitchComp } from '../../../components/ReactSwitch';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useFilter } from '../module/filterContext';
import { getFacetFilters, getNumericFilters, tmpFilterRec } from '../module/filterReducer';

const swClassName = {
    root: 'd-flex align-items-center justify-content-between',
    h5: 'fw-600 mb-0 mt-0',
    h6: 'fw-600 mb-0 mt-0 text-muted'
}

function FilterCoursesV2({ isShow, close }) {
    const { t } = useTranslation();
    const { filterState, dispatch, setFacet, setnumericFilters, trigger } = useFilter();

    const [tmpFilter, filterChange] = useReducer(tmpFilterRec, filterState);

    function handlePriceChange(val) {
        const min = val[0];
        const max = val[1];

        filterChange({ type: "PRICEMIN", payload: min })
        filterChange({ type: "PRICEMAX", payload: max })
    }

    function handleFilter() {

        const facetFiltersArr = getFacetFilters(tmpFilter);
        let numericFiltersArr = []

        // Numeric filters (for price range)
        if (tmpFilter.price) {
            numericFiltersArr = getNumericFilters(tmpFilter);
        }

        setFacet(facetFiltersArr);
        setnumericFilters(numericFiltersArr);
        close();
        trigger();

        dispatch({ type: 'UPDATE', payload: tmpFilter });

    }


    // console.log(filterState);

    return (
        <Modal show={isShow} onHide={close} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <i className="feather-filter font-md text-grey-500"></i>

                    <h2
                        style={{ display: "inline-block", marginLeft: "15px" }}
                        className="fw-600 mb-0 mt-0"
                    >
                        {t(textConvert('filter'))}
                    </h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <div className='d-flex flex-column gap-3 justify-content-center'>
                    <div className={swClassName.root}>
                        <span>
                            <h5 className={swClassName.h5}>
                                {t(textConvert('Offcial Course'))}
                            </h5>
                            <h6 className={swClassName.h6}>
                                {t(textConvert('Only officially registered golf courses with doonine'))}
                            </h6>
                        </span>
                        <ReactSwitchComp isChecked={tmpFilter.official} handleChecked={() => filterChange({ type: "OFFICIAL" })} />
                    </div>

                    <div>
                        <div className={swClassName.root}>
                            <span>
                                <h5 className={swClassName.h5}>
                                    {t(textConvert('Starting Price Range'))}
                                </h5>
                            </span>
                            <ReactSwitchComp isChecked={tmpFilter.price} handleChecked={() => filterChange({ type: "PRICE" })} />
                        </div>
                        {tmpFilter.price &&
                            <div className='mb-1 mt-2 d-flex align-items-center justify-content-between'>
                                <span className='text-center col-1'>{`฿ ${tmpFilter.price_min}`}</span>
                                <Slider
                                    range
                                    min={0}
                                    max={5000}
                                    step={100}
                                    value={[tmpFilter.price_min, tmpFilter.price_max]}
                                    onChange={handlePriceChange}
                                    allowCross={false}
                                    trackStyle={[{ backgroundColor: "#EE3D7F" }]}
                                    handleStyle={[
                                        { borderColor: "#EE3D7F" },
                                        { borderColor: "#EE3D7F" },
                                    ]}
                                />
                                <span className='text-center col-1'>{`฿ ${tmpFilter.price_max}`}</span>
                            </div>
                        }
                    </div>

                    <div className={swClassName.root}>
                        <span>
                            <h5 className={swClassName.h5}>
                                {t(textConvert('Night Golf'))}
                            </h5>
                        </span>
                        <CheckboxComp isChecked={tmpFilter.night} handleChecked={() => filterChange({ type: "NIGHT" })} />
                    </div>

                    <div className={swClassName.root}>
                        <span>
                            <h5 className={swClassName.h5}>
                                {t(textConvert('Driving Range'))}
                            </h5>
                        </span>
                        <CheckboxComp isChecked={tmpFilter.drive} handleChecked={() => filterChange({ type: "DRIVE" })} />
                    </div>

                    <div className={swClassName.root}>
                        <span>
                            <h5 className={swClassName.h5}>
                                {t(textConvert('Restaurant'))}
                            </h5>
                        </span>
                        <CheckboxComp isChecked={tmpFilter.restaurant} handleChecked={() => filterChange({ type: "RESTAURANT" })} />
                    </div>

                    <div className={swClassName.root}>
                        <span>
                            <h5 className={swClassName.h5}>
                                {t(textConvert('Banquet Rooms'))}
                            </h5>
                        </span>
                        <CheckboxComp isChecked={tmpFilter.banquet} handleChecked={() => filterChange({ type: "BANQUET" })} />
                    </div>

                    <div className={swClassName.root}>
                        <span>
                            <h5 className={swClassName.h5}>
                                {t(textConvert('Hotel'))}
                            </h5>
                        </span>
                        <CheckboxComp isChecked={tmpFilter.hotel} handleChecked={() => filterChange({ type: "HOTEL" })} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className=' d-inline-flex gap-2'>
                    <button className='btn btn-light' onClick={() => filterChange({ type: "RESET" })}><h4 className="fw-600 m-0 text-grey-700 p-1">{t(textConvert('close_filter'))}</h4></button>
                    <button className='btn bg-current' onClick={() => handleFilter()} ><h4 className="fw-600 m-0 text-white p-1">{t(textConvert('apply'))}</h4></button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default FilterCoursesV2