import React, { useRef, useEffect, useState } from "react";

import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Rightchat from '../../components/Rightchat';
import Appfooter from '../../components/Appfooter';
import Popupchat from '../../components/Popupchat';

import Friends from '../../components/Friends';

import CaddieStar from "./caddiestar/CaddieStar";
import CreatePost from "./createpost/CreatePost";
import MainPostview from "./postview/MainPostview";
import MainPostviewV2 from "./postview/MainPostviewV2";

function Home() {

    const [refreshKey, setRefreshKey] = useState(0);

    const handleRefresh = () => {
        setRefreshKey((prev) => prev + 1);
    }

    useEffect(() => {
        let isHomeClicked = false;

        const handleClick = () => {
            isHomeClicked = true;
            if (window.scrollY === 0) {
                handleRefresh();
                isHomeClicked = false;
            }
        };

        const handleScroll = () => {
            if (window.scrollY === 0 && isHomeClicked) {
                handleRefresh();
                isHomeClicked = false;
            }
        };

        const button = document.getElementById('home-btn');
        const buttonMb = document.getElementById('home-btn-mb');

        buttonMb.addEventListener('touchstart', handleClick)
        button.addEventListener('click', handleClick);
        window.addEventListener('scroll', handleScroll);

        return () => {
            buttonMb.removeEventListener('touchstart', handleClick)
            button.removeEventListener('click', handleClick);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <Header />
            <Leftnav />
            <Rightchat />

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom" >
                    <div className="middle-sidebar-left" >
                        <div className="row feed-body" >
                            <div className="col-xl-8 col-xxl-9 col-lg-8 px-md-3 feed-home">
                                <CaddieStar />
                                <CreatePost isRefresh={handleRefresh} />
                                <div className="d-lg-none d-block col-xl-4 col-xxl-3 col-lg-4 ps-lg-0 rounded-3" style={{ maxHeight: 200, overflow: 'auto' }}>
                                    <Friends />
                                </div>
                                {/* <MainPostview key={refreshKey} /> */}
                                <MainPostviewV2 key={refreshKey} />
                            </div>
                            <div className="d-none d-lg-block col-xl-4 col-xxl-3 col-lg-4 ps-lg-0 right-scroll-bar" style={{ maxHeight: 600, overflow: 'auto' }}>
                                <Friends />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popupchat />
            <Appfooter />
        </>
    );
}

export default Home;