import { useTranslation } from "react-i18next";
import { useFilter } from "../module/filterContext";
import { useSearchBox } from "react-instantsearch";
import { useState } from "react";
import { textConvert } from "../../../components/language/dropdown/textconvert";
import { Dropdown } from "react-bootstrap";
import { ReactSVG } from "react-svg";

function CustomSearchBox(...props) {
    const dropdownItems = 'd-flex justify-content-start align-items-center';
    const iconStyle = { width: 25, marginRight: 8, marginLeft: 10 }

    const { t } = useTranslation();
    const { dispatch, trigger } = useFilter();
    const { refine } = useSearchBox(props);
    const [searchVal, setSearchVal] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);

    const handleChange = (event) => {
        // Check if the Enter key is pressed
        const value = event.target.value;
        setSearchVal(value)
        if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i)) {
            refine(value);
        }
    };
    const handleKeyDown = (event) => {
        const value = event.target.value;
        if (event.key === 'Enter') {
            refine(value);
        }
    };

    const handleSelectLoc = async (loc) => {
        dispatch({ type: 'LOC', payload: loc });

        trigger();
    }
    return (
        <>
            <Dropdown show={showDropdown}>
                <div className="input-group">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t(textConvert('search here'))}
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                        onFocus={() => setShowDropdown(true)}
                        onBlur={() => setShowDropdown(false)}
                    />
                    <div class="input-group-append">
                        <button className="btn btn-light h-100" onClick={() => refine(searchVal)}>
                            <i className="bi bi-search"></i>
                        </button>
                    </div>
                </div>
                <Dropdown.Menu className="border-light shadow-sm">
                    <Dropdown.Item className={dropdownItems} onMouseDown={() => handleSelectLoc('Near Me')}>
                        <ReactSVG src="assets/icon/golf-course/Course-Near Me.svg" style={{ ...iconStyle, marginLeft: 0 }} />
                        <h3 className="fw-600">Near Me</h3>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item className={dropdownItems} disabled>
                        <ReactSVG src="assets/icon/golf-course/Course-Location Recommend.svg" style={{ ...iconStyle, marginLeft: 0 }} />
                        <h3 className="fw-600">Popular Location</h3>
                    </Dropdown.Item>
                    <Dropdown.Item className={dropdownItems} onMouseDown={() => handleSelectLoc('Pathum Thani')}>
                        <ReactSVG src="assets/icon/golf-course/Course-Location.svg" style={iconStyle} />
                        <h3 className="fw-500">Pathum Thani</h3>
                    </Dropdown.Item>
                    <Dropdown.Item className={dropdownItems} onMouseDown={() => handleSelectLoc('Chon Buri')}>
                        <ReactSVG src="assets/icon/golf-course/Course-Location.svg" style={iconStyle} />
                        <h3 className="fw-500">Chon Buri</h3>
                    </Dropdown.Item>
                    <Dropdown.Item className={dropdownItems} onMouseDown={() => handleSelectLoc('Phuket')}>
                        <ReactSVG src="assets/icon/golf-course/Course-Location.svg" style={iconStyle} />
                        <h3 className="fw-500">Phuket</h3>
                    </Dropdown.Item>
                    <Dropdown.Item className={dropdownItems} onMouseDown={() => handleSelectLoc('Chiang Mai')}>
                        <ReactSVG src="assets/icon/golf-course/Course-Location.svg" style={iconStyle} />
                        <h3 className="fw-500">Chiang Mai</h3>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}
export default CustomSearchBox