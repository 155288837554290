import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";

import { firestore } from "../../components/firebase/FirebaseConfig";
import Load from "../../components/Load";
import axios from "axios";

function Redirect() {
  const userData = useSelector((state) => state.user.data);
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState("");

  useEffect(() => {
    const handleLineActivityRedirect = async () => {
      setIsLoading(true);
      if (userData?.uid) {
        const apiUrl = `https://admin-tfqauxwr5a-as.a.run.app/v1/admin/playing-status/${userData?.uid}`;
        const response = await axios.get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer gx5f9x0CTM9tZepGSmZG9kQCdAT6qwVl`,
          },
        });
        if (response.status == 200) {
          if (response.data.playing == true) {
            // console.log("Playing");
            navigate(`/score?round=${response.data.roundId}`);
          } else {
            // console.log("Not Playing");
            navigate(`/round-activity`);
          }
        } else {
          navigate(`/round-activity`);
        }
      }

      // let roundId = [];
      // try {
      //   const q = query(
      //     collection(firestore, "roundActivity"),
      //     where("userList", "array-contains", userData?.uid)
      //   );
      //   const querySnapshot = await getDocs(q);
      //   const data = [];
      //   querySnapshot.forEach((doc2) => {
      //     roundId.push(doc2.data().roundId);
      //   });

      //   if (roundId.length > 0) {
      //     for (let index = 0; index < roundId.length; index++) {
      //       const element = roundId[index];

      //       const q = query(
      //         collection(firestore, "scoreCards"),
      //         where("roundId", "==", element),
      //         where("userId", "==", userData?.uid),
      //         where("status", "==", "playing")
      //       );

      //       const querySnapshot = await getDocs(q);
      //       querySnapshot.forEach(async (doc1) => {
      //         const docRef = doc(firestore, "round", element);
      //         const docSnap = await getDoc(docRef);

      //         if (docSnap.exists()) {
      //           if (docSnap.data().status !== "deleted") {
      //             const result = docSnap.data().userList.some((item) => {
      //               const caddieMatch =
      //                 item.caddie &&
      //                 item.caddie.uid === userData?.uid &&
      //                 item.caddie.isJoin;
      //               const playerMatch =
      //                 item.player &&
      //                 item.player.uid === userData?.uid &&
      //                 item.player.isJoin;

      //               return caddieMatch || playerMatch;
      //             });
      //             if (result) {
      //               console.log("Document data:", docSnap.data());
      //               // navigate(`/score?round=${docSnap.data().roundId}`);
      //             } else {
      //               console.log("No such document!");

      //               // navigate(`/round-activity`);
      //             }
      //             console.timeEnd("process");
      //           }
      //         } else {
      //           console.log("No such document!");
      //           // navigate(`/round-activity`);
      //         }
      //       });
      //     }
      //   }
      // } catch (error) {
      //   console.error("Error fetching data:", error);
      //   // navigate(`/round-activity`);
      // } finally {
      //   setIsLoading(false);
      // }
    };

    if (location.search || userData?.uid) {
      const searchParams = new URLSearchParams(location.search);
      const redirectToLine = searchParams.get("to");

      if (redirectToLine === "line-connect") {
        setRedirectTo(" Line Setting");
        navigate(`/line-connect`);
      } else if (redirectToLine === "line-activity") {
        setRedirectTo(" Activity");
        handleLineActivityRedirect();
      }
    }
  }, [location.search, userData?.uid]);

  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <h4 className="font-xs text-white fw-600 mb-0 mt-2">
                    Redirect Page
                  </h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <h4 className="text-center fw-600 mb-0 mt-2">
                    You are being redirected.
                  </h4>
                  <Load />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popupchat />
      <Appfooter />
    </>
  );
}

export default Redirect;
