// AuthProvider.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth } from '../firebase/FirebaseConfig';
import {
    signOut,
    signInWithPhoneNumber,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
    sendPasswordResetEmail,
    RecaptchaVerifier
} from 'firebase/auth';

import { collection, getDocs, where, query, addDoc, serverTimestamp } from "firebase/firestore";
import { firestore } from '../firebase/FirebaseConfig';

import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loadingUser, setLoadingUser] = useState(true);
    const provider = new GoogleAuthProvider();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
            setLoadingUser(false);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const phoneLogin = async (phone) => {
        try {
            
            const appVerifier = new RecaptchaVerifier(auth, 'register-course-button', { size: 'invisible' });
            const formattedPhoneNumber = `+66${phone.slice(1)}`;

            console.log("Formatted Phone Number:", formattedPhoneNumber);

            const confirmationResult = await signInWithPhoneNumber(auth, formattedPhoneNumber, appVerifier);
            return confirmationResult;
        } catch (error) {
            console.error("Phone Login Error:", error);
            if (error.code === 'auth/invalid-app-credential') {
                console.error("Possible reCAPTCHA configuration issue.");
            }
            return null;
        }
    };

    const emailRegister = async (email, password) => {

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            return user;

        } catch (error) {
            return error.message;
        }
    }

    const emailLogin = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            return user;

        } catch (error) {
            return error.message;
        }
    }

    const emailResetPassword = async (email) => {
        try {
            await sendPasswordResetEmail(auth, email);
            return true;
        } catch (error) {
            return error.message;
        }
    };


    const googleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, provider);

            // const credential = GoogleAuthProvider.credentialFromResult(result);
            // const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;

            const querySnapshot = await getDocs(query(collection(firestore, "users"), where("uid", "==", user.uid)));

            if (!querySnapshot.empty) {
                // User already signed up
                console.log("User already signed up");
            } else {

                const nick_name = user.displayName;

                const dooNineId = `${nick_name.replace(/\s+/g, '')}_${user.uid.slice(0, 2)}${user.uid.slice(3, 5)}`

                const registerData = {
                    firstName: user.displayName,
                    lastName: '',
                    email: user.email,
                    birthDate: { day: null, month: null, year: null },
                    gender: null,
                    mobile: null,
                    uid: user.uid,
                    profileImage: user.photoURL,
                    role: null,
                    dooNineId: dooNineId,
                    sendBirdAccessToken: "",
                    created: serverTimestamp(),
                    privacy: {
                        dooNineIdSearch: true,
                        emailSearch: false,
                        friendsPost: true,
                        caddiePost: true,
                        playerPost: true,
                        defaultAudience: 'public'
                    }
                };
                const usersCollection = collection(firestore, 'users');
                await addDoc(usersCollection, registerData);

                window.location.href = "/accountinformation";
            }

        } catch (error) {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData?.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.error("Error Code:", errorCode);
            console.error("Error Message:", errorMessage);
            console.error("Email:", email);
            console.error("Credential:", credential);
        }
    }




    const logout = async () => {
        try {
            await signOut(auth);
            localStorage.removeItem(`lastActivity_${user.uid}`)
            localStorage.removeItem("activeSectionRound");
            setUser(null);
            console.log("signed out");
        } catch (error) {
            console.error("Signed out failed", error);
        }
    };


    const handleCreateUserSendBird = async (profileImageUrl, dooNineId, nick_name) => {
        const application_id = 'B72515CE-E0DA-417F-AF02-4BF3D07B3D50';
        const url = `https://api-${application_id}.sendbird.com/v3/users`;
        const apiKey = '1a3a0f2103a01d56c01cde731a802118945aeeb4';

        // Data payload to be sent in the request body
        const data = {
            user_id: dooNineId,
            nickname: nick_name,
            profile_url: profileImageUrl,
            issue_access_token: true,
        };

        try {
            // Sending POST request to create a user
            const res = await axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf8',
                    'Api-Token': apiKey
                }
            });
            return { success: true, data: res.data };  // Return success status and response data
        } catch (error) {
            console.error('Error creating user:', error);
            return { success: false, error: error.response ? error.response.data : error.message };
        }
    };

    const handleUpdateUserSendBird = async (name, profileImageUrl, dooNineId) => {

        const application_id = 'B72515CE-E0DA-417F-AF02-4BF3D07B3D50';
        const url = `https://api-${application_id}.sendbird.com/v3/users/${dooNineId}`;
        const apiKey = '1a3a0f2103a01d56c01cde731a802118945aeeb4';

        let data = {};

        if (name) {
            data.nickname = name;
        }

        if (profileImageUrl) {
            data.profile_url = profileImageUrl
        }

        try {
            // Sending POST request to create a user
            const res = await axios.put(url, data, {
                headers: {
                    'Content-Type': 'application/json; charset=utf8',
                    'Api-Token': apiKey
                }
            });
            return { success: true, data: res.data };  // Return success status and response data
        } catch (error) {
            console.error('Error creating user:', error);
            return { success: false, error: error.response ? error.response.data : error.message };
        }
    };

    if (loadingUser) {
        const theme = localStorage.getItem('theme') || 'theme-light';
        return (
            <>
                {theme === "theme-dark" ?
                    <div className="d-flex justify-content-center align-items-center vh-100 bg-dark">
                        <div className="spinner-grow text-current" role="status"></div>
                    </div>
                    :
                    <div className="d-flex justify-content-center align-items-center vh-100 bg-white">
                        <div className="spinner-grow text-current" role="status"></div>
                    </div>
                }
            </>
        );
    }

    return (
        <AuthContext.Provider value={{
            user,
            logout,
            phoneLogin,
            emailRegister,
            emailLogin,
            googleLogin,
            handleCreateUserSendBird,
            handleUpdateUserSendBird,
            emailResetPassword,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
