import React, { useState, useReducer, useRef } from 'react'
import { Modal, Form } from 'react-bootstrap'
import { ReactSwitchComp, CheckboxComp } from '../../../../../components/ReactSwitch'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../../../components/language/dropdown/textconvert';


// Reducer for state management
const filterReducer = (state, action) => {
    switch (action.type) {

        case "SET_FILTER":
            return { ...state, [action.payload.key]: action.payload.value };

        case "SET_NESTED_FILTER":
            return {
                ...state,
                [action.payload.parentKey]: {
                    ...state[action.payload.parentKey],
                    [action.payload.key]: action.payload.value,
                },
            };

        case "RESET":
            return action.payload; // Reset to initial state
        default:
            return state;
    }
};

const FilterCourse = ({ isOpen, handleClose, filteredCourse, courseList, isActive }) => {
    const { t } = useTranslation();

    const initialState = {
        isFavorite: false,
        features: {
            nightGolf: false,
            drivingRange: false,
            officialCourse: false,
        },
        additionalServices: {
            restaurant: false,
            banquetRooms: false,
            hotel: false,
        }
    }

    const [state, dispatch] = useReducer(filterReducer, initialState);
    const [priceRange, setPriceRange] = useState(false);
    const [priceValue, setPriceValue] = useState({
        max: 5000,
        min: 0
    });

    const isFilterActive = (state, initialState) => {
        // Check top-level fields
        if (state.isFavorite !== initialState.isFavorite) {
            return true;
        }

        if (priceRange) {
            return true;
        }

        // Check features
        for (let key in state.features) {
            if (state.features[key] !== initialState.features[key]) {
                return true;
            }
        }

        // Check additionalServices
        for (let key in state.additionalServices) {
            if (state.additionalServices[key] !== initialState.additionalServices[key]) {
                return true;
            }
        }

        // If no differences are found, no filters are active
        return false;
    };

    const handleRangeChange = (value) => {
        setPriceValue({
            max: value[1],
            min: value[0]
        })
    }

    const handleApply = () => {
        const filteredCourses = courseList.filter((course) => {
            let matches = true;

            // Check for "isFavorite"
            if (state.isFavorite && !course.isFavorite) {
                matches = false;
            }

            // Check for "priceRange"
            if (priceRange) {
                const { min, max } = priceValue;
                const coursePrice = course.features.startingPriceRange;
                if (coursePrice.minPrice < min || coursePrice.maxPrice > max) {
                    matches = false;
                }
            }

            // Check for fields inside "features"
            if (matches && state.features) {
                for (let key in state.features) {
                    if (state.features[key] && key !== 'startingPriceRange') {
                        if (!course.features[key]) {
                            matches = false;
                            break;
                        }
                    }
                }
            }

            // Check for fields inside "additionalServices"
            if (matches && state.additionalServices) {
                for (let key in state.additionalServices) {
                    if (state.additionalServices[key] && !course.additionalServices[key]) {
                        matches = false;
                        break;
                    }
                }
            }

            return matches;
        });
        filteredCourse(filteredCourses);
        isActive(isFilterActive(state, initialState));
        handleClose()

    };

    const handleReset = () => {
        dispatch({
            type: 'RESET',
            payload: initialState,
        });
        setPriceRange(false)
        filteredCourse(courseList);
        isActive(false);
        handleClose()
    }

    // console.log(courseList);


    return (
        <>
            <Modal show={isOpen} onHide={handleClose} animation={false} centered>
                <Modal.Header>
                    <Modal.Title>
                        <div className='d-flex align-items-center'>
                            <i className='feather-filter me-2 text-muted' style={{ fontSize: 22 }}></i>
                            <h2 className='fw-600'>{t(textConvert('Filter'))}</h2>
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <RenderProp
                        state={state}
                        dispatch={dispatch}
                        type={"switch"}
                        filterKey={"isFavorite"}
                        label="Bookmark Only"
                    />

                    <RenderProp
                        state={state}
                        dispatch={dispatch}
                        type={"switch"}
                        filterKey={"officialCourse"}
                        parentKey={"features"}
                        label={"Official Course"}
                    />

                    <div className='mb-4'>
                        <div className='d-flex justify-content-between mb-2'>
                            <h5 className='fw-600'>{t(textConvert('Starting Price Range'))}</h5>
                            <ReactSwitchComp
                                isChecked={priceRange}
                                handleChecked={(checkValue) => { setPriceRange(checkValue) }} />
                        </div>
                        {priceRange &&
                            <div className=" mb-4">
                                <div className='d-flex justify-content-between align-items-center mb-2'>
                                    <div className='col-3 d-flex align-items-center'>
                                        <input type="number" placeholder='Min' min={0} max={4999} value={priceValue.min} name="price-min" id="price-min" className='form-control col-4'
                                            onChange={(e) => setPriceValue(prev => ({ ...prev, min: e.target.value }))} />
                                        <h3 className='ms-1'>฿</h3>
                                    </div>
                                    -
                                    <div className='col-3 d-flex align-items-center'>
                                        <input type="number" placeholder='Max' min={1} max={5000} value={priceValue.max} name="price-max" id="price-max" className='form-control col-4'
                                            onChange={(e) => setPriceValue(prev => ({ ...prev, max: e.target.value }))} />
                                        <h3 className='ms-1'>฿</h3>
                                    </div>
                                </div>
                                <Slider
                                    range
                                    min={0}
                                    max={5000}
                                    step={1}
                                    value={[
                                        priceValue.min,
                                        priceValue.max
                                    ]}
                                    trackStyle={[{ backgroundColor: "#EE3D7F" }]}
                                    handleStyle={[
                                        { borderColor: "#EE3D7F" },
                                        { borderColor: "#EE3D7F" },
                                    ]}
                                    onChange={handleRangeChange}
                                />

                            </div>
                        }
                    </div>

                    <RenderProp
                        state={state}
                        dispatch={dispatch}
                        type={"checkbox"}
                        filterKey={"nightGolf"}
                        parentKey={"features"}
                        label={"Night Golf"}
                    />

                    <RenderProp
                        state={state}
                        dispatch={dispatch}
                        type={"checkbox"}
                        filterKey="drivingRange"
                        parentKey={"features"}
                        label={"Driving Range"}
                    />

                    <RenderProp
                        state={state}
                        dispatch={dispatch}
                        type={"checkbox"}
                        filterKey={"restaurant"}
                        parentKey={"additionalServices"}
                        label={"Restaurant"}
                    />

                    <RenderProp
                        state={state}
                        dispatch={dispatch}
                        type={"checkbox"}
                        filterKey="banquetRooms"
                        parentKey={"additionalServices"}
                        label={"Banquet Rooms"}
                    />

                    <RenderProp
                        state={state}
                        dispatch={dispatch}
                        type={"checkbox"}
                        filterKey={"hotel"}
                        parentKey={"additionalServices"}
                        label={"Hotel"}
                    />


                </Modal.Body>

                <Modal.Footer className='justify-content-between'>
                    <button className='btn btn-light theme-dark-bg' onClick={() => handleReset()}><h5 className='fw-600'>{t(textConvert('Reset'))}</h5></button>
                    <div className='d-flex gap-1'>
                        <button className='btn btn-light theme-dark-bg' onClick={() => handleClose()}><h5 className='fw-600'>{t(textConvert('Cancel'))}</h5></button>
                        <button className='btn bg-current' onClick={() => handleApply()}><h5 className='fw-600 text-white'>{t(textConvert('Apply'))}</h5></button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default FilterCourse

// Handle change in the search input
export const handleSearch = (event, setSearchTerm, setFilteredCourse, courseList) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);

    // Filter data based on the input value
    const filtered = courseList.filter(item =>
        item.courseName.toLowerCase().includes(value) ||
        item.location.provinceEng.toLowerCase().includes(value) ||
        item.location.subDistrictEng.toLowerCase().includes(value)
    );
    setFilteredCourse(filtered);
};


function RenderProp({ state, dispatch, type, filterKey, parentKey, label }) {
    const { t } = useTranslation();
    // Determine the current checked value dynamically
    const isChecked = parentKey ? state[parentKey][filterKey] : state[filterKey];

    // Common handleChecked logic
    const handleChecked = (checkValue) => {
        if (parentKey) {
            dispatch({
                type: 'SET_NESTED_FILTER',
                payload: {
                    parentKey: parentKey,
                    key: filterKey,
                    value: checkValue,
                },
            });
        } else {
            dispatch({
                type: 'SET_FILTER',
                payload: { key: filterKey, value: checkValue },
            });
        }
    };

    return (
        <div className='d-flex justify-content-between mb-4'>
            <div>
                <h5 className='fw-600'>{t(textConvert(label))}</h5>
                {filterKey === 'officialCourse' &&
                    <small>{t(textConvert('Only officially registered golf courses with doonine'))}</small>
                }
            </div>
            {type === 'switch' ? (
                <ReactSwitchComp isChecked={isChecked} handleChecked={handleChecked} />
            ) : (
                <CheckboxComp isChecked={isChecked} handleChecked={handleChecked} />
            )}
        </div>
    );
}



