import React, { useEffect, useState } from 'react'

import Header from '../../../components/Header'
import Leftnav from '../../../components/Leftnav'
import Rightchat from '../../../components/Rightchat'
import Popupchat from '../../../components/Popupchat'
import Appfooter from '../../../components/Appfooter'

import { firestore } from '../../../components/firebase/FirebaseConfig'
import { where, query, collection, getDocs } from 'firebase/firestore';

import { useSelector } from 'react-redux'
import Load from '../../../components/Load'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { textConvert } from '../../../components/language/dropdown/textconvert'
import { Table } from 'react-bootstrap'
import clsx from 'clsx'
import { unblockFriend } from '../../../components/friend/BlockFriend'
import LoadCircle from '../../../components/LoadCircle'

function BlockList() {
    const { t } = useTranslation();
    const userData = useSelector((state) => state.user.data);
    const [blockedUser, setBlockedUser] = useState([]);
    const [unblockList, setUnblockList] = useState([]);
    const [loadingUser, setLoadingUser] = useState(false);
    const [loadingList, setLoadingList] = useState(true);

    useEffect(() => {

        async function getBlockedUserList(getBlockedUserData) {
            setLoadingList(true);
            const userIds = [];
            const q = query(collection(firestore, "blockedUsers"), where("userId", "==", userData.uid))
            const querySnapshot1 = await getDocs(q);

            querySnapshot1.forEach((doc) => {
                const data = doc.data();
                if (data.blockedUserId) {
                    userIds.push(data.blockedUserId)
                }
            });
            if (userIds.length) {
                await getBlockedUserData(userIds);
            } else {
                setBlockedUser([]);
            }
            setLoadingList(false);
        }

        async function getBlockedUserData(userIds) {
            const userList = [];

            const userquery = query(collection(firestore, "users"), where("uid", "in", userIds));

            const userSnapshot = await getDocs(userquery);
            userSnapshot.forEach((doc) => {
                const data = doc.data();
                userList.push(data)
            });
            setBlockedUser(userList);
        }

        if (userData) {
            getBlockedUserList(getBlockedUserData);
        }
    }, [userData, unblockList])

    async function handleUnblock(user) {

        setLoadingUser(prevState => ({ ...prevState, [user.uid]: true }));

        await unblockFriend(userData.uid, user.uid)

        setUnblockList((prev) => [...prev, user.uid]);
        setLoadingUser(prevState => ({ ...prevState, [user.uid]: false }));
    }

    return (
        <>
            <style>
                {`
                    .blocklist-card-body {
                        min-height: calc(100vh - 220px);
                    }
                `}
            </style>

            <Header />
            <Leftnav />
            <Rightchat />
            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4 blocklist-card">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <Link to="/defaultsettings" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">{t(textConvert('Block List'))}</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0 blocklist-card-body">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {!loadingList ?
                                                <>
                                                    {blockedUser.length ?
                                                        <div>
                                                            <Table responsive>
                                                                <thead>
                                                                    <tr>
                                                                        <th>No.</th>
                                                                        <th>{t(textConvert('Name block'))}</th>
                                                                        <th>{t(textConvert('Role'))}</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {blockedUser.map((user, idx) =>
                                                                        <tr>
                                                                            <td>{idx + 1}</td>
                                                                            <td>
                                                                                <div className='d-flex flex-wrap align-items-center gap-1'>
                                                                                    <img src={user.profileImage} alt="img" width={50} height={50}
                                                                                        className={clsx('rounded-circle', {
                                                                                            'player-color-outline': user.role !== 'caddie',
                                                                                            'caddie-color-outline': user.role === 'caddie'
                                                                                        })} />
                                                                                    <span>{user.firstName} {user.lastName}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <span className='text-capitalize'>{user.role}</span>
                                                                            </td>
                                                                            <td className='text-center'>
                                                                                <button className='btn bg-current text-white w100' onClick={() => handleUnblock(user)}>
                                                                                    {loadingUser[user.uid] ? (
                                                                                        <LoadCircle variant='light' size='sm' />
                                                                                    ) : (
                                                                                        t(textConvert('Unblock'))
                                                                                    )}
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                        :
                                                        <h3 className='fw-600 text-muted text-center'>
                                                            {t(textConvert('Your block list is empty'))}
                                                        </h3>
                                                    }
                                                </> : <Load />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popupchat />
            <Appfooter />
        </>
    )
}

export default BlockList