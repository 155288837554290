import { firestore } from "../firebase/FirebaseConfig"
import { collection, addDoc, where, query, getDocs, serverTimestamp, updateDoc, deleteDoc } from "firebase/firestore"

export const favFriend = async (userId, favId) => {
    try {
        const timestamp = Math.floor(Date.now() / 1000);
        const q1 = query(
            collection(firestore, "favoritePlayers"),
            where("playerId", "==", favId),
            where("userId", "==", userId)
        );

        const querySnapshot1 = await getDocs(q1);

        if (querySnapshot1.empty) {
            // No existing record, create a new one
            await addDoc(collection(firestore, "favoritePlayers"), {
                playerId: favId,
                userId: userId,
                timestamp: timestamp,
                status: 'active'
            });
        } else {
            // Sort documents by timestamp (optional but ensures consistency)
            const sortedDocs = querySnapshot1.docs.sort((a, b) => 
                b.data().timestamp - a.data().timestamp
            );

            const docToKeep = sortedDocs[0]; // Keep the most recent one
            const docsToDelete = sortedDocs.slice(1); // Delete the rest

            // Toggle the status of the one we keep
            const currentStatus = docToKeep.data().status;
            const newStatus = currentStatus === 'inactive' ? 'active' : 'inactive';

            await updateDoc(docToKeep.ref, {
                status: newStatus,
                timestamp: timestamp
            });

            // Delete all other duplicate documents
            const deletePromises = docsToDelete.map(doc => deleteDoc(doc.ref));
            await Promise.all(deletePromises);
        }
        return true;
    } catch (error) {
        console.error('Error favoriting user', error);
        return false;
    }
};


export const unfavFriend = async (userId, favId) => {
    try {
        const q1 = query(
            collection(firestore, "favoritePlayers"),
            where("playerId", "==", favId),
            where("userId", "==", userId),
            where("status", "==", 'active'));

        const querySnapshot1 = await getDocs(q1);

        if (!querySnapshot1.empty) {
            for (const doc of querySnapshot1.docs) {
                const docRef = doc.ref;
                await updateDoc(docRef, {
                    status: 'inactive'
                });
            }
            return true;
        } else {
            console.warn('This user is not in your favorites');
            return false;
        }
    } catch (error) {
        console.error('Error unfavoriting the user', error);
        return false;
    }
};


export const favFriendCaddie = async (userId, favId) => {
    try {
        const timestamp = Math.floor(Date.now() / 1000);

        const q2 = query(
            collection(firestore, "favoriteFriends"),
            where("caddieId", "==", favId),
            where("userId", "==", userId)
        );

        const querySnapshot2 = await getDocs(q2);

        if (querySnapshot2.empty) {
            // No existing record, create a new one
            await addDoc(collection(firestore, "favoriteFriends"), {
                caddieId: favId,
                userId: userId,
                timestamp: timestamp,
                status: 'active'
            });
        } else {
            // Sort documents by timestamp (optional but ensures consistency)
            const sortedDocs = querySnapshot2.docs.sort((a, b) => 
                b.data().timestamp - a.data().timestamp
            );

            const docToKeep = sortedDocs[0]; // Keep the most recent one
            const docsToDelete = sortedDocs.slice(1); // Delete the rest

            // Toggle the status of the one we keep
            const currentStatus = docToKeep.data().status;
            const newStatus = currentStatus === 'inactive' ? 'active' : 'inactive';

            await updateDoc(docToKeep.ref, {
                status: newStatus,
                timestamp: timestamp
            });

            // Delete all other duplicate documents
            const deletePromises = docsToDelete.map(doc => deleteDoc(doc.ref));
            await Promise.all(deletePromises);
        }
        return true;
    } catch (error) {
        console.error('Error favoriting caddie', error);
        return false;
    }
};


export const unfavFriendCaddie = async (userId, favId) => {
    try {

        const q2 = query(
            collection(firestore, "favoriteFriends"),
            where("caddieId", "==", favId),
            where("userId", "==", userId),
            where("status", "==", 'active'));

        const querySnapshot2 = await getDocs(q2);

        if (!querySnapshot2.empty) {
            for (const doc of querySnapshot2.docs) {
                const docRef = doc.ref;
                await updateDoc(docRef, {
                    status: 'inactive'
                });
            }
            return true;
        } else {
            console.warn('This user is not in your favorites');
            return false;
        }
    } catch (error) {
        console.error('Error unfavoriting the user', error);
        return false;
    }
};

const removeFavoriteFriendDoc = async (userId, caddieId) => {
    try {
        // Query the collection for documents where userId and caddieId match
        const q = query(
            collection(firestore, "favoriteFriends"),
            where("userId", "==", userId),
            where("caddieId", "==", caddieId)
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            console.warn('No matching document found to delete.');
            return false;
        }

        // Loop through the query results and delete each document
        for (const doc of querySnapshot.docs) {
            await deleteDoc(doc.ref);
            console.log(`Document with id ${doc.id} deleted.`);
        }

        return true;
    } catch (error) {
        console.error('Error removing document', error);
        return false;
    }
};