import React, { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
// player
import StepOneByPlayer from "./createByPlayer/StepOneByPlayer";
import StepTwoByPlayer from "./createByPlayer/StepTwoByPlayer";
import StepThreeByPlayer from "./createByPlayer/StepThreeByPlayer";
// caddie
import StepOneByCaddie from "./cretateByCaddie/StepOneByCaddie";
import StepTwoByCaddie from "./cretateByCaddie/StepTwoByCaddie";
import StepThreeByCaddie from "./cretateByCaddie/StepThreeByCaddie";

import ReqPreview from "./ReqPreview";
import { useWizard } from "./wizardContext";
import { useSelector } from "react-redux";
import {
  addDoc,
  updateDoc,
  collection,
  doc,
  serverTimestamp,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { gameModeDef, holesDef, handicapDef } from "./module/holeDef";
import { handleUploadImage } from "./module/uploadRoundImage";
import Swal from "sweetalert2";
import moment from "moment";

import axios from "axios";
import { createRoundNotification } from "../../../../components/notification/sentNotification";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../../../components/language/dropdown/textconvert";

import { sendLinePlainText } from "../../../../components/line/SendLinePlainText";
import {
  sendInviteRound,
  sendInviteRoundCreator,
} from "../../../../components/line/SendInviteRound";

const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;
const MainWizard = () => {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.data);

  const { stepData, nextStep, prevStep, currentStep } = useWizard();

  const [createLoading, setCreateLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const preSelectedCourse = location.state;

  const data1 = stepData.stepOne;
  const data2 = stepData.stepTwo;
  const data3 = stepData.stepThree;

  // console.log(data1);
  // console.log(data2);
  // console.log(data3);

  const isDisabled = () => {
    switch (currentStep) {
      case 1:
        if (data1.holes !== "" && data1.roundName !== "") return false;
        else return true;

      case 2:
        return false;

      case 3:
        if (
          (data3.teeTimeBtn === "schedule" && !data3.scheduleTime) ||
          (data3.groupChatBtn === "create" && data3.groupChatName === "")
        )
          return true;
        else return false;

      case 4:
        return true;

      default:
        return true;
    }
  };

  const handleRequestClick = async () => {
    setCreateLoading(true);

    const userList = data2.row.map((item) => ({
      row: item.row,
      player: item.player ? { ...item.player } : null,
      caddie: item.caddie ? { ...item.caddie } : null,
    }));

    if (!userList.length) {
      if (userData.role === "player") {
        userList.push({
          row: 0,
          player: {
            uid: userData.uid,
            isCreator: true,
            isJoin: true,
          },
          caddie: null,
        });
      } else {
        userList.push({
          row: 0,
          caddie: {
            uid: userData.uid,
            isCreator: true,
            isJoin: true,
          },
          player: null,
        });
      }
    } else {
      userList.forEach((item) => {
        if (item.player) {
          if (item.player.uid === userData.uid) {
            item.player = {
              uid: item.player.uid,
              isCreator: true,
              isJoin: true,
            };
            if (item.caddie) {
              item.caddie = {
                playerConfirm: true,
                uid: item.caddie.uid,
                isCreator: false,
                isJoin: false,
              };
            }
          } else {
            item.player = {
              uid: item.player.uid,
              isCreator: false,
              isJoin: false,
            };
          }
        }

        if (item.caddie) {
          if (item.caddie.uid === userData.uid) {
            item.caddie = {
              uid: item.caddie.uid,
              isCreator: true,
              isJoin: true,
            };
          } else {
            item.caddie = {
              playerConfirm: item?.caddie?.playerConfirm || false,
              uid: item.caddie.uid,
              isCreator: false,
              isJoin: false,
            };
          }
        }
      });
    }

    const data = {
      gameMode: gameModeDef(data1.gameMode),
      holes: holesDef(data1.holes),
      handicap: handicapDef(data1.handicap),
      scoring: data1.scoring,
      roundName: data1.roundName,
      holesCourse: data1.holesCourse,
      course: data1.course.id,
      userList: userList,
      caddieReq: data3.caddieReqBtn,
      groupChat: data3.groupChatBtn,
      groupChatName: data3.groupChatName,
      coverImage: "",
      scheduleTime:
        data3.scheduleTime && data3.teeTimeBtn === "schedule"
          ? data3.scheduleTime
          : moment().unix(),
      teeTime: data3.teeTimeBtn,
      creator: userData.uid,
    };

    let dooNineIds = [];
    const userList2 = data2.row.map((item) => ({
      row: item.row,
      player: item.player ? { ...item.player } : null,
      caddie: item.caddie ? { ...item.caddie } : null,
    }));
    // console.log(userList2);

    if (!userList2.length) {
      if (userData.role === "player") {
        dooNineIds.push(userData.dooNineId);
      } else {
        dooNineIds.push(userData.dooNineId);
      }
    } else {
      dooNineIds = data2.row
        .flatMap((item) => [item.player?.dooNineId, item.caddie?.dooNineId])
        .filter((id) => id != null);
    }

    let response = "";
    if (data3.groupChatBtn === "create") {
      try {
        // create
        response = await axios.post(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels`,
          {
            name: data3.groupChatName || data1.roundName,
            user_ids: [userData.dooNineId],
            operator_ids: [userData.dooNineId],
            auto_accept: false,
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": sendBird_api_token,
            },
          }
        );

        // messege
        const response2 = await axios.post(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${response.data.channel_url}/messages`,
          {
            message_type: "MESG",
            user_id: userData.dooNineId,
            message:
              "Welcome to the group chat for the " +
              data1.roundName +
              " round. ",
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": sendBird_api_token,
            },
          }
        );

        // disable auto accept
        for (const ids of dooNineIds) {
          const response3 = await axios.put(
            `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/users/${ids}/channel_invitation_preference`,
            {
              auto_accept: ids === userData.dooNineId ? true : false,
            },
            {
              headers: {
                "Content-Type": "application/json; charset=utf8",
                "Api-Token": sendBird_api_token,
              },
            }
          );
          console.log(response3);
        }

        // invite
        const response4 = await axios.post(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${response.data.channel_url}/invite`,
          {
            user_ids: dooNineIds,
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": sendBird_api_token,
            },
          }
        );
        console.log(response4);
      } catch (error) {
        console.log(error);
      }
    }

    try {
      const roundCollection = collection(firestore, "round");
      const activityCollection = collection(firestore, "roundActivity");

      // get leaderbaord privacy
      const userPrivacy = userData?.privacy;

      let isLeaderboardPublic = false;
      if (
        !userPrivacy?.leaderboardPrivacy ||
        userPrivacy?.leaderboardPrivacy === "public"
      ) {
        isLeaderboardPublic = true;
      }

      // return;
      // Add new round document
      const docRef = await addDoc(roundCollection, {
        ...data,
        created: serverTimestamp(),
        isLeaderBoardPublic: isLeaderboardPublic,
      });
      const roundId = docRef.id;

      // Handle image upload if available
      let imageUrl = "";
      if (data3.imageFile) {
        imageUrl = await handleUploadImage(roundId, data3.imageFile);
      }

      // Update the round document with roundId and coverImage
      await updateDoc(doc(firestore, "round", roundId), {
        roundId: roundId,
        coverImage: imageUrl,
        channelUrl: response?.data?.channel_url || "",
      });

      // After successfully updating the round, add the round activity
      const uidList = userList.flatMap((user) => {
        const uids = [];
        if (user.player) {
          uids.push(user.player.uid);
        }
        if (user.caddie) {
          uids.push(user.caddie.uid);
        }
        return uids;
      });

      const activityData = {
        roundId: roundId,
        creator: userData.uid,
        userList: uidList,
        caddieReq: data.caddieReq,
      };
      const q = query(activityCollection, where("roundId", "==", roundId));
      const activitySnapshot = await getDocs(q);
      if (!activitySnapshot.empty) {
        await updateDoc(activitySnapshot.docs[0].ref, {
          ...activityData,
          lastUpdate: serverTimestamp(),
        });
      } else {
        await addDoc(activityCollection, {
          ...activityData,
          created: serverTimestamp(),
        });
      }

      // filter user to send noti
      let filteredUser = [];
      userList.forEach((user) => {
        if (
          user.player &&
          user.player.uid === userData.uid &&
          user.player.isCreator
        ) {
          // console.log('you are player creator');
          if (user.caddie) {
            filteredUser.push(user.caddie.uid);
          }
        } else if (
          user.caddie &&
          user.caddie.uid === userData.uid &&
          user.caddie.isCreator
        ) {
          // console.log('you are caddie creator');
          if (user.player) {
            filteredUser.push(user.player.uid);
          }
        } else {
          if (user.player && user.player.uid !== userData.uid) {
            filteredUser.push(user.player.uid);
          }
        }
      });

      await createRoundNotification(
        userData.uid,
        filteredUser,
        data1.roundName,
        roundId
      );
      try {
        let lineIds = [];
        const userList3 = data2.row.map((item) => ({
          row: item.row,
          player: item.player ? { ...item.player } : null,
          caddie: item.caddie ? { ...item.caddie } : null,
        }));

        if (!userList3.length) {
          if (userData.role === "player") {
            lineIds.push(userData.lineUid);
          } else {
            lineIds.push(userData.lineUid);
          }
        } else {
          lineIds = data2.row
            .flatMap((item) => [item.player?.lineUid, item.caddie?.lineUid])
            .filter((id) => id != null);
        }
        let roundId = ""; // roundId
        try {
          for (let index = 0; index < lineIds.length; index++) {
            const q = query(
              collection(firestore, "users"),
              where("lineUid", "==", lineIds[index])
            );

            const querySnapshot = await getDocs(q);

            for (let index2 = 0; index2 < querySnapshot.docs.length; index2++) {
              const doc = querySnapshot.docs[index2];

              if (doc.data().notification?.line) {

                if (lineIds[index] === userData.lineUid) {
                  await sendInviteRoundCreator(
                    lineIds[index],
                    roundId,
                    data1,
                    data3
                  );
                } else {

                  const foundLineIdPlayer = data2.row.find(item => item?.player?.lineUid === lineIds[index]);
                  const foundLineIdCaddie = data2.row.find(item => item?.caddie?.lineUid === lineIds[index]);

                  if (foundLineIdPlayer) {
                    await sendInviteRound(lineIds[index], roundId, data1, data3);
                  }
                  if (foundLineIdCaddie) {
                    const foundCreatorCaddie = userList.find(item => item?.caddie?.uid === foundLineIdCaddie?.caddie?.uid)
                    if (foundCreatorCaddie) {
                      if (foundCreatorCaddie.caddie.playerConfirm) {
                        await sendInviteRound(lineIds[index], roundId, data1, data3);
                      }
                    }
                  }
                }
              }
            }
          }
        } catch (error) {
          console.error('error', error);
        }
      } catch (error) {
        console.error('error', error);
      }

      setCreateLoading(false);

      // Show success message and navigate to round activity page
      Swal.fire({
        title: t(textConvert("Round Created")),
        icon: "success",
        confirmButtonColor: "#ee3c7f",
      }).then(() => {
        navigate("/round-activity");
      });
    } catch (error) {
      setCreateLoading(false);

      console.error("Fail to create round", error);
    }
  };

  return (
    <div className="main-content right-chat-active">
      <div className="middle-sidebar-bottom">
        <div className="middle-sidebar-left">
          <div className="middle-wrap">
            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
              <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                <Link to="/round-activity" className="d-inline-block mt-2">
                  <i className="ti-arrow-left font-sm text-white"></i>
                </Link>
                <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                  {t(textConvert("Create Round"))}{" "}
                  {currentStep !== 4 ? `(${currentStep}/3)` : null}
                </h4>
              </div>
              <div className="card-body p-lg-5 p-4 w-100 border-0">
                <div className="row">
                  <div className="col-lg-12">
                    {userData?.role === "caddie" ? (
                      <>
                        {currentStep === 1 && <StepOneByCaddie />}
                        {currentStep === 2 && <StepTwoByCaddie />}
                        {currentStep === 3 && <StepThreeByCaddie />}
                        {currentStep === 4 && <ReqPreview />}
                      </>
                    ) : (
                      <>
                        {currentStep === 1 && (
                          <StepOneByPlayer
                            preSelectCourse={preSelectedCourse}
                          />
                        )}
                        {currentStep === 2 && <StepTwoByPlayer />}
                        {currentStep === 3 && <StepThreeByPlayer />}
                        {currentStep === 4 && <ReqPreview />}
                      </>
                    )}
                    <div className="text-end">
                      <button
                        className="btn btn-light mx-1 fs-5"
                        onClick={() => prevStep()}
                        disabled={currentStep === 1 ? true : false}
                      >
                        {t(textConvert("Back"))}
                      </button>
                      {currentStep !== 4 ? (
                        <button
                          className="btn bg-current text-white mx-1 fs-5"
                          onClick={() => nextStep()}
                          disabled={isDisabled()}
                        >
                          {t(textConvert("Next"))}
                        </button>
                      ) : (
                        <button
                          className="btn bg-current text-white mx-1 fs-5"
                          onClick={handleRequestClick}
                        >
                          {!createLoading ? (
                            t(textConvert("Send Request"))
                          ) : (
                            <Spinner
                              variant="light"
                              animation="border"
                              role="status"
                              size="sm"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </Spinner>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainWizard;
