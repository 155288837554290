import { firestore } from "../../../../../components/firebase/FirebaseConfig";
import {
  where,
  query,
  getDocs,
  updateDoc,
  collection,
  serverTimestamp,
  doc,
  getDoc,
} from "firebase/firestore";
import Swal from "sweetalert2";
import { updateScoreCard } from "./updateScoreCard";
import axios from "axios";
import { getPlayingRound } from "../../../roundactivity/startround/module/getPlayingRound";
import { textConvert } from "../../../../../components/language/dropdown/textconvert";

const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;

export const confirmJoinCaddie = async (
  roundData,
  userData,
  yourPlayer,
  setLoading,
  navigate,
  userList,
  t
) => {
  try {

    setLoading(true);

    // check playing round
    const foundPlayingRound = await getPlayingRound(userData.uid, true);
    if (foundPlayingRound.length > 0 && roundData.teeTime !== 'schedule') {
      const foundCurrentRound = foundPlayingRound.find(item => item.roundId === roundData.roundId);
      if (foundCurrentRound) {
        Swal.fire({
          text: t(textConvert("You are already in this round")),
          icon: "error",
          confirmButtonColor: "#ee3c7f",
        });
        setLoading(false);
        return;
      }
      Swal.fire({
        title: t(textConvert("You are already playing a round")),
        text: t(textConvert("You can only join one round at a time Please finish your current round before joining another round")),
        icon: "error",
        confirmButtonColor: "#ee3c7f",
      });
      setLoading(false);
      return;
    }

    // check caddie course
    const caddieReqQ = query(collection(firestore, 'caddieRequests'),
      where('courseId', '==', roundData.course),
      where('caddieId', '==', userData.uid),
      where('status', 'in', ['pending', 'approved']),
    )
    const caddieReqSnap = await getDocs(caddieReqQ);
    if (caddieReqSnap.empty) {
      Swal.fire({
        title: t(textConvert("Can not join")),
        text: t(textConvert("You are not caddie in this course")),
        icon: "error",
        confirmButtonColor: "#ee3c7f",
      });
      setLoading(false);
      return;
    }

    const foundCaddie = roundData.userList.find(
      (item) => item.caddie && item.caddie.uid === userData.uid
    );

    if (foundCaddie) {
      // invied caddie
      if (yourPlayer) {
        // found your player
        foundCaddie.caddie.isJoin = true;
        foundCaddie.player = {
          isCreator: yourPlayer.isCreator || false,
          isJoin: yourPlayer.isJoin || false,
          uid: yourPlayer.uid,
        };
      } else {
        // not found your player
        foundCaddie.caddie.isJoin = true;
        foundCaddie.player = null;
      }
    } else {
      // no invite caddie
      const foundNewCaddie = userList.find(
        (item) => item.caddie && item.caddie.uid === userData.uid
      );
      if (foundNewCaddie) {
        // found new caddie (you)
        const foundNewCaddieRow = roundData.userList.find(
          (item) => item.row === foundNewCaddie.row
        );
        if (foundNewCaddieRow) {
          // found your selected row
          if (yourPlayer) {
            foundNewCaddieRow.caddie = {
              uid: userData.uid,
              isCreator: false,
              isJoin: true,
              playerConfirm: true,
            };
            foundNewCaddieRow.player = {
              uid: yourPlayer.uid,
              isCreator: yourPlayer.isCreator || false,
              isJoin: yourPlayer.isJoin || false,
            };
          } else {
            // not found your player
            foundNewCaddieRow.caddie = {
              uid: userData.uid,
              isCreator: false,
              isJoin: true,
              playerConfirm: true,
            };
            foundNewCaddieRow.player = null;
          }
        }
      } else {
        // not found new caddie (you)
        setLoading(false);
        return;
      }
    }

    // console.log(roundData);

    // return
    const docRef = doc(firestore, "round", roundData.roundId);
    const roundSnapshot = await getDoc(docRef);

    if (roundSnapshot.exists()) {
      //   // Find the user in the provided roundData.userList
      //   const foundRoundDataUser = roundData.userList.find(
      //     (item) => item.caddie && item.caddie.uid === userData.uid
      //   );

      //   // Retrieve the original userList from Firestore
      //   const originalUserList = roundSnapshot.data()?.userList || [];

      //   // Update the userList based on whether we found the user in roundData
      //   const updatedUserList = originalUserList.map((user) => {
      //     if (user.caddie && user.caddie.uid === userData.uid) {
      //       // If found in roundData, use that; otherwise, use the original data
      //       return foundRoundDataUser ? { ...user, ...foundRoundDataUser } : user;
      //     }
      //     return user;
      //   });

      //   // Write the updated list back to Firestore
      //   if (foundCaddie) {
      //     await updateDoc(docRef, {
      //       userList: updatedUserList,
      //       lastUpdate: serverTimestamp(),
      //     });
      //   } else {
      //     await updateDoc(docRef, {
      //       userList: roundData.userList,
      //       lastUpdate: serverTimestamp(),
      //     });
      //   }

      if (foundCaddie) {
        const foundRoundDataUser = roundData.userList.find(
          (item) => item.caddie && item.caddie.uid === userData.uid
        );

        // Retrieve the original userList from Firestore
        const originalUserList = roundSnapshot.data()?.userList || [];

        // Update the userList based on whether we found the user in roundData
        const updatedUserList = originalUserList.map((user) => {
          if (user.caddie && user.caddie.uid === userData.uid) {
            // If found in roundData, use that; otherwise, use the original data
            return foundRoundDataUser
              ? { ...user, ...foundRoundDataUser }
              : user;
          }
          return user;
        });

        await updateDoc(docRef, {
          userList: updatedUserList,
          lastUpdate: serverTimestamp(),
        });
      } else {
        const originalUserList = roundSnapshot.data()?.userList || [];

        // ค้นหาข้อมูลของ user ปัจจุบันจาก roundData.userList
        const foundRoundDataUser = roundData.userList.find(
          (item) => item.caddie && item.caddie.uid === userData.uid
        );

        let positionOccupied = false; // ใช้สำหรับตรวจสอบว่าตำแหน่งไม่ว่าง

        // ตรวจสอบและอัปเดต userList
        const updatedUserList = originalUserList.map((user, index) => {
          if (
            roundData.userList[index]?.caddie?.uid === userData.uid // ต้องตรงกับ UID ที่ต้องการอัปเดต
          ) {
            if (user.caddie === null) {
              // อัปเดตเฉพาะตำแหน่งที่ว่าง
              return {
                ...user,
                caddie: foundRoundDataUser.caddie, // ใช้ข้อมูลใหม่
              };
            } else {
              positionOccupied = true; // พบตำแหน่งที่ไม่ว่าง
            }
          }
          return user; // คงค่าเดิมถ้าไม่ตรงเงื่อนไข
        });

        if (positionOccupied) {
          // แจ้งเตือนเมื่อพบตำแหน่งที่ไม่ว่าง
          Swal.fire({
            icon: "warning",
            title: "The position is not available.",
            text: "This position is already occupied. Please choose another position.",
            confirmButtonText: "#ee3c7f",
          });
        } else {
          // อัปเดต Firestore ถ้าตำแหน่งว่าง
          await updateDoc(docRef, {
            userList: updatedUserList,
            lastUpdate: serverTimestamp(),
          });
        }
      }
    } else {
      console.error(`Round document not found: ${roundData.roundId}`);
    }

    await updateScoreCard(roundData.userList, roundData.roundId);

    const userUids = roundData.userList.reduce((acc, item) => {
      if (item.player && item.player.uid) {
        acc.push(item.player.uid);
      }
      if (item.caddie && item.caddie.uid) {
        acc.push(item.caddie.uid);
      }
      return acc;
    }, []);

    const roundActivityCollection = collection(firestore, "roundActivity");
    const q = query(
      roundActivityCollection,
      where("roundId", "==", roundData.roundId)
    );
    const roundActivitySnapshot = await getDocs(q);
    if (!roundActivitySnapshot.empty) {
      const activityRef = roundActivitySnapshot.docs[0].ref;
      updateDoc(activityRef, {
        userList: userUids,
        lastUpdate: serverTimestamp(),
      });
    }

    if (roundData.groupChat === "create" && roundData.channelUrl) {
      console.log(userData.dooNineId);
      console.log(roundData.channelUrl);

      try {
        const response = await axios.put(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${roundData.channelUrl}/accept`,
          { user_id: userData.dooNineId },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": sendBird_api_token,
            },
          }
        );
        console.log("Joined successfully:", response.data);
        // const channelInfo = await axios.get(
        //     `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/users/${userData.dooNineId}/channel_invitation_preference`,
        //     {
        //         headers: { "Api-Token": sendBird_api_token }
        //     }
        // );
        // console.log("Channel Info:", channelInfo.data);
      } catch (error) {
        if (error.response) {
          console.error("Failed to join group chat:", error.response.data);
        } else {
          console.error("Error:", error.message);
        }
      }
    }

    setLoading(false);

    Swal.fire({
      title: t(textConvert("Joined")),
      icon: "success",
      confirmButtonColor: "#ee3c7f",
    }).then(() => {
      navigate("/round-activity");
    });
  } catch (error) {
    setLoading(false);
    console.error("Fail to join", error);
  }
};
