import React from 'react'
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite.css";
import { InstantSearch } from "react-instantsearch";
import CourseV2 from './CourseV2';
import { FilterProvider, useFilter } from './module/filterContext';

const searchClient = algoliasearch("RJJNPL7MRL", "bb21db75be816cbedc8dd1b5838e4bba");

function CoursesIndex() {
    return (
        <FilterProvider>
            <CourseRoot />
        </FilterProvider>
    )
}

export default CoursesIndex

function CourseRoot() {
    const { triggerKey } = useFilter();
    return (
        <React.Fragment key={triggerKey}>
            <InstantSearch
                searchClient={searchClient}
                indexName="courses"
            >
                <CourseV2 />
            </InstantSearch>
        </React.Fragment>
    )

}
