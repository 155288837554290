import React, { useEffect, useState } from 'react'

import { firestore } from '../../../../../components/firebase/FirebaseConfig'
import { where, query, collection, getDocs } from 'firebase/firestore'

import { useTranslation } from 'react-i18next'
import { textConvert } from '../../../../../components/language/dropdown/textconvert'
const TotalPost = ({ userData }) => {

    const { t } = useTranslation();
    const [postCount, setPostCount] = useState(0);
    const [postLoading, setPostLoading] = useState(true)

    useEffect(() => {

        async function getPost() {
            try {
                const postCollection = collection(firestore, 'posts');
                const q = query(postCollection, where('userId', '==', userData.uid), where('audience', '!=', 'only_me'));
                const postSnapshot = await getDocs(q);
                setPostCount(postSnapshot.size);
                setPostLoading(false);
            } catch (error) {
                setPostLoading(false);
                console.error('fail to get post count', error);
            }
        }
        if (userData) {
            getPost()
        }
    }, [userData])



    return (
        <div className="d-flex justify-content-start align-items-center p-3 rounded-3 mb-2" style={{ backgroundColor: '#FAF0E9' }}>
            <strong className="text-center py-1 px-3 fs-1 fw-bolder me-2 rounded-3 text-white" style={{ backgroundColor: '#E59E49' }}>
                {!postLoading ?
                    postCount
                    :
                    <div className="spinner-border spinner-border-sm mb-2" role="status">
                        <span className="sr-only"></span>
                    </div>
                }
            </strong>
            <strong className="fs-4">{t(textConvert('Total Posts'))}</strong>
        </div>
    )
}

export default TotalPost