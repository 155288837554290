import { collection, deleteDoc, doc, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import Swal from "sweetalert2";
import { addFriend } from "../../../components/friend/AddFriend";

export const handleFindFriendByDooNineId = async (
    userData,
    findDooNineId,
    setResultDooNineState,
    setResultDooNine,
    setIsFriendRequest
) => {

    // check is findDooNineId is email format
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(findDooNineId)) {
        console.log("email");
    }

    const qDooNineId = query(
        collection(firestore, "users"),
        where("dooNineId", "==", findDooNineId)
    );

    const qEmail = query(
        collection(firestore, "users"),
        where("email", "==", findDooNineId)
    );

    const [querySnapshotDooNineId, querySnapshotEmail] = await Promise.all([
        getDocs(qDooNineId),
        getDocs(qEmail),
    ]);

    let userDoc = null;
    let canSearchByDooNineId = false;
    let canSearchByEmail = false;

    let isMySelfBlock = false;
    let isBlocked = false;

    if (querySnapshotDooNineId.size > 0) {
        userDoc = querySnapshotDooNineId.docs[0];
        canSearchByDooNineId = userDoc.data().privacy?.dooNineIdSearch ?? false;
    }

    if (querySnapshotEmail.size > 0) {
        userDoc = querySnapshotEmail.docs[0];
        canSearchByEmail = userDoc.data().privacy?.emailSearch ?? false;
    }

    //Check is Block
    const q_Block = query(collection(firestore, "blockedUsers"), where("userId", "==", userData.uid), where("blockedUserId", "==", userDoc.data().uid));
    const querySnapshot_Block = await getDocs(q_Block);
    querySnapshot_Block.forEach((docBlock) => {
        isMySelfBlock = true
    });


    //Check is Block
    const q_Blocked = query(collection(firestore, "blockedUsers"), where("userId", "==", userDoc.data().uid), where("blockedUserId", "==", userData.uid));
    const querySnapshot_Blocked = await getDocs(q_Blocked);
    querySnapshot_Blocked.forEach((docBlocked) => {
        isBlocked = true
    });




    if (
        findDooNineId == userData.dooNineId ||
        findDooNineId == userData.email
    ) {
        setResultDooNineState("myself");
    } else if (isMySelfBlock) {
        // console.log('เราบล็อคเขา');
        setResultDooNine(userDoc.data());
        setResultDooNineState("my-self-block");
    } else if (isBlocked) {
        // console.log('เขาบล็อคเรา');
        setResultDooNine(userDoc.data());
        setResultDooNineState("me-blocked-by-user");
    }
    else if (userDoc && (canSearchByDooNineId || canSearchByEmail)) {

        let isFriend = false;
        setIsFriendRequest(false);

        const q3 = query(
            collection(firestore, "friends"),
            where("friendId", "==", userDoc.data().uid),
            where("userId", "==", userData?.uid)
        );

        const q2 = query(
            collection(firestore, "friends"),
            where("friendId", "==", userData?.uid),
            where("userId", "==", userDoc.data().uid)
        );

        const [querySnapshot2, querySnapshot3] = await Promise.all([
            getDocs(q2),
            getDocs(q3),
        ]);

        querySnapshot2.forEach((doc2) => {
            if (doc2.data().status == "active") {
                isFriend = true;
            }
        });

        setResultDooNine(userDoc.data());

        querySnapshot3.forEach((doc3) => {
            if (doc3.data().status == "active") {
                isFriend = true;
            }
        });

        const queryFriendRequest = query(
            collection(firestore, "friendRequests"),
            where("fromUserId", "==", userData?.uid),
            where("toUserId", "==", userDoc.data().uid)
        );

        const querySnapshotFriendRequest = await getDocs(queryFriendRequest);
        querySnapshotFriendRequest.forEach((doc4) => {
            if (doc4.data().status == "pending") {
                setIsFriendRequest(true);
            }
        });

        setResultDooNineState(isFriend ? "friend" : "not-friend");
    } else {
        setResultDooNineState("user-not-found");
    }
};

export const handleProfileClick = (userData, uid, navigate) => {
    if (!uid) return;

    if (uid === userData.uid) {
        navigate("/userPage");
    } else {
        navigate("/user", { state: { uid: uid } });
    }
};

export const handleAddFriend = (friendId, userId, isFriendRequest, setIsLoading, setIsFriendRequest) => {
    if (!isFriendRequest) {
        setIsLoading(true);

        setTimeout(() => {
            addFriend(friendId, userId);
            setIsFriendRequest(true);
            setIsLoading(false);
        }, 500);
    } else {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to cancel the friend request.",
            icon: "warning",
            cancelButtonText: "Cancel",
            confirmButtonText: "Ok",
            showCancelButton: true,
            customClass: {
                cancelButton: "post-warning-cancel-btn",
                confirmButton: "post-warning-confirm-btn",
                actions: "post-warning-actions",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                const q2 = query(
                    collection(firestore, "friendRequests"),
                    where("fromUserId", "==", userId),
                    where("toUserId", "==", friendId)
                );

                const querySnapshot2 = await getDocs(q2);

                querySnapshot2.forEach(async (doc3) => {
                    try {
                        // ลบเอกสาร
                        await deleteDoc(doc(firestore, "friendRequests", doc3.id));
                        // console.log(`Document with ID ${doc3.id} deleted successfully.`);
                    } catch (error) {
                        console.error("Error deleting document:", error);
                    }
                });
                setIsFriendRequest(false);
            }
        });
    }
};

export const handleUnblock = (userData, user, handleClose) => {

    Swal.fire({
        title: "Are you sure?",
        text: "You want to unblock.",
        icon: "warning",
        cancelButtonText: "Cancel",
        confirmButtonText: "Ok",
        showCancelButton: true,
        customClass: {
            cancelButton: "post-warning-cancel-btn",
            confirmButton: "post-warning-confirm-btn",
            actions: "post-warning-actions",
        },
    }).then(async (result) => {
        if (result.isConfirmed) {

            const blockedUsersRef = collection(firestore, "blockedUsers");

            const q = query(blockedUsersRef, where("blockedUserId", "==", user.uid), where("userId", "==", userData.uid));

            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(async (docSnapshot) => {
                await deleteDoc(docSnapshot.ref);
            });


            handleClose();
            //Show success message
            Swal.fire({
                title: "Unblock Success",
                icon: "success",
                confirmButtonText: "Ok",
                customClass: {
                    confirmButton: "post-warning-confirm-btn",
                },
            })

        }
    });

};