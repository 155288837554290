import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { countHole } from '../../scoreV2/module/countHole';
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../components/language/dropdown/textconvert';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function DetailModal({ isShow, closeModal, userScore }) {
    const { t } = useTranslation();
    const userData = useSelector((state) => state.user.data);
    const headContainerRef = useRef(null);
    const [nameWidthPlayer, setNameWidthPlayer] = useState(200);
    const [nameWidthCaddie, setNameWidthCaddie] = useState(200);
    const [isBoth, setIsBoth] = useState(false);

    useEffect(() => {
        if (!userScore) return;

        if (userScore.caddieData && userScore.playerData) {
            setIsBoth(true);
        }

        if (isShow && headContainerRef.current) {
            const totalWidth = headContainerRef.current.offsetWidth;
            // totalwidth - playerImg - nameMargin
            const playerWidth = totalWidth - 50 - 24;
            setNameWidthPlayer(playerWidth);

            // totalwidth - absoluteLeft - caddieImg - nameMargin
            const caddieWidth = totalWidth - 20 - (isBoth ? 40 : 50) - 24;
            setNameWidthCaddie(caddieWidth);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow, userScore])

    if (!userScore) return null;
    // console.log(foundScore);

    return (
        <Modal show={isShow} onHide={closeModal} centered animation={false}>
            <Modal.Body>
                <div className='position-relative' ref={headContainerRef} style={{ width: 'auto', height: isBoth ? 80 : 'auto' }}>
                    {/* <i className='bi bi-x-lg fs-2 position-absolute' style={{ top: -15, right: -10 }} onClick={closeModal}></i> */}

                    {userScore.playerData ?
                        <Link to={userScore.playerData.uid === userData.uid ? '/userPage' : `/user?uid=${userScore.playerData.uid}`}>
                            <div className={clsx('d-flex align-items-center', {
                                'position-absolute': userScore.caddieData
                            })}>
                                <img src={userScore.playerData.profileImage} alt="player" width={50} height={50} className='rounded-circle player-color-outline' />
                                <div className='ms-4' style={{ maxWidth: nameWidthPlayer }}>
                                    <h3 className='fw-600 text-truncate'>{`${userScore.playerData.firstName} ${userScore.playerData.lastName}`}</h3>
                                </div>
                            </div>
                        </Link>
                        :
                        null
                    }
                    {userScore.caddieData ?
                        <Link to={userScore.caddieData.uid === userData.uid ? '/userPage' : `/user?uid=${userScore.caddieData.uid}`}>
                            <div className={clsx('d-flex align-items-center', {
                                'position-absolute': userScore.playerData
                            })} style={{ bottom: 0, left: 20 }}>
                                <img src={userScore.caddieData.profileImage} alt="caddie" width={isBoth ? 40 : 50} height={isBoth ? 40 : 50} className='rounded-circle caddie-color-outline' />
                                <div className='ms-4' style={{ maxWidth: nameWidthCaddie }}>
                                    {isBoth ?
                                        <h5 className='fw-600 text-truncate'>{`${userScore.caddieData.firstName} ${userScore.caddieData.lastName}`}</h5>
                                        :
                                        <h3 className='fw-600 text-truncate'>{`${userScore.caddieData.firstName} ${userScore.caddieData.lastName}`}</h3>
                                    }
                                </div>
                            </div>
                        </Link>
                        :
                        null
                    }
                </div>
            </Modal.Body>
            <Modal.Body className='py-0'>
                <div className='bg-light rounded-3 p-2'>
                    <div className='d-flex justify-content-around align-items-center mb-2'>
                        <div className='d-flex flex-column align-items-center col-6'>
                            <h1 className='fw-bold text-current'>{userScore.combinePosition}</h1>
                            <h6 className='fw-bold'>{t(textConvert('Position'))}</h6>
                        </div>
                        <div className='d-flex flex-column align-items-center col-6'>
                            <h1 className='fw-bold text-current'>{userScore.roundPosition}</h1>
                            <h6 className='fw-bold'>{t(textConvert('Group Position'))}</h6>
                        </div>
                    </div>
                    <div className='bg-white p-3 text-center rounded-3'>
                        <div className='d-flex justify-content-between mb-2'>
                            <div className='d-flex flex-column align-items-center justify-content-start col-4'>
                                <h3 className='fw-bold text-danger'>{isNaN(userScore.sumPenal) ? "-" : userScore.sumPenal}</h3>
                                <h6 className='fw-500 text-danger'>{t(textConvert('Penalties'))}</h6>
                            </div>
                            <div className='d-flex flex-column align-items-center col-4'>
                                <h1 className='fw-bold'>{userScore.sumScore}</h1>
                                <h4 className='fw-500'>{t(textConvert('Score'))}</h4>
                            </div>
                            <div className='d-flex flex-column align-items-center justify-content-start col-4'>
                                <h3 className='fw-bold'>{isNaN(userScore.sumPutt) ? "-" : userScore.sumPutt}</h3>
                                <h6 className='fw-500'>{t(textConvert('Putt'))}</h6>
                            </div>
                        </div>
                        <h4 className='fw-bold text-capitalize'>{t(textConvert(userScore.status))} {`(H${countHole(userScore)})`}</h4>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='border-0'>
                <button className='btn btn-light fw-600 fs-5' onClick={closeModal}>{t(textConvert('Close'))}</button>
            </Modal.Footer>
        </Modal >
    )
}

export default DetailModal