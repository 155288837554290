import React, { Fragment, useEffect, useState } from "react";
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../components/language/dropdown/textconvert";

function MyQRCodeModal({ QRCodeModal, setQRCodeModal }) {
  const { t } = useTranslation()
  const [qrCodeValue, setQrCodeValue] = useState("");
  const [userShareLinkDocId, setUserShareLinkDocId] = useState("");

  const userData = useSelector((state) => state.user.data);

  useEffect(() => {
    async function fetchData() {
      if (userData?.privacy?.dooNineIdSearch == true) {
        const q = query(
          collection(firestore, "userShareLinks"),
          where("userId", "==", userData.uid)
        );

        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          // Create document
          const timestampInSecond = Math.round(new Date().getTime() / 1000);

          const docRef = await addDoc(collection(firestore, "userShareLinks"), {
            userId: userData.uid,
            createdAt: timestampInSecond,
            linkClickCount: 0,
            qrScanCount: 0,
          });

          setUserShareLinkDocId(docRef.id);
        } else {
          querySnapshot.forEach((doc) => {
            setUserShareLinkDocId(doc.id);
          });
        }
      }
    }

    if (userData) {
      fetchData();
    }
  }, [userData]);

  useEffect(() => {
    function getCurrentBaseURL() {
      return window.location.host; // ดึง hostname และ port จาก URL ปัจจุบัน
    }

    if (userShareLinkDocId) {
      let link = "";

      if (getCurrentBaseURL() == "localhost:3000") {
        link =
          "http://" +
          getCurrentBaseURL() +
          "/add-friend?ref=" +
          userShareLinkDocId;
      } else {
        link =
          "https://" +
          getCurrentBaseURL() +
          "/add-friend?ref=" +
          userShareLinkDocId;
      }

      setQrCodeValue(link);
    }
  }, [userShareLinkDocId]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(qrCodeValue).then(
      () => {
        Swal.fire({
          title: "Success",
          html: `<h5 class="fw-600">Link copied to clipboard.</h5>`,
          icon: "success",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });
      },
      () => {
        Swal.fire("Error", "Failed to copy link", "error");
      }
    );
  };

  const saveQRCode = (data) => {
    const qrCodeElement = document.getElementById("qrCodeEl");

    htmlToImage.toCanvas(qrCodeElement).then((qrCanvas) => {
      const qrCodeWidth = qrCanvas.width;
      const qrCodeHeight = qrCanvas.height;
      const borderWidth = 20;
      const textPadding = 10;
      const textFontSize = 16;
      const lineHeight = textFontSize + 4;

      const canvasWithBorder = document.createElement("canvas");
      const ctx = canvasWithBorder.getContext("2d");

      canvasWithBorder.width = qrCodeWidth + borderWidth * 2;
      canvasWithBorder.height = qrCodeHeight + borderWidth * 2;

      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvasWithBorder.width, canvasWithBorder.height);

      ctx.drawImage(qrCanvas, borderWidth, borderWidth);

      const qrCodeURL = canvasWithBorder
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      const aEl = document.createElement("a");
      aEl.href = qrCodeURL;
      aEl.download = `${"myQRcode"}.png`;
      document.body.appendChild(aEl);
      aEl.click();
      document.body.removeChild(aEl);
    });
  };

  return (
    <>
      <Modal
        show={QRCodeModal}
        onHide={() => setQRCodeModal(false)}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="fw-600 mb-0 mt-0">{t(textConvert('My QR Code'))}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {userData?.privacy?.dooNineIdSearch ? (
            <>
              <div className="row">
                <div className="col-xl-12 mb-3 mt-5">
                  {/* <div
                    id="qr-code"
                    style={{
                      height: "auto",
                      margin: "0 auto",
                      maxWidth: 200,
                      width: "100%",
                      borderStyle: "solid",
                      borderColor: "black",
                      padding: "10px",
                      borderWidth: "5px",
                    }}
                  >
                    <QRCode
                      id="qrCodeEl"
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={qrCodeValue}
                      viewBox={`0 0 256 256`}
                    />
                  </div> */}

                  <div
                    className="mb-10"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <QRCode
                      id="qrCodeEl"
                      size={200}
                      value={qrCodeValue}
                    />
                  </div>

                  <h5 className="fw-600 mt-3 text-center">
                    {t(textConvert('Use a QR code or link to let other users add you as a friend'))}
                  </h5>
                  <h6 className="fw-600 text-muted mt-3 text-center">
                    {qrCodeValue}
                  </h6>
                </div>
              </div>

              <div className="mb-5 text-center">
                <button
                  className="btn bg-greylight mx-2"
                  onClick={() => copyToClipboard()}
                >
                  <h4 className="fw-600 m-0 p-1">{t(textConvert('Copy link'))}</h4>
                </button>

                <button
                  className="btn bg-greylight mx-2"
                  onClick={() => saveQRCode(JSON.stringify(qrCodeValue))}
                >
                  <h4 className="fw-600 m-0 p-1">{t(textConvert('save'))}</h4>
                </button>

                <button
                  className="btn bg-greylight mx-2"
                  onClick={() => setQRCodeModal(false)}
                >
                  <h4 className="fw-600 m-0 p-1">{t(textConvert('close'))}</h4>
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-xl-12 mb-3 mt-3">
                  <h5 className="fw-600 mt-3 text-center">
                    Please enable "Doonine ID search" in Privacy to generate a
                    QR code.
                  </h5>
                </div>
              </div>

              <div className="mb-3 text-center">
                <button
                  className="btn bg-greylight mx-2"
                  onClick={() => setQRCodeModal(false)}
                >
                  <h4 className="fw-600 m-0 p-1">{t(textConvert('close'))}</h4>
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MyQRCodeModal;
