import { addDoc, collection, doc, getDoc, getDocs, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { firestore } from '../../components/firebase/FirebaseConfig';
import LanguageDropdown from '../../components/language/dropdown/DropDownLang';
import { useTranslation } from "react-i18next";
import { textConvert } from "../../components/language/dropdown/textconvert";
import ImageCropper from '../../components/ImageCropper';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { clearUserData } from '../../components/redux/feature/user/userSlice';
import { useAuth } from '../../components/auth/UserContext';
import LoadCircle from '../../components/LoadCircle';
import OTPInput from 'react-otp-input';
import { uploadImageToFirebase } from './Register';

const logo = "assets/images/banner/doonine_revB.png";
const text = "assets/images/banner/doonine_text_2.png";

function RegisterCourse() {
    const { emailRegister, handleCreateUserSendBird, phoneLogin } = useAuth();

    const urlQuery = new URLSearchParams(useLocation().search);
    const courseId = urlQuery.get("id");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { t, i18n } = useTranslation();

    const [otp, setOtp] = useState('');
    const confirmationRef = useRef(null);

    const [name, setName] = useState('');
    const [num, setNum] = useState('');
    const [phone, setPhone] = useState('');

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [verifyPage, setVerifyPage] = useState(false);
    const [croppedModal, setCroppedModal] = useState(false);
    const [croppedImage, setCroppedImage] = useState(null);
    const [profileImageChanged, setProfileImageChanged] = useState(null);
    const [profileImage, setProfileImage] = useState(null);

    let defaultProfileImage = "https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742"

    const [coursedata, setCourseData] = useState({});

    useEffect(() => {
        dispatch(clearUserData());
    }, [dispatch]);


    useEffect(() => {
        async function getCoursedata() {
            try {
                const courseDoc = doc(firestore, 'courses', courseId);
                const courseSnap = await getDoc(courseDoc);
                if (courseSnap.exists()) {
                    // console.log(courseSnap.data());
                    setCourseData(courseSnap.data())
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Course not found',
                        confirmButtonColor: '#ee3d7f',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        navigate('/login');
                    })
                }

            } catch (error) {
                console.error('Fail to get course data', error);
            }
        }


        if (courseId) {
            getCoursedata();
        }
    }, [courseId, navigate])

    const showCoppedModal = () => setCroppedModal(true);
    const hideCroppedModal = () => setCroppedModal(false);

    const handleCroppedImage = (file, url) => {
        setCroppedImage(url);
        setProfileImage(file);
    };

    const handleImageChange = (e) => {
        handleImage(e);
        // Reset the input value to allow selecting the same file again
        e.target.value = null;
    };

    function handleImage(e) {
        const file = e.target.files[0];
        if (file) {
            // console.log(file);
            setProfileImageChanged(URL.createObjectURL(file));
            // setProfileImage(file);
            showCoppedModal();
        }
    }

    const handleSubmit = async (e) => {

        e.preventDefault(); // Prevent default form submission
        setLoading(true);

        const name = document.getElementById('name-input').value;
        const caddieNumber = document.getElementById('number-input').value;
        setName(name);
        setNum(caddieNumber);

        const contact = document.getElementById('email-phone').value.trim();
        const password = document.getElementById('password').value;
        const rePassword = document.getElementById('re-password').value;

        // Regular Expressions
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^\+?\d{10}$/; // Matches 10-15 digit phone numbers with optional "+"

        // Check if contact is email or phone number
        if (!emailRegex.test(contact) && !phoneRegex.test(contact)) {
            setError(t(textConvert('invalid email or phone')));
            return;
        }

        // Check if passwords length
        if (password.length < 6) {
            setError(t(textConvert("Password must be at least six characters")));
            return;
        }

        // Check if passwords match
        if (password !== rePassword) {
            setError(t(textConvert("Password not matched Try again")));
            return;
        }

        if (emailRegex.test(contact) && !phoneRegex.test(contact)) {
            // const user = await emailRegister(contact, password);
            // if (!user.uid) {
            //     setError(user);
            //     setLoading(false);
            //     return;
            // }
            console.warn('use phone number not email');

        } else if (phoneRegex.test(contact) && !emailRegex.test(contact)) {
            const isConfirm = await phoneLogin(contact);
            if (isConfirm) {
                confirmationRef.current = isConfirm;
                setVerifyPage(true);
                setPhone(contact);
            } else {
                confirmationRef.current = null;
                setVerifyPage(false);
                setPhone('');
            }
        } else {
            setError(t(textConvert('invalid email or phone')));
            return;
        }

        setLoading(false);
        setError(null);
    };

    ////////////////////////////////////////////////
    const handleVerify = async () => {

        setLoading(true);
        try {
            const result = await confirmationRef.current.confirm(otp);
            if (result) {
                await createUserFirestore(result.user.uid);
                window.location.href = '/home'
            }
        } catch (error) {
            console.error('Failed to confirm verify code', error);
        }
        setLoading(false);
    }

    async function createUserFirestore(uid) {
        try {
            const q = query(collection(firestore, 'users'), where('uid', '==', uid));
            const userSnap = await getDocs(q);
    
            let profileImageUrl = defaultProfileImage;
            if (profileImage) {
                profileImageUrl = await uploadImageToFirebase(profileImage, uid);
            }
    
            const dooNineId = `${name.replace(/\s+/g, "")}_${uid.slice(0, 2)}${uid.slice(3, 5)}`;
    
            if (userSnap.empty) {
                const registerData = {
                    firstName: name || "",
                    lastName: "",
                    email: "",
                    birthDate: {
                        day: "",
                        month: "",
                        year: "",
                    },
                    gender: "",
                    mobile: phone || "",
                    uid: uid || "",
                    profileImage: profileImageUrl || "",
                    role: "caddie",
                    dooNineId: dooNineId || "",
                    sendBirdAccessToken: "",
                    created: serverTimestamp(),
                    privacy: {
                        dooNineIdSearch: true,
                        emailSearch: false,
                        friendsPost: true,
                        caddiePost: true,
                        playerPost: true,
                        defaultAudience: "public",
                    },
                };
    
                // Add document and wait for it to complete
                await addDoc(collection(firestore, "users"), registerData);
                
                // Call courseReq only after addDoc succeeds
                await courseReq(uid);
    
                setError('');
            } else {
                setError(t(textConvert('Your phone number already used')));
            }
        } catch (error) {
            console.error("Error creating user: ", error);
            setError("Failed to create user. Please try again.");
        }
    }
    

    const courseReq = async (uid) => {

        try {
            const q_check_caddieRequest = query(
                collection(firestore, "caddieRequests"),
                where("courseId", "==", courseId),
                where("caddieNumber", "==", num)
            );
            const querySnapshot_check_caddieRequest = await getDocs(q_check_caddieRequest);

            const q_check_caddieAssignment = query(
                collection(firestore, "caddieAssignments"),
                where("courseId", "==", courseId),
                where("caddieNumber", "==", num)
            );
            const querySnapshot_check_caddieAssignment = await getDocs(q_check_caddieAssignment);

            if (
                (querySnapshot_check_caddieRequest.size > 0 ||
                    querySnapshot_check_caddieAssignment.size > 0
                ) && num !== "") {
                Swal.fire({
                    title: "Error!",
                    text: t(textConvert("This caddie number is already in use")),
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: "#EE3C7F",
                });
            } else {
                const timestampInSecond = Math.round(Date.now() / 1000);
                const q = query(
                    collection(firestore, "caddieRequests"),
                    where("courseId", "==", courseId),
                    where("caddieId", "==", uid)
                );
                const querySnapshot = await getDocs(q);
                if (querySnapshot.size === 0) {
                    await addDoc(
                        collection(firestore, "caddieRequests"),
                        {
                            caddieNumber: num || "",
                            caddiePhone: phone || "",
                            note: "",
                            caddieId: uid,
                            courseId: courseId || "",
                            status: "pending",
                            timestamp: timestampInSecond,
                            requestTimestamp: timestampInSecond,
                        }
                    );
                } else {
                    querySnapshot.forEach(async (doc_) => {
                        const caddieReqRef = doc(firestore, "caddieRequests", doc_.id);
                        await updateDoc(caddieReqRef, {
                            caddieNumber: num || "",
                            caddiePhone: phone || "",
                            note: "",
                            status: "pending",
                            timestamp: timestampInSecond,
                            requestTimestamp: timestampInSecond,
                        });
                    });
                }
            }
        } catch (error) {
            console.error("Fail to send request", error);
        }
    };

    return (
        <>
            <div className="main-wrap">
                <div className="nav-header bg-transparent shadow-none border-0 d-md-none d-block" style={{ backdropFilter: 'blur(2px)' }}>
                    <div className="nav-top w-100 d-flex justify-content-between">
                        <div className='log-container d-flex align-items-center'>
                            <img src={logo} alt='logo' width={55} />
                            <img src={text} alt='logo' width={130} />
                        </div>
                        {/* <button className="nav-menu me-0 ms-auto"></button> */}

                        <LanguageDropdown />
                        {/* <a href="/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                          <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a> */}
                    </div>
                </div>

                <div className="nav-header bg-transparent shadow-none border-0 d-none d-md-block">
                    <div className="nav-top w-100 d-flex justify-content-between">
                        <div className='log-container d-flex align-items-center p-2'>
                            <img src={logo} alt='logo' width={55} />
                            <img src={text} alt='logo' width={180} />
                        </div>
                        {/* <button className="nav-menu me-0 ms-auto"></button> */}

                        <LanguageDropdown />
                        {/* <a href="/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                          <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a> */}
                    </div>
                </div>
                <div className="row" style={{ height: '100dvh', overflowY: 'scroll' }}>
                    <div className="col-xl-7 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                        style={{ backgroundImage: `url('assets/images/login/login_resized.jpg')` }}>
                    </div>
                    <div className="col-xl-5 vh-md-100-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
                        <div className="card shadow-none border-0 ms-auto me-auto login-card">
                            <div className="card-body rounded-0 text-left">
                                <h2 className="fw-700 display1-size display2-md-size mb-4 mt-3 text-center">
                                    {t(textConvert('Create your account caddie'))}
                                </h2>
                                {/*  */}
                                {!verifyPage ?
                                    <>
                                        <div className="image-profile-container mb-3 text-center">
                                            <div className="image-profile text-center">
                                                <img
                                                    src={croppedImage || defaultProfileImage}
                                                    className="border border-2 p-1"
                                                    alt="profile"
                                                    style={{ objectFit: "cover", borderRadius: 20 }}
                                                    width="160px"
                                                    height="160px"
                                                />
                                                <input
                                                    className="d-none"
                                                    id="upload-img"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                />
                                            </div>
                                            <i
                                                className="feather-camera rounded-circle border border-2 p-2 bg-light shadow-lg"
                                                onClick={() =>
                                                    document.getElementById("upload-img").click()
                                                }
                                                style={{ fontSize: 25 }}
                                            ></i>
                                        </div>

                                        <label htmlFor="course">{t(textConvert('course'))}</label>
                                        <div className='p-2 bg-light rounded-3' id='course'>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <img src={coursedata.profileImage} alt="course" width={50} height={50} className='rounded-circle me-2' />
                                                {i18n.language === 'th' ?
                                                    <h4 className='fw-600'>{coursedata.courseNameThai}</h4>
                                                    :
                                                    <h4 className='fw-600'>{coursedata.courseName}</h4>
                                                }
                                            </div>
                                        </div>

                                        <form onSubmit={handleSubmit}>

                                            <label htmlFor="name-input">{t(textConvert('name reg'))}<span className="text-danger fs-5">*</span></label>
                                            <input type="text" className='form-control' id='name-input' required />

                                            <label htmlFor="number-input">{t(textConvert('Caddie no'))}<span className="text-danger fs-5">*</span></label>
                                            <input type="text" className='form-control' id='number-input' required />

                                            <label htmlFor="email-phone">{t(textConvert('Phone'))}<span className="text-danger fs-5">*</span></label>
                                            <input type="tel" className='form-control' id='email-phone' required />

                                            <div className='d-flex gap-2'>
                                                <div className='w-50'>
                                                    <label htmlFor="password">{t(textConvert('Password'))}<span className="text-danger fs-5">*</span></label>
                                                    <input type="password" className='form-control' id='password' required />
                                                </div>

                                                <div className='w-50'>
                                                    <label htmlFor="re-password">{t(textConvert('Re-Password'))}<span className="text-danger fs-5">*</span></label>
                                                    <input type="password" className='form-control' id='re-password' required />
                                                </div>
                                            </div>

                                            <div className='text-danger mt-3'>{error}</div>
                                            <button className='btn btn-primary w-100 p-2 fs-3' type='submit' id='register-course-button'>
                                                {!loading ?
                                                    t(textConvert('Register'))
                                                    :
                                                    <LoadCircle size='sm' variant='light' />
                                                }
                                            </button>
                                        </form>
                                    </>
                                    :
                                    <div className='text-center'>
                                        <h3 className='fw-600'>{t(textConvert('please enter your sms verify code'))}</h3>
                                        <div className='my-3'>
                                            <OTPInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={6}
                                                renderSeparator={<span className='mx-1'>-</span>}
                                                renderInput={(props) => <input {...props} />}
                                                inputStyle={'w-100 h-100 border-grey'}
                                                inputType='tel'
                                                containerStyle={{ height: 40 }}
                                            />
                                        </div>
                                        <button className='btn btn-primary fs-3 p-2 w-50' onClick={() => handleVerify()}>
                                            {!loading ?
                                                t(textConvert('confirm'))
                                                :
                                                <LoadCircle size='sm' variant='light' />
                                            }
                                        </button>
                                    </div>
                                }
                                {/*  */}


                                <div className="col-sm-12 p-0 text-left">
                                    <h6 className="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                                        {t(textConvert("Already have account"))}{" "}
                                        <a href="/login" className="fw-700 ms-1">
                                            {t(textConvert("Login"))}
                                        </a>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <ImageCropper
                show={croppedModal}
                hide={hideCroppedModal}
                profileImage={profileImageChanged}
                croppedImage={handleCroppedImage}
            />
        </>
    )
}

export default RegisterCourse
