
import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { Modal, Form } from 'react-bootstrap';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import imageCompression from 'browser-image-compression';

import { firestore, storage } from '../../../components/firebase/FirebaseConfig';
import { collection, addDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import './createPostStyle.css'
import Slider from "react-slick";
import { ReactSVG } from 'react-svg'
import SelectedPrivBtn from '../postview/utilsfunction/SelectedPriv';
import { useNavigate } from 'react-router-dom';
import { imageConverter } from '../../../components/imageConverter';
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../components/language/dropdown/textconvert';

const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';

const sliderStyle = {
    swipeToSlide: true,
    arrows: false,
    dots: false,
    infinite: false,
    centerMode: false,
    variableWidth: true,
    autoplay: false,
    mobileFirst: true
}


function CreatePost({ isRefresh }) {
    const { t } = useTranslation();

    const [postModal, setPostModal] = useState(false);
    const navigate = useNavigate();

    const postTextRef = useRef(null);
    const [imageList, setImageList] = useState([]);
    const [showImageList, setShowImageList] = useState([]);
    const [loading, setLoading] = useState(false);

    const userData = useSelector((state) => state.user.data);

    const isMobile = useRef(window.matchMedia("(max-width: 768px)").matches);

    const [selectedPrivacy, setSelectedPrivacy] = useState(null);

    useEffect(() => {
        if (userData && !selectedPrivacy) {
            setSelectedPrivacy(userData?.privacy?.defaultAudience);
        }
    }, [userData, selectedPrivacy])


    const handleSelectPriv = (selectedPriv) => {
        setSelectedPrivacy(selectedPriv);
    }

    function postHandle(e) {
        e.preventDefault();
        setPostModal(true);
    }

    function handleHide() {
        if (loading) return;

        if (postTextRef?.current?.value[0] || showImageList.length) {
            Swal.fire({
                html: `
                    <div class="post-warning-container">
                        <img src="assets/images/dooNineIconV2/others/warning-sign.svg" alt="waring" class="w100">
                        <div class="post-warning-text"><h2>${t(textConvert("Your post will be discard"))}</h2></div>
                    </div>
                    `,
                cancelButtonText: t(textConvert('Cancel')),
                confirmButtonText: t(textConvert('Discard')),
                showCancelButton: true,
                customClass: {
                    cancelButton: 'post-warning-cancel-btn',
                    confirmButton: 'post-warning-confirm-btn',
                    actions: 'post-warning-actions',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    setPostModal(false)
                    setShowImageList([]);
                    setImageList([]);
                }
            });
        } else {
            setPostModal(false);
        }
    }

    async function handleImageSelect(e) {
        const selectedImage = e.target.files;
        const imageListUrl = [];
        const imageList = [];

        for (const image of selectedImage) {
            const convertedImage = await imageConverter(image);
            const imageUrl = URL.createObjectURL(convertedImage);
            imageList.push(convertedImage);
            imageListUrl.push(imageUrl);
        }

        // Update state for all image URLs, including converted ones
        setShowImageList((prevShowImageList) => [...prevShowImageList, ...imageListUrl]);
        setImageList((prevImageList) => [...prevImageList, ...imageList]);
    }


    const handleImageDelete = (image) => {
        setShowImageList(prevList => {
            const index = prevList.indexOf(image);
            if (index > -1) {
                setImageList(prevImageList => {
                    const updatedImageList = [...prevImageList];
                    updatedImageList.splice(index, 1);
                    return updatedImageList;
                });
                return prevList.filter((_, i) => i !== index);
            }
            return prevList;
        });
    };

    async function handlePostButton(e) {
        const postText = postTextRef.current.value;
        if (postText === "" && !showImageList.length) return;

        if (!userData.role || userData.role === "" || userData.role === "no-select") {
            navigate('/accountinformation');
            return;
        }

        e.preventDefault();

        try {
            setLoading(true);
            const docRef = await addDoc(collection(firestore, "posts"), {
                postId: "",
                timestamp: serverTimestamp(),
                userId: userData.uid,
                content: postText,
                media: [],
                mediaType: imageList.length > 0 ? "image" : "text",
                likesCount: 0,
                commentsCount: 0,
                tag: [],
                audience: selectedPrivacy || "public",
                role: userData.role,
                notification: true
            });
            const imageUrlList = await handleUploadImage(docRef.id);

            await updateDoc(docRef, {
                postId: docRef.id,
                media: imageUrlList,
                timestamp: serverTimestamp(),
            });
            setLoading(false);
            setImageList([]);
            setShowImageList([]);
            setPostModal(false);
            isRefresh();
        } catch (e) {
            setLoading(false);
            console.error("Error adding document: ", e);
        }
    }

    // const handleUploadImage = async (post_id) => {
    //     const promises = [];

    //     for (let file of imageList) {
    //         try {
    //             // Compress the image
    //             const compressedFile = await imageCompression(file, {
    //                 maxSizeMB: 0.3,
    //                 maxWidthOrHeight: 1920,
    //                 useWebWorker: true
    //             });

    //             const storageRef = ref(storage, `post_image/${post_id}/${file.name}.jpg`);
    //             const uploadTask = uploadBytesResumable(storageRef, compressedFile);

    //             promises.push(
    //                 new Promise((resolve, reject) => {
    //                     uploadTask.on(
    //                         'state_changed',
    //                         (snapshot) => {
    //                             const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //                             console.log(`Upload is ${progress}% done`);
    //                         },
    //                         (error) => {
    //                             console.error(error);
    //                             reject(error);
    //                         },
    //                         async () => {
    //                             try {
    //                                 const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
    //                                 resolve(downloadURL);
    //                             } catch (error) {
    //                                 console.error(error);
    //                                 reject(error);
    //                             }
    //                         }
    //                     );
    //                 })
    //             );
    //         } catch (error) {
    //             console.error('Error compressing file:', error);
    //         }
    //     }

    //     try {
    //         const downloadURLs = await Promise.all(promises);
    //         return downloadURLs;
    //     } catch (error) {
    //         console.error("Error uploading files: ", error);
    //         throw error; // Re-throw error to be handled by the caller if necessary
    //     }
    // };
    const handleUploadImage = async (post_id) => {
        const promises = [];

        for (let file of imageList) {
            try {
                // console.log(file);
                let convertedFile = await imageConverter(file);

                const compressedFile = await imageCompression(convertedFile, {
                    maxSizeMB: 0.3,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                });

                const storageRef = ref(storage, `post_image/${post_id}/${compressedFile.name}`);
                const uploadTask = uploadBytesResumable(storageRef, compressedFile);

                promises.push(
                    new Promise((resolve, reject) => {
                        uploadTask.on(
                            'state_changed',
                            (snapshot) => {
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                console.log(`Upload is ${progress}% done`);
                            },
                            (error) => {
                                console.error(error);
                                reject(error);
                            },
                            async () => {
                                try {
                                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                                    resolve(downloadURL);
                                } catch (error) {
                                    console.error(error);
                                    reject(error);
                                }
                            }
                        );
                    })
                );
            } catch (error) {
                console.error('Error processing file:', error);
            }
        }

        try {
            const downloadURLs = await Promise.all(promises);
            return downloadURLs;
        } catch (error) {
            console.error('Error uploading files:', error);
            throw error;
        }
    };

    return (
        <>
            <div className={clsx("card w-100 shadow-xss border-0 ps-4 pt-3 pe-4 pb-3 mb-2", {
                'rounded-xxl': !isMobile.current
            })}>
                <div className="card-body p-0 d-flex align-items-center">
                    <figure className="avatar my-auto me-2">
                        <img src={userData?.profileImage || defaultProfile} alt="icon" className="shadow-sm rounded-circle"
                            style={{
                                width: '45px',
                                height: '45px',
                                borderRadius: '50%',
                                objectFit: 'cover'
                            }}
                            onError={(e) => {
                                e.target.onerror = null;  // Prevents endless loop in case fallback image also fails
                                e.target.src = defaultProfile;  // Replace with default image on error
                            }}
                        />
                    </figure>
                    <div className='post-modal-trigger p-2 theme-dark-bg' onClick={postHandle} >{t("what_on_your_mind")}</div>
                </div>
            </div>
            <Modal show={postModal} onHide={handleHide} centered size='lg' animation={false}>
                <Modal.Header >
                    <Modal.Title className='mx-auto'><h1 className='fw-700 m-0'>{t(textConvert('Create Post'))}</h1></Modal.Title>
                    <button className='btn btn-close m-0' onClick={handleHide}></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex align-items-start'>
                        <figure className="avatar mt-2 me-2"><img src={userData?.profileImage} alt="icon" className="shadow-sm"
                            style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                objectFit: 'cover'
                            }}
                            onError={(e) => {
                                e.target.onerror = null;  // Prevents endless loop in case fallback image also fails
                                e.target.src = defaultProfile;  // Replace with default image on error
                            }}
                        /></figure>
                        <Form.Control as="textarea" rows={3} className='post-text-input' placeholder={t("what_on_your_mind")} ref={postTextRef} />
                    </div>

                    {showImageList.length > 0 && (
                        <Slider {...sliderStyle} className='selected-image-createpost'>
                            {showImageList.map((image, index) => (
                                <div key={index} className='each-image-container'>
                                    <img src={image} aria-hidden alt={`image-${index}`} className='shadow-sm' />
                                    <i
                                        className='bi bi-x-circle'
                                        onClick={() => handleImageDelete(image)}
                                    ></i>
                                </div>
                            ))}
                            <div className='add-image-input-container d-flex justify-content-center align-items-center' onClick={() => document.getElementById('add-image-input').click()}>
                                <ReactSVG src="assets/images/dooNineIconV2/others/create_Post_Add_Photo.svg" className='create-post-add-photo-btn' />
                                <input type="file" name="add-image-input" id="add-image-input" multiple accept="image/*, .heic, .heif, image/heic, image/heif" className='d-none' onChange={(e) => { handleImageSelect(e); }} />
                            </div>
                        </Slider>
                    )}
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between w-100 flex-nowrap'>
                    {showImageList.length < 1 && (
                        <div className='add-photo-btn-container'>
                            <ReactSVG src="assets/images/dooNineIconV2/others/create_Post_Add_Photo.svg" className='create-post-add-photo-btn ms-4 w40' onClick={() => document.getElementById('image-input').click()} />
                            <input type="file" name="image-input" id="image-input" multiple accept="image/*, .heic, .heif, image/heic, image/heif" className='d-none' onChange={(e) => { handleImageSelect(e); }} />
                        </div>
                    )}
                    <div></div>
                    <div className='create-post-action-btn d-flex justify-content-between gap-2'>
                        <SelectedPrivBtn handleSelectPriv={handleSelectPriv} isPreSelected={userData?.privacy?.defaultAudience} />
                        <button className='btn bg-current post-btn' style={{ width: 80 }} onClick={handlePostButton} disabled={loading ? true : false}>
                            {!loading ? (
                                <h4 className='fw-600 m-0 text-white p-1'>{t(textConvert('post'))}</h4>
                            ) : (
                                <div className="spinner-border text-white spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default CreatePost
