export const th = {
  translation: {
    log_in: 'ล็อกอิน',
    login: 'ล็อกอิน',
    language: 'ภาษา',
    Thai: 'ไทย',
    English: 'อังกฤษ',
    Phone: 'โทรศัพท์',
    name_reg: 'ชื่อ',
    email_or_phone:'อีเมลหรือเบอร์โทร',
    Forgot_your_Password: 'ลืมรหัสผ่าน ?',
    Remember_me: 'จดจำฉัน',
    email: 'อีเมล',
    password: 'รหัสผ่าน',
    do_not_have_account: 'ยังไม่มีบัญชี ?',
    register: 'ลงทะเบียน',
    what_on_your_mind: 'คุณกำลังคิดอะไรอยู่ ?',
    my_performance: 'ผลงานของฉัน',
    performance: 'ผลงาน',
    golf_course: 'สนามกอล์ฟ',
    caddies: 'แคดดี้',
    chat: 'แชท',
    tournament: 'ทัวร์นาเมนต์',
    store: 'ร้านค้า',
    my_friends: 'เพื่อน',
    my_activity: 'กิจกรรม',
    settings: 'ตั้งค่า',
    privacy: 'ความเป็นส่วนตัว',
    logout: 'ออกจากระบบ',
    about: 'เกี่ยวกับ',
    images: 'รูปภาพ',
    view_and_save: 'บันทึก',
    save_and_exit: 'ตกลง',
    intro: 'แนะนำตัวเอง',
    edit: 'แก้ไข',
    cancel: 'ยกเลิก',
    my_course: 'สนาม',
    your_caddie_number: 'หมายเลขแคดดี้ของคุณ',
    select_your_course: 'สนามของคุณ',
    phone_number: 'หมายเลขโทรศัพท์',
    note_to_admin: 'ขอความถึงผู้ดูแลระบบ (ถ้ามี)',
    document_evidence: 'แนบเอกสาร',
    request: 'ขออนุมัติ',
    remove_course: 'ลบข้อมูล',
    please_confirm_your_request: 'กรุณายืนยันคำขอของคุณ',
    caddie_status_waiting: 'สถานะแคดดี้ - รอยืนยัน',
    caddie_status_confirm: 'สถานะแคดดี้ - ยืนยันแล้ว',
    caddie_status_official: 'สถานะแคดดี้ - ยืนยันแล้ว',
    caddie_status_no: 'ไม่มีสถานะ',
    your_request_has_been_denied_please_contact_your_course_manager_or_doonine: 'คำขอของคุณถูกปฏิเสธ กรุณาติดต่อสนามหรือ Doonine.com',
    ok_req: 'ย้อนกลับ',
    course: 'สนาม',
    confirm: 'ยืนยัน',
    see_all: 'ดูทั้งหมด',
    service_mind: 'ความใส่ใจในการบริการ',
    advice_and_guidance: 'การให้คำแนะนำและคำปรึกษา',
    total_rounds: 'จำนวนรอบ',
    day_average: 'เฉลี่ย',
    day_total: '30 วันที่ผ่านมา',
    create_post: 'สร้างโพสต์',
    no_post_to_show: 'ไม่มีโพสต์',
    post: 'โพสต์',
    public: 'สาธารณะ',
    friend: 'เพื่อน',
    only_me: 'เฉพาะฉัน',
    social_stats: 'โซเชียลมีเดีย',
    total_posts: 'โพสต์ทั้งหมด',
    friends: 'เพื่อน',
    my_statistics: 'สถิติของฉัน',
    statistics: 'สถิติ',
    total_number_of_rounds_you_have_completed: 'จำนวนรอบทั้งหมดที่สำเร็จ',
    all_time: 'เวลาทั้งหมด',
    doonine_score: 'คะแนน',
    total_points_earned: 'คะแนนรวม',
    monthly_average_doonine_score: 'คะแนนเฉลี่ยรายเดือน',
    five_stars: '5 คะแนน',
    four_stars: '4 คะแนน',
    three_stars: '3 คะแนน',
    two_stars: '1 คะแน2',
    one_stars: '1 คะแนน',
    didnt_review: 'ไม่ได้รีวิว',
    january: 'มกราคม',
    february: 'กุมภาพันธ์',
    march: 'มีนาคม',
    april: 'เมษายน',
    may: 'พฤษภาคม',
    june: 'มิถุนายน',
    july: 'กรกฎาคม',
    august: 'สิงหาคม',
    september: 'กันยายน',
    october: 'ตุลาคม',
    november: 'พฤศจิกายน',
    december: 'ธันวาคม',
    my_profile: 'ข้อมูลส่วนตัว',
    profile: 'ข้อมูลส่วนตัว',
    my_clubs: 'ไม้กอล์ฟของฉัน',
    clubs: 'ไม้กอล์ฟ',
    clubs_in_your_bag: 'ไม้กอล์ฟในกระเป๋า',
    clubs_in_bag: 'ไม้กอล์ฟในกระเป๋า',
    handicap: 'แฮนดิแคป',
    putts_per_hole: 'จำนวนพัตต์ต่อหลุม',
    greens_in_regulation: 'กรีนในมาตรฐาน (%)',
    best_score: 'คะแนนที่ดีที่สุด (เทียบกับพาร์)',
    ok: 'ตกลง',
    white: 'ขาว',
    red: 'แดง',
    yellow: 'เหลือง',
    blue: 'น้ำเงิน',
    black: 'ดำ',
    save: 'บันทึก',
    update: 'อัพเดท',
    driver: 'ไดรเวอร์',
    hybrid: 'ไม้ไฮบริด',
    wood: 'หัวไม้',
    iron: 'หัวเหล็ก',
    pitching_wedge: 'พิชชิ่งเวดจ์',
    sand_wedge: 'แซนด์เวดจ์',
    lob_wedge: 'ล็อบเวดจ์',
    putter: 'พัตเตอร์',
    yd: 'หลา',
    score_distribution: 'การกระจายคะแนน',
    eagle: 'อีเกิ้ล',
    birdie: 'เบอร์ดี้',
    par: 'พาร์',
    bogey: 'โบกี้',
    bouble: 'ดับเบิ้ล',
    triple: 'ทริปเปิ้ล',
    score_by_par: 'คะแนนตามพาร์',
    par_three: 'พาร์ 3 ',
    par_four: 'พาร์ 4',
    par_five: 'พาร์ 5',
    add_friend: 'เพิ่มเพื่อน',
    block: 'บล็อก',
    search_here: 'ค้นหา',
    filter: 'ตัวกรอง',
    favorites: 'รายการโปรด',
    favorite: 'รายการโปรด',
    caddie_star: 'แคดดี้สตาร์',
    pro_caddie: 'แคดดี้โปร',
    age: 'อายุ',
    age_min: 'อายุน้อยสุด',
    age_max: 'อายุมากสุด',
    label: 'ป้ายกำกับ',
    this_user_is_unavailable: 'ผู้ใช้นี้ไม่พร้อมใช้งาน',
    unfriend: 'เลิกเป็นเพื่อน',
    menu: 'เมนู',
    more: 'อื่นๆ',
    offcial_course: 'สนามกอล์ฟที่ได้การรับรอง',
    only_officially_registered_golf_courses_with_doonine: 'เฉพาะสนามกอล์ฟที่จดทะเบียนอย่างเป็นทางการพร้อมดูไนน์เท่านั้น',
    starting_price_range: 'ช่วงราคาที่ต้องการ',
    night_golf: 'ไนท์กอล์ฟ',
    driving_range: 'สนามไดร์ฟกอล์ฟ',
    restaurant: 'ร้านอาหาร',
    banquet_rooms: 'ห้องจัดเลี้ยง',
    hotel: 'โรงแรม',
    close_filter: 'ล้าง',
    apply: 'ตกลง',
    round: 'รอบ',
    starting_price: 'ราคาเริ่มต้น',
    gallery: 'ภาพสนาม',
    caddie: 'แคดดี้',
    close: 'ปิด',
    pending: 'กำลังรอ',
    you_have: 'คุณมี',
    invitations_to_join_the_chat_group: 'คำเชิญเข้าร่วมแชทกลุ่ม',
    create_group_chat: 'สร้างแชทกลุ่ม',
    group_chat_name: 'ชื่อแชทกลุ่ม',
    selcet_member: 'เลือกสมาชิก',
    operator: 'สร้าง',
    start_group_chat: 'เริ่มแชทกลุ่ม',
    select_member: 'เลือกสมาชิก',
    deny: 'ปฏิเสธ',
    join: 'เข้าร่วม',
    back: 'ย้อนกลับ',
    invite_chat: 'คำเชิญเข้าร่วมแชท',
    create_group_chat_complete: 'สร้างกลุ่มแชทแล้ว',
    success: 'สำเร็จ',
    invite: 'เชิญ',
    members: 'สมาชิกกลุ่ม',
    setting: 'ตั้งค่า',
    leave_chat: 'ออกจากกลุ่ม',
    invite_member: 'เชิญเพื่อน',
    do_you_want_to_leave_this_chat: 'คุณต้องการออกจากแชทนี้หรือไม่?',
    Respond: 'ตอบสนอง',
    cancel_request: 'ยกเลิกคำขอ',
    invite_member_to_chat_complete: 'สมาชิกได้ถูกเชิญเข้าร่วมแชทกลุ่มแล้ว',
    group_members: 'สมาชิกในกลุ่ม',
    invite_members: 'เชิญเพื่อน',
    group_chat_image_cover: 'ภาพปกแชทกลุ่ม',
    delete_group_chat: 'ลบแชทกลุ่ม',
    search_by_doonine_id: 'ค้นหาด้วย doonine ID',
    my_qr_code: 'QR Code ของฉัน',
    scan: 'สแกน',
    use_a_qr_code_or_link_to_let_other_users_add_you_as_a_friend: 'ใช้ QR code หรือลิงก์ เพื่อให้ผู้อื่นเพิ่มคุณเป็นเพื่อนได้',
    copy_link: 'คัดลอกลิงก์',
    scan_qr_code: 'สแกน QR Code',
    unblock: 'เลิกบล็อค',
    create_round: 'ออกรอบ',
    round_request: 'คำขอชวนออกรอบ',
    no_rounds_request_available: 'ไม่มีคำขอชวนออกรอบ',
    friend_activity: 'กิจกรรมเพื่อน',
    upcoming_round: 'ออกรอบที่จะมาถึง',
    finished_round: 'ออกรอบที่จบแล้ว',
    round_name: 'ชื่อรอบ',
    select_golf_course: 'เลือกสนามกอล์ฟ',
    select_course: 'เลือกสนามกอล์ฟ',
    playing_holes: 'จำนวนหลุม',
    nine_holes: '9 หลุม',
    eighteen_holes: '18 หลุม',
    game_mode: 'ประเภทการแข่งขัน',
    scoring: 'การนับคะแนน',
    bookmark_only: 'บุ๊กมาร์ก',
    you_must_have_a_course_before_create_round: 'คุณต้องมีสนามสังกัดก่อนสร้างการออกรอบ',
    stroke_play: 'สโตรคเพลย์',
    stableford: 'สเตเบิลฟอร์ด',
    match_play: 'แมตช์เพลย์',
    scratch_no_handicap_applied: 'สแครช (ไม่ใช้ระบบแฮนดิแคป)',
    whs_world_handicap_system: 'ระบบแฮนดิแคปโลก ',
    s_thirtysix_system_thirtysix: 'S36 (ระบบ 36)',
    gross: 'คะแนนรวม',
    to_par: 'เทียบพาร์',
    gross_whs: 'คะแนนรวมตามระบบ WHS',
    to_par_whs: 'เทียบพาร์ตาม WHS',
    gross_s_thirtysix: 'คะแนนรวมตามระบบ S36',
    to_par_s_thirtysix: 'เทียบพาร์ตามระบบ S36',
    players_and_caddies_in_this_round: 'นักกอล์ฟและแคดดี้ในรอบการแข่งขันนี้',
    round_creator: 'ผู้สร้างรอบการแข่งขัน',
    tee_time: 'เวลา',
    start_now: 'เริ่มเลย',
    create_schedule: 'สร้างล่วงหน้า',
    group_chat: 'ห้องแชท',
    create: 'สร้าง',
    disable: 'ปิดการใช้งาน',
    name: 'ชื่อห้องแชท',
    round_chat: '[แชทออกรอบ] ',
    caddie_request: 'คำขอเชิญแคดดี้',
    would_you_like_the_caddies_at_the_course_to_see_and_ask_to_join_the_round: 'คุณต้องการให้แคดดี้ในสนามคนไหนมองเห็นและสามารถเข้าร่วมการแข่งขันรอบนี้',
    all_caddie: 'แคดดี้ทั้งหมด',
    favorite_caddie: 'แคดดี้รายการโปรด',
    disable_create: 'ปิดการมองเห็น',
    round_cover: 'ภาพหน้าปก',
    date: 'วันที่',
    time: 'เวลา',
    send_request: 'ส่งคำขอ',
    start_round: 'เริ่มการออกรอบ',
    next: 'ถัดไป',
    edit_round: 'แก้ไขออกรอบ',
    resume_round: 'เล่นต่อ',
    leader_board: 'ลีดเดอร์บอร์ด',
    leaderboard: 'ลีดเดอร์บอร์ด',
    competitor: 'คู่แข่ง',
    player_and_caddie: 'นักกอล์ฟ & แคดดี้',
    invitation_link: 'ลิงก์คำเชิญ',
    delete_round: 'ลบการออกรอบ',
    playing: 'กำลังเล่น',
    score: 'คะแนน',
    putt: 'พัตต์',
    fairway_hit: 'ลงแฟร์เวย์',
    bunker: 'บังเกอร์',
    penalties: 'การถูกปรับแต้ม',
    driving: 'ระยะการไดร์ฟ',
    clear: 'ล้าง',
    total_par: 'พาร์ทั้งหมด',
    finished: 'เล่นจบแล้ว',
    public_link: 'ลิงก์สาธารณะ',
    leaderboard_not_public: 'ลีดเดอร์บอร์ดไม่เป็นสาธารณะ',
    you_need_to_set_the_leaderboard_to_public: 'คุณต้องการให้ลีดเดอร์บอร์ดเป็นสาธารณะหรือไม่',
    this_will_allow_anyone_with_the_link_to_view_it: 'ให้คนอื่นที่มีลิงก์ดูได้',
    default: 'ค่าเริ่มต้น',
    this_will_not_apply_to_other_player: 'ค่านี้จะไม่นำไปใช้กับผู้เล่นอื่น',
    select_your_caddie: 'เลือกแคดดี้ของคุณ',
    end_round: 'จบรอบ',
    tee_box: 'กล่องทีออฟ',
    teebox: 'กล่องทีออฟ',
    you: 'คุณ',
    creator: 'ผู้สร้าง',
    your_player: 'ผู้เล่นของคุณ',
    delete: 'ลบ',
    copy: 'คัดลอก',
    copied: 'คัดลอกแล้ว',
    my_position: 'ตำแหน่งของฉัน',
    my_round: 'ตำแหน่งของฉัน (เฉพาะรอบของฉัน)',
    all: 'ทั้งหมด',
    unfinished: 'เล่นไม่จบ',
    add_another_leaderboard: 'เพิ่มลีดเดอร์บอร์ดอื่น',
    leaderboard_list: 'รายการลีดเดอร์บอร์ด',
    add: 'เพิ่ม',
    added: 'เพิ่มแล้ว',
    hide_qr_code: 'ซ่อน QR code',
    show_qr_code: 'แสดง QR code',
    failed_you_cant_add_a_private_leaderboard: "ล้มเหลว: คุณไม่สามารถเพิ่มลีดเดอร์บอร์ดที่เป็นส่วนตัวได้",
    failed_you_cant_add_a_leaderboard_with_a_different_number_of_holes: "ล้มเหลว: คุณไม่สามารถเพิ่มลีดเดอร์บอร์ดที่มีจำนวนหลุมต่างกันได้",
    failed_the_creator_of_this_leaderboard_has_not_selected_a_course_yet: 'ล้มเหลว: ผู้สร้างลีดเดอร์บอร์ดนี้ยังไม่ได้เลือกคอร์ส',
    failed_maximum_leaderboards_reached: 'ล้มเหลว: คุณเพิ่มลีดเดอร์บอร์ดถึงจุดสูงสุดแล้ว (18)',
    not_found_leaderboard: 'ไม่พบลีดเดอร์บอร์ด',
    invalid_link: 'ลิงก์ไม่ถูกต้อง',
    leaderboard_already_added: 'มีลีดเดอร์บอร์ดนี้อยู่แล้ว',
    success_leaderboard_added_successfully: 'สำเร็จ: เพิ่มลีดเดอร์บอร์ดแล้ว',
    empty_List: 'ไม่มีรายการ',
    finish_round: 'จบรอบ',
    are_you_sure_you_want_to_end_this_round: 'คุณแน่ใจแล้วใช่ไหมที่ต้องการจบการออกรอบนี้?',
    are_you_sure: 'คุณแน่ใจแล้วใช่ไหม',
    you_wont_be_able_to_revert_this: 'คุณไม่สามารถย้อนกลับได้',
    end_this_round: 'จบการออกรอบนี้',
    log_in_to_your_account: 'เข้าสู่ระบบของคุณ',
    see_password: 'แสดงรหัสผ่าน',
    forgot_your_password: 'ลืมรหัสผ่าน',
    or: 'หรือ',
    firstname: 'ชื่อจริง',
    lastname: 'นามสกุล',
    role: 'ประเภทผู้ใช้งาน',
    re_password: 'ยืนยันรหัสผ่าน',
    no_select: 'ยังไม่ได้เลือก',
    player: 'นักกอล์ฟ',
    create_your_account: 'สร้างบัญชีผู้ใช้',
    create_your_account_caddie: 'สร้างบัญชีผู้ใช้ สำหรับ แคดดี้',
    already_have_account: 'มีบัญชีแล้ว',
    password_not_matched_try_again: 'รหัสไม่ตรงกัน ลองอีกครั้ง',
    male: 'ชาย',
    female: 'หญิง',
    prefer_not_to_say: 'ไม่ระบุ',
    enter_your_doonine_id: 'กรอก Doonine ID ของคุณ',
    submit: 'ยืนยัน',
    skip: 'ข้าม',
    warning: 'คำเตือน',
    you_wont_be_able_to_change_id_later: 'ID ของคุณจะไม่สามารถเปลี่ยนได้ในภายหลัง',
    your_id_is: 'ID ของคุณคือ',
    saved: 'บันทึกแล้ว',
    wrong_email_or_password: 'อีเมล์ หรือ รหัสผ่านผิด',
    contacts: 'รายชื่อ',
    you_have_no_friend: 'คุณยังไม่มีเพื่อน',
    acount_information: 'ข้อมูลส่วนตัว',
    notification_line: 'การแจ้งเตือนผ่าน Line',
    notification: 'การแจ้งเตือน',
    block_list: 'บัญชีที่บล็อก',
    reset_password: 'เปลี่ยนรหัสผ่าน',
    doonine_id: 'Doonine ID',
    first_name: 'ชื่อจริง',
    last_name: 'นามสกุล',
    phone: 'เบอร์โทรศัพท์',
    gender: 'เพศ',
    date_of_birth: 'วันเกิด',
    please_select_role: 'โปรดเลือกประเภทผู้ใ้ช้งาน',
    other: 'อื่นๆ',
    your_block_list_is_empty: 'ยังไม่มีบัญชีที่ถูกบล็อก',
    qr_code: 'คิวอาร์โค้ด',
    logout_and_search: 'การออกจากระบบและการค้นหา',
    auto_logout: 'ออกจากระบบโดยอัตโนมัติ',
    doonine_id_search: 'ค้นหาโดย Doonine ID',
    allow_other_users_to_search_your_account_by_doonine_id: 'อนุญาตให้บัญชีผู้ใช้งานอื่นค้นหาบัญชีของคุณโดย Doonine ID',
    email_search: 'ค้นหาโดยอีเมล',
    allow_other_users_to_search_your_account_by_email: 'อนุญาตให้บัญชีผู้ใช้งานอื่นค้นหาบัญชีของคุณโดยอีเมล',
    post_and_feeds: 'โพสต์และฟีด',
    default_audience_for_your_posts: 'ตั้งค่ากลุ่มเป้าหมายเริ่มต้นสำหรับโพสต์',
    show_caddie_s_public_posts: 'แสดงโพสต์สาธารณะของแคดดี้',
    show_player_s_public_posts: 'แสดงโพสต์สาธารณะของนักกอล์ฟ',
    private: 'ส่วนตัว',
    forget_password: 'ลืมรหัสผ่าน',
    password_reset_email_sent_check_your_inbox: 'ส่งข้อความเปลี่ยนรหัสผ่านแล้ว กรุณาตรวจสอบกล่องข้อความที่อีเมลคุณ',
    activity: 'กิจกรรม',
    default_leaderboard_privacy: 'ค่าเริ่มต้นความเป็นส่วนตัวลีดเดอร์บอร์ด',
    official_course: 'สนามกอล์ฟทางการ',
    reset: 'ล้าง',
    review: 'รีวิว',
    what_do_you_think_about_service: 'คุณจะให้คะแนนการให้บริการจาก',
    what_do_you_think_about: 'คุณจะให้คะแนนจากใช้บริการ',
    later: 'ไว้ทีหลัง',
    review_success: 'การรีวิวเสร็จสิ้น',
    your_review_has_been_submitted: 'การรีวิวของคุณถูกส่งแล้ว',
    review_question: 'อย่างไร',
    question_mark: 'อย่างไร',
    friend_request: 'คำขอเป็นเพื่อน',
    you_have_no_friend_request: 'คุณยังไม่มีคำขอเป็นเพื่อน',
    accepted: 'ยอมรับแล้ว',
    deleted: 'ลบแล้ว',
    delete_request: 'ลบคำขอ',
    respond: "ตอบสนอง",
    edit_post: 'แก้ไขโพสต์',
    turn_off_notification: 'ปิดการแจ้งเตือน',
    turn_on_notification: 'เปิดการแจ้งเตือน',
    delete_post: 'ลบโพสต์',
    max_club_warning: 'คำเตือน: ไม้ในกระเป๋ามีมากกว่า 14 ไม้.',
    edit_club: 'แก้ไขไม้',
    club_name: 'ชื่อไม้',
    loft: 'องศาหน้าไม้',
    club_number: 'เบอร์ไม้',
    club_type: 'ชนิดไม้',
    distance: 'ระยะ',
    note: 'โน้ต',
    in_bag: 'ในกระเป๋า',
    remove: 'ลบ',
    select_club_distance: 'เลือกระยะไม้',
    select_club_type: 'เลือกชนิดไม้',
    select_club_number: 'เลือกเบอร์ไม้',
    select_club_loft: 'เลือกองศาหน้าไม้',
    add_club: 'เพิ่มไม้',
    swap_caddie: 'สลับแคดดี้',
    remove_caddie: 'ลบแคดดี้',
    remove_player: 'ลบผู้เล่น',
    remove_both: 'ลบทั้งคู่',
    remove_slot: 'ลบช่อง',
    select_player: 'เลือกผู้เล่น',
    select_caddie: 'เลือกแคดดี้',
    no_notification: 'ไม่มีแจ้งเตือน',
    view_more: 'ดูเพิ่มเติม',
    select: 'เลือก',
    rounds: 'รอบ',
    my_favorites: 'รายการที่ชอบของฉัน',
    to: 'ถึง',
    round_created: 'สร้างออกรอบแล้ว',
    round_edited: 'แก้ไขออกรอบแล้ว',
    round_is_private: 'ออกรอบนี้เป็นส่วนตัว',
    link: 'ลิงก์',
    scan_me: 'สแกนเลย',
    logout_timer: 'ตั้งเวลาออกจากระบบ',
    minutes: 'นาที',
    you_are_already_playing_a_round: 'คุณมีรอบที่กำลังเล่นอยู่แล้ว',
    you_can_only_join_one_round_at_a_time_please_finish_your_current_round_before_joining_another_round: 'คุณสามารถเข้าร่วมได้เพียงหนึ่งรอบในแต่ละครั้ง โปรดเล่นรอบที่กำลังเล่นให้เสร็จก่อนเข้าร่วมรอบอื่น',
    can_not_join: 'ไม่สามารถเข้าร่วมได้',
    you_are_not_caddie_in_this_course: 'คุณไม่ใช่แคดดี้ของสนามนี้',
    join_round: 'เข้าร่วมออกรอบ',
    joined: 'เข้าร่วมแล้ว',
    you_are_already_in_this_round: 'คุณอยู่ในรอบนี้อยู่แล้ว',
    confirmed: 'ยืนยันแล้ว',
    group_position: 'ตำแหน่งในกลุ่ม',
    position: 'ตำแหน่ง',
    you_must_select_a_course_before_viewing_the_leaderboard: 'คุณต้องเลือกสนามก่อนจึงจะสามารถดูลีดเดอร์บอร์ดได้',
    please_wait_for_the_round_creator_to_select_a_course: 'กรุณารอผู้สร้างรอบเลือกสนาม',
    this_caddie_number_is_already_in_use: 'หมายเลขแคดดี้นี้ถูกใช้ไปแล้ว',
    do_you_want_to_reset_your_bag_to_default: 'คุณต้องการรีเซ็ตกระเป๋าเป็นค่าเริ่มต้นหรือไม่',
    invalid_driving_range_maximum_is_1000_yd: 'ระยะไดร์ฟไม่ถูกต้อง (สูงสุด 1000 หลา)',
    yes: 'ใช่',
    no: 'ไม่',
    round_finished: 'จบรอบแล้ว',
    your_comment_will_be_discard: 'ละทิ้งความคิดเห็น',
    your_post_will_be_discard: 'ละทิ้งโพสต์',
    discard: 'ละทิ้ง',
    comment: 'แสดงความคิดเห็น',
    name_block: 'ชื่อ',
    this_section_is_currently_not_available: 'เนื้อหาส่วนนี้ยังไม่พร้อมใช้งาน',
    holes: 'หลุม',
    hole: 'หลุม',
    index: 'อินเด็กซ์',
    all_rounds: 'ทั้งหมด',
    nine_holes_finished: '9 หลุม',
    eighteen_holes_finished: '18 หลุม',
    start: 'เริ่ม',
    no_friend_activity_available: 'ไม่มีกิจกรรมของเพื่อน',
    you_ve_seen_all_post: 'คุณได้เห็นโพสท์ทั้งหมดแล้ว',
    bunker_yes: 'ลง',
    bunker_no: 'ไม่ลง',
    delete_this_round: 'ลบออกรอบนี้',
    are_you_sure_you_want_to_delete_this_round: 'ออกรอบนี้จะถูกลบ คูณแน่ใจหรือไม่',
    request_sent: 'ส่งคำขอแล้ว',
    your_request_to_join_the_round_has_been_successfully_submitted: 'คำขอเข้าร่วมออกรอบถูกส่งแล้ว',
    round_in_progress: 'มีรอบกำลังเล่นอยู่',
    you_still_have_a_round_in_progress_please_finish_that_round_first: 'มีรอบกำลังเล่นอยู่ กรูณาจบรอบก่อนหน้าก่อน',
    deny_this_round: 'ปฏิเสธออกรอบนี้',
    are_you_sure_you_want_to_deny_this_round: 'คุณแน่ใจหรือไม่ว่าจะปฏิเสธออกรอบนี้',
    you_want_to_leave_this_round: 'คุณต้องการจะออกจากรอบนี้หรือไม่',
    no_friends_activity_available: 'ไม่มีกิจกรรมของเพื่อน',
    no_rounds_upcoming_available: 'ไม่มีรอบที่กำลังมาถึง',
    select_an_option: 'เลือกตัวเลือก',
    select_or_create_an_option: 'เลือกหรือสร้างตัวเลือก',
    do_you_want_to: 'คุณต้องการที่จะ',
    with: 'กับ',
    yes_or_not: 'ใช่หรือไม่',
    update_complete: 'อัพเดทเรียบร้อย',
    caddie_no: 'หมายเลขแคดดี้',
    leave_round: 'ออกจากรอบนี้',
    invalid_email_or_phone: 'อีเมลหรือเบอร์โทรไม่ถูกต้อง',
    password_must_be_at_least_six_characters: 'รหัสผ่านต้องมีอย่างน้อย 6 ตัว',
    please_enter_your_sms_verify_code:'กรุณาใส่รหัสยืนยันใน sms ของคุณ',
    your_phone_number_already_used: 'หมายเลขนี้ถูกใช้ไปแล้ว',
  }
}
