import { Card } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  limit,
  orderBy,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { denyRound } from "../module/denyRound";
import { getDataRequestRound } from "./module/getData";
import { getDataStartRound } from "../startround/module/getData";
import ModalPlayerCaddie from "../PlayerCaddieModal";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../../../components/language/dropdown/textconvert";

function RoundRequest() {
  const { t, i18n } = useTranslation();
  const userData = useSelector((state) => state.user.data);
  const [listRoundRequest, setListRoundRequest] = useState([]);
  const [listRoundStart, setListRoundStart] = useState([]);
  const [listRoundTest, setListRoundTest] = useState([]);
  const [loading, setLoading] = useState(true);

  const maxUserList = { desktop: 5, mobile: 7 };
  const navigate = useNavigate();
  const [modalPlayerCaddie, setModalPlayerCaddie] = useState(false);
  const [listUserData, setListUserData] = useState([]);
  // useEffect(() => {
  //   const unsubscribe = onSnapshot(
  //     query(collection(firestore, "round")),
  //     async (userSnapshot) => {
  //       try {
  //         let roundDataArray = [];
  //         const fetchScoreDetails = async (uid, roundId) => {
  //           const userQuery = query(
  //             collection(firestore, "scoreCards"),
  //             where("userId", "==", uid),
  //             where("roundId", "==", roundId)
  //           );
  //           const caddieQuery = query(
  //             collection(firestore, "scoreCards"),
  //             where("caddieId", "==", uid),
  //             where("roundId", "==", roundId)
  //           );

  //           const [userSnapshot, caddieSnapshot] = await Promise.all([
  //             getDocs(userQuery),
  //             getDocs(caddieQuery),
  //           ]);

  //           let scoreDetails = {};

  //           userSnapshot.forEach((doc) => {
  //             scoreDetails = doc.data();
  //           });

  //           caddieSnapshot.forEach((doc) => {
  //             scoreDetails = doc.data();
  //           });

  //           return scoreDetails;
  //         };

  //         for (const roundDoc of userSnapshot.docs) {
  //           const roundData = roundDoc.data();
  //           const isCreator = roundData.creator === userData?.uid;
  //           const isUserInList =
  //             Array.isArray(roundData.userList) &&
  //             roundData.userList.some(
  //               (user) =>
  //                 (user.player?.uid === userData?.uid &&
  //                   user.player?.isJoin === true) ||
  //                 (user.caddie?.uid === userData?.uid &&
  //                   user.caddie?.isJoin === true)
  //             );

  //           if (isCreator || isUserInList) {
  //             const playerUids = (roundData.userList || [])
  //               .flatMap((user) => [user.player?.uid, user.caddie?.uid]) // รวม uid ของ player และ caddie
  //               .filter((uid) => uid !== undefined); // กรอง undefined ออก

  //             if (playerUids.length > 0) {
  //               const scoreDetailsArray = await Promise.all(
  //                 playerUids.map((uid) => fetchScoreDetails(uid, roundDoc.id))
  //               );

  //               roundData.userList = roundData.userList.map((user) => {
  //                 const playerUid = user.player?.uid;
  //                 const caddieUid = user.caddie?.uid;

  //                 const playerScore = scoreDetailsArray.find(
  //                   (details) =>
  //                     details.userId === playerUid ||
  //                     details.caddieId === caddieUid
  //                 );

  //                 const status = playerScore ? playerScore.status : undefined;

  //                 return {
  //                   ...user,
  //                   player: { ...user.player, status },
  //                   caddie: { ...user.caddie, status },
  //                 };
  //               });

  //               const isRoundStatus = roundData.userList.some(
  //                 (user) =>
  //                   (user.player?.uid === userData.uid &&
  //                     user.player !== null &&
  //                     (user.player?.status === "playing" ||
  //                       user.player?.status == undefined)) ||
  //                   (user.caddie?.uid === userData.uid &&
  //                     user.caddie !== null &&
  //                     (user.caddie?.status === "playing" ||
  //                       user.caddie?.status == undefined))
  //               );

  //               if (
  //                 isRoundStatus &&
  //                 roundData.teeTime === "now" &&
  //                 roundData.status !== "deleted"
  //               ) {
  //                 roundDataArray.push({
  //                   ...roundData,
  //                   roundId: roundDoc.id,
  //                 });
  //               }
  //             }
  //           }
  //         }

  //         setListRoundStart(roundDataArray);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     }
  //   );

  //   return () => unsubscribe(); // Clean up the listener on unmount
  // }, [userData]);

  // useEffect(() => {
  //   if (!userData?.uid) return;

  //   const fetchUserDetails = async (uid, userCache) => {
  //     if (userCache[uid]) return userCache[uid];

  //     const userQuery = query(
  //       collection(firestore, "users"),
  //       where("uid", "==", uid)
  //     );
  //     const userSnapshot = await getDocs(userQuery);
  //     const userDetails = userSnapshot.docs[0]?.data() || {};
  //     userCache[uid] = userDetails;
  //     return userDetails;
  //   };

  //   const fetchScoreDetails = async (uid, roundId) => {
  //     const userQuery = query(
  //       collection(firestore, "scoreCards"),
  //       where("userId", "==", uid),
  //       where("roundId", "==", roundId)
  //     );
  //     const caddieQuery = query(
  //       collection(firestore, "scoreCards"),
  //       where("caddieId", "==", uid),
  //       where("roundId", "==", roundId)
  //     );

  //     const [userSnapshot, caddieSnapshot] = await Promise.all([
  //       getDocs(userQuery),
  //       getDocs(caddieQuery),
  //     ]);

  //     let scoreDetails = {};

  //     userSnapshot.forEach((doc) => {
  //       scoreDetails = doc.data();
  //     });

  //     caddieSnapshot.forEach((doc) => {
  //       scoreDetails = doc.data();
  //     });

  //     return scoreDetails;
  //   };

  //   const roundActivityQuery = query(
  //     collection(firestore, "roundActivity"),
  //     where("userList", "array-contains", userData.uid),
  //     where("creator", "!=", userData.uid),
  //     orderBy("created", "desc")
  //   );

  //   const unsubscribe = onSnapshot(
  //     roundActivityQuery,
  //     async (roundActivitySnapshot) => {
  //       if (roundActivitySnapshot.empty) return setListRoundRequest([]);

  //       const rounds = roundActivitySnapshot.docs.map((doc) => doc.data());
  //       const dataArray = [];
  //       const userCache = {};

  //       for (const roundActivity of rounds) {
  //         const { roundId } = roundActivity;

  //         const roundQuery = query(
  //           collection(firestore, "round"),
  //           where("roundId", "==", roundId),
  //           orderBy("created", "desc"),
  //           limit(1)
  //         );

  //         const roundSnapshot = await getDocs(roundQuery);
  //         if (roundSnapshot.empty) continue;

  //         const roundDoc = roundSnapshot.docs[0];
  //         const round = roundDoc.data();

  //         let isJoinTrue = round.userList.some(
  //           ({ player, caddie }) =>
  //             (player?.uid === userData.uid && player.isJoin) ||
  //             (caddie?.uid === userData.uid && caddie.isJoin)
  //         );

  //         if (!isJoinTrue && round.scheduleTime) {
  //           let holesCourse = "";
  //           let courseData = null;

  //           // Check if round.holesCourse exists before querying
  //           if (round.holesCourse) {
  //             const courseHolesRef = doc(
  //               firestore,
  //               "courseHoles",
  //               round.holesCourse
  //             );
  //             const courseHolesSnapshot = await getDoc(courseHolesRef);
  //             if (courseHolesSnapshot.exists()) {
  //               holesCourse = courseHolesSnapshot.data().courseHoleName;
  //             }
  //           }

  //           const courseQuery = query(
  //             collection(firestore, "courses"),
  //             where("id", "==", round.course)
  //           );
  //           const courseSnapshot = await getDocs(courseQuery);
  //           courseData = courseSnapshot.docs[0]?.data() || {};

  //           const { time, day, month } = epochToDateTime(round.scheduleTime);

  //           const userFetches = round.userList.map(
  //             async ({ player, caddie }) => {
  //               const userDataArray = [];
  //               if (player?.uid) {
  //                 const playerData = await fetchUserDetails(
  //                   player.uid,
  //                   userCache
  //                 );
  //                 userDataArray.push({
  //                   ...playerData,
  //                   isCreator: player.isCreator,
  //                   isJoin: player.isJoin,
  //                 });
  //               }
  //               if (caddie?.uid) {
  //                 const caddieData = await fetchUserDetails(
  //                   caddie.uid,
  //                   userCache
  //                 );
  //                 userDataArray.push({
  //                   ...caddieData,
  //                   isCreator: caddie.isCreator,
  //                   isJoin: caddie.isJoin,
  //                   playerConfirm: caddie.playerConfirm,
  //                 });
  //               }
  //               return userDataArray;
  //             }
  //           );

  //           const userDataArray = (await Promise.all(userFetches)).flat();

  //           // แก้ไข playerUids
  //           const playerUids = round.userList
  //             .flatMap((user) => [user.player?.uid, user.caddie?.uid]) // รวม uid ของ player และ caddie
  //             .filter((uid) => uid !== undefined); // กรอง undefined ออก

  //           if (playerUids.length > 0) {
  //             const scoreDetailsArray = await Promise.all(
  //               playerUids.map((uid) => fetchScoreDetails(uid, roundDoc.id))
  //             );

  //             round.userList = round.userList.map((user) => {
  //               const playerUid = user.player?.uid;
  //               const caddieUid = user.caddie?.uid;

  //               const playerScore = scoreDetailsArray.find(
  //                 (details) =>
  //                   details.userId === playerUid ||
  //                   details.caddieId === caddieUid
  //               );

  //               const status = playerScore ? playerScore.status : undefined;

  //               return {
  //                 ...user,
  //                 player: { ...user.player, status },
  //                 caddie: { ...user.caddie, status },
  //               };
  //             });

  //             const isRoundStatus = round.userList.some(
  //               (user) =>
  //                 (user.player?.uid === userData.uid &&
  //                   ["playing", undefined].includes(user.player?.status)) ||
  //                 (user.caddie?.uid === userData.uid &&
  //                   ["playing", undefined].includes(user.caddie?.status))
  //             );

  //             if (isRoundStatus && round.status !== "deleted") {
  //               dataArray.push({
  //                 ...round,
  //                 courseInfo: courseData,
  //                 time,
  //                 day,
  //                 month,
  //                 userData: userDataArray,
  //                 holesCourse,
  //               });
  //             }
  //           }
  //         }
  //       }

  //       setListRoundRequest(dataArray);
  //     }
  //   );

  //   return () => unsubscribe();
  // }, [userData]);

  useEffect(() => {
    if (!userData) return;
    getDataStartRound(userData, setListRoundStart, setLoading);
    getDataRequestRound(userData, setListRoundTest, setLoading);
  }, [userData]);

  // console.log(listRoundRequest)
  const handleJoinClick = (roundId, teeTime) => {
    if (listRoundStart.length > 0 && teeTime == "now") {
      Swal.fire({
        title: t(textConvert("Round In Progress")),
        text: t(textConvert("You still have a round in progress Please finish that round first")),
        icon: "warning",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: t(textConvert("OK")),
      });
    } else {
      navigate(`/join-round?id=${roundId}`);
    }
  };

  const handleDeny = async (roundId, userId) => {
    Swal.fire({
      title: t(textConvert("Deny this round")),
      html: `
        <h5 class="fw-600">${t(textConvert('Are you sure you want to deny this round'))}</h5>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t(textConvert("OK")),
      cancelButtonText: t(textConvert("Cancel")),
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await denyRound(roundId, userId);
          console.log("Round denied successfully");
          Swal.fire("Denied!", "The round has been denied.", "success");
        } catch (error) {
          console.error("Error denying round:", error);
          Swal.fire("Error!", "There was an issue denying the round.", "error");
        }
      } else {
        console.log("Round denial cancelled");
      }
    });
  };

  // const filteredRoundRequest = listRoundRequest
  //   .filter((round) =>
  //     round.userData.some((user) => {
  //       if (user.uid === userData.uid) {
  //         if (user.role === "player") {
  //           return user.isJoin === false;
  //         } else if (user.role === "caddie") {
  //           return user.playerConfirm === true;
  //         }
  //       }
  //       return false;
  //     })
  //   )
  //   .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  //   .slice(0, 1); // เลือกแค่รอบที่สร้างล่าสุด
  console.log(listRoundTest);
  const filteredRoundRequest = listRoundTest
    .sort((a, b) => b.created.seconds - a.created.seconds)
    .slice(0, 1); // เลือกแค่รอบที่สร้างล่าสุด

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  const handleOpenPlayerCaddieModal = (userData) => {
    setModalPlayerCaddie(true);
    setListUserData(userData);
  };

  const handleClosePlayerCaddieModal = () => setModalPlayerCaddie(false);

  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl w-100 mb-2 mt-md-0 mt-2">
        <Card.Body className="main-player-stat p-1">
          <div className="card-body d-flex align-items-center p-2">
            <h4 className="fw-700 mb-0 font-xssss text-grey-900">
              {t(textConvert('Round Request'))}
            </h4>
            <Link
              to="/round-activity?roundRequest=allRoundRequest"
              className="fw-600 ms-auto font-xssss text-primary"
            >
              {t(textConvert('See all'))}
            </Link>
          </div>

          {filteredRoundRequest.length > 0 ? (
            filteredRoundRequest.map((round, index) => (
              <div key={index} className="create-round-req-main">
                <section className="create-round-req-head">
                  <div className="pointer">
                    <h5 className="fw-600 d-sm-block d-none">
                      {round.roundName}
                    </h5>
                    <h4 className="fw-600 d-sm-none d-block">
                      {round.roundName}
                    </h4>
                  </div>
                </section>

                <section
                  className="create-round-req-body p-1 pointer"
                  style={{
                    backgroundImage: round.coverImage
                      ? typeof round.coverImage === "string"
                        ? `url(${round.coverImage})`
                        : `url(${URL.createObjectURL(round.coverImage)})`
                      : "none",
                    height: round.coverImage ? "250px" : "inherit",
                  }}
                >
                  <div
                    className="req-course-data d-flex align-items-center w-100"
                    style={{
                      backgroundColor: round.coverImage ? "" : "#fff",
                    }}
                  >
                    <div className="col-sm-3 col-3 pointer p-0 d-flex justify-content-center align-items-center">
                      <img
                        src={round.courseInfo.profileImage}
                        alt="course-profile"
                        width={50}
                        height={50}
                        className="rounded-circle my-2"
                      />
                    </div>
                    <div className="col-sm-6 col-xs-6 d-flex align-items-center justify-content-start pointer">
                      <div>
                        <span
                          className="fw-600 d-sm-block d-none"
                          style={{ fontSize: "11px" }}
                        >
                          {round.courseInfo.courseName}
                        </span>
                        <span className="fw-600 fs-6 d-sm-none d-block">
                          {round.courseInfo.courseName}
                        </span>
                        {i18n.language === 'th' ?
                          <>
                            <span className="fw-600 fs-6 d-sm-none d-block">{`${round.courseInfo.location.province}`}</span>
                            <span
                              className="fw-600 d-sm-block d-none"
                              style={{ fontSize: "10px" }}
                            >
                              {`${round.courseInfo.location.province}`}
                            </span>
                          </>
                          :
                          <>
                            <span className="fw-600 fs-6 d-sm-none d-block">{`${round.courseInfo.location.provinceEng}`}</span>
                            <span
                              className="fw-600 d-sm-block d-none"
                              style={{ fontSize: "10px" }}
                            >
                              {`${round.courseInfo.location.provinceEng}`}
                            </span>
                          </>
                        }
                      </div>
                    </div>
                    <div className="col-sm-4 col-3 px-sm-20">
                      <div
                        className="h-100 w-75 d-flex flex-column justify-content-evenly rounded-3 py-2 pointer"
                        style={{
                          backgroundColor:
                            round.teeTime === "schedule"
                              ? "#E59E49"
                              : "#81D77A",
                        }}
                      >
                        {round.teeTime === "schedule" ? (
                          <>
                            <h5 className="fw-600 text-white text-center">
                              {round.month}
                            </h5>
                            <h5 className="fw-700 text-white text-center">
                              {round.day}
                            </h5>
                            <div className="bg-white text-center w-100">
                              <span
                                className="fw-600 fs-6"
                                style={{ color: "#E59E49" }}
                              >
                                {round.time}
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <h5 className="fw-600 text-white text-center">
                              {round.month}
                            </h5>
                            <h5 className="fw-700 text-white text-center">
                              {round.day}
                            </h5>
                            <div className="bg-white text-center w-100">
                              <span
                                className="fw-600 fs-6"
                                style={{ color: "#81D77A" }}
                              >
                                {round.time}
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <div
                    className="d-none d-sm-flex flex-column justify-content-between pointer"
                    style={{ padding: "0.6rem 1rem 0.7rem" }}
                  >
                    <div className="d-flex align-items-center mb-3 ms-2">
                      {round.userData
                        .slice(0, maxUserList.desktop)
                        .map((user, idx) =>
                          user.isCreator ? (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={40}
                                  height={40}
                                  className="rounded-circle player-color-outline me-4"
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={40}
                                  height={40}
                                  className="rounded-circle caddie-color-outline me-4"
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={40}
                                  height={40}
                                  className="rounded-circle player-color-outline"
                                  style={{
                                    marginLeft: "0px",
                                    zIndex: maxUserList.desktop - idx,
                                  }}
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={40}
                                  height={40}
                                  className="rounded-circle caddie-color-outline"
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.desktop - idx,
                                  }}
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              )}
                            </React.Fragment>
                          )
                        )}
                      {round.userData.length > maxUserList.desktop && (
                        <div
                          className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                          style={{
                            width: 40,
                            height: 40,
                            opacity: 0.6,
                          }}
                        >
                          <h4
                            className="text-center"
                            onClick={() =>
                              handleOpenPlayerCaddieModal(round.userData)
                            }
                          >
                            +{round.userData.length - maxUserList.desktop}
                          </h4>
                        </div>
                      )}
                    </div>

                    <div className="d-flex align-items-center mb-3">
                      {round.userData.map(
                        (user, idx) =>
                          user.isCreator && (
                            <div key={idx}>
                              <h6
                                className="fw-600"
                                style={{ fontSize: "11px" }}
                              >
                                Request By {user.firstName} {user.lastName}
                              </h6>
                            </div>
                          )
                      )}
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        className="p-2 lh-20 w100 bg-current me-1 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                        onClick={() =>
                          handleJoinClick(round.roundId, round.teeTime)
                        }
                      >
                        {t(textConvert('Join'))}
                      </button>
                      <button
                        className="p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                        style={{ background: "#f5f5f5" }}
                        onClick={() => handleDeny(round.roundId, userData.uid)}
                      >
                        {t(textConvert('Deny'))}
                      </button>
                    </div>
                  </div>

                  <div
                    className="d-sm-none d-flex flex-column justify-content-between pointer"
                    style={{ padding: "0.3rem 1rem 0.6rem" }}
                  >
                    <div className="d-flex align-items-center mb-3 ms-2">
                      {round.userData
                        .slice(0, maxUserList.mobile)
                        .map((user, idx) =>
                          user.isCreator ? (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={40}
                                  height={40}
                                  className="rounded-circle player-color-outline me-4"
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={40}
                                  height={40}
                                  className="rounded-circle caddie-color-outline me-4"
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment key={idx}>
                              {user.role === "player" ? (
                                <img
                                  src={user.profileImage}
                                  alt="player"
                                  width={40}
                                  height={40}
                                  className="rounded-circle player-color-outline"
                                  style={{
                                    marginLeft: "0px",
                                    zIndex: maxUserList.mobile - idx,
                                  }}
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              ) : (
                                <img
                                  src={user.profileImage}
                                  alt="caddie"
                                  width={40}
                                  height={40}
                                  className="rounded-circle caddie-color-outline"
                                  style={{
                                    marginLeft: "-10px",
                                    zIndex: maxUserList.mobile - idx,
                                  }}
                                  onClick={() => handleProfileClick(user?.uid)}
                                />
                              )}
                            </React.Fragment>
                          )
                        )}
                      {round.userData.length > maxUserList.mobile && (
                        <div
                          className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1 ms-2"
                          style={{
                            width: 40,
                            height: 40,
                            // outline: "1px dashed grey",
                          }}
                        >
                          <h4
                            className="text-center"
                            onClick={() =>
                              handleOpenPlayerCaddieModal(round.userData)
                            }
                          >
                            +{round.userData.length - maxUserList.mobile}
                          </h4>
                        </div>
                      )}
                    </div>

                    <div className="d-flex align-items-center mb-2">
                      {round.userData.map(
                        (user, idx) =>
                          user.isCreator && (
                            <div key={idx}>
                              <h6 className="fw-600 fs-7">
                                Request By {user.firstName} {user.lastName}
                              </h6>
                            </div>
                          )
                      )}
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        className="p-2 lh-20 w100 bg-current me-1 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                        onClick={() =>
                          handleJoinClick(round.roundId, round.teeTime)
                        }
                      >
                        {t(textConvert('Join'))}
                      </button>
                      <button
                        className="p-2 lh-20 w100 text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                        style={{ background: "#f5f5f5", color: "black" }}
                        onClick={() => handleDeny(round.roundId, userData.uid)}
                      >
                        {t(textConvert('Deny'))}
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            ))
          ) : (
            // <div
            //   className="d-flex justify-content-center align-items-center"
            //   style={{ height: "150px" }}
            // >
            //   <h5 className="text-center p-2">No rounds request available</h5>
            // </div>
            <h5 className="text-center p-2">{t(textConvert('No rounds request available'))}</h5>
          )}
        </Card.Body>
      </Card>

      <ModalPlayerCaddie
        isOpen={modalPlayerCaddie}
        handleClose={handleClosePlayerCaddieModal}
        listUserData={listUserData}
      />
    </>
  );
}
export default RoundRequest;
