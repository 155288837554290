import React from 'react'
import { Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { textConvert } from '../../../components/language/dropdown/textconvert'

const CreateRound = ({ listRoundTest }) => {
    const { t } = useTranslation();
    return (
        <Card className='border-0 shadow-xss' style={{ borderRadius: '15px' }}>
            <Card.Body>
                <Link to={'/create-round'}>
                    <button className='btn w-100 p-sm-4 p-3 rounded-3 mb-3 text-center bg-light theme-dark-bg border'>
                        <div className='d-inline-flex align-items-center'>
                            <h1 className='feather-plus-circle me-1 text-current'></h1>
                            <h1 className='fw-700'>{t(textConvert('create round'))}</h1>
                        </div>
                    </button>
                </Link>

                {listRoundTest.length > 0 && (
                    <Link to={'/create-round'} state={{ schedule: true }}>
                        <button className='btn bg-light theme-dark-bg d-inline-flex align-items-center border'>
                            <i className='feather-plus-circle text-current fs-1 me-2'></i>
                            <h5 className='fw-600'>{t(textConvert('create round'))}</h5>
                        </button>
                    </Link>
                )}

            </Card.Body>
        </Card>
    )
}

export default CreateRound