import React, { useState, useMemo, useEffect } from "react";
import Header from "../../components/Header";
import Rightchat from "../../components/Rightchat";
import Leftnav from "../../components/Leftnav";
import Popupchat from "../../components/Popupchat";
import Appfooter from "../../components/Appfooter";
import ReactSwitch from "react-switch";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../components/redux/feature/user/userSlice";
import { firestore } from "../../components/firebase/FirebaseConfig";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../components/language/dropdown/textconvert";

const switchStyle = {
  width: 44,
  height: 20,
  onColor: "#EE3D7F",
  boxShadow: "0 0 2px 1px #fff",
  handleDiameter: 16,
};

const timer = [5, 10, 20, 30];

function Privacy() {
  const { t } = useTranslation();
  const [autoLogoutSW, setAutoLogoutSW] = useState(null);
  const [dooNineIdSearch, setDooNineIdSearch] = useState(false);
  const [emailSearch, setEmailSearch] = useState(false);
  const [logoutTimer, setLogoutTimer] = useState(timer[0]);

  const [friendsPostPublicSW, setFriendsPostPublicSW] = useState(false);
  const [caddiePublicSW, setCaddiePublicSW] = useState(false);
  const [playerPublicSW, setPlayerPublicSW] = useState(false);

  const [defaultLeaderbaord, setDefaultLeaderboard] = useState('public');

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.data);

  // const userStatus = useSelector((state) => state.user.status);

  useEffect(() => {
    if (userData) {
      const privacy = userData?.privacy;
      if (privacy?.logoutTimer) {
        setAutoLogoutSW(true);
        setLogoutTimer(privacy?.logoutTimer);
      }
      setDooNineIdSearch(privacy?.dooNineIdSearch || false);
      setEmailSearch(privacy?.emailSearch || false);
      setFriendsPostPublicSW(privacy?.friendsPost || false);
      setCaddiePublicSW(
        privacy?.caddiePost === undefined ? false : privacy?.caddiePost
      );
      setPlayerPublicSW(
        privacy?.playerPost === undefined ? false : privacy?.playerPost
      );
      setDefaultLeaderboard(privacy?.leaderboardPrivacy || 'public')
    }
  }, [userData]);

  const handleAutoLogout = useMemo(() => {
    if (!autoLogoutSW) return;

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-2 pt-2">
          {/* por 020924 */}
          {/* <h4 className='fw-500 m-0 me-2'>Logout Timer</h4> */}
          <h4 className="fw-500 m-0 me-2">{t(textConvert('Logout Timer'))}</h4>
          {/* por 020924 */}

          <Dropdown>
            <Dropdown.Toggle variant="light">
              <span className="fw-600 fs-5">{logoutTimer} {t(textConvert('minutes'))}</span>
              <i className="bi-chevron-down fs-5 ms-1"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ borderRadius: 15 }} className="shadow-xss p-0 border">
              {timer.map(time =>
                <Dropdown.Item key={time} className="py-2" onClick={() => setLogoutTimer(time)}>
                  {time} {t(textConvert('minutes'))}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }, [autoLogoutSW, logoutTimer, t]);

  const handleDefaultPosAudience = useMemo(() => {
    if (!userData) return;

    const defaultAudience = userData?.privacy?.defaultAudience || "public";

    function audienceCapialize(audience) {
      switch (audience) {
        case "public":
          return "Public";

        case "friends":
          return "Friends";

        case "only_me":
          return "Only Me";

        default:
          return "Public";
      }
    }

    const updateDefaultAudience = (selectedAudience) => {
      dispatch(
        updateUser(userData.uid, {
          privacy: {
            ...userData.privacy,
            defaultAudience: selectedAudience,
          },
        })
      );
    };

    return (
      <>
        <Dropdown>
          <Dropdown.Toggle
            className="mw150 d-flex align-items-center justify-content-center"
            variant="light"
          >
            <span className="fw-600 fs-5">
              {t(textConvert(audienceCapialize(defaultAudience)))}
            </span>
            <i className="bi-chevron-down fs-5 ms-1"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ borderRadius: 15 }} className="shadow-xss p-0 border">
            <Dropdown.Item className="py-2" onClick={() => updateDefaultAudience("public")}>
              {t(textConvert('Public'))}
            </Dropdown.Item>
            <Dropdown.Item className="py-2" onClick={() => updateDefaultAudience("friends")}>
              {t(textConvert('Friends'))}
            </Dropdown.Item>
            <Dropdown.Item className="py-2" onClick={() => updateDefaultAudience("only_me")}>
              {t(textConvert('Only Me'))}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }, [t, userData]);

  useEffect(() => {
    if (userData && autoLogoutSW !== null) {
      localStorage.setItem(`lastActivity_${userData.uid}`, Date.now());
      if (autoLogoutSW && logoutTimer) {
        dispatch(
          updateUser(userData.uid, {
            privacy: {
              ...userData.privacy,
              logoutTimer: logoutTimer,
            },
          })
        );
      } else {
        dispatch(
          updateUser(userData.uid, {
            privacy: {
              ...userData.privacy,
              logoutTimer: 0,
            },
          })
        );
      }
    }
  }, [logoutTimer, autoLogoutSW]);

  const handleSw = async (SwName) => {
    switch (SwName) {
      case "dooNineSearch":
        setDooNineIdSearch(!dooNineIdSearch);
        dispatch(
          updateUser(userData.uid, {
            privacy: {
              ...userData.privacy,
              dooNineIdSearch: !dooNineIdSearch,
            },
          })
        );
        if (dooNineIdSearch) {
          const q = query(
            collection(firestore, "userShareLinks"),
            where("userId", "==", userData.uid)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (doc1) => {
            try {
              await deleteDoc(doc(firestore, "userShareLinks", doc1.id));
            } catch (error) { }
          });
        }

        break;

      case "emailSearch":
        setEmailSearch(!emailSearch);
        dispatch(
          updateUser(userData.uid, {
            privacy: {
              ...userData.privacy,
              emailSearch: !emailSearch,
            },
          })
        );
        break;

      case "friendPost":
        setFriendsPostPublicSW(!friendsPostPublicSW);
        dispatch(
          updateUser(userData.uid, {
            privacy: {
              ...userData.privacy,
              friendsPost: !friendsPostPublicSW,
            },
          })
        );
        break;

      case "caddiePost":
        setCaddiePublicSW(!caddiePublicSW);
        dispatch(
          updateUser(userData.uid, {
            privacy: {
              ...userData.privacy,
              caddiePost: !caddiePublicSW,
            },
          })
        );
        break;

      case "playerPost":
        setPlayerPublicSW(!playerPublicSW);
        dispatch(
          updateUser(userData.uid, {
            privacy: {
              ...userData.privacy,
              playerPost: !playerPublicSW,
            },
          })
        );
        break;

      default:
        break;
    }
  };

  function handleChangeLeaderboardPri(privacy) {
    dispatch(
      updateUser(userData.uid, {
        privacy: {
          ...userData.privacy,
          leaderboardPrivacy: privacy,
        },
      })
    );
  }

  return (
    <>
      <style>
        {`.privacy-card {
    min-height: calc(100vh - 150px);
}`}
      </style>
      <Header />
      <Leftnav />
      <Rightchat />
      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4 privacy-card">
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <h1 className="mb-4 fw-700 mont-font mb-lg-5 mb-4 font-md-xs">
                        {t(textConvert('Privacy'))}
                      </h1>
                      <h5 className="fw-600 text-muted mb-4">
                        {t(textConvert('Logout and search'))}
                      </h5>
                      <ul>
                        <li className="border-bottom mb-4">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            {/* por 020924 */}
                            {/* <span htmlFor='auto-logout'><h4 className="fw-600 mb-0 mt-0">Auto Logout</h4></span> */}
                            <span htmlFor="auto-logout">
                              <h4 className="fw-600 mb-0 mt-0">{t(textConvert('Auto Logout'))}</h4>
                            </span>
                            {/* por 020924 */}

                            <ReactSwitch
                              onChange={() => setAutoLogoutSW(!autoLogoutSW)}
                              checked={autoLogoutSW ? true : false}
                              onColor={switchStyle.onColor}
                              width={switchStyle.width}
                              height={switchStyle.height}
                              handleDiameter={switchStyle.handleDiameter}
                              activeBoxShadow={switchStyle.boxShadow}
                              disabled={userData?.role === 'caddie'}
                            />
                          </div>
                          {handleAutoLogout}
                        </li>

                        <li className="border-bottom mb-4">
                          <div className="d-flex justify-content-between mb-2">
                            <span htmlFor="auto-logout">
                              {/* por 020924 */}
                              {/* <h4 className="fw-600 mb-0 mt-0">Doonine ID search</h4> */}
                              {/* <h6 className="fw-600 mb-0 mt-0 text-muted">Allow other users to search your account by Doonine ID</h6> */}
                              <h4 className="fw-600 mb-0 mt-0">
                                {t(textConvert('Doonine ID search'))}
                              </h4>
                              <h6 className="fw-500 mb-0 mt-0 text-muted">

                                {t(textConvert('Allow other users to search your account by Doonine ID'))}
                              </h6>
                              {/* por 020924 */}
                            </span>
                            <ReactSwitch
                              onChange={() => handleSw("dooNineSearch")}
                              checked={dooNineIdSearch ? true : false}
                              onColor={switchStyle.onColor}
                              width={switchStyle.width}
                              height={switchStyle.height}
                              handleDiameter={switchStyle.handleDiameter}
                              activeBoxShadow={switchStyle.boxShadow}
                            />
                          </div>
                        </li>

                        {/* <li className='border-bottom mb-4'>
                                                    <div className='d-flex justify-content-between mb-2'>
                                                        <span htmlFor='auto-logout'>
                                                            <h4 className="fw-600 mb-0 mt-0">Name search</h4>
                                                            <h6 className="fw-600 mb-0 mt-0 text-muted">Allow other users to search your account by first and last name.</h6>
                                                        </span>
                                                        <ReactSwitch
                                                            onChange={() => setNameSearch(!nameSearch)}
                                                            checked={nameSearch ? true : false}
                                                            onColor={switchStyle.onColor}
                                                            width={switchStyle.width}
                                                            height={switchStyle.height}
                                                            handleDiameter={switchStyle.handleDiameter}
                                                            activeBoxShadow={switchStyle.boxShadow} />
                                                    </div>
                                                </li> */}

                        <li className="border-bottom mb-4">
                          <div className="d-flex justify-content-between mb-2">
                            <span htmlFor="auto-logout">
                              {/* por 020924 */}
                              {/* <h4 className="fw-600 mb-0 mt-0">Email search</h4> */}
                              {/* <h6 className="fw-600 mb-0 mt-0 text-muted">Allow other users to search your account by E-Mail.</h6> */}

                              <h4 className="fw-600 mb-0 mt-0">{t(textConvert('Email search'))}</h4>
                              <h6 className="fw-500 mb-0 mt-0 text-muted">

                                {t(textConvert('Allow other users to search your account by EMail'))}
                              </h6>

                              {/* por 020924 */}
                            </span>
                            <ReactSwitch
                              onChange={() => handleSw("emailSearch")}
                              checked={emailSearch ? true : false}
                              onColor={switchStyle.onColor}
                              width={switchStyle.width}
                              height={switchStyle.height}
                              handleDiameter={switchStyle.handleDiameter}
                              activeBoxShadow={switchStyle.boxShadow}
                            />
                          </div>
                        </li>
                      </ul>

                      <h5 className="fw-600 text-muted mb-4 mt-5">
                        {t(textConvert('Post and feeds'))}
                      </h5>
                      <ul>
                        <li className="border-bottom mb-4">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            {/* por 020924 */}
                            {/* <span htmlFor='auto-logout'><h4 className="fw-600 mb-0 mt-0">Default audience for your posts</h4></span> */}
                            <span htmlFor="auto-logout">
                              <h4 className="fw-600 mb-0 mt-0">
                                {t(textConvert('Default audience for your posts'))}
                              </h4>
                            </span>

                            {/* por 020924 */}

                            {handleDefaultPosAudience}
                          </div>
                        </li>

                        {/* <li className='border-bottom mb-4'>
                                                    <div className='d-flex justify-content-between mb-2'>
                                                        <span htmlFor='auto-logout'>
                                                            <h4 className="fw-600 mb-0 mt-0">Show friend's public posts</h4>
                                                        </span>
                                                        <ReactSwitch
                                                            onChange={() => handleSw('friendPost')}
                                                            checked={friendsPostPublicSW ? true : false}
                                                            onColor={switchStyle.onColor}
                                                            width={switchStyle.width}
                                                            height={switchStyle.height}
                                                            handleDiameter={switchStyle.handleDiameter}
                                                            activeBoxShadow={switchStyle.boxShadow} />
                                                    </div>
                                                </li> */}

                        <li className="border-bottom mb-4">
                          <div className="d-flex justify-content-between mb-2">
                            <span htmlFor="auto-logout">
                              {/* por 020924 */}
                              {/* <h4 className="fw-600 mb-0 mt-0">Show caddie's public posts</h4> */}
                              <h4 className="fw-600 mb-0 mt-0">
                                {t(textConvert('Show caddie s public posts'))}
                              </h4>
                              {/* por 020924 */}
                            </span>
                            <ReactSwitch
                              onChange={() => handleSw("caddiePost")}
                              checked={caddiePublicSW ? true : false}
                              onColor={switchStyle.onColor}
                              width={switchStyle.width}
                              height={switchStyle.height}
                              handleDiameter={switchStyle.handleDiameter}
                              activeBoxShadow={switchStyle.boxShadow}
                            />
                          </div>
                        </li>

                        <li className="border-bottom mb-4">
                          <div className="d-flex justify-content-between mb-2">
                            <span htmlFor="auto-logout">
                              {/* por 020924 */}
                              {/* <h4 className="fw-600 mb-0 mt-0">Show player's public posts</h4> */}
                              <h4 className="fw-600 mb-0 mt-0">
                                {t(textConvert("Show player s public posts"))}
                              </h4>

                              {/* por 020924 */}
                            </span>
                            <ReactSwitch
                              onChange={() => handleSw("playerPost")}
                              checked={playerPublicSW ? true : false}
                              onColor={switchStyle.onColor}
                              width={switchStyle.width}
                              height={switchStyle.height}
                              handleDiameter={switchStyle.handleDiameter}
                              activeBoxShadow={switchStyle.boxShadow}
                            />
                          </div>
                        </li>
                      </ul>

                      <h5 className="fw-600 text-muted mb-4 mt-5">
                        {t(textConvert('Activity'))}
                      </h5>
                      <ul>
                        <li className="border-bottom mb-4">
                          <div className="d-flex justify-content-between mb-2">
                            <span htmlFor="auto-logout">
                              {/* por 020924 */}
                              {/* <h4 className="fw-600 mb-0 mt-0">Show player's public posts</h4> */}
                              <h4 className="fw-600 mb-0 mt-0">
                                {t(textConvert('Default leaderboard privacy'))}
                              </h4>

                              {/* por 020924 */}
                            </span>
                            <Dropdown>
                              <Dropdown.Toggle
                                className="mw150 d-flex align-items-center justify-content-center"
                                variant="light"
                              >
                                <span className="fw-600 fs-5 text-capitalize">
                                  {t(textConvert(defaultLeaderbaord))}
                                </span>
                                <i className="bi-chevron-down fs-5 ms-1"></i>
                              </Dropdown.Toggle>

                              <Dropdown.Menu style={{ borderRadius: 15 }} className="shadow-xss p-0 border">
                                <Dropdown.Item className="py-2" onClick={() => handleChangeLeaderboardPri("public")}>
                                  {t(textConvert('Public'))}
                                </Dropdown.Item>
                                <Dropdown.Item className="py-2" onClick={() => handleChangeLeaderboardPri("private")}>
                                  {t(textConvert('Private'))}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popupchat />
      <Appfooter />
    </>
  );
}

export default Privacy;
