import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../../components/firebase/FirebaseConfig';

export function sortScore(arr, key, isStableford) {
    arr.sort((a, b) => {
        if (a.status === 'unfinished' && b.status !== 'unfinished') {
            return 1; // Move 'a' to the end
        }
        if (a.status !== 'unfinished' && b.status === 'unfinished') {
            return -1; // Keep 'b' before 'a'
        }
        if (isStableford === 'Stableford') {
            return b[key] - a[key];
        } else {
            return a[key] - b[key];
        }
    });
    return arr;
}


export function defPosition(scoreList, isMyRound, isCombineRound) {

    if (isMyRound) {
        const scoreWithPosition = scoreList.map((item, index) => ({
            ...item,
            roundPosition: index + 1
        }));
        return scoreWithPosition;
    }

    if (isCombineRound) {
        const scoreWithPosition = scoreList.map((item, index) => ({
            ...item,
            combinePosition: index + 1
        }));
        return scoreWithPosition;
    }

    if (!isMyRound && !isCombineRound) {
        const scoreWithPosition = scoreList.map((item, index) => ({
            ...item,
            roundPosition: index + 1
        }));
        return scoreWithPosition;
    }
}

export const getUserData = async (round, roundData) => {

    try {
        // Clone the object
        const thisRound = structuredClone(round);
        const userIds = extractUid(thisRound);

        if (!userIds.length) {
            return thisRound;
        }

        // Query Firestore for the user data
        const userQ = query(collection(firestore, 'users'), where('uid', 'in', userIds));
        const userSnap = await getDocs(userQ);

        if (!userSnap.empty) {
            // Create a map of user data by UID for quick lookup
            const userDataMap = {};
            userSnap.forEach(doc => {
                const userData = doc.data();
                if (userData.uid) { // Ensure UID exists in the document data
                    userDataMap[userData.uid] = userData;
                }
            });

            // Update thisRound.userList with fetched user data
            thisRound.userList.forEach(user => {
                if (user.player && userDataMap[user.player.uid]) {
                    user.player = { ...user.player, ...userDataMap[user.player.uid] };
                }
                if (user.caddie && userDataMap[user.caddie.uid]) {
                    user.caddie = { ...user.caddie, ...userDataMap[user.caddie.uid] };
                }
            });
        }

        thisRound.courseHoleData = await getCourseData(thisRound, roundData);

        // console.log(thisRound);

        return thisRound; // Return the updated object
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error;
    }
}

export async function getCourseData(round, roundData) {
    if (!round.holesCourse) {
        return {}
    }
    try {
        const courseDoc = doc(firestore, 'courseHoles', round.holesCourse);
        const courseSnap = await getDoc(courseDoc);
        if (courseSnap.exists()) {
            return courseSnap.data()
        } else {
            console.error('Not found course hole id:', round.holesCourse);
            return {};
        }
    } catch (error) {
        console.error('Error fetching course data:', error);
    }
}

export const getStat = async (round) => {

    const uids = extractUid(round)

    if (!uids.length) return;

    try {
        const statCollection = collection(firestore, 'playerStatistic');
        const q = query(statCollection, where('userId', 'in', uids));
        const statSnapshot = await getDocs(q);
        const statArr = [];
        if (!statSnapshot.empty) {
            statSnapshot.forEach(doc => {
                const data = doc.data();
                statArr.push(data);
            });
        }
        return statArr;
    } catch (error) {
        console.error('Error fetching stat', error);
    }
}

export function extractUid(round) {
    // Extract user IDs from the user list
    const userList = round.userList;
    const userIds = [];
    userList.forEach(user => {
        if (user.player) {
            userIds.push(user.player.uid);
        }
        if (user.caddie) {
            userIds.push(user.caddie.uid);
        }
    });
    return userIds || []
}

export function processScoreList(scoreList, fetchedData) {
    if (!fetchedData) return [];

    const clonedList = structuredClone(scoreList);

    clonedList.forEach(score => {
        score.playerData = null;
        score.caddieData = null;

        const foundRound = fetchedData.find(item => item.roundId === score.roundId);
        if (foundRound) {
            const userList = foundRound.userList;
            userList.forEach(user => {
                if (user.player && user.player.uid === score.userId) {
                    score.playerData = user.player;
                }
                if (user.caddie && user.caddie.uid === score.caddieId) {
                    score.caddieData = user.caddie;
                }
            });
        }
    });

    return clonedList;
}

export function processAndGroupScores(scoreList, gameMode) {
    // Step 1: Group by roundId
    const groupedScore = Object.values(
        scoreList.reduce((acc, obj) => {
            const { roundId } = obj;
            if (!acc[roundId]) {
                acc[roundId] = [];
            }
            acc[roundId].push(obj);
            return acc;
        }, {})
    );

    // Step 2: Sort and assign positions for each group
    const finalGroupedScores = groupedScore.map(scoreGroup => {
        const sorted = sortScore(scoreGroup, 'sumScore', gameMode);
        return defPosition(sorted, false, false);
    });

    return finalGroupedScores;
}
