import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useSelector } from "react-redux";
import Pagetitle from "../../components/Pagetitle";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import { or } from "firebase/firestore";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Dropdown } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import ReactDOM from "react-dom";
import ModalFavoriteFriend from "./ModalFavoriteFriend";
import ModalFavoritePlayer from "./ModalFavoritePlayer";

import ModalFilter from "./ModalFilter";
import StackFilter from "./StackFilter";
import Label from "../createlabel/Label";
import {
  favFriend,
  favFriendCaddie,
  unfavFriend,
  unfavFriendCaddie,
} from "../../components/friend/FavFriend";
import Search from "./Search";
import "./FavoriteFriend.css";

import { ChatTo } from "../../components/chat/Chat";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../components/language/dropdown/textconvert";

const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;

function FavoriteFriend() {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.data);
  const navigate = useNavigate();

  const [memberList, setMemberList] = useState([]);
  const [findDooNineId, setFindDooNineId] = useState("");
  const [show, setShow] = useState(false);
  const [caddieData, setCaddieData] = useState({});
  const [courseData, setCourseData] = useState();
  const [favoriteFriendsList, setFavoriteFriendsList] = useState({});
  const [caddieProfile, setCaddieProfile] = useState({});
  const [favoriteFriends, setFavoriteFriends] = useState(false);
  const [showMoadalPlayersDetail, setShowMoadalPlayersDetail] = useState(false);

  const [resultDooNine, setResultDooNine] = useState({});
  const [resultDooNineState, setResultDooNineState] = useState(""); //'', 'myself', 'user-not-found', 'friend', 'not-friend'
  const [userLabels, setUserLabels] = useState([]);
  const [triggerGetLabel, setTriggerGetLabel] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [filteredMembersIsPlayer, setFilteredMembersIsPlayer] = useState([]);
  const [favoritePlayers, setFavoritePlayers] = useState(false);
  const [listUserBlocked, setListUserBolcked] = useState([]);
  const [isLoadingCard, setIsLoadingCard] = useState(false);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [selectedUserLabel, setSelectedUserLabel] = useState(null);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [sortData, setSortData] = useState({
    isLabel: { state: false, selectedLabels: [] },
    isFavorites: { state: false },
    isStar: { state: false },
    isPro: { state: false },
    age: { state: false, min: 18, max: 40 },
    isBlocks: { state: false },
  });

  const [tempSortData, setTempSortData] = useState({
    isLabel: { state: true, selectedLabels: [] },
    isFavorites: { state: true },
    isStar: { state: true },
    isPro: { state: true },
    age: { state: true, min: 18, max: 40 },
    isBlocks: { state: true },
  });

  const [search, setSearch] = useState({
    text: {
      state: false,
      value: "",
    },
  });

  const allStatesFalse = Object.keys(sortData).every(
    (key) => sortData[key].state === false
  );

  const trueStates = Object.keys(sortData).filter(
    (key) => sortData[key].state === true
  );

  useEffect(() => {
    async function fetchData() {
      const q = query(
        collection(firestore, "labels"),
        where("userId", "==", userData.uid)
      );
      const querySnapshot = await getDocs(q);
      let dataArray = [];
      querySnapshot.forEach((doc2) => {
        const data = doc2.data();
        dataArray.push({ docId: doc2.id, ...data });
      });
      setUserLabels(dataArray);
    }
    if (userData) {
      fetchData();
    }
  }, [userData, triggerGetLabel]);

  useEffect(() => {
    async function fetchData() {
      const q = query(
        collection(firestore, "blockedUsers"),
        where("userId", "==", userData.uid)
      );
      const querySnapshot = await getDocs(q);
      let dataArray = [];
      querySnapshot.forEach((doc2) => {
        const data = doc2.data();
        dataArray.push(data.blockedUserId);
      });
      setListUserBolcked(dataArray);
    }
    if (userData) {
      fetchData();
    }
  }, [userData]);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       setIsLoadingCard(true);

  //       let memberListArray = [];

  //       if (!sortData.isBlocks.state) {
  //         // Fetch favoriteFriends and favoritePlayers in one go using Promise.all
  //         const [friendsSnapshot, playersSnapshot] = await Promise.all([
  //           getDocs(
  //             query(
  //               collection(firestore, "favoriteFriends"),
  //               where("userId", "==", userData.uid),
  //               where("status", "==", "active")
  //             )
  //           ),
  //           getDocs(
  //             query(
  //               collection(firestore, "favoritePlayers"),
  //               where("userId", "==", userData.uid),
  //               where("status", "==", "active")
  //             )
  //           ),
  //         ]);

  //         // Combine caddieId and playerId into a single list
  //         const listId = [
  //           ...friendsSnapshot.docs.map((doc) => doc.data().caddieId),
  //           ...playersSnapshot.docs.map((doc) => doc.data().playerId),
  //         ];

  //         if (listId.length === 0) {
  //           setMemberList([]);
  //           return;
  //         }

  //         // Fetch users based on combined listId
  //         const userSnapshot = await getDocs(
  //           query(collection(firestore, "users"), where("uid", "in", listId))
  //         );

  //         // Create memberListArray by mapping over userSnapshot
  //         memberListArray = userSnapshot.docs.map((doc) => ({
  //           ...doc.data(),
  //           imageUrl: doc.data().profileImage || "user.png",
  //           name: `${doc.data().firstName} ${doc.data().lastName}`,
  //           docId: doc.id,
  //         }));
  //       } else {
  //         // Fetch all users if sortData.isBlocks.state is true
  //         const querySnapshot = await getDocs(collection(firestore, "users"));
  //         memberListArray = querySnapshot.docs.map((doc) => ({
  //           imageUrl: doc.data().profileImage || "user.png",
  //           name: `${doc.data().firstName} ${doc.data().lastName}`,
  //           ...doc.data(),
  //           birthDate: doc.data().birthDate,
  //           docId: doc.id,
  //         }));
  //       }

  //       // Fetch labels for each user in parallel, in both cases
  //       const labelPromises = memberListArray.map(async (member) => {
  //         const labelSnapshot = await getDocs(
  //           query(
  //             collection(firestore, "labeledUsers"),
  //             where("friendId", "==", member.uid),
  //             where("userId", "==", userData.uid)
  //           )
  //         );

  //         let labels = [];
  //         labelSnapshot.forEach((doc) => {
  //           const data = doc.data();
  //           if (data.labels) {
  //             labels = data.labels
  //               .map((labelId) =>
  //                 userLabels.find((item) => item.docId === labelId)
  //               )
  //               .filter(Boolean)
  //               .map((labelItem) => labelItem.label);
  //           }
  //         });

  //         return { ...member, labels };
  //       });

  //       // Wait for all label queries to resolve
  //       memberListArray = await Promise.all(labelPromises);

  //       // Update state
  //       setMemberList(memberListArray);
  //       setFilteredMembers(memberListArray);
  //       setIsLoadingCard(false);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   }

  //   // Trigger fetchData if userData exists
  //   if (userData) {
  //     fetchData();
  //   }
  // }, [userData, userLabels, sortData.isBlocks.state]);

  // Define fetchData outside of the useEffect
  const fetchData = async () => {
    try {
      setIsLoadingCard(true);

      let memberListArray = [];

      if (!sortData.isBlocks.state) {
        // Fetch favoriteFriends and favoritePlayers in one go using Promise.all
        const [friendsSnapshot, playersSnapshot] = await Promise.all([
          getDocs(
            query(
              collection(firestore, "favoriteFriends"),
              where("userId", "==", userData.uid),
              where("status", "==", "active")
            )
          ),
          getDocs(
            query(
              collection(firestore, "favoritePlayers"),
              where("userId", "==", userData.uid),
              where("status", "==", "active")
            )
          ),
        ]);

        // Combine caddieId and playerId into a single list
        const listId = [
          ...friendsSnapshot.docs.map((doc) => doc.data().caddieId),
          ...playersSnapshot.docs.map((doc) => doc.data().playerId),
        ];

        if (listId.length === 0) {
          setMemberList([]);
          setFilteredMembers([]); // Ensure this is updated if listId is empty
          setIsLoadingCard(false); // Stop the loading state
          return;
        }

        // Fetch users based on combined listId
        const userSnapshot = await getDocs(
          query(collection(firestore, "users"), where("uid", "in", listId))
        );

        // Create memberListArray by mapping over userSnapshot
        memberListArray = userSnapshot.docs.map((doc) => ({
          ...doc.data(),
          imageUrl: doc.data().profileImage || "user.png",
          name: `${doc.data().firstName} ${doc.data().lastName}`,
          docId: doc.id,
        }));
      } else {
        // Fetch all users if sortData.isBlocks.state is true
        const querySnapshot = await getDocs(collection(firestore, "users"));
        memberListArray = querySnapshot.docs.map((doc) => ({
          imageUrl: doc.data().profileImage || "user.png",
          name: `${doc.data().firstName} ${doc.data().lastName}`,
          ...doc.data(),
          birthDate: doc.data().birthDate,
          docId: doc.id,
        }));
      }

      // Fetch labels for each user in parallel, in both cases
      const labelPromises = memberListArray.map(async (member) => {
        const labelSnapshot = await getDocs(
          query(
            collection(firestore, "labeledUsers"),
            where("friendId", "==", member.uid),
            where("userId", "==", userData.uid)
          )
        );

        let labels = [];
        labelSnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.labels) {
            labels = data.labels
              .map((labelId) =>
                userLabels.find((item) => item.docId === labelId)
              )
              .filter(Boolean)
              .map((labelItem) => labelItem.label);
          }
        });

        return { ...member, labels };
      });

      // Wait for all label queries to resolve
      memberListArray = await Promise.all(labelPromises);

      // Update state
      setMemberList(memberListArray);
      setFilteredMembers(memberListArray);
      setIsLoadingCard(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Trigger fetchData if userData exists
    if (userData) {
      fetchData();
    }
  }, [userData, userLabels, sortData.isBlocks.state]);

  // Modify handleFavorite to call fetchData after updating favorites
  const handleFavorite = async (value) => {
    console.log(value);
    const uId = value.uid;
    const role = value.role;
    if (role === "player") {
      await unfavFriend(userData.uid, uId);
    } else {
      await favFriendCaddie(userData.uid, uId);
    }

    // Call fetchData to refresh the member list
    fetchData();
  };
  // console.log(memberList);
  useEffect(() => {
    const filteredList = memberList.filter((member) => {
      const isStarMatch =
        !sortData.isStar.state || (sortData.isStar.state && member.isStar);

      const isProMatch =
        !sortData.isPro.state || (sortData.isPro.state && member.isPro);

      const hasValidBirthDate = member.birthDate.year;

      const isAgeMatch =
        !sortData.age.state ||
        (hasValidBirthDate &&
          2024 - member.birthDate.year >= sortData.age.min &&
          2024 - member.birthDate.year <= sortData.age.max);

      // const isFavoriteMatch =
      //   !sortData.isFavorites.state ||
      //   Object.values(favoriteFriendsList).includes(member.uid);

      const selectedLabels =
        sortData.isLabel?.selectedLabels.map((option) => option.label) || [];
      const isLabelMatch =
        !sortData.isLabel?.state ||
        selectedLabels.length === 0 ||
        (member.labels &&
          selectedLabels.every((label) => member.labels.includes(label)));

      const isBlockMatch =
        !sortData.isBlocks.state ||
        Object.values(listUserBlocked).includes(member.uid);

      const isSearchMatch =
        !search.text.state ||
        member.name.toLowerCase().includes(search.text.value) ||
        member.dooNineId.toLowerCase().includes(search.text.value) ||
        member.role.toLowerCase().includes(search.text.value) ||
        (member.labels &&
          member.labels.some((label) =>
            label.toLowerCase().includes(search.text.value)
          ));
      // ต้อง match ทุก filter ที่ถูกเปิดใช้งานพร้อมกัน
      return (
        isStarMatch &&
        isProMatch &&
        isAgeMatch &&
        isLabelMatch &&
        isBlockMatch &&
        isSearchMatch
        // isFavoriteMatch &&
        // isLabelMatch &&
        // isBlockMatch
      );
    });

    setFilteredMembers(filteredList);
  }, [memberList, sortData, search]);

  const handleChat = async (dooNineId) => {

    const result = await ChatTo(userData.dooNineId, dooNineId);
    if (result) {
      navigate("/chat-room?channelId=" + result[0].channel_url);
    }

    // try {
    //   const response = await axios.get(
    //     `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels?members_exactly_in=${userData.dooNineId}&show_member=false&members_exactly_in=${dooNineId}`,
    //     {
    //       headers: {
    //         "Content-Type": "application/json; charset=utf8",
    //         "Api-Token": sendBird_api_token,
    //       },
    //     }
    //   );

    //   if (response.data.channels.length > 0) {
    //     isMobile
    //       ? navigate("/chat-room", {
    //         state: {
    //           channelUrl: response.data.channels[0].channel_url,
    //           previousPage: "caddie",
    //         },
    //       })
    //       : navigate("/chat", {
    //         state: {
    //           channelUrl: response.data.channels[0].channel_url,
    //           previousPage: "caddie",
    //         },
    //       });
    //   } else {
    //     const response2 = await axios.post(
    //       "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels",
    //       {
    //         user_ids: [userData.dooNineId, dooNineId],
    //       },
    //       {
    //         headers: {
    //           "Content-Type": "application/json; charset=utf8",
    //           "Api-Token": sendBird_api_token,
    //         },
    //       }
    //     );
    //     isMobile
    //       ? navigate("/chat-room", {
    //         state: {
    //           channelUrl: response.data.channels[0].channel_url,
    //           previousPage: "caddie",
    //         },
    //       })
    //       : navigate("/chat", {
    //         state: {
    //           channelUrl: response.data.channels[0].channel_url,
    //           previousPage: "caddie",
    //         },
    //       });
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const handleShow = async (docID) => {
    setFavoriteFriends(false);
    setFavoritePlayers(false);

    const docRef = doc(firestore, "users", docID);
    const docSnap = await getDoc(docRef);

    if (docSnap.data().role == "caddie") {
      setShow(true);
    } else {
      setShowMoadalPlayersDetail(true);
      setFilteredMembersIsPlayer({ ...docSnap.data() });

      const q = query(
        collection(firestore, "favoritePlayers"),
        where("playerId", "==", docSnap.data().uid),
        where("userId", "==", userData.uid)
      );
      const querySnapshot = await getDocs(q);

      let isFavorite = false;

      querySnapshot.forEach((doc1) => {
        if (doc1.data().status === "active") {
          isFavorite = true;
        }
      });

      setFavoritePlayers(isFavorite);
    }

    if (docSnap.exists()) {
      let isFriend = false;

      if (docSnap.data().uid) {
        const q3 = query(
          collection(firestore, "friends"),
          where("friendId", "==", docSnap.data().uid),
          where("userId", "==", userData?.uid)
        );

        const q2 = query(
          collection(firestore, "friends"),
          where("friendId", "==", userData?.uid),
          where("userId", "==", docSnap.data().uid)
        );

        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach(async (doc2) => {
          if (doc2.data().status == "active") {
            isFriend = true;
          }
        });

        const querySnapshot3 = await getDocs(q3);
        querySnapshot3.forEach(async (doc3) => {
          if (doc3.data().status == "active") {
            isFriend = true;
          }
        });
      }

      let caddieNumber = null;
    const caddieRequestQuery = query(
      collection(firestore, "caddieRequests"),
      where("caddieId", "==", docSnap.data().uid)
    );
    const caddieRequestSnapshot = await getDocs(caddieRequestQuery);
    if (!caddieRequestSnapshot.empty) {
      caddieNumber = caddieRequestSnapshot.docs[0].data().caddieNumber;
    }

      const q_caddieAssignments = query(
        collection(firestore, "caddieAssignments"),
        where("caddieId", "==", docSnap.data().uid)
      );
      const querySnapshot_caddieAssignments = await getDocs(
        q_caddieAssignments
      );
      querySnapshot_caddieAssignments.forEach(async (docCaddieAssignment) => {
        const docRefCourse = doc(
          firestore,
          "courses",
          docCaddieAssignment.data().courseId
        );
        const docSnapCourse = await getDoc(docRefCourse);

        if (docSnapCourse.exists()) {
          setCourseData({
            ...docSnapCourse.data(),
            courseId: docSnapCourse.id,
          });
        } else {
          console.log("No such document!");
        }
      });

      setCaddieData({ isFriend: isFriend, caddieNumber, ...docSnap.data() });

      const q_caddieProfiles = query(
        collection(firestore, "caddieProfiles"),
        where("caddieId", "==", docSnap.data().uid)
      );
      const querySnapshot_caddieProfiles = await getDocs(q_caddieProfiles);
      querySnapshot_caddieProfiles.forEach(async (docCaddieprofile) => {
        let listImage = [
          { imageUrl: docSnap.data()?.profileImage, isPrimary: true, order: 0 },
        ];
        const querySnapshot = await getDocs(
          collection(firestore, "caddieProfiles", docCaddieprofile.id, "image")
        );
        querySnapshot.forEach((doc) => {
          listImage.push(doc.data());
        });
        setCaddieProfile({ ...docCaddieprofile.data(), image: listImage });
      });
    }

    if (docSnap.data().uid === undefined) {
    } else {
      const q = query(
        collection(firestore, "favoriteFriends"),
        where("caddieId", "==", docSnap.data().uid),
        where("userId", "==", userData.uid)
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.size == 0) {
        setFavoriteFriends(false);
      } else {
        querySnapshot.forEach((doc1) => {
          if (doc1.data().status == "active") {
            setFavoriteFriends(true);
          } else {
            setFavoriteFriends(false);
          }
        });
      }
    }
  };

  const handleSwitchChange = (checked, switchId) => {
    setTempSortData((prevState) => ({
      ...prevState,
      [switchId]: { ...prevState[switchId], state: checked },
    }));
  };

  const handleSubmit = () => {
    setSortData(tempSortData);
    handleCloseSortAndFilter();
  };

  const [showSortAndFilter, setShowSortAndFilter] = useState(false);
  const handleCloseSortAndFilter = () => {
    setTempSortData(sortData);
    setShowSortAndFilter(false);
  };
  const handleShowSortAndFilter = () => {
    setTempSortData(sortData);

    setShowSortAndFilter(true);
  };

  const handleInputChange = (e, field, type) => {
    const value = e && e.target ? e.target.value : e;

    setTempSortData((prevState) => ({
      ...prevState,
      [field]: { ...prevState[field], [type]: value },
    }));
  };

  // const handleFavorite = async (value) => {
  //   console.log(value)
  //   const uId = value.uid
  //   const role = value.role
  //   if(role === "player"){
  //     await unfavFriend(userData.uid, uId)

  //   } else {
  //     await favFriendCaddie(userData.uid,uId)

  //   }
  //   fetchData();

  // };

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12 pageMyFavorite">
                <div className="card shadow-xss w-100 d-block d-flex border-0 mb-1">
                  <div className="d-flex w-100 flex-column flex-md-row p-3">
                    <h2 className="d-flex fw-700 mb-2 mb-md-0 mt-0 font-md text-grey-900 align-items-center">
                      {t(textConvert('My Favorites'))}
                    </h2>

                    <div className="d-flex align-items-center ms-md-auto mt-2 mt-md-0 w-md-auto">
                      {/* <form
                        action="#"
                        className="d-flex align-items-center w-100"
                      >
                        <div className="search-form-2 flex-grow-1">
                          <i className="ti-search font-xss"></i>
                          <input
                            type="text"
                            className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
                            placeholder="Search here."
                            style={{ flexGrow: 1, minWidth: "150px" }}
                          />
                        </div>
                      </form> */}
                      <Search
                        filteredMembers={filteredMembers}
                        setFilteredMembers={setFilteredMembers}
                        search={search}
                        setSearch={setSearch}
                      />

                      <div
                        onClick={() => handleShowSortAndFilter()}
                        style={{ cursor: "pointer" }}
                        className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3"
                      >
                        <i className="feather-filter font-xss text-grey-500"></i>
                      </div>
                    </div>
                  </div>
                  <StackFilter
                    trueStates={trueStates}
                    sortData={sortData}
                    setSortData={setSortData}
                    setTempSortData={setTempSortData}
                    search={search}
                    setSearch={setSearch}
                  ></StackFilter>
                  {isLoadingCard ? (
                    <div></div>
                  ) : (
                    <div className="row gx-1 ps-1 pe-1">
                      {filteredMembers.map((value, index) => (
                        <div key={index} className="col-lg-2 col-md-4 col-4">
                          <div
                            className="square-card shadow-xss rounded-xxxl bg-gradiant-bottom-2 overflow-hidden mb-1 bg-image-cover"
                            style={{
                              backgroundImage: `url(${value.profileImage})`,
                              borderColor: "white",
                              cursor: "pointer",
                            }}
                          >
                            <div className="card-body d-block w-100 position-absolute bottom-0">
                              <div
                                className="clearfix"
                                style={{ height: "85%" }}
                                onClick={() => handleShow(value.docId)}
                              ></div>

                              {value.isStar ? (
                                <h4
                                  className="fw-600 position-absolute z-index-1 ls-3 font-xssss text-white"
                                  style={{ top: "5px", right: "5px" }}
                                >
                                  <ReactSVG
                                    src="assets/icon/CaddieFriendFav-CaddieStar.svg"
                                    style={{ width: "25px" }}
                                  />
                                </h4>
                              ) : (
                                ""
                              )}

                              {value.isPro ? (
                                <h4
                                  className="fw-600 position-absolute z-index-1 ls-3 font-xssss text-white"
                                  style={{
                                    top: value.isStar ? "35px" : "5px",
                                    right: "5px",
                                  }}
                                >
                                  <ReactSVG
                                    src="assets/icon/CaddieFriendFav-ProCaddie.svg"
                                    style={{ width: "25px" }}
                                  />
                                </h4>
                              ) : (
                                ""
                              )}

                              <div
                                className="d-flex justify-content-between align-items-center position-absolute z-index-1 text-white w-100"
                                style={{
                                  bottom: "-5px",
                                  left: "5px",
                                }}
                              >
                                <h4
                                  className="fw-600 ls-3 font-xssss text-white mb-0"
                                  style={{
                                    overflow: "hidden",
                                    width: "60%",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                  }}
                                  onClick={() => handleShow(value.id)}
                                >
                                  {value.firstName} {value.lastName}
                                </h4>
                                <Dropdown
                                  className="ms-auto drodownFriend"
                                  drop="down-centered"
                                  align="end"
                                >
                                  <Dropdown.Toggle
                                    as="i"
                                    className="ti-more-alt text-white-900 btn-round-md font-xss"
                                    id="dropdown-basic"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i className="fas fa-bars text-white"></i>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu
                                    as={CustomDropdown}
                                    popperConfig={{
                                      modifiers: [
                                        {
                                          name: "flip",
                                          options: {
                                            altBoundary: true,
                                            rootBoundary: "viewport",
                                            padding: 8,
                                          },
                                        },
                                        {
                                          name: "preventOverflow",
                                          options: {
                                            altAxis: true,
                                            altBoundary: true,
                                            tether: false,
                                          },
                                        },
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [0, 10],
                                          },
                                        },
                                      ],
                                    }}
                                  >
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleChat(value.dooNineId)
                                      }
                                      style={{ display: "flex" }}
                                    >
                                      <ReactSVG
                                        src="assets/images/CaddieFriendFav-ChatDropDown.svg"
                                        style={{
                                          width: "25px",
                                          marginRight: "8px",
                                          marginTop: "-1px",
                                        }}
                                      />
                                      Chat
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{ display: "flex" }}
                                      onClick={() => {
                                        setSelectedUserLabel(value);
                                        setShowLabelModal(true);
                                      }}
                                    >
                                      <ReactSVG
                                        src="assets/images/dooNineIconV3/my-performance/profile-label.svg"
                                        style={{
                                          width: "25px",
                                          marginRight: "8px",
                                          marginTop: "-1px",
                                        }}
                                      />
                                      Label
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => handleFavorite(value)}
                                      style={{ display: "flex" }}
                                    >
                                      <ReactSVG
                                        src="assets/images/dooNineIconV3/my-performance/profile-fav.svg"
                                        style={{
                                          width: "25px",
                                          marginRight: "8px",
                                          marginTop: "-1px",
                                        }}
                                      />
                                      Favorites
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalFavoriteFriend
        show={show}
        setShow={setShow}
        caddieData={caddieData}
        setCaddieData={setCaddieData}
        setCourseData={setCourseData}
        setFavoriteFriends={setFavoriteFriends}
        favoriteFriends={favoriteFriends}
        courseData={courseData}
        caddieProfile={caddieProfile}
        setCaddieProfile={setCaddieProfile}
      ></ModalFavoriteFriend>

      <ModalFavoritePlayer
        showMoadalPlayersDetail={showMoadalPlayersDetail}
        setShowMoadalPlayersDetail={setShowMoadalPlayersDetail}
        filteredMembersIsPlayer={filteredMembersIsPlayer}
        setFavoritePlayers={setFavoritePlayers}
        favoritePlayers={favoritePlayers}
      ></ModalFavoritePlayer>

      <ModalFilter
        showSortAndFilter={showSortAndFilter}
        setShowSortAndFilter={setShowSortAndFilter}
        handleCloseSortAndFilter={handleCloseSortAndFilter}
        tempSortData={tempSortData}
        handleSubmit={handleSubmit}
        handleSwitchChange={handleSwitchChange}
        handleInputChange={handleInputChange}
        userLabels={userLabels}
      // handleInputLabelChange={handleInputLabelChange}
      />

      <Label
        showLabelModal={showLabelModal}
        setShowLabelModal={setShowLabelModal}
        setSelectedUserLabel={setSelectedUserLabel}
        selectedUserLabel={selectedUserLabel}
        userLabels={userLabels}
        triggerGetLabel={triggerGetLabel}
        setTriggerGetLabel={setTriggerGetLabel}
      />

      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}

export default FavoriteFriend;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
