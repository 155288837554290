import React, { useEffect, useRef, useState } from 'react'
import { firestore } from '../../../components/firebase/FirebaseConfig';
import { collection, getDocs, query, where, orderBy, limit, startAfter } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { getBlockedUserList, getFriendList } from './utilsfunction/getFriend';
import Load from '../../../components/Load';
import PostviewDesktop from './PostviewDesktop';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../components/language/dropdown/textconvert';
import { filterPosts } from './utilsfunction/filterPosts';

const MAX_POST = 10;
const postRef = collection(firestore, 'posts');

function MainPostviewV2() {
    const { t } = useTranslation()
    const userData = useSelector((state) => state.user.data);
    const [posts, setPosts] = useState([]);

    const [lastVisible, setLastVisible] = useState(null);  // The last visible post document reference
    const [hasMore, setHasMore] = useState(true);

    const friendsListRef = useRef([]);
    const blockListRef = useRef([]);

    const initPost = async (lastDoc = null, accumulatedPosts = []) => {
        let q;

        if (lastDoc) {
            q = query(
                postRef,
                where('audience', 'in', ['friends', 'public']),
                orderBy('timestamp', 'desc'),
                startAfter(lastDoc),
                limit(MAX_POST - accumulatedPosts.length > 0 ? MAX_POST - accumulatedPosts.length : 1)
            );
        } else {
            q = query(
                postRef,
                where('audience', 'in', ['friends', 'public']),
                orderBy('timestamp', 'desc'),
                limit(MAX_POST)
            );
        }

        try {
            const postSnap = await getDocs(q);

            if (!postSnap.empty) {
                const newPosts = postSnap.docs.map(p => p.data());

                const filteredPosts = filterPosts(userData, newPosts, blockListRef.current, friendsListRef.current) || [];

                const postsWuserData = await getUserData(filteredPosts);

                const allPosts = [...accumulatedPosts, ...postsWuserData];

                setPosts(prevPosts => [...prevPosts, ...postsWuserData]);

                if (allPosts.length >= 10 || postSnap.docs.length < (MAX_POST - accumulatedPosts.length)) {
                    setLastVisible(postSnap.docs[postSnap.docs.length - 1] || lastDoc);
                    // Set posts and update state when we have enough filtered posts or no more data

                    // Corrected hasMore logic: Only set to true if more posts exist
                    setHasMore(filteredPosts.length > 0 && postSnap.docs.length >= MAX_POST);
                } else {
                    // Recursively fetch more posts if filtered posts are still less than 10
                    initPost(postSnap.docs[postSnap.docs.length - 1], allPosts);
                }

            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    };


    const getMorePosts = async () => {
        if (!hasMore) return;

        const q = query(
            postRef,
            where('audience', 'in', ['friends', 'public']), // Audience filter
            orderBy('timestamp', 'desc'), // Order by timestamp
            startAfter(lastVisible), // Start after the last fetched post
            limit(MAX_POST) // Limit to MAX_POST for pagination
        );

        try {
            const postSnap = await getDocs(q);

            if (!postSnap.empty) {
                const newPosts = postSnap.docs.map(p => p.data());
                const filteredPosts = filterPosts(userData, newPosts, blockListRef.current, friendsListRef.current) || [];
                const postsWuserData = await getUserData(filteredPosts);

                setPosts(prevPosts => [...prevPosts, ...postsWuserData]); // Append new posts to existing ones
                setLastVisible(postSnap.docs[postSnap.docs.length - 1]); // Update the last visible post reference
                setHasMore(true);

            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error fetching more posts:', error);
        }
    };

    useEffect(() => {
        async function getFriendAndBlocked() {
            try {
                const [friends, blocked] = await Promise.all([
                    getFriendList(userData),
                    getBlockedUserList(userData)
                ])

                friendsListRef.current = friends
                blockListRef.current = blocked

                await initPost();

            } catch (error) {
                console.error('Failed to initialize', error);
            }
        }

        if (userData) {
            getFriendAndBlocked();
        }
    }, [userData])

    return (
        <>
            <InfiniteScroll
                dataLength={posts.length}
                next={getMorePosts}
                hasMore={hasMore}
                loader={<Load />}
                endMessage={
                    <>
                        {posts.length ?
                            <div className='text-center py-sm-3 py-2'>
                                <h5 className='fw-600'>{t(textConvert('You ve seen all post'))}</h5>
                            </div>
                            :
                            <div className='text-center py-sm-3 py-2'>
                                <h5 className='fw-600'>{t(textConvert('No post to show'))}</h5>
                            </div>
                        }
                    </>
                }
            // className='overflow-hidden'
            >
                <PostviewDesktop posts={posts} />

            </InfiniteScroll>
        </>
    )
}

export default MainPostviewV2

async function getUserData(posts) {
    try {
        const userIds = [...new Set(posts.map(post => post.userId))];
        if (userIds.length === 0) {
            return posts;
        }

        const userRefs = query(collection(firestore, "users"), where("uid", "in", userIds));
        const userSnapshot = await getDocs(userRefs);

        const userMap = {};
        userSnapshot.docs.forEach(userDoc => {
            userMap[userDoc.data().uid] = userDoc.data();
        });

        const mergedPosts = posts.map(post => ({
            ...post,
            userData: userMap[post.userId] || {}
        }));
        return mergedPosts;
    } catch (error) {
        console.error('Failed to get userData', error);
        return posts;
    }
}