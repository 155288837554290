import { and, collection, getDocs, limit, or, orderBy, query, startAfter, where } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { mapFavUser, mapUserlabel } from "./mapUser";
import { getFavCaddie, getFavPlayer } from "./fetchFavorite";

// initial fetch friend
export const initialFetchFriend = async (
    userData,
    setHasMore,
    setLastVisible,
    setUserList,
    friendListIds,
    lableList
) => {
    const maxInit = 20
    try {
        const q = query(collection(firestore, "friends"),
            and(
                or(
                    where("friendId", "==", userData.uid),
                    where("userId", "==", userData.uid)
                ),
                where('status', '==', 'active'),
            ),
            limit(maxInit),
            orderBy("timestamp", 'desc')
        );

        const friendsSnapshots = await getDocs(q);

        if (friendsSnapshots.size < maxInit) {
            setHasMore(false);
        }
        if (!friendsSnapshots.empty) {

            setLastVisible(friendsSnapshots.docs[friendsSnapshots.docs.length - 1]);

            const friendArr = []
            friendsSnapshots.forEach(doc => {
                const data = doc.data();
                if (data.friendId !== userData.uid) {
                    friendArr.push(data.friendId);
                } else {
                    friendArr.push(data.userId);
                }
            });

            friendListIds.current = friendArr;

            const [user, label, favCaddie, favPlayer] = await Promise.all([
                getUserData(friendArr),
                getUserLabel(userData, friendArr),
                getFavCaddie(userData, friendArr),
                getFavPlayer(userData, friendArr)
            ]);

            const mergedFav = [...favCaddie, ...favPlayer];
            const mappedFav = mapFavUser(user, mergedFav);
            const mappedLabel = mapUserlabel(mappedFav, label, lableList);
            setUserList(mappedLabel);
        }
    } catch (error) {
        console.error('Failed to get init friend', error);
        setUserList([]);
    }
}

// fetch next friend
export const nextFetchFriend = async (
    userData,
    setHasMore,
    setLastVisible,
    setUserList,
    friendListIds,
    lastVisible,
    lableList
) => {

    if (!lastVisible) return;

    const maxNext = 10;

    try {
        const q = query(collection(firestore, "friends"),
            and(
                or(
                    where("friendId", "==", userData.uid),
                    where("userId", "==", userData.uid)
                ),
                where('status', '==', 'active'),
            ),
            orderBy("timestamp", 'desc'),
            startAfter(lastVisible),
            limit(maxNext),
        );

        const friendsSnapshots = await getDocs(q);
        if (friendsSnapshots.size < maxNext) {
            setHasMore(false);
        }
        if (!friendsSnapshots.empty) {

            setLastVisible(friendsSnapshots.docs[friendsSnapshots.docs.length - 1]);

            const friendArr = []
            friendsSnapshots.forEach(doc => {
                const data = doc.data();
                if (data.friendId !== userData.uid) {
                    friendArr.push(data.friendId);
                } else {
                    friendArr.push(data.userId);
                }
            });

            friendListIds.current = [...friendListIds.current, ...friendArr];

            const [user, label, favCaddie, favPlayer] = await Promise.all([
                getUserData(friendArr),
                getUserLabel(userData, friendArr),
                getFavCaddie(userData, friendArr),
                getFavPlayer(userData, friendArr)
            ]);
            // console.log(label);
            const mergedFav = [...favCaddie, ...favPlayer];
            const mappedFav = mapFavUser(user, mergedFav);
            const mappedLabel = mapUserlabel(mappedFav, label, lableList);
            setUserList((prev) => [...new Map([...prev, ...mappedLabel].map(item => [item.uid, item])).values()]);

        }
    } catch (error) {
        console.error('Failed to get next friend', error);
        setUserList(prev => [...prev]);
    }
}

// user data
const getUserData = async (uids) => {
    if (!uids.length) return [];

    try {
        const q = query(collection(firestore, 'users'), where('uid', 'in', uids));
        const userSnap = await getDocs(q);

        let userDataArr = []
        if (!userSnap.empty) {
            userDataArr = userSnap.docs.map(item => item.data());
        }
        return userDataArr;

    } catch (error) {
        console.error('Failed to get user data', error);
        return [];
    }
}

// user label
export const getUserLabel = async (userData, uids) => {
    if (!uids.length) return [];

    try {
        const q = query(collection(firestore, 'labeledUsers'), where('friendId', 'in', uids), where('userId', '==', userData.uid));
        const userLabelSnap = await getDocs(q);

        let labelArr = [];
        if (!userLabelSnap.empty) {
            labelArr = userLabelSnap.docs.map(item => item.data());
        }
        return labelArr;

    } catch (error) {
        console.error('Failed to get user label data', error);
        return [];
    }
}

// user block
// export const getUserBlock = async (userData) => {
//     if (!userData) return [];

//     try {
//         const q = query(collection(firestore, 'blockedUsers'), where('userId', '==', userData.uid));
//         const blockedUserSnap = await getDocs(q);

//         let blockedUserDataArr = [];
//         if (!blockedUserSnap.empty) {
//             const blockedArr = blockedUserSnap.docs.map(item => item.data().blockedUserId);

//             blockedUserDataArr = await getUserData(blockedArr);
//             blockedUserDataArr = blockedUserDataArr.map(user => ({
//                 ...user,
//                 isBlocked: true
//             }));
//         }
//         return blockedUserDataArr;


//     } catch (error) {
//         console.error('Failed to get user label data', error);
//         return [];
//     }
// }