import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import Table from "./component/Table";
import Swal from "sweetalert2";
import CreateLink from "./component/CreateLink";
import AddLeaderboard from "./component/AddLeaderboard";
import LoadCircle from "../../components/LoadCircle";
import { useTranslation } from "react-i18next";
import { convertScoring, holeConvert, textConvert } from "../../components/language/dropdown/textconvert";

function LeaderboardIndex() {
    const { t } = useTranslation();
    const params = new URLSearchParams(window.location.search);
    const roundId = params.get("round");
    const [roundData, setRoundData] = useState({});
    const [otherRoundList, setOtherRoundList] = useState([]);
    const [loading, setLoading] = useState(true);
    const userData = useSelector((state) => state.user.data);
    const navigate = useNavigate();

    useEffect(() => {
        let unsubscribeRound = null;

        async function getRoundData(roundId) {
            let courseHoleData = {}, roundData = {};

            try {
                // Listen for changes to round data
                const roundDoc = doc(firestore, 'round', roundId);
                unsubscribeRound = onSnapshot(roundDoc, async (roundSnapshot) => {
                    if (roundSnapshot.exists()) {
                        roundData = roundSnapshot.data();

                        if (!roundData.isLeaderBoardPublic) {
                            const userList = roundData.userList;
                            const foundUser = userList.find(item =>
                                (item.player && item.player.uid === userData.uid) ||
                                (item.caddie && item.caddie.uid === userData.uid)
                            );
                            if (!foundUser) {
                                navigate("/round-activity");

                                Swal.fire({
                                    title: t(textConvert("Round is Private")),
                                    html: `
                                    <h5 class="fw-600">The round you selected is private.</h5>
                                    <h5 class="fw-600">Please check and try again.</h5>
                                    `,
                                    icon: "error",
                                    confirmButtonColor: "#ee3d7f",
                                    confirmButtonText: "OK",
                                })
                                return
                            }
                        }

                        // Fetch course data if applicable
                        if (roundData.holesCourse) {
                            const courseHoleDoc = doc(firestore, 'courseHoles', roundData.holesCourse);
                            const courseHoleSnapshot = await getDoc(courseHoleDoc);
                            if (courseHoleSnapshot.exists()) {
                                courseHoleData = courseHoleSnapshot.data();
                            } else {
                                console.error('Not found hole course:', roundData.holesCourse);
                            }
                        } else {
                            console.warn('Not select course hole yet');
                        }

                        // Update state with both round and course data
                        setRoundData({
                            ...roundData,
                            courseHoleData: courseHoleData
                        });
                    } else {
                        console.error('Not found round:', roundId);
                    }
                });
            } catch (error) {
                setRoundData({
                    ...{},
                    courseHoleData: {}
                });
            } finally {
                setLoading(false);
            }
        }

        if (roundId && userData) {
            getRoundData(roundId);
        }

        return () => {
            // Cleanup listener
            if (unsubscribeRound) unsubscribeRound();
        };
    }, [navigate, roundId, userData]);


    useEffect(() => {
        function getOtherRound(roundId) {
            try {
                const combinedLeaderboard = collection(firestore, 'combinedLeaderboard');
                const q = query(combinedLeaderboard, where('mainRound', '==', roundId));

                const unsubscribeCombined = onSnapshot(q, async (combinedLeaderSnap) => {
                    if (!combinedLeaderSnap.empty) {
                        const otherRoundIds = [];
                        combinedLeaderSnap.forEach(doc => {
                            const data = doc.data();
                            otherRoundIds.push(...data.roundList);
                        });

                        const filteredRounds = [...new Set(otherRoundIds)].filter(round => round !== roundId);

                        // Update state immediately with filtered round IDs
                        // setOtherRoundList(filteredRounds);

                        if (filteredRounds.length > 0) {
                            // Fetch round data based on filtered round IDs
                            const roundCollection = collection(firestore, 'round');
                            const roundQ = query(roundCollection, where('roundId', 'in', filteredRounds));
                            const roundSnap = await getDocs(roundQ); // Fetch once without subscription

                            if (!roundSnap.empty) {
                                const otherRoundDataList = [];
                                roundSnap.forEach(doc => {
                                    otherRoundDataList.push(doc.data());
                                });
                                setOtherRoundList(otherRoundDataList); // Update with detailed round data
                            }
                        } else {
                            setOtherRoundList([]);
                        }
                    } else {
                        setOtherRoundList([]); // Clear the list if no data
                    }
                });

                return unsubscribeCombined; // Ensure cleanup is returned

            } catch (error) {
                console.error('Error fetching other round', error);
            }
        }

        if (roundId) {
            const unsubscribe = getOtherRound(roundId);
            return () => unsubscribe && unsubscribe(); // Properly cleanup the listener
        }
    }, [roundId]);

    // console.log(otherRoundList);

    return (
        <>
            <Header />
            <Leftnav />
            <Rightchat />

            <style>
                {`
                    @media (max-width: 991.98px) {
                        .main-content .middle-sidebar-bottom {
                            width: 100%;
                            padding: 76px 4px 0;
                        }
                    }
                    `}
            </style>

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">

                                {/* nav */}
                                {/* <div className="card-body p-4 w-100 bg-current border-0 d-flex justify-content-between align-items-center rounded-3"> */}
                                <div className="card-body w-100 bg-current border-0 d-flex justify-content-between align-items-center rounded-3" style={{ padding: "1.2rem" }}>
                                    <div className="d-flex align-items-center">
                                        <Link to={-1} className="d-inline-block mt-2">
                                            <i className="ti-arrow-left font-sm text-white"></i>
                                        </Link>
                                        <div>
                                            <div className='mx-2'>
                                                <h3 className='fw-700 text-white'>{roundData.roundName}</h3>
                                                <h6 className='fw-600 text-white text-capitalize'>{`${t(textConvert(holeConvert(roundData?.holes) || ""))}(${roundData.courseHoleData?.courseHoleName || ""}), ${t(textConvert(roundData?.gameMode)) || ""}(${t(textConvert(convertScoring(roundData?.scoring))) || ""})`}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* body */}
                                <div className="card-body p-lg-3 py-4 px-2 w-100 border-0">
                                    {!loading ?
                                        <>
                                            <Table roundData={roundData} otherRound={otherRoundList} key={otherRoundList.length} />
                                            
                                            {roundData?.creator === userData?.uid ?
                                                <>
                                                    <CreateLink roundData={roundData} />
                                                    <AddLeaderboard
                                                        yourRoundData={roundData}
                                                        otherRound={otherRoundList}
                                                    />
                                                </>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        <LoadCircle variant="dark" size="lg" className="text-center" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />

        </>
    )
}

export default LeaderboardIndex