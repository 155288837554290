import React from 'react'
import HeaderDropdown from '../../score/component/HeaderDropdown'
import { Link } from 'react-router-dom'
import { useScore } from '../ScoreContext/ScoreContext'
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { convertScoring, holeConvert, textConvert } from '../../../components/language/dropdown/textconvert';

function ScoreTopNav() {
    const { t } = useTranslation();
    const { roundData, courseHoles, playerGameMode } = useScore();
    const nav = useNavigate()
    if (!roundData) return;

    const handleChat = async (channelUrl) => {
        nav("/chat-room?channelId=" + channelUrl);
    };

    return (
        // <div className="card-body p-4 w-100 bg-current border-0 d-flex align-items-center rounded-3">

        <div className="card-body w-100 bg-current border-0 d-flex align-items-center rounded-3 py-3 px-4 position-relative" >
            <Link to="/round-activity" className="d-inline-block mt-2">
                <i className="ti-arrow-left font-sm text-white"></i>
            </Link>
            <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='mx-2'>
                    <h3 className='fw-700 text-white'>{roundData.roundName}</h3>
                    {playerGameMode && !playerGameMode.defaultSw ?
                        <h6 className='fw-600 text-white text-capitalize'>{`${t(textConvert(holeConvert(roundData.holes)))}(${courseHoles?.courseHoleName || ""}), ${t(textConvert(playerGameMode.gameMode))}(${t(textConvert(convertScoring(playerGameMode.scoring)))})`}</h6>
                        :
                        <h6 className='fw-600 text-white text-capitalize'>{`${t(textConvert(holeConvert(roundData.holes)))}(${courseHoles?.courseHoleName || ""}), ${t(textConvert(roundData.gameMode))}(${t(textConvert(convertScoring(roundData.scoring)))})`}</h6>
                    }
                </div>
                <div className='d-flex align-items-center gap-1 flex-sm-row flex-column-reverse'>
                    <ReactSVG
                        src="assets/images/dooNineIconV2/chat-SL.svg"
                        wrapper="svg"
                        width={45}
                        height={45}
                        className={clsx("pointer mx-1", {
                            "d-none": roundData.groupChat !== "create",
                        })}
                        onClick={() => handleChat(roundData.channelUrl)}
                    />
                    <HeaderDropdown />
                </div>
            </div>
        </div>
    )
}

export default ScoreTopNav