import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
  limit,
  startAfter,
  orderBy
} from "firebase/firestore";
import { firestore } from "../../../../../components/firebase/FirebaseConfig";
import { epochToDateTime } from "../../../component/wizard/module/covertTime";

export const getDataFinshedRound = async (
  userData,
  setListRoundTest,
  setLoading,
  lastVisibleDoc = null,
  append = false
) => {
  if (!userData) throw new Error("userData is required");

  setLoading(true);

  let nextVisibleDoc = lastVisibleDoc;
  let filteredRoundStart = [];
  const loadedRoundIds = new Set();

  try {
    let fetchCount = 0;
    const maxFetchCount = 5;

    while (filteredRoundStart.length < 5 && fetchCount < maxFetchCount) {
      fetchCount++;

      const roundActivityQuery = query(
        collection(firestore, "roundActivity"),
        where("userList", "array-contains", userData.uid),
        orderBy("created", "desc"),
        limit(5),
        ...(nextVisibleDoc ? [startAfter(nextVisibleDoc)] : [])
      );

      const roundActivitySnapshot = await getDocs(roundActivityQuery);

      if (roundActivitySnapshot.empty) break;

      const roundId = roundActivitySnapshot.docs.map((doc) => doc.data().roundId);

      const batchQueries = [];
      for (let i = 0; i < roundId.length; i += 10) {
        const batch = roundId.slice(i, i + 10);
        batchQueries.push(
          getDocs(
            query(
              collection(firestore, "round"),
              where("roundId", "in", batch),
              where("teeTime", "==", "now")
            )
          )
        );
      }

      const batchResults = await Promise.all(batchQueries);
      const roundSnapshot = batchResults.flatMap((batch) => batch.docs);

      const roundData = roundSnapshot
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((round) => round.status !== "deleted")
  .sort((a, b) => (b.scheduleTime || 0) - (a.scheduleTime || 0));

      roundData.forEach((round) => loadedRoundIds.add(round.id));

      const roundsWithDetails = await addCourseAndHolesData(roundData);
      const roundsWithUsers = await fetchUserDetails(roundsWithDetails, userData);
      const checkRoundStart = await checkPlayingRounds(roundsWithUsers);

      const validRounds = checkRoundStart.filter((user) => {
        return user.userList.some((userItem) => {
          const isPlayerMatch =
            userItem.player?.uid === userData.uid &&
            (userItem.player?.status === "finished" || userItem.player?.status === "unfinished");

          const isCaddieMatch =
            userItem.caddie?.uid === userData.uid &&
            (userItem.caddie?.status === "finished" || userItem.caddie?.status === "unfinished");

          return isPlayerMatch || isCaddieMatch;
        });
      });

      filteredRoundStart = [...filteredRoundStart, ...validRounds];

      nextVisibleDoc =
        roundActivitySnapshot.docs[roundActivitySnapshot.docs.length - 1];

      if (filteredRoundStart.length >= 5) break;
    }

    if (filteredRoundStart.length === 0 && nextVisibleDoc === null) {
      setListRoundTest([]);
      setLoading(false);
      return null;
    }

    const sortedRounds = filteredRoundStart.sort((a, b) => (b.scheduleTime || 0) - (a.scheduleTime || 0));

    setListRoundTest((prevList) => (append ? [...prevList, ...sortedRounds] : sortedRounds));
  } catch (error) {
    console.error("Error fetching finished rounds:", error);
    setListRoundTest((prevList) => (append ? [...prevList] : []));
  } finally {
    setLoading(false);
  }

  return nextVisibleDoc;
};


const checkPlayingRounds = async (filteredRounds) => {
  const promises = filteredRounds.map(async (round) => {
    try {
      // อัปเดต userList โดยเพิ่ม status ของ player และ caddie
      const updatedUserList = await Promise.all(
        round.userList.map(async (user) => {
          const queries = [];

          // สร้าง query สำหรับ caddie
          if (user.caddie?.uid) {
            const caddieQuery = query(
              collection(firestore, "scoreCards"),
              where("caddieId", "==", user.caddie.uid),
              where("roundId", "==", round.roundId)
            );
            queries.push(
              getDocs(caddieQuery).then((snapshot) => ({
                role: "caddie",
                status:
                  snapshot.docs.length > 0
                    ? snapshot.docs[0].data().status
                    : null,
              }))
            );
          }

          // สร้าง query สำหรับ player
          if (user.player?.uid) {
            const playerQuery = query(
              collection(firestore, "scoreCards"),
              where("userId", "==", user.player.uid),
              where("roundId", "==", round.roundId)
            );
            queries.push(
              getDocs(playerQuery).then((snapshot) => ({
                role: "player",
                status:
                  snapshot.docs.length > 0
                    ? snapshot.docs[0].data().status
                    : null,
              }))
            );
          }

          const statuses = await Promise.all(queries);

          // เพิ่มสถานะลงใน userList
          const updatedUser = {
            ...user,
            caddie: user.caddie
              ? {
                  ...user.caddie,
                  status:
                    statuses.find((s) => s.role === "caddie")?.status || null,
                }
              : null,
            player: user.player
              ? {
                  ...user.player,
                  status:
                    statuses.find((s) => s.role === "player")?.status || null,
                }
              : null,
          };

          return updatedUser;
        })
      );

      // อัปเดต userList ใน round
      return { ...round, userList: updatedUserList };
    } catch (error) {
      console.error(`Error checking round ${round.roundId}:`, error);
      return round; // ส่ง round เดิมกลับถ้าเกิดข้อผิดพลาด
    }
  });

  const results = await Promise.all(promises);
  return results;
};

const fetchUserDetails = async (checkFinishedRound, data) => {
  if (!data || !data.uid) {
    throw new Error("userData is not defined or uid is missing");
  }

  const promises = checkFinishedRound.map(async (round) => {
    const userDetails = [];

    // จัดเรียง userList โดยให้ player.uid === userData.uid อยู่ข้างหน้า
    const sortedUserList = [...round.userList].sort((a, b) => {
      const aHasUser = a?.player?.uid === data.uid;
      const bHasUser = b?.player?.uid === data.uid;

      return aHasUser === bHasUser ? 0 : aHasUser ? -1 : 1;
    });

    // สร้าง userDetails หลังจากจัดเรียง userList แล้ว
    sortedUserList.forEach((slot) => {
      if (slot?.player?.uid) {
        userDetails.push({
          uid: slot.player.uid,
          isCreator: slot.player.isCreator || false,
          isJoin: slot.player.isJoin || false,
          isReview: slot.isReview || false,
        });
      }
      if (slot?.caddie?.uid) {
        userDetails.push({
          uid: slot.caddie.uid,
          isCreator: slot.caddie.isCreator || false,
          isJoin: slot.caddie.isJoin || false,
          isReview: slot.isReview || false,
        });
      }
    });

    // Remove duplicates based on UID
    const uniqueUserDetails = [
      ...new Map(userDetails.map((user) => [user.uid, user])).values(),
    ];

    // Query ข้อมูล user จาก Firestore
    const userDataPromises = uniqueUserDetails.map(async (userDetail) => {
      const userQuery = query(
        collection(firestore, "users"),
        where("uid", "==", userDetail.uid)
      );

      const userSnapshot = await getDocs(userQuery);

      if (!userSnapshot.empty) {
        const user = userSnapshot.docs[0].data();
        // Merge both isCreator, isJoin and the user data
        return { ...user, ...userDetail };
      }
      return null;
    });

    const userData = (await Promise.all(userDataPromises)).filter(
      (user) => user !== null
    );

    return { ...round, userData };
  });

  const roundsWithUserData = await Promise.all(promises);
  return roundsWithUserData;
};

const addCourseAndHolesData = async (rounds) => {
  const promises = rounds.map(async (round) => {
    let holesCourse = "";
    let holesData = "";

    if (round?.holesCourse) {
      const courseHolesRef = doc(firestore, "courseHoles", round.holesCourse);
      const courseHolesSnapshot = await getDoc(courseHolesRef);
      holesData = courseHolesSnapshot.exists()
      ? courseHolesSnapshot.data()
      : "";
      holesCourse = courseHolesSnapshot.exists()
        ? courseHolesSnapshot.data().courseHoleName
        : "";
    }

    const courseSnapshot = await getDocs(
      query(collection(firestore, "courses"), where("id", "==", round.course))
    );

    const courseData = courseSnapshot.empty
      ? null
      : courseSnapshot.docs[0].data();

    const { time, day, month } = epochToDateTime(round.scheduleTime);

    return {
      ...round,
      holesCourse,
      courseInfo: courseData,
      courseHoleData:holesData,
      time,
      day,
      month,
    };
  });

  return Promise.all(promises);
};
