import React, { useState, useMemo, useEffect } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../../../components/language/dropdown/textconvert';
const ServiceMindChartCaddie = ({ userId = null, completedRound, reviewData }) => {

    const { t } = useTranslation();

    // console.log(completedRound);
    // console.log(reviewData);

    const [series, setSeries] = useState([
        {
            name: '',
            data: [0, 0, 0, 0, 0, 0]
        }
    ])

    useEffect(() => {
        function getStar() {
            let oneStarService = 0;
            let twoStarService = 0;
            let threeStarService = 0;
            let fourStarService = 0;
            let fiveStarService = 0;
            let noStarService = 0;

            // Count each star rating
            completedRound.forEach(round => {
                const foundReview = reviewData.find(item => item.roundId === round.roundId);
                if (foundReview) {
                    switch (foundReview.serviceRating) {
                        case 1:
                            oneStarService += 1;
                            break;
                        case 2:
                            twoStarService += 1;
                            break;
                        case 3:
                            threeStarService += 1;
                            break;
                        case 4:
                            fourStarService += 1;
                            break;
                        case 5:
                            fiveStarService += 1;
                            break;
                        default:
                            noStarService += 1;
                            break;
                    }
                } else {
                    noStarService += 1;
                }
            });

            // Calculate total reviews
            const totalReviews = oneStarService + twoStarService + threeStarService + fourStarService + fiveStarService + noStarService;

            // Calculate percentages for each rating
            const oneStarPercentage = (oneStarService / totalReviews) * 100;
            const twoStarPercentage = (twoStarService / totalReviews) * 100;
            const threeStarPercentage = (threeStarService / totalReviews) * 100;
            const fourStarPercentage = (fourStarService / totalReviews) * 100;
            const fiveStarPercentage = (fiveStarService / totalReviews) * 100;
            const noStarPercentage = (noStarService / totalReviews) * 100;

            // Set the series data with percentages
            setSeries([
                {
                    name: '',
                    data: [
                        fiveStarPercentage.toFixed(2),
                        fourStarPercentage.toFixed(2),
                        threeStarPercentage.toFixed(2),
                        twoStarPercentage.toFixed(2),
                        oneStarPercentage.toFixed(2),
                        noStarPercentage.toFixed(2)
                    ]
                },
            ]);
        }

        if (completedRound?.length) {
            getStar();
        }
    }, [reviewData, completedRound]);


    const renderChart = useMemo(() => {
        if (!userId) return

        const options = {
            chart: {
                id: 'responsive-bar',
            },
            xaxis: {
                categories: [t(textConvert('five Stars')), t(textConvert('four Stars')), t(textConvert('three Stars')), t(textConvert('two Stars')), t(textConvert('one Stars')), t(textConvert('didnt_review'))],
            },
            yaxis: {
                max: 100,
                show: false,
                labels: {
                    formatter: (value) => `$${value}`, // Add unit to y-axis labels
                },
            },
            title: {
                text: t(textConvert('Service Mind')),
                align: 'center',
                style: {
                    fontSize: '16px',
                    color: '#333'
                }
            },
            colors: ['#93e8ff80', '#a3b8e380', '#88db8580', '#ffeb9980', '#ffceb280', '#88888880'],
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    horizontal: false,
                    distributed: true,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val + "%";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                y: {
                    formatter: (value) => `${value}%`
                }
            },
            legend: {
                show: false
            }
        }

        return (
            <div className="chart-container" style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="100%"
                    height="300"
                />
            </div>
        )
    }, [series, t, userId])

    return (
        <>
            {renderChart}
        </>
    );
}

export default ServiceMindChartCaddie