import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  orderBy,
  onSnapshot,
  limit,
  startAfter,
} from "firebase/firestore";
import { firestore } from "../../../../../components/firebase/FirebaseConfig";

export const getData = async (
  userData,
  setCaddieListTest,
  setListUserBolcked,
  setOriginalCaddieList,
  setUserLabels,
  triggerGetLabel,
  setLoading,
  courseData,
  lastVisibleDoc = null,
  allCaddieList = []
) => {
  if (!userData) return;
  setLoading(true);

  try {
    // Fetch user labels and blocked users
    const [labelsSnapshot, blockedSnapshot] = await Promise.all([
      getDocs(
        query(collection(firestore, "labels"), where("userId", "==", userData.uid))
      ),
      getDocs(
        query(collection(firestore, "blockedUsers"), where("userId", "==", userData.uid))
      ),
    ]);

    const userLabelsData = labelsSnapshot.docs.map((doc) => ({
      docId: doc.id,
      ...doc.data(),
    }));
    setUserLabels(userLabelsData);

    const listUserBlocked = blockedSnapshot.docs.map((doc) => doc.data().blockedUserId);
    setListUserBolcked(listUserBlocked);

    // Initialize pagination variables
    let newLastVisibleDoc = lastVisibleDoc;

    // Fetch caddie assignments with pagination
    let caddieQuery = query(
      collection(firestore, "caddieRequests"),
      where("courseId", "==", courseData.courseId),
      where("status", "!=", "removed"),
      limit(15) // Limit per fetch
    );

    if (newLastVisibleDoc) {
      caddieQuery = query(caddieQuery, startAfter(newLastVisibleDoc));
    }

    const querySnapshot = await getDocs(caddieQuery);
    if (querySnapshot.empty) {
      setLoading(false);
      return null; // Stop loading when no more data
    }

    newLastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

    const caddieAssignments = querySnapshot.docs.map((doc) => doc.data());
    const caddieIds = [...new Set(caddieAssignments.map((a) => a.caddieId))];

    // Fetch caddies in batch
    const caddieQueryBatch = query(
      collection(firestore, "users"),
      where("uid", "in", caddieIds)
    );
    const caddieSnapshot = await getDocs(caddieQueryBatch);
    
    let caddieMap = {};
    caddieSnapshot.forEach((doc) => {
      caddieMap[doc.data().uid] = doc.data();
    });

    // Fetch caddie numbers in batch
    const caddieNumbersPromises = caddieIds.map(async (caddieId) => {
      const qCaddieRequests = query(
        collection(firestore, "caddieRequests"),
        where("caddieId", "==", caddieId)
      );
      const querySnapshotCaddieRequests = await getDocs(qCaddieRequests);
      
      let caddieNumber = null;
      querySnapshotCaddieRequests.forEach((doc) => {
        caddieNumber = doc.data().caddieNumber || null;
      });
      
      return { caddieId, caddieNumber };
    });

    const caddieNumbers = await Promise.all(caddieNumbersPromises);
    const caddieNumberMap = caddieNumbers.reduce((acc, curr) => {
      acc[curr.caddieId] = curr.caddieNumber;
      return acc;
    }, {});

    // Construct caddie list
    let caddieList = caddieIds.map((caddieId) => {
      const caddieData = caddieMap[caddieId] || {};
      return {
        imageUrl: caddieData.profileImage,
        name: `${caddieData.firstName || ""} ${caddieData.lastName || ""}`.trim(),
        bgImage: caddieData.coverImage,
        location: courseData.location,
        caddieNumber: caddieNumberMap[caddieId] || null,
        ...caddieData,
      };
    });

    // Fetch labels for caddies
    const labelPromises = caddieList.map(async (caddie) => {
      const qCaddieLabels = query(
        collection(firestore, "labeledUsers"),
        where("friendId", "==", caddie.uid),
        where("userId", "==", userData.uid)
      );
      const caddieLabelsSnapshot = await getDocs(qCaddieLabels);

      let labels = [];
      caddieLabelsSnapshot.forEach((doc) => {
        const { labels: labelIds = [] } = doc.data();
        labels = labelIds
          .map((labelId) => userLabelsData.find((item) => item.docId === labelId)?.label)
          .filter(Boolean);
      });

      caddie.labels = labels;
    });

    await Promise.all(labelPromises);

    // Update state
    setCaddieListTest((prev) => [...prev, ...caddieList]);
    setOriginalCaddieList((prev) => [...prev, ...caddieList]);

    return newLastVisibleDoc;
  } catch (error) {
    console.error("Error fetching caddie data:", error);
    return null;
  } finally {
    setLoading(false);
  }
};
