import React from 'react';
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../components/language/dropdown/textconvert';

const Search = ({ memberList, setFilteredMembers, setSearch, search }) => {
  const { t } = useTranslation();
  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();

    if (searchTerm.trim() === "") {
      setSearch({
        ...search,
        text: {
          state: false,
          value: "",
        },
      });
    } else {
      setSearch({
        ...search,
        text: {
          state: true,
          value: searchTerm,
        },
      });
    }
  };

  return (
    <form action="#" className="d-flex align-items-center w-100">
      <div className="search-form-2 flex-grow-1">
        <i className="ti-search font-xss"></i>
        <input
          type="text"
          className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
          placeholder={t(textConvert('search here'))}
          style={{ flexGrow: 1, minWidth: "150px" }}
          value={search.text.value}
          onChange={handleSearch}
        />
      </div>
    </form>
  );
};

export default Search;
