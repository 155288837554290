import { Scanner } from '@yudiel/react-qr-scanner';
import clsx from 'clsx';
import React, { useRef, useState } from 'react'
import { Modal, Form, InputGroup, Button } from 'react-bootstrap'
import QRCode from 'react-qr-code';
import { useLocation } from 'react-router-dom';
import { firestore } from '../../../components/firebase/FirebaseConfig';
import { addDoc, arrayRemove, collection, doc, getDoc, getDocs, query, serverTimestamp, updateDoc, where } from 'firebase/firestore';
import LoadCircle from '../../../components/LoadCircle';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../components/language/dropdown/textconvert';
import { toPng } from 'html-to-image';

function AddLeaderboard({ yourRoundData, otherRound }) {
    const location = useLocation();
    const { t } = useTranslation();
    const [showAddLeaderboard, setShowAddLeaderboard] = useState(false);
    const [showMyQRCode, setShowMyQRCode] = useState(false);
    const [showScanQRCode, setShowScanQRCode] = useState(false);
    const [roundData, setRoundData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isAdded, setIsAdded] = useState(false);
    const [loadingAdd, setLoadingAdd] = useState(false);
    const [inputSearch, setInputSearch] = useState('');
    const [copied, setCopied] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const qrCodeRef = useRef(null);
    
    const handleExtractUrl = async (url) => {
        try {
            setError(null);
            if (typeof url === 'object') {
                url = url[0].rawValue;
            }
            const parsedUrl = new URL(url);
            // Extract query parameters using URLSearchParams
            const params = new URLSearchParams(parsedUrl.search);

            // Get the "round" parameter
            const round = params.get('round');

            // check same round id 
            if (round !== yourRoundData.roundId) {
                await handleFindRound(round);
            } else {
                console.error('this is current leaderboard');
                setError('not_found_leaderboard')
            }

        } catch (error) {
            console.error(`Invalid URL: "${url}"`, error);
            setError(`invalid_link`);
        }
    }

    const handleFindRound = async (roundId) => {
        try {
            handleResetToggle();
            setRoundData(null);
            setLoading(true);

            const roundDoc = doc(firestore, 'round', roundId);
            const roundSnap = await getDoc(roundDoc);

            if (!roundSnap.exists()) {
                setError(`not_found_leaderboard`);
                console.error(`Round with ID "${roundId}" does not exist`);
                return;
            }

            const roundData = roundSnap.data();

            const foundRound = otherRound.find(item => item.roundId === roundId);
            if (foundRound) {
                setIsAdded(true);
                setSuccess('leaderboard_already_added');
            }

            const updatedRoundData = { ...roundData };

            // Fetch creator data
            const userQuery = query(
                collection(firestore, 'users'),
                where('uid', '==', roundData.creator)
            );
            const userSnap = await getDocs(userQuery);

            if (!userSnap.empty) {
                updatedRoundData.creatorData = userSnap.docs[0].data();
            }

            // Fetch course hole data
            if (roundData.holesCourse) {
                const courseHoleDoc = doc(firestore, 'courseHoles', roundData.holesCourse);
                const courseHoleSnap = await getDoc(courseHoleDoc);

                if (courseHoleSnap.exists()) {
                    updatedRoundData.courseHoleData = courseHoleSnap.data();
                }

            }
            setRoundData(updatedRoundData);
        } catch (error) {
            console.error(`Error finding round with ID "${roundId}"`, error);
        } finally {
            setLoading(false);
        }
    };

    // add all leaderbaord to other leaderbaord
    const handleAddLeaderboard = async (selRoundData) => {
        setError(null);
        setLoadingAdd(true);

        try {
            const roundDoc = doc(firestore, 'round', selRoundData.roundId);
            const roundSnap = await getDoc(roundDoc);
            const getRoundData = roundSnap.data();

            if (!getRoundData.isLeaderBoardPublic) {
                setError('failed_you_cant_add_a_private_leaderboard');
                console.error(`Round with ID "${roundData.roundId}" is private`);
                return;
            }

            if (getRoundData.holes !== yourRoundData.holes) {
                setError(`failed_you_cant_add_a_leaderboard_with_a_different_number_of_holes`);
                console.error(`Difference hole '${getRoundData.holes}' and '${yourRoundData.holes}'.`);
                return;
            }

            if (!getRoundData.holesCourse) {
                setError(`failed_the_creator_of_this_leaderboard_has_not_selected_a_course_yet`);
                console.error(`Round creator not select course yet`);
                return;
            }

            // main roundList is round list of whom scan
            let mainRoundList = Array.from(new Set([yourRoundData.roundId, selRoundData.roundId])); // Ensure unique entries initially

            const leaderboardCol = collection(firestore, 'combinedLeaderboard');
            const mainQ = query(leaderboardCol, where('mainRound', '==', yourRoundData.roundId));

            if (mainRoundList.length >= 18) {
                setError('failed_maximum_leaderboards_reached');
                return;
            }

            // Fetch main document
            const leaderboardSnap = await getDocs(mainQ);
            if (leaderboardSnap.empty) {
                // Add a new document with unique roundList
                await addDoc(leaderboardCol, {
                    mainRound: yourRoundData.roundId,
                    created: serverTimestamp(),
                    roundList: mainRoundList
                });
                setIsAdded(true);
                setSuccess('success_leaderboard_added_successfully');
            } else {
                const doc = leaderboardSnap.docs[0]; // Get the document
                const existingRoundList = doc.data().roundList || []; // Retrieve roundList or initialize empty array

                // Merge and ensure unique entries
                mainRoundList = Array.from(new Set([...existingRoundList, selRoundData.roundId]));

                if (!existingRoundList.includes(selRoundData.roundId)) {
                    // Update the document if new ID is added
                    await updateDoc(doc.ref, {
                        roundList: mainRoundList,
                        lastUpdate: serverTimestamp()
                    });
                    setIsAdded(true);
                    setSuccess('success_leaderboard_added_successfully');
                } else {
                    // setError('Failed : This round already added.');
                    console.error(`${selRoundData.roundId} already exists in roundList`);
                }
            }

            // console.log('Updated mainRoundList:', mainRoundList);

            // Check and add to other rounds
            for (const id of mainRoundList) {
                const otherQ = query(leaderboardCol, where('mainRound', '==', id));
                const otherSnap = await getDocs(otherQ);

                if (otherSnap.empty) {
                    // Add a new document for the other round with the unique mainRoundList
                    await addDoc(leaderboardCol, {
                        mainRound: id,
                        created: serverTimestamp(),
                        roundList: mainRoundList
                    });
                } else {
                    const doc = otherSnap.docs[0]; // Get the document
                    const existingRoundList = doc.data().roundList || []; // Retrieve roundList or initialize empty array

                    // Merge and ensure unique entries
                    const updatedRoundList = Array.from(new Set([...existingRoundList, ...mainRoundList]));

                    // Update the document with the unique list
                    await updateDoc(doc.ref, {
                        roundList: updatedRoundList,
                        lastUpdate: serverTimestamp()
                    });
                }
            }

        } catch (error) {
            console.error('Failed to add round', error);
        } finally {
            setLoadingAdd(false);
        }
    }

    // add remove list to db
    const handleDeleteLeaderboard = async (selRoundData) => {
        // console.log(selRoundData);
        Swal.fire({
            icon: 'warning',
            html: `Do you want to remove <strong>"${selRoundData.roundName}"</strong> from leaderboard ?`,
            confirmButtonColor: '#ee3d7f',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showCancelButton: true
        }).then(async (result) => {
            if (!result.isConfirmed) return;
            try {
                const leaderboardCol = collection(firestore, 'combinedLeaderboard');
                const q = query(leaderboardCol, where('mainRound', '==', yourRoundData.roundId));
                const leaderboardSnap = await getDocs(q);
                if (!leaderboardSnap.empty) {
                    leaderboardSnap.forEach(async (docSnapshot) => {
                        const docRef = doc(firestore, 'combinedLeaderboard', docSnapshot.id);

                        // Use `arrayRemove` to remove the selected element
                        await updateDoc(docRef, {
                            roundList: arrayRemove(selRoundData.roundId),
                            lastUpdate: serverTimestamp()
                        });
                    });
                }
            } catch (error) {
                console.error('Failed to remove leaderboard', error);
            }
        })
    }

    const handleCopyToClipboard = () => {
        const url = window.location.origin + location.pathname + location.search;
        navigator.clipboard
            .writeText(url)
            .then(() => {
                setCopied(true);

                setTimeout(() => {
                    setCopied(false);
                }, 1000);
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    };

    const handleSaveImage = () => {
        if (qrCodeRef.current) {
            setLoadingSave(true);
            toPng(qrCodeRef.current, {
                backgroundColor: '#ffffff',
            })
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = `${yourRoundData.roundName}.png`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((error) => {
                    console.error('Error saving image:', error);
                }).finally(() => {
                    setLoadingSave(false);
                });
        }
    };

    const handleToggleMyQr = () => {
        setShowMyQRCode(!showMyQRCode);
        setShowScanQRCode(false);
        setLoading(false);
        setRoundData(null);
        setError(null);
        setSuccess(null);
    }
    const handleToggleScanQr = () => {
        setShowScanQRCode(!showScanQRCode);
        setShowMyQRCode(false);
        setLoading(false);
        setRoundData(null);
        setError(null);
        setSuccess(null);
    }
    const handleResetToggle = () => {
        setShowMyQRCode(false);
        setShowScanQRCode(false);
        setLoading(false);
        setError(null);
        setIsAdded(false);
        setSuccess(null);
    }

    return (
        <>
            <div className='mt-4'>
                <h2 className='fw-600 mb-3'>{t(textConvert('Add another leaderboard'))}</h2>
                {otherRound.length > 0 ?
                    <>
                        <h5 className='fw-600'>{t(textConvert('Leaderboard List'))}</h5>
                        <div className=' p-1'>
                            {otherRound.map((otherRoundData) =>
                                <div key={otherRoundData.roundId} className=' d-flex justify-content-between align-items-center border-bottom pb-1 mb-2'>
                                    <h5 className=''>{otherRoundData.roundName}</h5>
                                    <i className='bi-trash fs-3 text-danger pointer' onClick={() => handleDeleteLeaderboard(otherRoundData)}></i>
                                </div>
                            )}
                        </div>
                    </>
                    : null}
                <button className='btn bg-light theme-dark-bg' onClick={() => setShowAddLeaderboard(true)}>
                    <h4 className='fw-600'>{t(textConvert('Add'))}</h4>
                </button>
            </div>

            {/* main modal */}
            <Modal show={showAddLeaderboard} onHide={() => setShowAddLeaderboard(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title><h4 className='fw-600'>{t(textConvert('Add another leaderboard'))}</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body className='overflow-auto'>
                    {/* search link input */}
                    <Form className='mb-2' onSubmit={(e) => { e.preventDefault(); handleExtractUrl(e.target[0].value) }}>
                        <InputGroup>
                            <input type="text"
                                className='form-control'
                                placeholder={t(textConvert('Public Link'))}
                                required
                                value={inputSearch}
                                onChange={(e) => setInputSearch(e.target.value)} />
                            <Button variant="outline-dark" id="button-addon2" type='submit'>
                                <i className='bi-search fs-4'></i>
                            </Button>
                        </InputGroup>
                        <small className='text-danger fw-600'>{t(error)}</small>
                        <small className='text-success fw-600'>{t(success)}</small>
                    </Form>

                    {loading ?
                        <div className='text-center'>
                            <LoadCircle variant='dark' size='lg' />
                        </div>
                        :
                        roundData &&
                        <div className='px-2 py-3 bg-light rounded-3'>
                            <h4 className='fw-600'>{roundData.roundName}</h4>
                            <h6 className='fw-600'>{`${roundData.holes}(${roundData.courseHoleData?.courseHoleName || ""}), ${roundData.gameMode}(${roundData.scoring})`}</h6>
                            <div className='d-flex align-items-center justify-content-between py-1 mt-2'>
                                <img src={roundData.creatorData.profileImage} width={40} height={40} alt="creator" className='rounded-circle player-color-outline' />
                                {isAdded ?
                                    <button className='btn bg-success rounded-pill text-white py-2 px-3' onClick={() => handleAddLeaderboard(roundData)} disabled={isAdded}>
                                        {!loadingAdd ?
                                            <>
                                                {t(textConvert(isAdded ? 'Added' : 'Add'))}
                                            </>
                                            :
                                            <LoadCircle size='sm' variant='light' className='px-1' />
                                        }
                                    </button>
                                    :
                                    <button className='btn bg-current rounded-pill text-white py-2 px-3' onClick={() => handleAddLeaderboard(roundData)} disabled={isAdded}>
                                        {!loadingAdd ?
                                            <>
                                                {t(textConvert(isAdded ? 'Added' : 'Add'))}
                                            </>
                                            :
                                            <LoadCircle size='sm' variant='light' className='px-1' />
                                        }
                                    </button>
                                }

                            </div>
                        </div>
                    }

                    {/* QR code */}
                    <div className={clsx('p-1', { 'd-none': !showMyQRCode })}>
                        <div className='p-2' ref={qrCodeRef}>
                            <h2 className='fw-600 text-center mb-2'>{t(textConvert('SCAN ME'))}</h2>
                            <h4 className='fw-600 text-center'>{yourRoundData?.roundName}</h4>
                            <div className='p-3 text-center' >
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "80%", width: "80%" }}
                                    value={window.location.origin + location.pathname + location.search}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                        </div>
                        <div className='mb-2 d-flex align-items-center gap-2'>
                            <label htmlFor="link-input" className='fw-600 fs-4'>{t(textConvert('link'))}</label>
                            <input type="text"
                                id='link-input'
                                value={window.location.origin + location.pathname + location.search}
                                disabled
                                className='form-control'
                            />
                        </div>
                        <div className='d-flex justify-content-center gap-4'>
                            {loadingSave ?
                                <button className='btn btn-light btn-md fs-4'>
                                    <LoadCircle size='sm' variant='dark' className='mx-2' />
                                </button>
                                :
                                <button className='btn btn-light btn-md fs-4' onClick={() => handleSaveImage()}>{t('save')}</button>
                            }
                            {copied ?
                                <button className='btn btn-light btn-md fs-4'>{t('copied')}</button>
                                :
                                <button className='btn btn-light btn-md fs-4' onClick={() => handleCopyToClipboard()}>{t('copy')}</button>
                            }
                        </div>
                    </div>

                    {/* scan qr code */}
                    <div className={clsx('p-3', { 'd-none': !showScanQRCode })}>
                        <Scanner onScan={(result) => handleExtractUrl(result)} paused={!showScanQRCode} onError={(err) => console.error(err)}
                            components={{ audio: false }}
                            allowMultipleRead={true}
                        />
                    </div>

                    <div className='d-flex gap-1 flex-wrap mt-4'>
                        <button className='btn bg-current' onClick={handleToggleMyQr}>
                            <h5 className='fw-600 text-white'>
                                {t(textConvert(showMyQRCode ? 'Hide QR code' : 'Show QR code'))}
                            </h5>
                        </button>
                        <button className='btn bg-current' onClick={handleToggleScanQr}>
                            <h5 className='fw-600 text-white'>
                                {t(textConvert(showScanQRCode ? 'Scan' : 'Scan'))}
                            </h5></button>
                        <button className='btn btn-light ms-auto' onClick={() => setShowAddLeaderboard(false)}><h5 className='fw-600'>{t(textConvert('Close'))}</h5></button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddLeaderboard