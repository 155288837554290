import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";

export const getMyLabel = async (userData) => {
    if (!userData) return [];
    try {
        const q = query(
            collection(firestore, "labels"),
            where("userId", "==", userData.uid)
        );
        const labelSnap = await getDocs(q);
        let labelArr = []
        if (!labelSnap.empty) {
            labelArr = labelSnap.docs.map(item => ({
                docId: item.id,
                ...item.data()
            }));
        }
        return labelArr;
    } catch (error) {
        console.error('Fail to get label', error);
        return [];
    }
}