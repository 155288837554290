import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import Picker from 'react-mobile-picker';
import { convertHexToRGBA } from '../../../myperfprofile/module/colorCovert';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { firestore } from '../../../../../components/firebase/FirebaseConfig';
import { updateDoc, addDoc, where, query, collection, getDocs } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../../../components/language/dropdown/textconvert';
const selections = {
    color: ['Red', 'Yellow', 'White', 'Blue', 'Black'],
}

const DefaultTeeBox = ({ data }) => {

    const { t } = useTranslation();
    const [selectTeeboxModal, setSelectTeeboxModal] = useState(false);
    const userData = useSelector((state) => state.user.data);
    const [selectedColor, setSelectedColor] = useState({
        color: 'White'
    });
    const [colorObj, setColorObj] = useState(colorDef('White'));

    const [loadingTeebox, setLoadingTeebox] = useState(true);

    const handleOpenTeeboxModal = () => {
        setSelectTeeboxModal(true)
    }

    useEffect(() => {

        async function getTeebox() {
            const statCollection = collection(firestore, 'playerStatistic');
            const q = query(statCollection, where("userId", "==", data.uid));
            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const doc = querySnapshot.docs[0].data();
                    if (doc.defaultTeebox) {
                        setSelectedColor({
                            color: doc.defaultTeebox
                        })
                        setColorObj(colorDef(doc.defaultTeebox))
                    }
                }
                setLoadingTeebox(false)
            } catch (e) {
                setLoadingTeebox(false)
                console.error("fail to get handicap ", e);
            }
        }


        if (userData && data) {
            getTeebox()
        }
    }, [data, userData])

    const handleSubmit = () => {
        setColorObj(colorDef(selectedColor.color))
        setSelectTeeboxModal(false);
        updateTeebox(selectedColor.color)
    }

    const updateTeebox = async (teebox) => {
        const statCollection = collection(firestore, 'playerStatistic');
        const q = query(statCollection, where("userId", "==", userData.uid));
        try {
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const ref = querySnapshot.docs[0].ref
                updateDoc(ref, {
                    defaultTeebox: teebox
                })
            } else {
                addDoc(statCollection, {
                    userId: userData.uid,
                    defaultTeebox: teebox
                })

            }
        } catch (e) {
            console.error("Error adding or updating document: ", e);
        }
    }

    return (
        <>
            {!loadingTeebox ?
                <div className="w-100 p-3 rounded-3 position-relative" style={{ backgroundColor: convertHexToRGBA(colorObj.colorCode, 0.2) }}>
                    <Link to={'#'} className={clsx('d-block', { 'd-none': data?.uid !== userData.uid })}><div className="position-absolute" style={{ top: 5, right: 10 }} onClick={handleOpenTeeboxModal}>{t(textConvert('update'))}</div></Link>
                    <div className="d-flex justify-content-start align-items-center">
                        <strong className="px-3 rounded-3" style={{ backgroundColor: colorObj.colorCode }}>
                            {colorObj.text}
                        </strong>
                        <div className="ms-2">
                            <span>Default Teebox</span><br />
                            <small>{t(textConvert(colorObj.color))}</small>
                        </div>
                    </div>
                </div>
                :
                <div className='w-100 p-3 text-center bg-light rounded-3'>
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>
            }
            <Modal show={selectTeeboxModal} onHide={() => setSelectTeeboxModal(false)} centered animation={false}>
                <Modal.Body>
                    <h4><strong>Your default Teebox</strong></h4>
                    <Picker value={selectedColor} onChange={setSelectedColor} wheelMode='normal' height={180}>
                        {Object.keys(selections).map(name => (
                            <Picker.Column key={name} name={name}>
                                {selections[name].map(option => (
                                    <Picker.Item key={option} value={option}>
                                        {t(textConvert(option))}
                                    </Picker.Item>
                                ))}
                            </Picker.Column>
                        ))}
                    </Picker>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn bg-current text-white' onClick={handleSubmit}>{t(textConvert('ok'))}</button>
                    <button className='btn btn-light' onClick={() => setSelectTeeboxModal(false)}>{t(textConvert('cancel'))}</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DefaultTeeBox

function colorDef(text) {
    switch (text) {
        case 'Red':
            return {
                color: text,
                text: 'R',
                colorCode: '#ff3a3a'
            };
        case 'Yellow':
            return {
                color: text,
                text: 'Y',
                colorCode: '#EAE400'
            };
        case 'White':
            return {
                color: text,
                text: 'W',
                colorCode: '#B0B5BD'
            };
        case 'Blue':
            return {
                color: text,
                text: 'B',
                colorCode: '#4A74E5'
            };
        case 'Black':
            return {
                color: text,
                text: 'BK',
                colorCode: '#212529'
            };
        default:
            throw new Error();
    }
}