import axios from "axios";
export const ChatTo = async (userId, friendId) => {
    try {

        const response = await axios.get(
            `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels?members_exactly_in=${userId}&show_member=false&members_exactly_in=${friendId}`,
            {
                headers: {
                    "Content-Type": "application/json; charset=utf8",
                    "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
                },
            }
        );

        if (response.data.channels.length > 0) {

            const fillteredChat = response.data.channels.filter(item => item.member_count === 2 && !item.name.includes('Round'));

            if (fillteredChat.length) {
                return fillteredChat

            } else {
                const isCreated = await createChat(userId, friendId);
                return isCreated
            }


        } else {

            const isCreated = await createChat(userId, friendId);
            // const response3 = await axios.post(
            //     "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/" + response2.data.channel_url + "/metadata",
            //     {
            //         "metadata": {
            //             "chatType": "private",
            //         },
            //         "include_ts": true
            //     },
            //     {
            //         headers: {
            //             "Content-Type": "application/json; charset=utf8",
            //             "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            //         },
            //     }
            // );

            return isCreated

        }

    } catch (error) {
        return error
    }
}

async function createChat(userId, friendId) {
    try {
        const response2 = await axios.post(
            "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels",
            {
                user_ids: [userId, friendId],
                is_distinct: true,
            },
            {
                headers: {
                    "Content-Type": "application/json; charset=utf8",
                    "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
                },
            }
        );
        return [response2.data];
    } catch (error) {
        console.error('Failed to create chat', error);
        return [];
    }
}