import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./UserContext";
import { useSelector } from "react-redux";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { firestore } from "../firebase/FirebaseConfig";

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const { user } = useAuth();
  const location = useLocation();
  const userData = useSelector((state) => state.user.data);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleLineActivityRedirect = async () => {
      setIsLoading(true);

      let roundId = [];
      try {
        const q = query(
          collection(firestore, "roundActivity"),
          where("userList", "array-contains", userData?.uid)
        );
        const querySnapshot = await getDocs(q);
        const data = [];
        querySnapshot.forEach((doc2) => {
          roundId.push(doc2.data().roundId);
        });

        if (roundId.length > 0) {
          for (let index = 0; index < roundId.length; index++) {
            const element = roundId[index];

            const q = query(
              collection(firestore, "scoreCards"),
              where("roundId", "==", element),
              where("userId", "==", userData?.uid),
              where("status", "==", "playing")
            );

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc1) => {
              const docRef = doc(firestore, "round", element);
              const docSnap = await getDoc(docRef);

              if (docSnap.exists()) {
                if (docSnap.data().status !== "deleted") {
                  const result = docSnap.data().userList.some((item) => {
                    const caddieMatch =
                      item.caddie &&
                      item.caddie.uid === userData?.uid &&
                      item.caddie.isJoin;
                    const playerMatch =
                      item.player &&
                      item.player.uid === userData?.uid &&
                      item.player.isJoin;

                    return caddieMatch || playerMatch;
                  });
                  if (result) {
                    console.log("Document data:", docSnap.data());
                    window.location.href = `/score?round=${
                      docSnap.data().roundId
                    }`;
                  }
                }
              } else {
                console.log("No such document!");
              }
            });
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const searchParams = new URLSearchParams(location.search);
    const redirectToLine = searchParams.get("redirect");

    if (redirectToLine === "line-connect") {
      window.location.href = "/line-connect";
    } else if (redirectToLine === "line-activity") {
      handleLineActivityRedirect();
    }
  }, [location.search, userData?.uid]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  return Component ? <Component {...rest} /> : <Outlet />;
};

export default ProtectedRoute;
