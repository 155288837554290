import React, { useEffect, useState } from "react";
import Lightbox from "react-image-lightbox";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  orderBy,
} from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../../components/language/dropdown/textconvert";

function CourseDetailAboutGallery({ courseData }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tab1, setTab1] = useState(0);
  const [imageList, setImageList] = useState([]);
  const TabOne = [
    {
      image: "01",
      bigImage: "assets/images/hotel.png",
    },
    {
      image: "02",
      bigImage: "assets/images/hotel.png",
    },
    {
      image: "03",
      bigImage: "assets/images/hotel.png",
    },
    {
      image: "04",
      bigImage: "assets/images/hotel.png",
    },
    {
      image: "05",
      bigImage: "assets/images/hotel.png",
    },
    {
      image: "06",
      bigImage: "assets/images/hotel.png",
    },
  ];

  useEffect(() => {
    async function fetchData() {
      let list = [];
      const q = query(
        collection(firestore, "courseImage"),
        where("courseId", "==", courseData.courseId),
        orderBy("name", "asc")
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        list.push({ image: doc.data().name, bigImage: doc.data().url });
      });
      setImageList(list);
    }

    if (courseData) {
      fetchData();
    }
  }, [courseData]);

  return (
    <>
      <div className="card d-block border-0 rounded-3 overflow-hidden p-4 shadow-xss">
        <h2 className="fw-600 mb-3 mt-1 ps-1 mb-3">{t(textConvert('Gallery'))}</h2>
        <div className="row ps-3 pe-3">
          {imageList.map((value, index) => (
            <div className="col-sm-4 col-xss-4 pe-1 ps-1 mb-2" key={index}>
              {isOpen && (
                <Lightbox
                  mainSrc={imageList[tab1].bigImage}
                  onCloseRequest={() => setIsOpen(false)}
                  onMovePrevRequest={() =>
                    setTab1((tab1 + imageList.length - 1) % imageList.length)
                  }
                  onMoveNextRequest={() =>
                    setTab1((tab1 + 1) % imageList.length)
                  }
                />
              )}

              <div
                onClick={() => {
                  setIsOpen(true);
                  setTab1(index);
                }}
              >
                <img
                  style={{ cursor: "pointer" }}
                  src={`${value.bigImage}`}
                  alt="Portfolio"
                  className="w-100"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CourseDetailAboutGallery;
