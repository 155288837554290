import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ReactSVG } from 'react-svg'
import { textConvert } from '../../../components/language/dropdown/textconvert'
import { useNavigate } from 'react-router-dom'
import { UnFriend } from '../../../components/friend/UnFriend'
import { blockFriend, unblockFriend } from '../../../components/friend/BlockFriend'
import { handleChat, handleFav } from '../module/dropdownFunc'

const dropdownItemStyle = {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 2,
    paddingBottom: 2,
}

const iconStyle = {
    width: "25px",
    marginRight: "8px",
}

const itemStyle = 'd-flex align-items-center py-1'

function FriendDropdown({ userData, user, updateUser, selectLabel }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isFav, setIsfav] = useState(user.isFav);

    const handleFavChange = () => {
        user.isFav = !user.isFav
        setIsfav(user.isFav)
        handleFav(userData, user?.role, user, user.isFav ? 'unfav' : 'fav');
    }

    const handleUnfriend = async () => {
        const isUnFriend = await UnFriend(userData.uid, user.uid, t);
        if (isUnFriend) {
            updateUser(user);
        }
    };

    const handleBlock = async () => {
        const isBlocked = await blockFriend(userData.uid, user.uid);
        if (isBlocked) {
            const isUnFriend = await UnFriend(userData.uid, user.uid);
            if (isUnFriend) {
                handleFav(userData, user?.role, user, 'unfav');
                updateUser(user);
            }
        }
    };

    const handleUnblock = async () => {
        const isUnblocked = await unblockFriend(userData.uid, user.uid);
        if (isUnblocked) {
            user.isBlocked = false
        }
    }


    return (
        <Dropdown onToggle={() => setIsfav(user.isFav)}>
            <Dropdown.Toggle
                as="button"
                className="ti-more-alt text-grey-900 btn-round-md bg-greylight font-xss border-0"
            ></Dropdown.Toggle>
            {!user.isBlocked ?

                <Dropdown.Menu className='p-0 overflow-hidden shadow-xss border-greylight' style={{ borderRadius: 15 }}>

                    {/* chat */}
                    <Dropdown.Item style={dropdownItemStyle} onClick={() => handleChat(userData, user.dooNineId, navigate)}>
                        <div className={itemStyle}>
                            <ReactSVG src="assets/images/CaddieFriendFav-ChatDropDown.svg" style={iconStyle} />
                            {t(textConvert('Chat'))}
                        </div>
                    </Dropdown.Item>

                    {/* label */}
                    <Dropdown.Item style={dropdownItemStyle} onClick={() => selectLabel(user)}>
                        <div className={itemStyle}>
                            <ReactSVG src="assets/images/dooNineIconV3/my-performance/profile-label.svg" style={iconStyle} />
                            {t(textConvert('Label'))}
                        </div>
                    </Dropdown.Item>

                    {/* favorite */}
                    <Dropdown.Item style={dropdownItemStyle} onClick={() => handleFavChange()}>
                        <div className={itemStyle}>
                            {isFav ?
                                <ReactSVG src="assets/images/dooNineIconV3/my-performance/profile-fav.svg" style={iconStyle} />
                                :
                                <ReactSVG src="assets/images/dooNineIconV3/my-performance/profile-unfav.svg" style={iconStyle} />
                            }
                            {t(textConvert('Favorite'))}
                        </div>
                    </Dropdown.Item>

                    <Dropdown.Divider />

                    {/* friend */}
                    <Dropdown.Item style={dropdownItemStyle} onClick={() => handleUnfriend()}>
                        <div className={itemStyle}>
                            <ReactSVG src="assets/images/dooNineIconV3/my-performance/profile-unfriend.svg" style={iconStyle} />
                            {t(textConvert('Unfriend'))}
                        </div>
                    </Dropdown.Item>

                    {/* block */}
                    <Dropdown.Item style={dropdownItemStyle} onClick={() => handleBlock()}>
                        <div className={itemStyle}>
                            <ReactSVG src="assets/images/dooNineIconV3/my-performance/profile-block.svg" style={iconStyle} />
                            {t(textConvert('Block'))}
                        </div>
                    </Dropdown.Item>
                </Dropdown.Menu>
                :
                <Dropdown.Menu className='p-0 overflow-hidden shadow-xss border-greylight' style={{ borderRadius: 15 }}>
                    {/* block */}
                    <Dropdown.Item style={dropdownItemStyle} onClick={() => handleUnblock()}>
                        <div className={itemStyle}>
                            <ReactSVG src="assets/images/dooNineIconV3/my-performance/profile-block.svg" style={iconStyle} />
                            {t(textConvert('Unblock'))}
                        </div>
                    </Dropdown.Item>
                </Dropdown.Menu>
            }
        </Dropdown >
    )
}


export default FriendDropdown