import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { firestore } from '../../../components/firebase/FirebaseConfig';
import { gameModeSelection } from '../module/scoreCalculation';
import LoadCircle from '../../../components/LoadCircle';
import { Accordion, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { processScoreList, getUserData, getStat, defPosition, sortScore, processAndGroupScores } from '../module/tableFunc';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../components/language/dropdown/textconvert';
import DetailModal from './DetailModal';
import { Link } from 'react-router-dom';

function Table({ roundData, otherRound }) {
    const { t } = useTranslation();
    const userData = useSelector((state) => state.user.data);

    const isOtherRound = otherRound.length > 0;
    const [dropdownVal, setDropdownVal] = useState(isOtherRound ? 'My Position' : 'My Round');
    // main
    const [scoreList, setScoreList] = useState([]);
    // const [scoreListPlaying, setScoreListPlaying] = useState([]);
    // const [scoreListFinished, setScoreListFinished] = useState([]);
    // const [scoreListUnfinished, setScoreListUnfinished] = useState([]);
    const [statList, setStatList] = useState([])
    const [fetchedRounData, setFetchedRoundData] = useState(null);
    const [allScoreList, setAllScoreList] = useState([]);

    // other
    const [otherScoreList, setOtherScoreList] = useState([]);
    // const [otherScoreListPlaying, setOtherScoreListPlaying] = useState([]);
    // const [otherScoreListFinished, setOtherScoreListFinished] = useState([]);
    // const [otherScoreListUnfinished, setOtherScoreListUnfinished] = useState([]);
    const [otherStatList, setOtherStatList] = useState([]);
    const [fetchedOtherRounData, setFetchedOtherRoundData] = useState(null);
    // const [allOtherScoreList, setAllOtherScoreList] = useState([]);

    // combine
    const [combineScoreList, setCombineScoreList] = useState([]);
    const [combineScoreListPlaying, setCombineScoreListPlaying] = useState([]);
    const [combineScoreListFinished, setCombineScoreListFinished] = useState([]);
    const [combineScoreListUnfinished, setCombineScoreListUnfinished] = useState([]);

    const [scoreLoaded, setScoreLoaded] = useState(false);

    // main round
    useEffect(() => {
        async function getRoundUserData() {
            const fetchedRound = await getUserData(roundData);
            setFetchedRoundData(fetchedRound);

            const fetchedStat = await getStat(roundData);
            setStatList(fetchedStat);

        }

        if (Object.keys(roundData).length) {
            getRoundUserData();
        }

    }, [roundData])

    // other round
    useEffect(() => {
        async function getOtherUserData() {
            const statArr = [];
            const roundArr = [];
            for (const round of otherRound) {
                const fetchedRound = await getUserData(round, roundData);
                let fetchedStat = await getStat(fetchedRound);
                if (!Array.isArray(fetchedStat)) {
                    fetchedStat = []
                }
                statArr.push(...fetchedStat);
                roundArr.push(fetchedRound);
            }
            setOtherStatList(statArr);
            setFetchedOtherRoundData(roundArr);
        }

        if (Object.keys(roundData).length && otherRound.length) {
            getOtherUserData()
        }

    }, [otherRound, roundData])

    // main score
    useEffect(() => {
        if (!fetchedRounData || !fetchedRounData.roundId) return;

        const scoreQ = query(
            collection(firestore, 'scoreCards'),
            where('roundId', '==', roundData.roundId)
        );

        // Subscribe to real-time updates
        const unsubscribe = onSnapshot(scoreQ, (snapshot) => {
            const scoreList = snapshot.docs.map(doc => doc.data());
            const sortedScore = gameModeSelection(scoreList, fetchedRounData, statList);
            setScoreList(sortedScore); // Update state with the new scores
            setScoreLoaded(true)
        }, (error) => {
            console.error('Error fetching scores in real-time:', error);
        });

        // Cleanup subscription on unmount or when the round changes
        return () => unsubscribe();
    }, [fetchedRounData, roundData, statList]);

    // other score
    useEffect(() => {
        if (!fetchedRounData || !fetchedRounData.roundId) return;

        const roundIds = otherRound.map(item => item.roundId);

        if (!roundIds.length) return;

        const scoreQ = query(
            collection(firestore, 'scoreCards'),
            where('roundId', 'in', roundIds)
        );

        // Subscribe to real-time updates
        const unsubscribe = onSnapshot(scoreQ, (snapshot) => {
            const scoreList = snapshot.docs.map(doc => doc.data());
            const sortedScore = gameModeSelection(scoreList, fetchedRounData, otherStatList, fetchedOtherRounData);
            setOtherScoreList(sortedScore); // Update state with the new scores
        }, (error) => {
            console.error('Error fetching scores in real-time:', error);
        });

        // Cleanup subscription on unmount or when the round changes
        return () => unsubscribe();
    }, [fetchedRounData, otherRound, otherStatList, roundData]);

    // manage score 
    useEffect(() => {
        if (!fetchedRounData) return;

        // Processing scoreList
        const processedScoreList = processScoreList(scoreList, [fetchedRounData]).filter(item => item.userId || item.caddieId);

        // Processing otherScoreList
        const processedOtherScoreList = processScoreList(otherScoreList, fetchedOtherRounData).filter(item => item.userId || item.caddieId);

        const finalGroupedScores = processAndGroupScores(processedScoreList, fetchedRounData.gameMode);
        const finalGroupedOtherScores = processAndGroupScores(processedOtherScoreList, fetchedRounData.gameMode);

        // ==================================all status================================== //
        const mergedScore = [...finalGroupedScores, ...finalGroupedOtherScores];
        const mergedPositioned = defPosition(sortScore(mergedScore.flat(), 'sumScore', fetchedRounData.gameMode), false, true);

        setCombineScoreList(mergedPositioned);

        // filter only my round
        const filteredMyRound = mergedPositioned.filter(item => item.roundId === fetchedRounData.roundId);
        const sortedMyRound = sortScore(filteredMyRound, 'sumScore', fetchedRounData.gameMode)
        setAllScoreList(sortedMyRound)

        // ==================================playing================================== //
        // const playingScore = defPosition(sortScore(processedScoreList.filter(item => item.status === 'playing'), 'sumScore', fetchedRounData.gameMode), true, false);
        // // const playingOtherScore = defPosition(sortScore(processedOtherScoreList.filter(item => item.status === 'playing'), 'sumScore', fetchedRounData.gameMode), false, false);
        // const playingOtherScore = sortScore(finalGroupedOtherScores.flat().filter(item => item.status === 'playing'), 'sumScore', fetchedRounData.gameMode)

        // const combinedScoreList = defPosition(sortScore([...playingScore, ...playingOtherScore], 'sumScore', fetchedRounData.gameMode), false, true);

        // playingScore.forEach(score => {
        //     const foundScore = combinedScoreList.find(item => item.userId === score.userId);
        //     if (foundScore) {
        //         score.combinePosition = foundScore.combinePosition
        //     }
        // });

        // setScoreListPlaying(playingScore);
        // setOtherScoreListPlaying(playingOtherScore);
        // setCombineScoreListPlaying(combinedScoreList);

        setCombineScoreListPlaying(mergedPositioned.filter(item => item.status === 'playing'))

        // ==================================finished================================== //
        // const finishedScore = defPosition(sortScore(processedScoreList.filter(item => item.status === 'finished'), 'sumScore', fetchedRounData.gameMode), true, false);
        // // const finishedOtherScore = defPosition(sortScore(processedOtherScoreList.filter(item => item.status === 'finished'), 'sumScore', fetchedRounData.gameMode), false, false);
        // const finishedOtherScore = sortScore(finalGroupedOtherScores.flat().filter(item => item.status === 'finished'), 'sumScore', fetchedRounData.gameMode)

        // const combinedFinScoreList = defPosition(sortScore([...finishedScore, ...finishedOtherScore], 'sumScore', fetchedRounData.gameMode), false, true);

        // finishedScore.forEach(score => {
        //     const foundScore = combinedFinScoreList.find(item => item.userId === score.userId);
        //     if (foundScore) {
        //         score.combinePosition = foundScore.combinePosition
        //     }
        // });

        // setScoreListFinished(finishedScore);
        // setOtherScoreListFinished(finishedOtherScore);
        // setCombineScoreListFinished(combinedFinScoreList);

        setCombineScoreListFinished(mergedPositioned.filter(item => item.status === 'finished'))

        // ==================================unfinished================================== //
        // const unfinishedScore = defPosition(sortScore(processedScoreList.filter(item => item.status === 'unfinished'), 'sumScore', fetchedRounData.gameMode), true, false);
        // // const unfinishedOtherScore = defPosition(sortScore(processedOtherScoreList.filter(item => item.status === 'unfinished'), 'sumScore', fetchedRounData.gameMode), false, true);
        // const unfinishedOtherScore = sortScore(finalGroupedOtherScores.flat().filter(item => item.status === 'unfinished'), 'sumScore', fetchedRounData.gameMode)

        // const combinedUnfinScoreList = defPosition(sortScore([...unfinishedScore, ...unfinishedOtherScore], 'sumScore', fetchedRounData.gameMode), false, true);

        // unfinishedScore.forEach(score => {
        //     const foundScore = combinedUnfinScoreList.find(item => item.userId === score.userId);
        //     if (foundScore) {
        //         score.combinePosition = foundScore.combinePosition
        //     }
        // });

        // setScoreListUnfinished(unfinishedScore);
        // setOtherScoreListUnfinished(unfinishedOtherScore);
        // setCombineScoreListUnfinished(combinedUnfinScoreList);

        setCombineScoreListUnfinished(mergedPositioned.filter(item => item.status === 'unfinished'))

    }, [fetchedOtherRounData, fetchedRounData, otherScoreList, scoreList])


    if (!scoreLoaded) {
        return (
            <div className='d-flex justify-content-center'>
                <LoadCircle variant='dark' size='lg' />
            </div>
        )
    } else {

        return (
            <>
                <style>
                    {`  
                        .all-acd-header button,
                        .playing-acd-header button,
                        .finish-acd-header button,
                        .unfinish-acd-header button {
                            background-color: #ee3c8000;
                            color: black;
                        }
                        .all-acd-header .accordion-button:not(.collapsed),
                        .playing-acd-header .accordion-button:not(.collapsed),
                        .finish-acd-header .accordion-button:not(.collapsed),
                        .unfinish-acd-header .accordion-button:not(.collapsed) {
                            background-color: #ee3c8011;
                            color: black;
                        }
                    `}

                </style>
                <div className="col-xl-12 d-flex justify-content-between align-items-center mb-3">
                    <h2 className="fw-600">{t(textConvert('Leaderboard'))}</h2>

                    {(() => {
                        const foundUser = combineScoreList.findIndex(item => item.userId === userData.uid || item.caddieId === userData.uid);
                        if (isOtherRound && foundUser > -1) {
                            return (
                                <div className='col-5'>
                                    <Form.Select size='sm' value={dropdownVal} onChange={(e) => setDropdownVal(e.target.value)}>
                                        <option value="My Position">{t(textConvert('My Position'))}</option>
                                        <option value="My Round">{t(textConvert('My Round'))}</option>
                                    </Form.Select>
                                </div>
                            )
                        }
                    })()}

                </div>

                {!roundData.holesCourse &&
                    <div className='text-center'>
                        {roundData.creator === userData.uid ? (
                            <>
                                <h5 className='text-muted'>{t(textConvert('You must select a course before viewing the leaderboard'))}</h5>
                                <Link className='fw-600' to={`/score?round=${roundData.roundId}`}>{t(textConvert('Select Course'))}</Link>
                            </>
                        ) : (
                            <h5 className='text-muted'>{t(textConvert('Please wait for the round creator to select a course'))}</h5>
                        )}
                    </div>

                }

                {/* my position focus */}
                {(() => {
                    const foundUser = combineScoreList.findIndex(item => item.userId === userData.uid || item.caddieId === userData.uid);
                    if (foundUser > -1) {
                        return (
                            <>
                                {dropdownVal === 'My Round' ?
                                    <>
                                        {isOtherRound ?
                                            <RenderMyPosition scoreList={allScoreList} userData={userData} />
                                            :
                                            <>
                                                {allScoreList.map((user, idx) =>
                                                    <RenderRow userData={userData} user={user} key={user.userId + idx} />
                                                )}
                                            </>
                                        }
                                    </>
                                    :
                                    <RenderMyPosition scoreList={combineScoreList} userData={userData} />
                                }
                            </>
                        )
                    }
                })()}

                {isOtherRound &&
                    <>
                        <Accordion defaultActiveKey="0" className='p-0 mb-2' flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className='all-acd-header'>{t(textConvert('All'))} ({combineScoreList.length})</Accordion.Header>
                                <Accordion.Body className='px-0'>
                                    {combineScoreList.map((user, idx) =>
                                        <RenderRow userData={userData} user={user} key={user.userId + idx} />
                                    )}
                                    <RenderEmpty scoreList={combineScoreList} t={t} />

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className='playing-acd-header'>{t(textConvert('Playing'))} ({combineScoreListPlaying.length})</Accordion.Header>
                                <Accordion.Body className='px-0'>
                                    {combineScoreListPlaying.map((user, idx) =>
                                        <RenderRow userData={userData} user={user} key={user.userId + idx} />
                                    )}
                                    <RenderEmpty scoreList={combineScoreListPlaying} t={t} />

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header className='finish-acd-header'>{t(textConvert('Finished'))} ({combineScoreListFinished.length})</Accordion.Header>
                                <Accordion.Body className='px-0'>
                                    {combineScoreListFinished.map((user, idx) =>
                                        <RenderRow userData={userData} user={user} key={user.userId + idx} />
                                    )}
                                    <RenderEmpty scoreList={combineScoreListFinished} t={t} />

                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header className='unfinish-acd-header'>{t(textConvert('Unfinished'))} ({combineScoreListUnfinished.length})</Accordion.Header>
                                <Accordion.Body className='px-0'>
                                    {combineScoreListUnfinished.map((user, idx) =>
                                        <RenderRow userData={userData} user={user} key={user.userId + idx} />
                                    )}
                                    <RenderEmpty scoreList={combineScoreListUnfinished} t={t} />

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <hr className='text-black-50' />
                    </>
                }
            </>
        )
    }
}

export default Table

function RenderRow({ user, userData }) {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    if (user) {
        return (
            <>
                <div className={clsx('p-1 px-2 px-md-3 d-flex py-2 border-bottom', { 'bg-light': user.userId === userData.uid || user.caddieId === userData.uid })}
                    onClick={() => setShowModal(true)}>
                    <div className='col-3 d-flex align-items-center'>
                        <div className='d-flex align-items-baseline gap-1'>
                            <h1 className='text-current fw-600'>{user?.combinePosition}</h1>
                            <h4 className='text-current fw-600'>{user?.roundPosition}</h4>
                        </div>
                    </div>
                    <div className='col-4 d-flex align-items-center justify-content-center'>
                        {user?.playerData ?
                            <div style={{ zIndex: 1 }}>
                                <img src={user?.playerData?.profileImage} alt="player" width={40} height={40} className='player-color-outline rounded-circle' />
                            </div>
                            :
                            null
                        }
                        {user?.caddieData ?
                            <div>
                                <img src={user?.caddieData?.profileImage} alt="caddie" width={40} height={40} className='caddie-color-outline rounded-circle' />
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className='col-5 text-center'>
                        <div className='d-flex justify-content-center align-items-baseline gap-2'>
                            <h4 className='fw-600 text-danger'>{user.sumPenal}</h4>
                            <h1 className='fw-600'>{user.sumScore}</h1>
                            <h4 className='fw-600'>{user.sumPutt}</h4>
                        </div>
                        <h5 className='fw-600 text-capitalize'>{t(textConvert(user.status))}</h5>
                    </div>
                </div>
                <DetailModal
                    isShow={showModal}
                    closeModal={() => setShowModal(false)}
                    userScore={user}
                />
            </>
        )
    } else {
        return null
    }
}

function RenderMyPosition({ scoreList, userData }) {
    const myLeaderboardPosIndex = scoreList.findIndex(item => item && (item.userId === userData.uid || item.caddieId === userData.uid));

    let playersToDisplay = [];

    if (myLeaderboardPosIndex > -1) {
        // Determine the players to show based on position
        if (myLeaderboardPosIndex === 0) {
            // If the current player is at the start, include the next two players
            playersToDisplay = scoreList.slice(0, 3);
        } else if (myLeaderboardPosIndex === scoreList.length - 1) {
            // If the current player is at the end, include the previous two players
            playersToDisplay = scoreList.slice(-3);
        } else {
            // Otherwise, include the previous, current, and next player
            playersToDisplay = scoreList.slice(myLeaderboardPosIndex - 1, myLeaderboardPosIndex + 2);
        }
    } else {
        if (userData) {
            playersToDisplay = scoreList.slice(0, 3);
        }
    }

    return (
        <div className='text-center'>
            {playersToDisplay.map((player, idx) => player && (
                <RenderRow key={player.userId + idx} user={player} userData={userData} />
            ))}

            {/* No players */}
            {playersToDisplay.length === 0 ? (
                <h4 className='text-muted fw-600 mb-2'>No "Playing" player.</h4>
            ) : null}
        </div>
    );
}


function RenderEmpty({ scoreList, t }) {
    if (!scoreList.length) {
        return (
            <div className='p-2 text-center'>
                <h5 className=' text-muted fw-600'>{t(textConvert('Empty List'))}</h5>
            </div>
        )
    }
}