export const en = {
    translation: {
        log_in: 'Log in',
        login: 'Log in',
        language: 'Language',
        Thai: 'Thai',
        English: 'English',
        Phone: 'Phone',
        name_reg: 'Name',
        email_or_phone: 'Email or Phone',
        Forgot_your_Password: 'Forgot your Password ?',
        Remember_me: 'Remember me',
        email: 'Email',
        password: 'Password',
        do_not_have_account: 'Do not have account ?',
        register: 'Register',
        what_on_your_mind: "What's on your mind ?",
        my_performance: 'Performance',
        performance: 'Performance',
        golf_course: 'Golf Course',
        caddies: 'Caddies',
        chat: 'Chat',
        tournament: 'Tournament',
        store: 'Store',
        my_friends: 'Friends',
        my_activity: 'Activity',
        settings: 'Settings',
        privacy: 'Privacy',
        logout: 'Logout',
        about: 'About',
        images: 'Images',
        view_and_save: 'submit',
        save_and_exit: 'Save',
        intro: 'Profile Status',
        edit: 'Edit',
        cancel: 'Cancel',
        my_course: 'My Course',
        your_caddie_number: 'Your Caddie Number',
        select_your_course: 'Select your course',
        phone_number: 'Phont Number',
        note_to_admin: 'Note to admin',
        document_evidence: 'Document Evidence',
        request: 'Request ',
        remove_course: 'Delete',
        please_confirm_your_request: 'Please comfirm your request',
        caddie_status_waiting: 'Caddie Status – Waiting',
        caddie_status_confirm: 'Caddie Status – Confirm',
        caddie_status_official: 'Caddie Status – Official',
        caddie_status_no: 'Caddie Status – No Status',
        your_request_has_been_denied_please_contact_your_course_manager_or_doonine: 'Your request has been denied. Please contact your course manager or doonine.com.',
        ok_req: 'Back',
        course: 'Course',
        confirm: 'Confirm',
        see_all: 'See all',
        service_mind: 'Service Mind',
        advice_and_guidance: 'Advice and Guidance',
        total_rounds: 'Total Rounds',
        day_average: '30-day average',
        day_total: '30-day total',
        create_post: 'Create Post',
        no_post_to_show: 'No post',
        post: 'Post',
        public: 'Public',
        friend: 'Friend',
        only_me: 'only Me',
        social_stats: 'Social stats',
        total_posts: 'Total Posts',
        friends: 'Friends',
        my_statistics: 'My Statistics',
        statistics: 'Statistics',
        total_number_of_rounds_you_have_completed: 'Total number of rounds you have completed',
        all_time: 'All Time',
        doonine_score: 'Doonine Score',
        total_points_earned: 'Total points earned',
        monthly_average_doonine_score: 'Monthly Average Doonine Score',
        five_stars: '5 Stars',
        four_stars: '4 Stars',
        three_stars: '3 Stars',
        two_stars: '2 Stars',
        one_stars: '1 Star',
        didnt_review: "Didn't review",
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
        my_profile: 'My Profile',
        profile: 'Profile',
        my_clubs: 'My Clubs',
        clubs: 'Clubs',
        clubs_in_your_bag: 'Clubs in your bag',
        clubs_in_bag: 'Clubs in bag',
        handicap: 'Handicap',
        putts_per_hole: 'Putts per hole',
        greens_in_regulation: 'Greens in Regulation(%)',
        best_score: 'Best Score (to par)',
        ok: 'OK',
        white: 'White',
        red: 'Red',
        yellow: 'Yellow',
        blue: 'Blue',
        black: 'Black',
        save: 'Save',
        update: 'Update',
        driver: 'Driver',
        hybrid: 'Hybrid',
        wood: 'Wood',
        iron: 'Iron',
        pitching_wedge: 'Pitching Wedge',
        sand_wedge: 'Sand Wedge',
        lob_wedge: 'Lob Wedge',
        putter: 'Putter',
        yd: 'yd',
        score_distribution: 'Score Distribution',
        eagle: 'Eagle',
        birdie: 'Birdie',
        par: 'Par',
        bogey: 'Bogey',
        bouble: 'Bouble',
        triple: 'Triple',
        score_by_par: 'Score By Par',
        par_three: 'Par 3',
        par_four: 'Par 4',
        par_five: 'Par 5',
        add_friend: 'Add friend',
        block: 'Block',
        search_here: 'Search',
        filter: 'Filter',
        favorites: 'Favorites',
        favorite: 'Favorite',
        caddie_star: 'Caddie Star',
        pro_caddie: 'Pro Caddie',
        age: 'Age',
        age_min: 'Age min',
        age_max: 'Age max',
        label: 'Label',
        this_user_is_unavailable: 'This user is unavailable',
        unfriend: 'Unfriend',
        menu: 'Menu',
        more: 'More',
        offcial_course: 'Offcial Course',
        only_officially_registered_golf_courses_with_doonine: '',
        starting_price_range: 'Starting Price Range',
        night_golf: 'Night Golf',
        driving_range: 'Driving Range',
        restaurant: 'Restaurant',
        banquet_rooms: 'Banquet Rooms',
        hotel: 'Hotel',
        close_filter: 'Reset',
        apply: 'OK',
        round: 'Round',
        starting_price: 'Starting Price',
        gallery: 'Gallery',
        caddie: 'Caddie',
        close: 'Close',
        pending: 'Pending',
        you_have: 'You have',
        invitations_to_join_the_chat_group: 'invitations to join the chat group',
        create_group_chat: 'Create Group Chat ',
        group_chat_name: 'Group Chat Name',
        selcet_member: 'Select Member',
        operator: 'Creater',
        start_group_chat: 'Start Group Chat',
        select_member: 'Select Member',
        deny: 'Deny',
        join: 'Join',
        back: 'Back',
        invite_chat: 'Invite Chat',
        create_group_chat_complete: 'Create group chat complete.',
        success: 'Success',
        invite: 'Invite',
        members: 'Members',
        setting: 'Setting',
        leave_chat: 'Leave Chat',
        invite_member: 'Invite Memeber',
        do_you_want_to_leave_this_chat: 'Do you want to leave chat?',
        Respond: 'Respond',
        cancel_request: 'Cancel Request',
        invite_member_to_chat_complete: 'Member is invited to the group chat',
        group_members: 'Group Member',
        invite_members: 'Invite Members',
        group_chat_image_cover: 'Group Chat Image Cover',
        delete_group_chat: 'Delete Group Chat ',
        search_by_doonine_id: 'Search by doonine ID',
        my_qr_code: 'My QR Code',
        scan: 'Scan ',
        use_a_qr_code_or_link_to_let_other_users_add_you_as_a_friend: 'send a QR code or link to friends to let them add you',
        copy_link: 'Copy link',
        scan_qr_code: 'Scan QR Code',
        unblock: 'Unblock',
        create_round: 'Create Round',
        round_request: 'Round Requests',
        no_rounds_request_available: 'No round request available',
        friend_activity: 'Friends Activities',
        upcoming_round: 'Upcoming Rounds',
        finished_round: 'Finished Round',
        round_name: 'Round Name',
        select_golf_course: 'Select Golf Course',
        select_course: 'Select Course',
        playing_holes: 'Holes',
        nine_holes: '9 holes',
        eighteen_holes: '18 holes',
        game_mode: 'Game Mode',
        scoring: 'Scoring',
        bookmark_only: 'Bookmark',
        you_must_have_a_course_before_create_round: 'You must have a course before create round',
        stroke_play: 'Stoke Play',
        stableford: 'Stableford',
        match_play: 'Match Play',
        scratch_no_handicap_applied: 'Scratch (No Handicap Applied)',
        whs_world_handicap_system: 'WHS (World Handicap System)',
        s_thirtysix_system_thirtysix: 'S36 (System 36)',
        gross: 'Gross',
        to_par: 'To Par',
        gross_whs: 'Gross-WHS',
        to_par_whs: 'To Par-WHS',
        gross_s_thirtysix: 'Gross-S36',
        to_par_s_thirtysix: 'To Par-S36',
        players_and_caddies_in_this_round: 'Players and caddies in this round',
        round_creator: 'Round Creater',
        tee_time: 'Tee Time',
        start_now: 'Start Now',
        create_schedule: 'Create Schedule',
        group_chat: 'Group Chat',
        create: 'Create',
        disable: 'Disable',
        name: 'Name',
        round_chat: '[Round Chat]',
        caddie_request: 'Caddie Requests',
        would_you_like_the_caddies_at_the_course_to_see_and_ask_to_join_the_round: 'Would you like the caddies at the course to see and ask to join the round',
        all_caddie: 'All Caddies',
        favorite_caddie: 'Favorites',
        disable_create: 'Disable',
        round_cover: 'Round Cover',
        date: 'Date',
        time: 'Time',
        send_request: 'Send Request',
        start_round: 'Start Round',
        next: 'Next',
        edit_round: 'Edit Round',
        resume_round: 'Resume Round',
        leader_board: 'Leaderboard',
        leaderboard: 'Leaderboard',
        competitor: 'Competitor',
        player_and_caddie: 'Players & Caddies',
        invitation_link: 'Invitation Link',
        delete_round: 'Delete Round',
        playing: 'Playing ',
        score: 'Score',
        putt: 'Putt',
        fairway_hit: 'Fairway Hit',
        bunker: 'Bunker',
        penalties: 'Penalties',
        driving: 'Driving',
        clear: 'Clear',
        total_par: 'Total Par',
        finished: 'Finished',
        public_link: 'Public Link',
        leaderboard_not_public: 'Leaderboard Not Public',
        you_need_to_set_the_leaderboard_to_public: 'You need to set the leaderboard to public',
        this_will_allow_anyone_with_the_link_to_view_it: 'This will allow anyone with the link to view it',
        default: 'Default',
        this_will_not_apply_to_other_player: 'This will not apply to other player.',
        select_your_caddie: 'Select your Caddie',
        end_round: 'End Round',
        tee_box: 'Tee Box',
        teebox: 'Tee Box',
        you: 'You',
        creator: 'Creator',
        your_player: 'Your Player',
        delete: 'Delete',
        copy: 'Copy',
        copied: 'Copied',
        my_position: 'My Position',
        my_round: 'My Round',
        all: 'All',
        unfinished: 'Unfinished',
        add_another_leaderboard: 'Add another leaderboard',
        leaderboard_list: 'Leaderboard List',
        add: 'Add',
        added: 'Added',
        hide_qr_code: 'Hide QR code',
        show_qr_code: 'Show QR code',
        failed_you_cant_add_a_private_leaderboard: "Failed: You can't add a private leaderboard.",
        failed_you_cant_add_a_leaderboard_with_a_different_number_of_holes: "Failed: You can't add a leaderboard with a different number of holes.",
        failed_the_creator_of_this_leaderboard_has_not_selected_a_course_yet: 'Failed: The creator of this leaderboard has not selected a course yet.',
        failed_maximum_leaderboards_reached: 'Failed: Maximum leaderboards reached (18).',
        not_found_leaderboard: 'Not found leaderboard.',
        invalid_link: 'Invalid Link.',
        leaderboard_already_added: 'Leaderboard already added.',
        success_leaderboard_added_successfully: 'Success: Leaderboard added successfully.',
        empty_List: 'Empty List',
        finish_round: 'Finished Round',
        are_you_sure_you_want_to_end_this_round: 'Are you sure you want to end this round?',
        are_you_sure: 'Are you sure? ',
        you_wont_be_able_to_revert_this: "You won't be able to revert this.",
        end_this_round: 'End this Round',
        log_in_to_your_account: 'Log in to your account',
        see_password: 'Show password',
        forgot_your_password: 'Forgot password?',
        or: 'or',
        firstname: 'First Name',
        lastname: 'Last Name ',
        role: 'Role',
        re_password: 'Comfirm',
        no_select: 'no-select',
        player: 'Player',
        create_your_account: 'Create your account',
        create_your_account_caddie: 'Create account for Caddie',
        already_have_account: 'Already have account',
        password_not_matched_try_again: 'Password not matched, Try again',
        male: 'Male',
        female: 'Female',
        prefer_not_to_say: 'Prefer Not to Say',
        enter_your_doonine_id: 'Enter your Doonine ID',
        submit: 'submit',
        skip: 'skip',
        warning: 'Warning',
        you_wont_be_able_to_change_id_later: 'Your ID cannot be changed later.',
        your_id_is: 'Your ID is',
        saved: 'Saved',
        wrong_email_or_password: 'Wrong email or Password',
        contacts: 'Contacts',
        you_have_no_friend: 'You have no friend.',
        acount_information: 'Account',
        notification_line: 'Line Notification',
        notification: 'Notification',
        block_list: 'Blocked accounts',
        reset_password: 'Reset Password',
        doonine_id: 'Doonine ID',
        first_name: 'First Name',
        last_name: 'Last Name',
        phone: 'Phone',
        gender: 'Gender',
        date_of_birth: 'Birth Date',
        please_select_role: 'Please select role',
        other: 'Other',
        your_block_list_is_empty: "You haven't blocked any accounts",
        qr_code: 'QR code',
        logout_and_search: 'Logout and search',
        auto_logout: 'Auto Logout',
        doonine_id_search: 'Doonine ID search ',
        allow_other_users_to_search_your_account_by_doonine_id: 'Allow other users to search your account by Doonine ID  ',
        email_search: 'Email Search',
        allow_other_users_to_search_your_account_by_email: 'Allow other users to search your account by Email',
        post_and_feeds: 'Post and feeds',
        default_audience_for_your_posts: 'Default audience for your posts',
        show_caddie_s_public_posts: "Show caddie's public posts",
        show_player_s_public_posts: "Show player's public posts",
        private: 'Private',
        forget_password: 'Forget Password',
        password_reset_email_sent_check_your_inbox: 'Password reset email sent, Check your inbox',
        activity: 'Activity',
        default_leaderboard_privacy: 'Default leaderboard privacy',
        official_course: 'Official Course',
        reset: 'Reset',
        review: 'Review',
        what_do_you_think_about_service: 'How would you rate your experience with',
        what_do_you_think_about: 'How would you rate your experience with',
        later: 'Later',
        review_success: 'Review Success',
        your_review_has_been_submitted: 'Your review has been submitted',
        review_question: 'service ?',
        question_mark: '?',
        friend_request: 'Friend Request',
        you_have_no_friend_request: 'You have no friend request',
        accepted: 'Accepted',
        deleted: 'Deleted',
        delete_request: 'Delete Request',
        respond: "Respond",
        edit_post: 'Edit Post',
        turn_off_notification: 'Turn off notification',
        turn_on_notification: 'Turn on notification',
        delete_post: 'Delete post',
        max_club_warning: 'Warning: Maximum 14 clubs allowed in your bag.',
        edit_club: 'Edit Club',
        club_name: 'Club Name',
        loft: 'Loft',
        club_number: 'Club Number',
        club_type: 'Club Type',
        distance: 'Distance',
        note: 'Note',
        in_bag: 'In Bag',
        remove: 'Remove',
        select_club_distance: 'Select Club Distance',
        select_club_type: 'Select Club type',
        select_club_number: 'Select Club Number',
        select_club_loft: 'Select Club Loft',
        add_club: 'Add Club',
        swap_caddie: 'Swap Caddie',
        remove_caddie: 'Remove Caddie',
        remove_player: 'Remove Player',
        remove_both: 'Remove Both',
        remove_slot: 'Remove Slot',
        select_player: 'Select Player',
        select_caddie: 'Select Caddie',
        no_notification: 'No Notification',
        view_more: 'View More',
        select: 'Select',
        rounds: 'Rounds',
        my_favorites: 'My Favorites',
        to: 'to',
        round_created: 'Round Created',
        round_edited: 'Round Edited',
        round_is_private: 'Round is Private',
        link: 'Link',
        scan_me: 'SCAN ME',
        logout_timer: 'Logout timer',
        minutes: 'Minutes',
        you_are_already_playing_a_round: 'You are already playing a round',
        you_can_only_join_one_round_at_a_time_please_finish_your_current_round_before_joining_another_round: 'You can only join one round at a time. Please finish your current round before joining another round.',
        can_not_join: 'Can not join',
        you_are_not_caddie_in_this_course: 'You are not caddie in this course.',
        join_round: 'Join Round',
        joined: 'Joined',
        you_are_already_in_this_round: 'You are already in this round.',
        confirmed: 'Confirmed',
        group_position: 'Group Position',
        position: 'Position',
        you_must_select_a_course_before_viewing_the_leaderboard: 'You must select a course before viewing the leaderboard.',
        please_wait_for_the_round_creator_to_select_a_course: 'Please wait for the round creator to select a course.',
        this_caddie_number_is_already_in_use: 'This caddie number is already in use.',
        do_you_want_to_reset_your_bag_to_default: 'Do you want to reset your bag to default',
        invalid_driving_range_maximum_is_1000_yd: 'Invalid driving range maximum is 1000 yd.',
        yes: 'Yes',
        no: 'No',
        round_finished: 'Round Finished',
        your_comment_will_be_discard: 'Your comment will be discard',
        your_post_will_be_discard: 'Your post will be discard',
        discard: 'Discard',
        comment: 'Comment',
        name_block: 'Name',
        this_section_is_currently_not_available: 'This section is currently not available.',
        holes: 'Holes',
        hole: 'Hole',
        index: 'Index',
        all_rounds: 'All Rounds',
        nine_holes_finished: '9 Holes Finished',
        eighteen_holes_finished: '18 Holes Finished',
        start: 'Start',
        no_friend_activity_available: 'No friend activity available',
        you_ve_seen_all_post: "You've seen all post",
        bunker_yes: 'Yes',
        bunker_no: 'No',
        delete_this_round: 'Delete this round',
        are_you_sure_you_want_to_delete_this_round: 'Are you sure you want to delete this round ?',
        request_sent: 'Request Sent',
        your_request_to_join_the_round_has_been_successfully_submitted: 'Your request to join the round has been successfully submitted',
        round_in_progress: 'Round In Progress',
        you_still_have_a_round_in_progress_please_finish_that_round_first: 'You still have a round in progress. Please finish that round first.',
        deny_this_round: 'Deny this round?',
        are_you_sure_you_want_to_deny_this_round: 'Are you sure you want to deny this round?',
        you_want_to_leave_this_round: 'You want to leave this round?',
        no_friends_activity_available: 'No friends activity available',
        no_rounds_upcoming_available: 'No rounds upcoming available',
        select_an_option: 'Select an option',
        select_or_create_an_option: 'Select or create an option',
        do_you_want_to: 'Do you want to',
        with: 'with',
        yes_or_not: '?',
        update_complete: 'Update Complete',
        caddie_no: 'Caddie No.',
        leave_round: 'Leave Round',
        invalid_email_or_phone: 'invalid email or phone',
        password_must_be_at_least_six_characters: 'Password must be at least 6 characters',
        please_enter_your_sms_verify_code: 'Please enter your sms verify code',
        your_phone_number_already_used: 'Your phone number already used',
    }
}
