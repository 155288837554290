import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../../../components/firebase/FirebaseConfig";

export async function getCaddieNumber(caddieList) {

    if (!caddieList || !caddieList.length) {
        console.error('Invalid caddieList: ', caddieList);
        return [];
    }
    // console.log(caddieList);

    try {
        const extractedUid = caddieList.map(item => item.uid);

        const q = query(collection(firestore, 'caddieAssignments'),
            where('caddieId', 'in', extractedUid)
        )
        const caddieAssignSnap = await getDocs(q);
        if (!caddieAssignSnap.empty) {
            const caddieUpdateList = structuredClone(caddieList).map(caddie => ({
                ...caddie,
                number: "-"
            }));

            caddieAssignSnap.forEach(doc => {
                const data = doc.data();
                caddieUpdateList.forEach(caddie => {
                    if (caddie.uid === data.caddieId) {
                        caddie.number = data.caddieNumber;
                    }
                });
            });
            return caddieUpdateList;
        }
    } catch (error) {
        console.error('Failed to get caddie number', error);

    }
}