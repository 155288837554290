import React, { createContext, useReducer, useContext, useState } from "react";

export const initialFilterState = {
    official: false,
    price: false,
    price_min: 500,
    price_max: 4500,
    night: false,
    drive: false,
    restaurant: false,
    banquet: false,
    hotel: false,
    loc: null,
};

function filterRec(state, action) {
    switch (action.type) {
        case "OFFICIAL":
            return { ...state, official: !state.official };
        case "PRICE":
            return { ...state, price: !state.price };
        case "PRICEMIN":
            return { ...state, price_min: action.payload };
        case "PRICEMAX":
            return { ...state, price_max: action.payload };
        case "NIGHT":
            return { ...state, night: !state.night };
        case "DRIVE":
            return { ...state, drive: !state.drive };
        case "RESTAURANT":
            return { ...state, restaurant: !state.restaurant };
        case "BANQUET":
            return { ...state, banquet: !state.banquet };
        case "HOTEL":
            return { ...state, hotel: !state.hotel };
        case "LOC":
            return { ...state, loc: action.payload };
        case "RESET":
            return initialFilterState;
        case "UPDATE":
            return action.payload;
        default:
            return state;
    }
}

// Create context
const filterContext = createContext();

// Context Provider Component
export const FilterProvider = ({ children }) => {
    const [filterState, dispatch] = useReducer(filterRec, initialFilterState);
    const [facet, setFacet] = useState([]);
    const [numericFilters, setnumericFilters] = useState([]);
    const [triggerKey, setTriggerKey] = useState(null);

    const trigger = () => {
        setTriggerKey((prevKey) => prevKey + 1);
    };

    return (
        <filterContext.Provider value={{
            filterState,
            dispatch,
            facet,
            setFacet,
            numericFilters,
            setnumericFilters,
            trigger,
            triggerKey,
        }}>
            {children}
        </filterContext.Provider>
    );
};

// Custom Hook to use Counter Context
export const useFilter = () => useContext(filterContext);
