import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Card } from "react-bootstrap";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Link } from "react-router-dom";
import FetchUserPost from "../../FetchUserPost";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import { where, query, or, collection, getDocs } from "firebase/firestore";
import { addFriend } from "../../../../components/friend/AddFriend";
import {
  FriendReqAccept,
  FriendReqCancel,
  FriendReqDeny,
} from "../../../../components/friend/FriendConfirmation";
import { Dropdown } from "react-bootstrap";
import FriendProfileMoreBtn, {
  FriendChatClick,
  FriendChatClickV2,
} from "../../module/ProfileInteract";
import ViewCaddieAbout from "../ViewCaddieAbout";
import FriendCount from "../../module/FriendCount";
import ViewCaddieReqStatus from "../ViewCaddieReqStatus";
import { ChatTo } from "../../../../components/chat/Chat";
import { ViewCaddiePerf } from "../ViewPerf";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../../../components/language/dropdown/textconvert";

function CaddieProfile({ data }) {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.data);
  const [isFriend, setIsFriend] = useState(null);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [addFriendComplete, setAddFriendComplete] = useState(false);
  const [isAccept, setIsAccept] = useState(null);
  const isBlockedRef = useRef(false);
  const [isBlock, setIsBlock] = useState(false);
  const isFavRef = useRef(false);

  const [triggerGetLabel, setTriggerGetLabel] = useState(false);
  const [userLabels, setUserLabels] = useState([]);
  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    if (userData && data) {
      getBlockedUser();
      getFavUser();
    }
  }, [userData, data]);

  async function getBlockedUser() {
    const q1 = query(
      collection(firestore, "blockedUsers"),
      where("blockedUserId", "==", userData.uid),
      where("userId", "==", data.uid)
    );

    const q2 = query(
      collection(firestore, "blockedUsers"),
      where("blockedUserId", "==", data.uid),
      where("userId", "==", userData.uid)
    );

    const querySnapshot1 = await getDocs(q1);
    const querySnapshot2 = await getDocs(q2);

    if (!querySnapshot1.empty || !querySnapshot2.empty) {
      // isBlockedRef.current = true;
      setIsBlock(true);
    }
  }

  async function getFavUser() {
    const q = query(
      collection(firestore, "favoriteFriends"),
      where("caddieId", "==", data.uid),
      where("userId", "==", userData.uid),
      where("status", "==", "active")
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      isFavRef.current = true;
    }
  }

  useEffect(() => {
    async function getFriends() {
      const listId = new Set();

      const q = query(
        collection(firestore, "friends"),
        or(where("friendId", "==", data?.uid), where("userId", "==", data?.uid))
      );

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (doc.data().status === "active") {
          listId.add(doc.data().friendId);
          listId.add(doc.data().userId);
        }
      });

      const isFriend = listId.has(userData?.uid);
      if (!isFriend) {
        const FriendReqResult = await getFriendReq();
        const FriendAcceptResult = await getFriendAccept();
        if (FriendReqResult) {
          setAddFriendComplete(true);
        }
        if (FriendAcceptResult) {
          setIsAccept(false);
        }
      }
      setIsFriend(isFriend);
    }

    async function getFriendReq() {
      const q = query(
        collection(firestore, "friendRequests"),
        where("fromUserId", "==", userData.uid),
        where("toUserId", "==", data.uid)
      );
      const q2 = query(
        collection(firestore, "friendRequests"),
        where("fromUserId", "==", data.uid),
        where("toUserId", "==", userData.uid)
      );
      try {
        const querySnapshot = await getDocs(q);
        const querySnapshot2 = await getDocs(q2);

        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          const status = doc.data().status;
          if (status === "pending") {
            return true;
          }
        } else {
          return false;
        }
        if (!querySnapshot2.empty) {
          const doc = querySnapshot.docs[0];
          const status = doc.data().status;
          if (status === "pending") {
            return true;
          }
        } else {
          return false;
        }
      } catch (error) {
        throw error;
      }
    }

    async function getFriendAccept() {
      const q2 = query(
        collection(firestore, "friendRequests"),
        where("fromUserId", "==", data.uid),
        where("toUserId", "==", userData.uid)
      );
      try {
        const querySnapshot2 = await getDocs(q2);
        if (!querySnapshot2.empty) {
          const doc = querySnapshot2.docs[0];
          const status = doc.data().status;
          if (status === "pending") {
            return true;
          }
        } else {
          return false;
        }
      } catch (error) {
        throw error;
      }
    }
    if (data) {
      getFriends();
    }
  }, [data, userData?.uid]);

  const handleChat = async (dooNineId) => {
    const result = await ChatTo(userData.dooNineId, dooNineId);
    if (result) {
      navigate("/chat-room?channelId=" + result[0].channel_url);
    }

    // // const channel_url = await FriendChatClick(userData, dooNineId);
    // // console.log(channel_url);

    // const channel_url2 = await FriendChatClickV2(userData, dooNineId);

    // if (channel_url2.length > 1) {
    //   //   console.log(channel_url2);
    // //   navigate("/chat");
    // navigate("/chat-room?channelId=" + channel_url2[0].channel_url);

    // } else {
    //   //   console.log(channel_url2[0].channel_url);
    //   navigate("/chat-room?channelId=" + channel_url2[0].channel_url);
    // }

    // // const route = isMobile ? '/chat-room' : '/chat';

    // // navigate(route, { state: { channelUrl: channel_url, previousPage: 'myfriend' } });
  };

  const handleAddFriend = useCallback(
    async (friend_uid, user_uid) => {
      if (!addFriendComplete) {
        setLoading(true);
        const addFriendResult = await addFriend(friend_uid, user_uid);

        if (addFriendResult === "addFriend_complete") {
          setLoading(false);
          setAddFriendComplete(true);
        } else {
          setLoading(false);
          setAddFriendComplete(false);
        }
      }
    },
    [userData]
  );

  const handleFriendReqAccept = useCallback(
    async (req_uid) => {
      const AcceptList = await FriendReqAccept(userData, req_uid);
      if (AcceptList) {
        setIsFriend(true);
      }
    },
    [userData]
  );

  const handleFriendReqCancel = useCallback(
    async (req_uid) => {
      const cancelId = await FriendReqCancel(userData, req_uid);
      if (cancelId) {
        setIsFriend(false);
        setAddFriendComplete(false);
      }
    },
    [userData]
  );

  const handleFriendReqDeny = useCallback(
    async (req_uid) => {
      const deniedId = await FriendReqDeny(userData, req_uid);
      if (deniedId) {
        setIsFriend(false);
        setAddFriendComplete(false);
        setIsAccept(null);
      }
    },
    [userData]
  );

  const handleUnfriend = async (isUnfriend) => {
    if (isUnfriend) {
      setIsFriend(false);
      setAddFriendComplete(false);
      setIsAccept(null);
    }
  };
  const handleBlock = async (isBlocked) => {
    if (isBlocked) {
      setIsFriend(false);
      setAddFriendComplete(false);
      setIsAccept(null);
    }
  };

  useEffect(() => {
    async function fetchData() {
      let memberListArray = [];
      // console.log(data)
      const q = query(
        collection(firestore, "labels"),
        where("userId", "==", userData.uid)
      );
      const querySnapshot = await getDocs(q);
      let dataArray = [];
      querySnapshot.forEach((doc2) => {
        const data = doc2.data();
        dataArray.push({ docId: doc2.id, ...data });
      });
      setUserLabels(dataArray);

      const q2 = query(collection(firestore, "users"), where("uid", "==", data.uid));
      const querySnapshot2 = await getDocs(q2);
      querySnapshot2.forEach((doc) => {
        memberListArray.push({
          imageUrl: doc.data().profileImage || "user.png",
          name: `${doc.data().firstName} ${doc.data().lastName}`,
          uid: doc.data().uid,
          dooNineId: doc.data().dooNineId,
          role: doc.data().role,
          isStar: doc.data().isStar || false,
          isPro: doc.data().isPro || false,
          coverImage: doc.data().coverImage || "",
          birthDate: doc.data().birthDate,
        });
      });

      const labelPromises = memberListArray.map(async (member, index) => {
        const q3 = query(
          collection(firestore, "labeledUsers"),
          where("friendId", "==", member.uid),
          where("userId", "==", userData.uid)
        );
        const querySnapshot3 = await getDocs(q3);

        let labels = [];
        querySnapshot3.forEach((doc2) => {
          const data = doc2.data();
          if (data.labels) {
            labels = data.labels.map((labelId) => {
              const item = userLabels.find((item) => item.docId === labelId);
              return item?.label;
            });
          }
        });
        memberListArray[index].labels = labels;
      });

      await Promise.all(labelPromises);

      setMemberList(memberListArray);
    }
    if (data) {
      fetchData();
    }
  }, [userData, triggerGetLabel, data]);

  return (
    <>
      {isFriend !== null ? (
        <>
          <div
            className="main-content right-chat-active"
            id="main-content-user-page"
          >
            <div className="middle-sidebar-bottom">
              <div className="middle-sidebar-left pe-0">
                {!isBlock ? (
                  <div className="row">
                    <div className="col-xl-12 mb-3 p-0 px-sm-3">
                      <Card className="w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                        <Card.Body
                          className="p-0 rounded-xxl overflow-hidden m-3 profile-cover-card"
                          style={{ height: 250 }}
                        >
                          {data?.coverImage ? (
                            <PhotoProvider bannerVisible={false}>
                              <PhotoView src={data?.coverImage}>
                                <img
                                  src={data?.coverImage}
                                  alt="avater"
                                  style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "100%",
                                    cursor: "pointer",
                                  }}
                                />
                              </PhotoView>
                            </PhotoProvider>
                          ) : (
                            <div className="w-100 h-100 bg-light"></div>
                          )}
                        </Card.Body>
                        <Card.Body className="p-0 position-relative profile-image-card">
                          <PhotoProvider bannerVisible={false}>
                            <figure
                              className="avatar position-absolute w100 h100 z-index-1"
                              style={{ top: "-40px", left: "30px" }}
                            >
                              <PhotoView src={data?.profileImage}>
                                <img
                                  src={data?.profileImage}
                                  alt="avater"
                                  className="float-right p-1 rounded-circle w-100 h-100 caddie-color"
                                  style={{
                                    objectFit: "cover",
                                    cursor: "pointer",
                                  }}
                                />
                              </PhotoView>
                            </figure>
                          </PhotoProvider>

                          <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">
                            {data?.firstName || data?.firstname}{" "}
                            {data?.lastName || data?.lastname}
                            <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block text-truncate">
                              <FriendCount user={data} />
                            </span>
                          </h4>
                          <div className="d-flex align-items-stretch justify-content-center position-absolute-md right-15 top-0 me-2 mb-3 mb-sm-0 gap-2">
                            {!isFriend ? (
                              <>
                                {addFriendComplete ? (
                                  <>
                                    {addFriendComplete && (
                                      <button
                                        className="btn bg-light theme-dark-bg py-2 px-4 caddie-color d-flex align-items-center h50"
                                        onClick={() =>
                                          handleFriendReqCancel(data.uid)
                                        }
                                      >
                                        <h6 className="m-0 fw-600">
                                          {t(textConvert('Cancel Request'))}
                                        </h6>
                                      </button>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {!loading ? (
                                      <>
                                        {isAccept !== null &&
                                          isAccept !== true ? (
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              variant="light"
                                              className="py-2 px-4 bg-light theme-dark-bg border border-0"
                                            >
                                              <h6 className="fw-600 m-0">
                                                {t(textConvert('Respond'))}
                                              </h6>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu
                                              className="border border-0 shadow-xss p-0"
                                              style={{
                                                borderRadius: "15px",
                                                overflow: "hidden",
                                              }}
                                            >
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleFriendReqAccept(
                                                    data.uid
                                                  )
                                                }
                                              >
                                                <span className="fs-5 fw-bold">
                                                  {t(textConvert('Confirm'))}
                                                </span>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleFriendReqDeny(data.uid)
                                                }
                                              >
                                                <span className="fs-5 fw-bold">
                                                  {t(textConvert('Cancel Request'))}
                                                </span>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        ) : (
                                          <button
                                            className="btn py-2 px-4 caddie-color d-flex align-items-center h50"
                                            onClick={() =>
                                              handleAddFriend(
                                                data.uid,
                                                userData.uid
                                              )
                                            }
                                          >
                                            <h6 className="m-0 text-white fw-600">
                                              {t(textConvert('Add Friend'))}
                                            </h6>
                                          </button>
                                        )}
                                      </>
                                    ) : (
                                      <button
                                        className="btn text-white py-2 px-4 caddie-color d-flex align-items-center h50"
                                        disabled
                                      >
                                        <div
                                          className="spinner-border spinner-border-sm"
                                          role="status"
                                        >
                                          <span className="sr-only"></span>
                                        </div>
                                      </button>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <Link to={`/perf-profile?uid=${data.uid}`}>
                                  <button className="btn text-white p-2 px-3 caddie-color">
                                    {t(textConvert('Performance'))}
                                  </button>
                                </Link>
                                <i
                                  className="bi bi-chat text-grey-900 btn-round-sm bg-greylight font-xss pointer"
                                  onClick={() => handleChat(data.dooNineId)}
                                ></i>
                              </>
                            )}
                            <FriendProfileMoreBtn
                              isFriend={isFriend}
                              friendId={data.uid}
                              userId={userData.uid}
                              isUnFriendComplete={handleUnfriend}
                              isBlockComplete={handleBlock}
                              isFavorite={isFavRef.current}
                              isCaddie={true}
                              setTriggerGetLabel={setTriggerGetLabel}
                              userLabels={userLabels}
                              triggerGetLabel={triggerGetLabel}
                              memberList={memberList}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-xl-4 col-xxl-3 col-lg-4 pe-sm-0 px-sm-3 px-0 mb-2">
                      <Card className="border border-0 shadow-xss rounded-xxl">
                        {/* About */}
                        <Card.Body>
                          <ViewCaddieAbout data={data} />
                        </Card.Body>
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <h4 className="fw-600">{t(textConvert('Course'))}</h4>
                          </div>
                          <ViewCaddieReqStatus userData={data} />
                        </Card.Body>
                        {/* My peformance */}
                        <Card.Body>
                          <div className="d-flex justify-content-between">
                            <h4 className="fw-600">{t(textConvert('Performance'))}</h4><Link to={`/perf-profile?uid=${data.uid}`}>{t(textConvert('See all'))}</Link>
                          </div>
                          <ViewCaddiePerf userId={data.uid} />
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-xl-8 col-xxl-9 col-lg-8 px-sm-3 px-0">
                      <FetchUserPost user={data} />
                    </div>
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "80vh" }}
                  >
                    <div className="text-center">
                      <i
                        className="bi bi-person-slash"
                        style={{ fontSize: "40px" }}
                      ></i>
                      <h2 className="fw-bold m-0">{t(textConvert('This user is unavailable'))}</h2>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <div className="spinner-grow text-current" role="status"></div>
        </div>
      )}
    </>
  );
}

export default CaddieProfile;
