import React, { useEffect, useState } from 'react'
import { firestore } from '../../components/firebase/FirebaseConfig';
import { doc, collection, query, getDocs, where, onSnapshot, addDoc, updateDoc, serverTimestamp, or, and } from 'firebase/firestore';
import ScoreTopNav from './component/ScoreTopNav';
import { useScore } from './ScoreContext/ScoreContext';
import SelectHoleCourse from './component/SelectHoleCourse';

import { create9Holes, create18Holes } from './scoring/module/createHoleObj';

import { useSelector } from 'react-redux';
import LoadCircle from '../../components/LoadCircle';
import { useNavigate } from 'react-router-dom';
import ScoreTable from './component/ScoreTable';
import MatchPlayWarning from './component/MatchPlayWarning';
const ScoreMain = ({ roundId }) => {

    const userData = useSelector((state) => state.user.data);

    const { roundData, setRoundData, setScore, mappedScore, mappedUser, playerGameMode } = useScore();

    const [created, setCreated] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        if (!roundId) return;

        const roundDoc = doc(firestore, 'round', roundId);

        // Set up the Firestore real-time listener
        const unsubscribe = onSnapshot(roundDoc, async (roundSnapshot) => {
            if (roundSnapshot.exists()) {
                const data = roundSnapshot.data();
                await getUserData(data.userList);
                setRoundData(data);
            } else {
                console.warn('round not found', roundId);
                navigate('/round-activity')
            }
        }, (error) => {
            console.error('Fail to get round data', error);
        });

        async function getUserData(userList) {
            const userListCopy = [...userList];
            const userUidList = [];

            userList.forEach(user => {
                if (user.player) {
                    userUidList.push(user.player.uid);
                }
                if (user.caddie) {
                    userUidList.push(user.caddie.uid);
                }
            });

            try {
                const userCollection = collection(firestore, 'users');
                const q = query(userCollection, where('uid', 'in', userUidList));
                const userSnapshot = await getDocs(q);

                if (!userSnapshot.empty) {
                    userSnapshot.forEach(doc => {
                        const firestoreUserData = doc.data();
                        const firestoreUid = firestoreUserData.uid;

                        userListCopy.forEach(user => {
                            if (user.player && user.player.uid === firestoreUid) {
                                user.player = {
                                    ...user.player,
                                    ...firestoreUserData
                                };
                            }
                            if (user.caddie && user.caddie.uid === firestoreUid) {
                                user.caddie = {
                                    ...user.caddie,
                                    ...firestoreUserData
                                };
                            }
                        });
                    });
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        }

        return () => unsubscribe();

    }, [roundId]);


    // console.log(roundData);

    // create score card if don't existed
    useEffect(() => {
        async function getScore() {
            try {
                const qCondition = [
                    where('roundId', '==', roundId)
                ];

                if (userData.role === 'caddie') {
                    qCondition.push(
                        where('caddieId', '==', userData.uid)
                    )
                } else {
                    qCondition.push(
                        where('userId', '==', userData.uid)
                    )
                }

                const scoreCollection = collection(firestore, 'scoreCards');
                const q = query(scoreCollection, ...qCondition);
                const scoreSnapshot = await getDocs(q);

                if (scoreSnapshot.empty && roundData.holesCourse !== "") {

                    // Create holes object based on round type
                    const holeObj = roundData.holes === '9 holes' ? create9Holes() : create18Holes();

                    // Find the user or caddie
                    const foundUser = roundData.userList.find(({ player, caddie }) =>
                        (player?.uid === userData.uid) || (caddie?.uid === userData.uid)
                    );

                    if (!foundUser) {
                        navigate('/round-activity');
                        return
                    }

                    // Destructure properties for cleaner access
                    const { player, caddie, row } = foundUser || {};

                    // Create score object
                    const scoreObj = {
                        ...holeObj,
                        userId: player?.isJoin ? player.uid : null,
                        caddieId: caddie?.isJoin ? caddie.uid : null,
                        roundId,
                        status: 'playing',
                        created: serverTimestamp(),
                        teebox: {
                            color: 'not_set',
                            courseRating: 72, //default 
                            slopeRating: 113, //default
                        },
                        row
                    };

                    // Add document to collection and update with its document ID
                    const docRef = await addDoc(scoreCollection, scoreObj);
                    await updateDoc(docRef, { docId: docRef.id });
                } else {
                    if (scoreSnapshot.size > 1) {
                        console.warn('found similar doc more than one');
                    }
                }
            } catch (error) {
                setCreated(false);
                console.error('Fail to get or create score', error);
            } finally {
                setCreated(true)
            }
        }

        if (roundId && userData && roundData) {
            getScore()
        }

    }, [roundId, userData, roundData])


    // listen to score card player
    useEffect(() => {

        if (!roundData || !roundId) return;

        const userList = []
        roundData.userList.forEach(user => {
            if (user.player) {
                userList.push(user.player.uid);
            }

            if (user.caddie) {
                userList.push(user.caddie.uid);
            }
        });
        if (!userList.length) {
            console.warn('no user found');
            navigate('/round-activity');
        }

        const q = query(collection(firestore, "scoreCards"),
            and(
                or(
                    where("userId", "in", userList),
                    where("caddieId", "in", userList),
                ),
                where('roundId', '==', roundId),
            ));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const scoreList = []
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                scoreList.push({
                    ...data,
                    docId: doc.id
                });
            });

            setScore(scoreList);
            setCreated(true)
        });
        return () => unsubscribe();
    }, [roundId, roundData])


    const data = {
        nodes: [...mappedScore]
    }

    if (created) {
        return (
            <>
                <ScoreTopNav />

                <div className='overflow-auto py-1'>

                    <SelectHoleCourse />

                    {roundData ?
                        <>
                            <ScoreTable
                                mappedScore={data}
                                mappedUser={mappedUser}
                            />
                        </>
                        :
                        null
                    }
                </div>
                <MatchPlayWarning />
            </>
        );
    } else {
        return (
            <div className='d-flex justify-content-center p-5'>
                <LoadCircle size='md' variant='dark' />
            </div>
        )
    }
}

export default ScoreMain