import { and, collection, getDocs, or, query, where } from "firebase/firestore"
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { getUserLabel } from "./fetchFriends";
import { getFavCaddie, getFavPlayer } from "./fetchFavorite";
import { mapFavUser, mapUserlabel } from "./mapUser";

export const searchQuery = async (userData, searchObj, lableList) => {
    const text = searchObj?.text?.value.trim();
    if (!text) return []; // Return early if no search text

    try {
       
        const userQuery = query(
            collection(firestore, "users"),
            or(
                and(
                    where("firstName", ">=", text),
                    where('firstName', '<=', text + '\uf8ff')
                ),
                and(
                    where("lastName", ">=", text),
                    where('lastName', '<=', text + '\uf8ff')
                ),
                and(
                    where("dooNineId", ">=", text),
                    where('dooNineId', '<=', text + '\uf8ff')
                ),
                where("role", "==", text),
            ),

        );

        const userSnap = await getDocs(userQuery);
        // Merge results while avoiding duplicates
        const results = new Map();
        if (!userSnap.empty) {
            userSnap.forEach((doc) => results.set(doc.id, { id: doc.id, ...doc.data() }));
        }
        const userIds = Array.from(results.values().map(item => item.uid))
        const userDataList = Array.from(results.values())

        const friendList = await getFriend(userData, userIds, userDataList, lableList)

        return friendList;

    } catch (error) {
        console.error("Error searching users:", error);
        return [];
    }
};

const getFriend = async (userData, userIds, userDataList, lableList) => {
    if (!userIds.length) return [];

    const chunkSize = 10; // Firestore limit for 'in' queries
    const userChunks = chunkArray(userIds, chunkSize);
    let friendList = [];

    try {
        for (const chunk of userChunks) {
            const q = query(collection(firestore, "friends"),
                and(
                    or(
                        and(
                            where("userId", "==", userData.uid),
                            where("friendId", "in", chunk),
                        ),
                        and(
                            where("userId", "in", chunk),
                            where("friendId", "==", userData.uid),
                        ),
                    ),
                    where('status', '==', 'active'),
                )
            );

            const friendSnap = await getDocs(q);
            if (!friendSnap.empty) {
                friendSnap.forEach(doc => {
                    const data = doc.data();
                    if (data.friendId === userData.uid) {
                        friendList.push(data.userId);
                    } else {
                        friendList.push(data.friendId);
                    }
                });
            }
        }
        const [label, favCaddie, favPlayer] = await Promise.all([
            getUserLabel(userData, friendList),
            getFavCaddie(userData, friendList),
            getFavPlayer(userData, friendList)
        ]);

        const filteredUser = userDataList.filter(item => friendList.includes(item.uid));

        const mergedFav = [...favCaddie, ...favPlayer];
        const mappedFav = mapFavUser(filteredUser, mergedFav);
        const mappedLabel = mapUserlabel(mappedFav, label, lableList);

        return mappedLabel;

    } catch (error) {
        console.error("Error searching users:", error);
        return [];
    }

};

const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
        chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
};
