import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { firestore } from "../../../../../components/firebase/FirebaseConfig";
import { epochToDateTime } from "../../../component/wizard/module/covertTime";

export const getDataIsPlayer = async (
  userData,
  setListRound,
  setLoading,
  lastVisibleDoc = null,
  append = false
) => {
  if (!userData) throw new Error("userData is required");

  setLoading(true);
  let nextVisibleDoc = lastVisibleDoc;
  let filteredRounds = [];

  try {
    const userQuery = query(
      collection(firestore, "friends"),
      where("userId", "==", userData.uid),
      where("status", "==", "active")
    );

    const userQuery2 = query(
      collection(firestore, "friends"),
      where("friendId", "==", userData.uid),
      where("status", "==", "active")
    );

    const [userSnapshot, friendSnapshot] = await Promise.all([
      getDocs(userQuery),
      getDocs(userQuery2),
    ]);

    const friendIds = [
      ...userSnapshot.docs.map((doc) => doc.data().friendId),
      ...friendSnapshot.docs.map((doc) => doc.data().userId),
    ];

    const uniqueFriendIds = [...new Set(friendIds)];
    if (uniqueFriendIds.length === 0) {
      setListRound([]);
      setLoading(false);
      return null;
    }

    const playerQuery = query(
      collection(firestore, "users"),
      where("uid", "in", uniqueFriendIds),
      where("role", "==", "player")
    );

    const playerSnapshot = await getDocs(playerQuery);
    const playerIds = playerSnapshot.docs.map((doc) => doc.data().uid);
    if (playerIds.length === 0) {
      setListRound([]);
      setLoading(false);
      return null;
    }

    let roundQueryConstraints = [
      where("creator", "in", playerIds),
      orderBy("scheduleTime", "desc"),
      limit(5),
    ];

    if (nextVisibleDoc) {
      roundQueryConstraints.push(startAfter(nextVisibleDoc));
    }

    const roundQuery = query(
      collection(firestore, "round"),
      ...roundQueryConstraints
    );
    const roundSnapshot = await getDocs(roundQuery);

    if (roundSnapshot.empty) {
      setListRound([]);
      setLoading(false);
      return null;
    }

    nextVisibleDoc = roundSnapshot.docs[roundSnapshot.docs.length - 1];

    const filteredRoundsData = await Promise.all(
      roundSnapshot.docs.map(async (doc) => {
        const roundData = doc.data();
        const hasEmptySlot = roundData.userList.some(
          (slot) => slot.player === null
        );
        const isUserInRound = roundData.userList.some(
          (slot) => slot.player?.uid === userData.uid
        );
        if (!hasEmptySlot || isUserInRound || roundData.status === "deleted")
          return null;
        return roundData;
      })
    );

    filteredRounds = filteredRoundsData.filter((round) => round !== null);
    const checkFinishedRound = await checkPlayingRounds(filteredRounds);
    const roundsWithDetails = await addCourseAndHolesData(checkFinishedRound);
    const roundIsPlaying = await fetchUserDetails(roundsWithDetails);
    const roundsRecent = await filterRecentRounds(roundIsPlaying);

    setListRound((prevList) =>
      append ? [...prevList, ...roundsRecent] : roundsRecent
    );

    // Stop loading when all data is fetched
    if (filteredRounds.length === 0) {
      setLoading(false);
    }
  } catch (error) {
    console.error("Error fetching players:", error);
    throw error;
  } finally {
    setLoading(false);
  }
  return nextVisibleDoc;
};

export const getDataIsCaddie = async (
  userData,
  setListRound,
  setLoading,
  lastVisibleDoc = null,
  append = false
) => {
  if (!userData) throw new Error("userData is required");

  setLoading(true);
  let nextVisibleDoc = lastVisibleDoc;
  let filteredRounds = [];

  try {
    const caddieQuery = query(
      collection(firestore, "caddieRequests"),
      where("caddieId", "==", userData.uid),
      where("status", "!=", "removed")
    );

    const caddieSnapshot = await getDocs(caddieQuery);
    const courseIds = caddieSnapshot.docs.map((doc) => doc.data().courseId);
    if (courseIds.length === 0) {
      setListRound([]);
      setLoading(false);
      return null;
    }

    let roundQueryConstraints = [
      where("course", "in", courseIds),
      orderBy("scheduleTime", "desc"),
      limit(5),
    ];

    if (nextVisibleDoc) {
      roundQueryConstraints.push(startAfter(nextVisibleDoc));
    }

    const roundQuery = query(
      collection(firestore, "round"),
      ...roundQueryConstraints
    );
    const roundSnapshot = await getDocs(roundQuery);

    if (roundSnapshot.empty) {
      setListRound([]);
      setLoading(false);
      return null;
    }

    nextVisibleDoc = roundSnapshot.docs[roundSnapshot.docs.length - 1];

    const filteredRoundsData = await Promise.all(
      roundSnapshot.docs.map(async (doc) => {
        const roundData = doc.data();
        if (roundData.caddieReq === "disable" || roundData.status === "deleted")
          return null;
        const hasEmptySlot = roundData.userList.some(
          (slot) => slot.caddie === null
        );
        const isUserInRound = roundData.userList.some(
          (slot) => slot.caddie?.uid === userData.uid
        );
        if (!hasEmptySlot || isUserInRound) return null;
        if (roundData.caddieReq === "favorite") {
          const favQuery = query(
            collection(firestore, "favoriteFriends"),
            where("caddieId", "==", userData.uid),
            where("userId", "==", roundData.creator)
          );
          const favSnapshot = await getDocs(favQuery);
          const isFavorite = favSnapshot.docs.some(
            (doc) => doc.data().status === "active"
          );
          if (!isFavorite) return null;
        }
        return roundData;
      })
    );

    filteredRounds = filteredRoundsData.filter((round) => round !== null);
    const checkFinishedRound = await checkPlayingRounds(filteredRounds);
    const roundsWithDetails = await addCourseAndHolesData(checkFinishedRound);
    const roundIsPlaying = await fetchUserDetails(roundsWithDetails);
    const roundsRecent = await filterRecentRounds(roundIsPlaying);

    setListRound((prevList) =>
      append ? [...prevList, ...roundsRecent] : roundsRecent
    );

    // Stop loading when all data is fetched
    if (filteredRounds.length === 0) {
      setLoading(false);
    }
  } catch (error) {
    console.error("Error fetching caddie rounds:", error);
    throw error;
  } finally {
    setLoading(false);
  }
  return nextVisibleDoc;
};

const checkPlayingRounds = async (filteredRounds) => {
  const promises = filteredRounds.map(async (round) => {
    try {
      // if (!round.creator || !round.roundId) {
      //   console.warn(`Skipping round due to missing data:`, round);
      //   return null;
      // }

      const caddieQuery = query(
        collection(firestore, "scoreCards"),
        where("caddieId", "==", round.creator),
        where("roundId", "==", round.roundId)
      );

      const playerQuery = query(
        collection(firestore, "scoreCards"),
        where("userId", "==", round.creator),
        where("roundId", "==", round.roundId)
      );

      const [caddieSnapshot, playerSnapshot] = await Promise.all([
        getDocs(caddieQuery),
        getDocs(playerQuery),
      ]);

      // ถ้าไม่มีข้อมูลในทั้งสอง query ให้คืนค่ารอบเดิม
      if (caddieSnapshot.empty && playerSnapshot.empty) {
        return round;
      }

      // ตรวจสอบว่า status เป็น "playing" หรือไม่
      const isPlaying = [...caddieSnapshot.docs, ...playerSnapshot.docs].some(
        (doc) => {
          const scoreData = doc.data();
          return scoreData.status === "playing";
        }
      );

      return isPlaying ? round : null;
    } catch (error) {
      console.error(`Error checking round ${round.roundId}:`, error);
      return null;
    }
  });

  const results = await Promise.all(promises);
  return results.filter((round) => round !== null);
};

const fetchUserDetails = async (checkFinishedRound) => {
  const promises = checkFinishedRound.map(async (round) => {
    const uids = [];

    round.userList.forEach((slot) => {
      if (slot?.player?.uid) uids.push(slot.player.uid);
      if (slot?.caddie?.uid) uids.push(slot.caddie.uid);
    });

    const uniqueUids = [...new Set(uids)];

    const userDataPromises = uniqueUids.map(async (uid) => {
      const userQuery = query(
        collection(firestore, "users"),
        where("uid", "==", uid)
      );

      const userSnapshot = await getDocs(userQuery);

      if (!userSnapshot.empty) {
        const user = userSnapshot.docs[0].data();

        const isCreator = round.userList.some(
          (slot) => slot.player?.uid === user.uid && slot.player?.isCreator
        );

        return { ...user, isCreator: isCreator || false };
      }
      return null;
    });

    const userData = (await Promise.all(userDataPromises)).filter(
      (user) => user !== null
    );

    return { ...round, userData };
  });

  const roundsWithUserData = await Promise.all(promises);
  return roundsWithUserData;
};

const addCourseAndHolesData = async (rounds) => {
  const promises = rounds.map(async (round) => {
    let holesCourse = "";

    if (round?.holesCourse) {
      const courseHolesRef = doc(firestore, "courseHoles", round.holesCourse);
      const courseHolesSnapshot = await getDoc(courseHolesRef);
      holesCourse = courseHolesSnapshot.exists()
        ? courseHolesSnapshot.data().courseHoleName
        : "";
    }

    const courseSnapshot = await getDocs(
      query(collection(firestore, "courses"), where("id", "==", round.course))
    );

    const courseData = courseSnapshot.empty
      ? null
      : courseSnapshot.docs[0].data();

    const { time, day, month } = epochToDateTime(round.scheduleTime);

    return {
      ...round,
      holesCourse,
      courseInfo: courseData,
      time,
      day,
      month,
    };
  });

  return Promise.all(promises);
};

function filterRecentRounds(rounds) {
  // เวลาปัจจุบันในหน่วยวินาที
  const now = Math.floor(Date.now() / 1000);

  // กรองข้อมูลที่ scheduleTime ยังไม่เกิน 24 ชั่วโมง
  return rounds.filter((round) => {
    const scheduleTime = round.scheduleTime;
    // เช็คว่าเวลาปัจจุบัน - scheduleTime น้อยกว่า 24 ชั่วโมง (86400 วินาที)
    return now - scheduleTime <= 86400;
  });
}
