
import { where, collection, query, getDocs, deleteDoc } from "firebase/firestore";
import { firestore } from "../firebase/FirebaseConfig";
import Swal from "sweetalert2";
import { textConvert } from "../language/dropdown/textconvert";

export const UnFriend = async (userId, friendId, t) => {
    let friendData = {};

    try {
        const userCollection = collection(firestore, 'users');
        const q = query(userCollection, where('uid', '==', friendId));
        const friendSnapshot = await getDocs(q);

        if (friendSnapshot.empty) {
            console.error('Not found this friend', friendId);
            return false; // Return false if friend not found
        } else {
            friendData = friendSnapshot.docs[0].data();
        }
    } catch (error) {
        console.error('Failed to get friend data', error);
        return false; // Return false on error
    }

    const text = ['Do you want to', 'Unfriend', 'with', '?']
    const textBtn = ['Yes', 'No'];

    if (t !== undefined) {
        text[0] = t(textConvert('Do you want to'));
        text[1] = t(textConvert('Unfriend'));
        text[2] = t(textConvert('with'));
        text[3] = t(textConvert('yes or not'));

        textBtn[0] = t(textConvert('Yes'));
        textBtn[1] = t(textConvert('No'));
    }


    const result = await Swal.fire({
        icon: 'warning',
        title: 'Warning',
        html: `<div>${text[0]} <span class="text-danger">${text[1]}</span> ${text[2]} <strong>${friendData?.firstName || ""} ${friendData?.lastName || ""}</strong> ${text[3]}</div>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#ee3c7f',
        confirmButtonText: textBtn[0],
        cancelButtonText: textBtn[1]
    });

    if (!result.isConfirmed) return false; // Return false if the user cancels

    // Proceed with deleting friend requests and friendships
    const reqCollection = collection(firestore, "friendRequests");
    const friendCollection = collection(firestore, "friends");

    const queryFromFriendToUser = query(
        reqCollection,
        where("fromUserId", "==", friendId),
        where("toUserId", "==", userId)
    );

    const queryFromUserToFriend = query(
        reqCollection,
        where("fromUserId", "==", userId),
        where("toUserId", "==", friendId)
    );

    const queryRelation1 = query(
        friendCollection,
        where("friendId", "==", friendId),
        where("userId", "==", userId)
    );

    const queryRelation2 = query(
        friendCollection,
        where("friendId", "==", userId),
        where("userId", "==", friendId)
    );

    try {
        const [snapshotFromFriendToUser, snapshotFromUserToFriend] = await Promise.all([
            getDocs(queryFromFriendToUser),
            getDocs(queryFromUserToFriend)
        ]);

        const friendRequestResults = [
            ...snapshotFromFriendToUser.docs,
            ...snapshotFromUserToFriend.docs
        ];

        const friendRequestDeletions = friendRequestResults.map(doc => deleteDoc(doc.ref));
        await Promise.all(friendRequestDeletions);

        const [snapshotRelation1, snapshotRelation2] = await Promise.all([
            getDocs(queryRelation1),
            getDocs(queryRelation2)
        ]);

        const friendshipResults = [
            ...snapshotRelation1.docs,
            ...snapshotRelation2.docs
        ];

        const friendshipDeletions = friendshipResults.map(doc => deleteDoc(doc.ref));
        await Promise.all(friendshipDeletions);

        return true; // Return true after successful deletion
    } catch (error) {
        console.error("Error unfriending:", error);
        return false; // Return false if an error occurs
    }
};
