import { firestore } from "../firebase/FirebaseConfig"
import { collection, addDoc, where, query, getDocs, serverTimestamp, deleteDoc } from "firebase/firestore"
import axios from "axios";

export const blockFriend = async (userId, blockId) => {
    try {
        const q1 = query(collection(firestore, "blockedUsers"), where("blockedUserId", "==", blockId), where("userId", "==", userId));
        const q2 = query(collection(firestore, "blockedUsers"), where("blockedUserId", "==", userId), where("userId", "==", blockId));

        const querySnapshot1 = await getDocs(q1);
        const querySnapshot2 = await getDocs(q2);

        if (querySnapshot1.empty && querySnapshot2.empty) {
            await addDoc(collection(firestore, "blockedUsers"), {
                blockedUserId: blockId,
                userId: userId,
                timestamp: serverTimestamp()
            });

            let myDoonineId = '';
            let friendDoonineId = '';

            const q = query(collection(firestore, "users"), where("uid", "==", userId));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                myDoonineId = doc.data().dooNineId;
            });

            const q2 = query(collection(firestore, "users"), where("uid", "==", blockId));
            const querySnapshot2 = await getDocs(q2);
            querySnapshot2.forEach((doc) => {
                friendDoonineId = doc.data().dooNineId;
            });


            try {
                const response = await axios.get(`https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels?members_exactly_in=${myDoonineId}&show_member=false&members_exactly_in=${friendDoonineId}`, {
                    headers: {
                        'Content-Type': 'application/json; charset=utf8',
                        'Api-Token': '1a3a0f2103a01d56c01cde731a802118945aeeb4'
                    }
                });

                for (let index = 0; index < response.data.channels.length; index++) {
                    const element = response.data.channels[index];
                    await axios.delete(
                        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${element.channel_url}`,

                        {
                            headers: {
                                "Content-Type": "application/json; charset=utf8",
                                "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
                            },
                        }
                    );
                }

            } catch (error) {
                console.log(error)
            }

            return true;
        } else {
            console.warn('Alredy block this user');
            return false;
        }
    } catch (error) {
        console.error('Error blocking user', error);
        return false;
    }
}

export const unblockFriend = async (userId, blockId) => {
    try {
        const blockedUsersRef = collection(firestore, "blockedUsers");
        const q = query(blockedUsersRef, where('userId', '==', userId), where("blockedUserId", "==", blockId));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
            return false;
        }

        const deletePromises = querySnapshot.docs.map(docSnapshot => deleteDoc(docSnapshot.ref));
        await Promise.all(deletePromises);

        return true;
    } catch (error) {
        console.error('Failed to unblock user', error);
        return false;
    }
};
