import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUser } from "../../components/redux/feature/user/userSlice";
import axios from "axios";
import { sendLinePlainText } from "../../components/line/SendLinePlainText";
import Swal from "sweetalert2";
import ReactSwitch from "react-switch";
const liff = window.liff;

const switchStyle = {
  width: 44,
  height: 20,
  onColor: "#EE3D7F",
  boxShadow: "0 0 2px 1px #fff",
  handleDiameter: 16,
};

function LineConnect() {
  const userData = useSelector((state) => state.user.data);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profile, setProfile] = useState(null);
  const dispatch = useDispatch();
  // const [userAgentText, setUserAgentText] = useState(navigator.userAgent);

  useEffect(() => {
    const initializeLiff = async () => {
      try {
        console.log("Initializing LIFF...");
        await liff.init({
          liffId: "2006737261-OpdVbJN2",
          redirectUri: "https://doonine.com/line-connect",
        });
        if (liff.isLoggedIn()) {
          console.log("Line Logout");
          liff.logout();
        }
      } catch (error) {
        console.error("Error initializing LIFF:", error);
      }
    };

    initializeLiff();
  }, []);

  const handleConnectNotification = () => {
    const { userAgent } = navigator;

    if (!liff.isInClient() && userAgent.includes("Line")) {
      navigate("/line-connect-enable");
    } else {
      window.location.href = "https://liff.line.me/2006737261-OpdVbJN2";
    }
  };

  const handleDisconnectNotification = async () => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const res = await sendLinePlainText(
      userData.lineUid,
      // "คุณได้ยกเลิกการแจ้งเตือนกิจกรรมต่างๆ ผ่าน Line Message"
      "คุณได้ยกเลิกการเชื่อมต่อ doonine กับ Line Message"
    );

    // console.log(res);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_LINE_NOTIFY}/v1/rich-menu/set`,
        {
          state: "enable",
          lineUid: userData.lineUid,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_LINE_NOTIFY_TOKEN}`,
          },
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    dispatch(
      updateUser(userData.uid, {
        lineUid: "",
      })
    );

    Swal.close();
  };

  const handleLineNotification = async () => {
    dispatch(
      updateUser(userData.uid, {
        notification: {
          ...userData.notification,
          line: !userData?.notification?.line,
        },
      })
    );
    try {
      if (!userData?.notification?.line) {
        const res = await sendLinePlainText(
          userData.lineUid,
          "คุณได้เปิดรับการแจ้งเตือนกิจกรรมต่างๆ ผ่าน Line Message"
        );
        // console.log(res);
      } else {
        const res = await sendLinePlainText(
          userData.lineUid,
          "คุณได้ยกเลิกการแจ้งเตือนกิจกรรมต่างๆ ผ่าน Line Message"
        );
        // console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link to="/defaultsettings" className="d-inline-block mt-2">
                    <i className="ti-arrow-left font-sm text-white"></i>
                  </Link>
                  <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                    Line Notification
                  </h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 text-center">
                      <figure
                        className="avatar ms-auto me-auto mb-0 mt-2 position-relative z-index-1 me-3"
                        style={{
                          flexShrink: 0,
                          justifyContent: "center",
                          display: "flex",
                          width: "120px",
                          height: "120px",
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          src={userData?.profileImage}
                          alt="avatar"
                          className="bg-image-cover float-left p-1 rounded-circle w-100"
                          style={{
                            cursor: "pointer",
                            objectFit: "cover",
                            backgroundColor:
                              userData?.role == "caddie"
                                ? "#EE3D7F"
                                : "#4A74E5",
                          }}
                        />
                      </figure>

                      <h2 className="fw-700 font-sm text-grey-900 mt-3">
                        {userData?.firstName || userData?.firstname}{" "}
                        {userData?.lastName || userData?.lastname}
                      </h2>

                      {!userData?.lineUid ? (
                        <h3 className="fw-600 text-grey-700 mt-4">
                          รับการแจ้งเตือนกิจกรรมต่างๆ ผ่าน Line Message
                        </h3>
                      ) : (
                        <>
                          <ul className="mb-4 mt-4">
                            <li>
                              <div className="d-flex justify-content-between mb-2">
                                <span htmlFor="auto-logout">
                                  <h4 className="fw-600 mb-0 mt-0">
                                    การแจ้งเตือนกิจกรรมต่างๆ ผ่าน Line Message
                                  </h4>
                                </span>
                                <ReactSwitch
                                  onChange={() => handleLineNotification()}
                                  checked={userData?.notification?.line}
                                  onColor={switchStyle.onColor}
                                  width={switchStyle.width}
                                  height={switchStyle.height}
                                  handleDiameter={switchStyle.handleDiameter}
                                  activeBoxShadow={switchStyle.boxShadow}
                                />
                              </div>
                            </li>
                          </ul>

                          <hr></hr>

                          <h3 className="fw-600 text-grey-700 mt-4">
                            ยกเลิกการเชื่อมต่อผ่าน Line Message
                          </h3>
                        </>
                      )}

                      <div className="avatar ms-auto me-auto mb-0 mt-4 position-relative z-index-1 me-3">
                        {!userData?.lineUid ? (
                          <button
                            className={clsx(
                              "btn bg-current text-white fw-500 fs-3 py-2 px-3"
                            )}
                            style={{ minWidth: "6rem" }}
                            onClick={handleConnectNotification}
                          >
                            เชื่อมต่อ
                          </button>
                        ) : (
                          <button
                            className={clsx(
                              "btn bg-greylight fw-500 fs-3 py-2 px-3"
                            )}
                            style={{ minWidth: "6rem" }}
                            onClick={handleDisconnectNotification}
                          >
                            ยกเลิกการเชื่อมต่อ
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <p style={{ color: "#e7e7e7" }}>{userAgentText}</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popupchat />
      <Appfooter />
    </>
  );
}

export default LineConnect;
