import { ChatTo } from "../../../components/chat/Chat";
import { favFriend, favFriendCaddie } from "../../../components/friend/FavFriend";

export const handleChat = async (userData, firendDooNineId, navigate) => {
    const result = await ChatTo(userData.dooNineId, firendDooNineId);
    if (result) {
        navigate("/chat-room?channelId=" + result[0].channel_url);
    }
};

export const handleFav = async (userData, isCaddie, friend, favType) => {
    if (userData && friend.uid) {
        if (favType === "fav") {
            if (isCaddie === "caddie") {
                await favFriendCaddie(userData?.uid, friend.uid);
            } else {
                await favFriend(userData?.uid, friend.uid);
            }
        } else {

            if (isCaddie === "caddie") {
                await favFriendCaddie(userData?.uid, friend.uid);
            } else {
                await favFriend(userData?.uid, friend.uid);
            }
        }
    }
};