import React from 'react'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import { sumPar } from '../../scoring/module/scoreCalculation'
import { useTranslation } from 'react-i18next'
import { textConvert } from '../../../../components/language/dropdown/textconvert'

function LeaderboardTotalPar({ roundData, courseHoles }) {
    const { t } = useTranslation()
    return (
        <div className='d-flex flex-column justify-content-center align-items-center'>
            <Link to={`/leader-board?round=${roundData.roundId}`} className='pointer theme-dark-bg text-center bg-light rounded-3 mb-2 p-1 w-100'>
                <ReactSVG src='assets/images/dooNineIconV3/svg-scorecard-030924/scorecard_leader_board.svg' wrapper='svg' width={30} height={40} />
                <h6 className='fw-600 text-wrap text-center' style={{ fontSize: 11 }}>{t(textConvert('Leader Board'))}</h6>
            </Link>
            <div className='theme-dark-bg bg-light text-wrap text-center rounded-3 p-1 w-100'>
                <h6 className='fw-600'>{t(textConvert('Total Par'))} {sumPar(courseHoles)}</h6>
            </div>
        </div>
    )
}

export default LeaderboardTotalPar