import React, { useEffect, useRef } from 'react'
import Stack from 'react-bootstrap/Stack';
import { useFilter } from '../module/filterContext';
import { getFacetFilters, getNumericFilters } from '../module/filterReducer';

function FilterStackV2() {
    const renderCountRef = useRef(0);

    const { filterState, dispatch, trigger, setnumericFilters, setFacet } = useFilter();

    const handleRemoveFilter = (val) => {
        val = val.toUpperCase();
        dispatch({ type: val });
        trigger();
    };

    useEffect(() => {
        // unsure re-render will happen or not
        renderCountRef.current += 1;
        if (renderCountRef.current > 200) {
            console.warn('too many render at FilterStackV2');
            return
        }

        const newFacetFilters = getFacetFilters(filterState);
        const newNumericFilters = filterState.price ? getNumericFilters(filterState) : [];

        setFacet(newFacetFilters);
        setnumericFilters(newNumericFilters);

    }, [filterState, setFacet, setnumericFilters])


    return (
        <Stack direction="horizontal" gap={3}>
            {(() => {
                const trueList = Object.keys(filterState).filter(key => filterState[key] && key !== 'price_min' && key !== 'price_max');
                // console.log(trueList);
                if (trueList.length) {
                    return (
                        <>
                            {trueList.map(item =>
                                <div className='d-inline-flex align-items-center rounded-pill bg-light mx-2 my-1 px-3' key={item}>
                                    {stackIconDef(item, filterState)}
                                    <h6 className='fw-600'>
                                        {stackTypeDef(item, filterState)}
                                    </h6>
                                    <i className='bi-x ms-1 fs-2 pointer' onClick={() => handleRemoveFilter(item)}></i>
                                </div>
                            )}
                        </>
                    )
                }
            })()}
        </Stack>
    )
}

export default FilterStackV2

function stackTypeDef(type, filterState) {
    switch (type) {
        case 'official':
            return 'Offcial Course';

        case 'price':
            return `Starting Price : ${filterState.price_min} to ${filterState.price_max}`;

        case 'night':
            return 'Night Golf';

        case 'drive':
            return 'Driving Range';

        case 'restaurant':
            return 'Restaurant';

        case 'banquet':
            return 'Banquet Rooms';

        case 'hotel':
            return 'Hotel';

        case 'loc':
            return filterState.loc;

        default:
            break;
    }
}
function stackIconDef(type, filterState) {
    switch (type) {
        case 'official':
        case 'price':
        case 'night':
        case 'drive':
        case 'restaurant':
        case 'banquet':
        case 'hotel':
            return <i className='bi bi-funnel me-2'></i>;

        case 'loc':
            if (filterState.loc === 'Near Me') {
                return <i className="bi bi-crosshair me-2"></i>;
            } else {

                return <i className="bi bi-geo-alt me-2"></i>;
            }

        default:
            break;
    }
}