import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { collection, doc, getDoc, getDocs, query, Timestamp, where } from 'firebase/firestore'
import { firestore } from '../../../components/firebase/FirebaseConfig'
import { getCompletedRound, getGir, getPuttPerHole } from '../myperfprofile/module/statCalculation'
import LoadCircle from '../../../components/LoadCircle'
import { useTranslation } from 'react-i18next'
import { textConvert } from '../../../components/language/dropdown/textconvert'
export const ViewPlayerPerf = ({ userId }) => {
    const { t } = useTranslation();
    const [bestScore, setBestScore] = useState(null);
    const [puttPerHole, setPuttsPerHole] = useState(0);
    const [gir, setGir] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        async function getStat(getRoundData, getCourseData) {
            try {
                const statCollection = collection(firestore, 'playerStatistic');
                const q = query(statCollection, where('userId', '==', userId));
                const statSnapshot = await getDocs(q);
                if (!statSnapshot.empty) {
                    const statData = statSnapshot.docs[0].data();
                    const { roundData, courseData } = await getRoundData(statData.bestScore, getCourseData);
                    setBestScore({
                        statData: {
                            ...statData,
                            bestScore: { bestHoleScore: !roundData ? '-' : statData.bestScore.bestHoleScore }

                        },
                        roundData: roundData,
                        courseData: courseData
                    })
                } else {
                    setBestScore({
                        statData: { bestScore: { bestHoleScore: '-' } }
                    })
                    setGir('-');
                    setLoading(false);
                    setPuttsPerHole('-')
                }

                const completeRound = await getCompletedRound(userId, 0);
                const puttsPerHole = getPuttPerHole(completeRound);
                setPuttsPerHole(puttsPerHole);

                const gir = getGir(completeRound);
                setGir(gir);
                setLoading(false);
            } catch (error) {
                console.error('Fail to get stat', error);
                setBestScore({
                    statData: { bestScore: { bestHoleScore: '-' } }
                })
                setGir('-');
                setLoading(false);
                setPuttsPerHole('-')
            }
        }

        async function getRoundData(bestScore, getCourseData) {

            if (typeof bestScore !== 'object') {
                return { roundData: null, courseData: null }
            }
            const roundDoc = doc(firestore, 'round', bestScore.roundId);
            const roundSnapshot = await getDoc(roundDoc);
            if (roundSnapshot.exists()) {
                const roundData = roundSnapshot.data();
                const courseData = await getCourseData(roundData.course);
                return { roundData, courseData }
            } else {
                console.warn('not found round id: ', bestScore.roundId);
                return { roundData: null, courseData: null }
            }
        }

        async function getCourseData(courseId) {
            const courseDoc = doc(firestore, 'courses', courseId);
            const courseSnapshot = await getDoc(courseDoc);
            if (courseSnapshot.exists()) {
                const courseData = courseSnapshot.data();
                return courseData
            } else {
                console.warn('not found course id: ', courseId);
                return {}
            }
        }

        if (userId) {
            getStat(getRoundData, getCourseData);
        }

    }, [userId]);

    return (
        <>
            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3 mb-2 position-relative" style={{ backgroundColor: '#FDE7EF', fontWeight: 700, fontSize: 14 }}>
                <div className='position-absolute fw-normal fs-6' style={{ top: 0, right: '5px' }}>
                    {!loading &&
                        <>
                            {moment.unix(bestScore?.roundData?.scheduleTime).format('DD MMM YY')}
                        </>
                    }
                </div>
                <strong className="p-2 rounded-3 text-white col-4 text-center" style={{ fontSize: 20, backgroundColor: '#EE3C7F' }}>
                    {!loading ?
                        <>
                            {bestScore?.statData?.bestScore?.bestHoleScore}
                        </>
                        :
                        <div className=' d-flex align-items-center justify-content-center py-2 fs-1'>
                            <LoadCircle />
                        </div>
                    }
                </strong>
                <div className='ms-2 d-flex flex-column'>
                    <span>{t(textConvert('best score'))}</span>
                    {/* desktop */}
                    <small className='fw-normal text-truncate d-sm-inline-block d-none' style={{ maxWidth: '140px' }}>{bestScore?.courseData?.courseName}</small>
                    {/* mobile */}
                    <small className='fw-normal text-truncate d-inline-block d-sm-none' style={{ maxWidth: '170px' }}>{bestScore?.courseData?.courseName}</small>
                </div>
            </div>

            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3 mb-2" style={{ backgroundColor: '#F5F3FF', fontWeight: 700, fontSize: 14 }}>
                <strong className="p-2 rounded-3 text-white col-4 text-center" style={{ fontSize: 20, backgroundColor: '#5D3DB5' }}>
                    {!loading ?
                        <>
                            {puttPerHole}
                        </>
                        :
                        <div className=' d-flex align-items-center justify-content-center py-2 fs-1'>
                            <LoadCircle />
                        </div>
                    }
                </strong>
                <span className="mb-3 ms-2">{t(textConvert('Putts per hole'))}</span>
            </div>

            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3" style={{ backgroundColor: '#E9F6E9', fontWeight: 700, fontSize: 14 }}>
                <strong className="p-2 rounded-3 text-white col-4 text-center" style={{ fontSize: 20, backgroundColor: '#81D77A' }}>
                    {!loading ?
                        <>
                            {gir}
                        </>
                        :
                        <div className=' d-flex align-items-center justify-content-center py-2 fs-1'>
                            <LoadCircle />
                        </div>
                    }
                </strong>
                <span className="mb-3 ms-2">{t(textConvert('Greens in Regulation'))}</span>
            </div>
        </>
    )
}

export const ViewCaddiePerf = ({ userId }) => {
    const { t } = useTranslation();

    const [service, setService] = useState(0);
    const [advice, setAdvice] = useState(0);
    const [totalRound, setTotalRound] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getData() {
            // get all round ignore score card "finished" status

            const now = Timestamp.now();
            const pastTimestamp = new Timestamp(now.seconds - 30 * 24 * 60 * 60, now.nanoseconds);
            const roundCollection = collection(firestore, 'round');
            const q = query(roundCollection, where('scheduleTime', '>=', pastTimestamp.seconds));
            const roundSnapshot = await getDocs(q);
            const myRoundArr = [];
            if (!roundSnapshot.empty) {
                roundSnapshot.forEach(doc => {
                    const data = doc.data();
                    const userList = data.userList;
                    userList.forEach(user => {
                        if (user.caddie && user.caddie.uid === userId) {
                            if (user.caddie.isJoin) {
                                myRoundArr.push(data);
                            }
                        }
                    });
                });
                if (myRoundArr.length > 3) {
                    const reviewData = await getReviewData(myRoundArr);
                    const { avgAdvice, avgService } = reviewCalculation(reviewData);

                    setService(avgService);
                    setAdvice(avgAdvice);
                } else {
                    console.warn('Not enough review data found', myRoundArr.length);
                    setService("-");
                    setAdvice("-");
                }

                setTotalRound(myRoundArr.length)
                setLoading(false)
            }
        }

        async function getReviewData(roundList) {
            if (!roundList.length) {
                console.warn('no round found');
                return [];
            }
            try {
                // Extract roundIds and filter out any falsy values
                const roundIds = roundList.map(item => item.roundId).filter(item => item);

                if (roundIds.length === 0) return [];

                // Helper function to split the array into chunks of 30
                const chunkArray = (array, size) => {
                    const result = [];
                    for (let i = 0; i < array.length; i += size) {
                        result.push(array.slice(i, i + size));
                    }
                    return result;
                };

                // Split roundIds into chunks of 30 (Firestore limit)
                const roundIdChunks = chunkArray(roundIds, 30);
                const reviewCollection = collection(firestore, 'reviewCaddies');

                let allReviews = [];

                // Fetch reviews for each chunk of roundIds
                for (const chunk of roundIdChunks) {
                    const q = query(reviewCollection, where('roundId', 'in', chunk));
                    const reviewSnapshot = await getDocs(q);

                    if (!reviewSnapshot.empty) {
                        reviewSnapshot.forEach(doc => {
                            allReviews.push({ id: doc.id, ...doc.data() });
                        });
                    }
                }

                return allReviews;
            } catch (error) {
                console.error('Error fetching reviews:', error);
                return [];
            }
        }

        function reviewCalculation(reviewData) {
            // console.log(reviewData);
            if (!reviewData.length) {
                return { avgAdvice: 0, avgService: 0 }
            }

            let sumService = 0, sumAdvice = 0
            reviewData.forEach(review => {
                sumService += review.serviceRating
                sumAdvice += review.adviceRating
            });
            const totalReview = reviewData.length
            const avgService = (sumService / totalReview).toFixed(2);
            const avgAdvice = (sumAdvice / totalReview).toFixed(2);

            // console.log(avgService);
            // console.log(avgAdvice);

            return { avgAdvice, avgService }
        }

        if (userId) {
            getData()
        }
    }, [userId])


    return (
        <>
            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3 mb-2" style={{ backgroundColor: '#FDE7EF', fontWeight: 700, fontSize: 14 }}>
                <strong className="p-2 rounded-3 text-white col-4 text-center" style={{ fontSize: 20, backgroundColor: '#EE3C7F' }}>
                    {!loading ?
                        <>
                            {service}
                        </>
                        :
                        <div className='d-flex align-items-center justify-content-center py-3 fs-1'>
                            <LoadCircle />
                        </div>
                    }
                </strong>
                <div className='ms-2 d-flex flex-column'>
                    <span>{t(textConvert('service_mind'))}</span>
                    <small className='text-muted'>{t(textConvert('day average'))}</small>
                </div>
            </div>

            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3 mb-2" style={{ backgroundColor: '#F5F3FF', fontWeight: 700, fontSize: 14 }}>
                <strong className="p-2 rounded-3 text-white col-4 text-center" style={{ fontSize: 20, backgroundColor: '#5D3DB5' }}>
                    {!loading ?
                        <>
                            {advice}
                        </>
                        :
                        <div className='d-flex align-items-center justify-content-center py-3 fs-1'>
                            <LoadCircle />
                        </div>
                    }
                </strong>
                <div className='ms-2 d-flex flex-column'>
                    <span>{t(textConvert('Advice and Guidance'))}</span>
                    <small className='text-muted'>{t(textConvert('day average'))}</small>
                </div>
            </div>

            <div className="w-100 d-flex justify-content-start align-items-center p-3 rounded-3" style={{ backgroundColor: '#E9F6E9', fontWeight: 700, fontSize: 14 }}>
                <strong className="p-2 rounded-3 text-white col-4 text-center" style={{ fontSize: 20, backgroundColor: '#81D77A' }}>
                    {!loading ?
                        <>
                            {totalRound}
                        </>
                        :
                        <div className='d-flex align-items-center justify-content-center py-3 fs-1'>
                            <LoadCircle />
                        </div>
                    }
                </strong>
                <div className='ms-2 d-flex flex-column'>
                    <span >{t(textConvert('Total Rounds'))}</span>
                    <small className='text-muted'>{t(textConvert('day total'))}</small>
                </div>
            </div>
        </>
    )
}