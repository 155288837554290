import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useScore } from '../ScoreContext/ScoreContext'
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { firestore } from '../../../components/firebase/FirebaseConfig';
import { holesDef } from '../../activity/component/wizard/module/holeDef';

const SelectHoleCourseModal = ({ isOpen, closeModal }) => {
    const { roundData, setCourseHoles } = useScore();
    const [courseHolesList, setCourseHolesList] = useState([]);

    useEffect(() => {

        async function getHoleCourse() {
            const holeCollection = collection(firestore, 'courseHoles');
            const q = query(holeCollection, where('courseId', '==', roundData.course));
            const holeSnapShot = await getDocs(q);
            if (!holeSnapShot.empty) {
                const holeList = [];
                holeSnapShot.forEach(doc => {
                    holeList.push({ id: doc.id, ...doc.data() });
                });
                setCourseHolesList(holeList);
            }
        }

        async function getExistedHoleCourse() {
            const holeDoc = doc(firestore, 'courseHoles', roundData.holesCourse);
            const holeSnapshot = await getDoc(holeDoc);
            if (holeSnapshot.exists()) {
                setCourseHoles(holeSnapshot.data())
            }
        }

        if (roundData && !roundData.holesCourse) {
            getHoleCourse();
        } else if (roundData && roundData.holesCourse) {
            getExistedHoleCourse()
        } else {
            console.warn('not found round data');
        }
    }, [roundData]);

    const handleSelectedHole = async (hole) => {
        try {
            const numberOfHole = hole.numberOfHole.replace(/(\d+)(holes)/, "$1 $2");
            const roundDoc = doc(firestore, 'round', roundData.roundId);
            const roundSnapshot = await getDoc(roundDoc);
            if (roundSnapshot.exists()) {
                await updateDoc(roundSnapshot.ref, {
                    holesCourse: hole.id,
                    holes: numberOfHole
                })
            }
            closeModal()
        } catch (error) {
            console.error('Fail to select course', error);

        }
    }

    return (
        <>
            <Modal show={isOpen} onHide={closeModal} centered animation={false}>
                <Modal.Header closeButton>
                    <h2 className='fw-600'>Select holes course</h2>
                </Modal.Header>
                <Modal.Body className='right-scroll-bar' style={{ maxHeight: '50dvh', minHeight: '30dvh' }}>
                    {courseHolesList.map((hole, idx) =>
                        <button key={idx}
                            className='btn btn-outline-light theme-light-bg p-3 rounded-3 d-flex justify-content-between align-items-center w-100 my-2 border'
                            onClick={() => handleSelectedHole(hole)}
                        >
                            <h4 className='fw-600 text-truncate me-2'>{hole.courseHoleName}</h4>
                            <h4 className='fw-600 text-nowrap'>{holesDef(hole.numberOfHole)}</h4>
                        </button>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SelectHoleCourseModal