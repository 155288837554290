import React, { useEffect, useState } from "react";

import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

import {
  containerStyle,
  searchContainerStyle,
  searchInputStyle,
  chatItemStyle,
  userInfoStyle,
  avatarStyle,
  userTextContainerStyle,
  nameStyle,
  messagePreviewStyle,
  timeStyle,
  notificationBubbleStyle,
} from "./StyleComponent";

import { useSelector } from "react-redux";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../../components/language/dropdown/textconvert";

const menu = "assets/images/message/Chat-Menu.svg";
const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;
const ChannelInviteList = ({ channel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.data);

  const handleJoinChannel = async (channelURL) => {
    try {
      const response = await axios.put(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelURL}/accept`,
        { user_id: userData.dooNineId },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": sendBird_api_token,
          },
        }
      );
      //   console.log("Joined successfully:", response.data);

      // messege
      const response2 = await axios.post(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelURL}/messages`,
        {
          message_type: "MESG",
          user_id: userData.dooNineId,
          message: `${userData?.firstName} ${userData?.lastName} joined to the group chat.`,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": sendBird_api_token,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Joined successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      if (error.response) {
        console.error("Failed to join group chat:", error.response.data);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handleDenyChannel = async (channelURL) => {
    try {
      const response = await axios.put(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelURL}/decline`,
        { user_id: userData.dooNineId },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": sendBird_api_token,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Denied successfully",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        //refresh
        window.location.reload();
      });
    } catch (error) {
      if (error.response) {
        console.error("Failed to join group chat:", error.response.data);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  return (
    <>
      <div key={channel._url} className="d-flex align-items-center justify-content-between p-2 border-bottom">
        <div className="d-inline-flex align-items-center text-truncate">
          <div className="p-2">
            <img
              className="rounded-circle"
              src={channel?.coverUrl}
              alt="chat cover"
              width={50}
              height={50}
              style={{
                outline: "3px solid #81d77a",
              }}
            />
          </div>
          <OverlayTrigger
            placement="auto"
            overlay={(props) => (
              <Tooltip {...props}>
                {channel?._name ? `${channel?._name} (${channel?.memberCount})` : "No channel info"}
              </Tooltip>
            )}
          >
            <h5 className="fw-600 mb-1 text-truncate">
              {`${channel?._name} (${channel?.memberCount})`}
            </h5>
          </OverlayTrigger>
        </div>

        <div className="d-inline-flex">
          <button
            className="btn bg-current text-white mx-1 fs-5 text-nowrap"
            onClick={() => handleJoinChannel(channel._url)}
          >
            {t(textConvert('join'))}
          </button>
          <button
            className="btn btn-light mx-1 fs-5"
            onClick={() => handleDenyChannel(channel._url)}
          >
            {t(textConvert('deny'))}
          </button>
        </div>
      </div>
    </>
  );
};

export default ChannelInviteList;
