import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import Header from "../../components/Header";
// import Leftnav from "../../components/Leftnav";
// import Rightchat from "../../components/Rightchat";
// import Appfooter from "../../components/Appfooter";
// import Popupchat from "../../components/Popupchat";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateUser } from "../../components/redux/feature/user/userSlice";
import { sendLinePlainText } from "../../components/line/SendLinePlainText";

const liff = window.liff;

function LineConnectEnable() {
  const userData = useSelector((state) => state.user.data);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeLiff = async () => {
      try {
        await liff.init({
          liffId: "2006737261-OpdVbJN2",
          redirectUri: "https://doonine.com/line-connect-enable",
          // redirectUri: "https://liff.line.me/2006737261-OpdVbJN2",
        });

        if (liff.isLoggedIn()) {
          Swal.fire({
            title: "Loading...",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          const userProfile = await liff.getProfile();
          if (userData?.uid !== undefined) {
            const res = await sendLinePlainText(
              userProfile.userId,
              "คุณได้เชื่อมต่อ doonine กับ Line Message"
            );
            try {
              const response = await axios.post(
                `${process.env.REACT_APP_API_LINE_NOTIFY}/v1/rich-menu/set`,
                {
                  state: "disable",
                  lineUid: userProfile.userId,
                },
                {
                  headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_API_LINE_NOTIFY_TOKEN}`,
                  },
                }
              );
            } catch (error) {
              console.error("Error fetching data:", error);
            }
            Swal.close();
            dispatch(
              updateUser(userData.uid, {
                lineUid: userProfile.userId,
                notification: {
                  ...userData.notification,
                  line: true,
                },
              })
            );
            navigate("/line-connect");
          }
        } else {
          liff.login({
            redirectUri: "https://doonine.com/line-connect-enable",
            // redirectUri: "https://liff.line.me/2006737261-OpdVbJN2",
          });
        }
      } catch (error) {
        console.error("Error initializing LIFF:", error);
      }
    };

    if (userData && liff) {
      initializeLiff();
    }
  }, [userData, liff]);

  return (
    <>
      {/* <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap"></div>
          </div>
        </div>
      </div>
      <Popupchat />
      <Appfooter /> */}
    </>
  );
}

export default LineConnectEnable;
