export function countHole(scoreCard) {
    let playedHole = 0;

    if (!scoreCard) return playedHole;

    const playerHoleFiltered = Object.keys(scoreCard).filter(key => /hole\d+/.test(key));
    const holeValues = playerHoleFiltered.map(key => scoreCard[key]);

    holeValues.forEach(score => {
        if (!isNaN(score.score)) {
            playedHole += 1
        }
    });

    return playedHole

}