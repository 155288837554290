export const mapUserlabel = (user, label = [], lableList) => {
  const clonedUser = structuredClone(user);

  const labelLookup = lableList.reduce((acc, l) => {
    acc[l.docId] = l;
    return acc;
  }, {});

  clonedUser.forEach(foundUser => {

    foundUser.labels = [];

    const matchingLabel = label.find(item => item.friendId === foundUser.uid);
    if (matchingLabel) {
      foundUser.labels = matchingLabel.labels
        .map(labelId => labelLookup[labelId].label || labelLookup[labelId].value)
        .filter(Boolean);
    }
  });

  return clonedUser;
};

export const mapFavUser = (user, mergedFav) => {
  return user.map(u => ({
    ...u,
    isFav: mergedFav.includes(u.uid),
  }));
};

