import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { textConvert } from '../../../components/language/dropdown/textconvert'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom';

function InviteLink({ isOpen, closeModal, roundData }) {
    const { t } = useTranslation();
    const location = useLocation();
    const [copied, setCopied] = useState(false);

    let extractParam = location.search.replace('round', 'id');
    if (!extractParam) {
        if (roundData) {
            extractParam = '?id=' + roundData.roundId
        }
    }
    const link = window.location.origin + '/join-round' + extractParam;

    return (
        <>
            <Modal show={isOpen} onHide={closeModal} centered animation={false}>
                <Modal.Body>
                    <div className='d-flex justify-content-between align-items-center mb-2'>
                        <h2 className='fw-600'>{t(textConvert('Invitation Link'))}</h2>
                        <i className='bi-x-lg fs-2' onClick={closeModal}></i>
                    </div>
                    <input type="text"
                        className='form-control mb-2'
                        disabled
                        value={link} />
                    <div className='text-end'>
                        <button className='btn btn-outline-dark fs-5' onClick={() => {
                            navigator.clipboard.writeText(link);
                            setCopied(true);
                            setTimeout(() => {
                                setCopied(false)
                            }, 1000);
                        }}>
                            {copied ?
                                t(textConvert('copied'))
                                :
                                t(textConvert('copy'))
                            }
                        </button>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default InviteLink