import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import Gamemode from "../../scoreV2/component/Gamemode";
import useModalWithHistory from "../../../components/ModalPopState";
import Competitor from "../../scoreV2/component/Competitor";
import { useScore } from "../../scoreV2/ScoreContext/ScoreContext";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../../components/language/dropdown/textconvert";
import InviteLink from "../../scoreV2/component/InviteLink";

function HeaderDropdown() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { playerGameMode } = useScore();
  const [roundId, setRoundId] = useState(null);
  const [roundData, setRoundData] = useState(null);
  const userData = useSelector((state) => state.user.data);
  const { isModalOpen, openModal, closeModal } = useModalWithHistory();
  const [gameModeModal, setGameModeModal] = useState(false);
  const [competModal, setCompetModal] = useState(false);
  const [inviteLinkModal, setInviteLinkModal] = useState(false);

  useEffect(() => {
    async function getRound() {
      const params = new URLSearchParams(window.location.search);
      const roundId = params.get("round");
      setRoundId(roundId);

      const docRef = doc(firestore, "round", roundId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setRoundData(docSnap.data());
      } else {
        console.log("No such document!");
      }
    }
    getRound();
  }, []);

  const openGameMode = () => {
    setGameModeModal(true);
    setCompetModal(false);
    openModal()
  }
  const openCompet = () => {
    setCompetModal(true);
    setGameModeModal(false);
    openModal()
  }

  const handleEndRound = async () => {
    Swal.fire({
      title: t(textConvert('End this round')),
      html: `
        <h5 class="fw-600">${t(textConvert('Are you sure you want to end this round'))}</h5>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t(textConvert("OK")),
      cancelButtonText: t(textConvert("Cancel")),
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate(`/finish-round?round=${roundId}`);
      }
    });
  };

  // console.log(roundData);
  // console.log(userData);

  return (
    <>
      <Dropdown className="drodownFriend position-absolute" style={{ top: 10, right: 5 }} drop="down-centered">
        <Dropdown.Toggle className="p-0 bg-transparent border-0 text-white">
          <i className="bi bi-three-dots-vertical fs-1"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ borderRadius: 15, overflow: 'hidden' }} className="border-0 shadow-md theme-dark-bg">
          <Dropdown.Item onClick={() => navigate(`/leader-board?round=${roundId}`)} style={dropdownItemStyle}>
            <span className="fs-5 fw-600" >
              {t(textConvert('Leaderboard'))}
            </span>
          </Dropdown.Item>
          <Dropdown.Item style={dropdownItemStyle} onClick={() => openGameMode()}>
            <span className="fs-5 fw-600">
              {t(textConvert('Game mode'))}
            </span>
          </Dropdown.Item>
          <Dropdown.Item
            style={dropdownItemStyle}
            onClick={() => openCompet()}
            disabled={(playerGameMode?.gameMode ?? roundData?.gameMode) === 'Match Play' ? false : true}
          >
            <span className="fs-5 fw-600">
              {t(textConvert('Competitor'))}
            </span>
          </Dropdown.Item>

          <hr />
          <Dropdown.Item as={Link} to={`/p-and-c-round?round=${roundData?.roundId}`} style={dropdownItemStyle}>
            <span className="fs-5 fw-600">
              {t(textConvert('Player and Caddie'))}
            </span>
          </Dropdown.Item>
          <Dropdown.Item style={dropdownItemStyle}
            onClick={() => {
              navigate(`/course-detail?courseId=${roundData?.course}`);
            }}>
            <span className="fs-5 fw-600">
              {t(textConvert('Course'))}
            </span>
          </Dropdown.Item>
          <hr />
          <Dropdown.Item style={dropdownItemStyle} disabled>
            <span className="fs-5 fw-600">
              {t(textConvert('Edit Round'))}
            </span>
          </Dropdown.Item>
          <Dropdown.Item style={dropdownItemStyle}
            onClick={() => setInviteLinkModal(true)}
          >
            <span className="fs-5 fw-600" >
              {t(textConvert('Invitation Link'))}
            </span>
          </Dropdown.Item>
          <Dropdown.Item
            // disabled={roundData?.creator !== userData?.uid}
            // disabled={userData?.role === "caddie"}
            disabled={roundData?.userList.some((user) => (userData?.uid === user?.caddie?.uid) && (!user?.caddie?.isCreator))}// กำหนดให้ disabled เมื่อ role เป็น "caddie"
            style={dropdownItemStyle}
            onClick={() => handleEndRound()}
          >
            <span className="fs-5 fw-600"
              // style={{
              //   color:
              //     roundData?.creator == userData?.uid ? "black" : "#cdcdcd",
              // }}

              style={{
                color: roundData?.userList.some(
                  (user) =>
                    user.caddie?.uid === userData.uid && !user.caddie?.isCreator
                )
                  ? "#cdcdcd"
                  : "black",
              }}

            >
              {t(textConvert('End Round'))}
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {gameModeModal &&
        <Gamemode
          isOpen={isModalOpen}
          closeModal={closeModal}
        />
      }
      {competModal &&
        <Competitor
          isOpen={isModalOpen}
          closeModal={closeModal}
        />
      }
      {inviteLinkModal &&
        <InviteLink
          isOpen={inviteLinkModal}
          closeModal={() => setInviteLinkModal(false)}
        />
      }

    </>
  );
}

export default HeaderDropdown;

// function CustomDropdown({ children, ...props }) {
//   return ReactDOM.createPortal(
//     <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
//     document.body
//   );
// }

const dropdownItemStyle = {
  paddingTop: 5,
  paddingBottom: 5
}
