import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../components/language/dropdown/textconvert";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { initialFetchFriend, nextFetchFriend } from "./module/fetchFriends";
import { getMyLabel } from "./module/fetchLabel";
import Load from "../../components/Load";
import clsx from "clsx";
import FriendDropdown from "./component/FriendDropdown";
import Search from "./Search";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddFriendModal from "./component/AddFriendModal";
import { handleAddFriend, handleFindFriendByDooNineId, handleProfileClick, handleUnblock } from "./module/addFriendFunc";
import { handleChat } from "./module/dropdownFunc";
import MyQRCodeModal from "../addfriend/MyQRCodeModal";
import ScanQRCodeModal from "../addfriend/ScanQRCodeModal";
import ModalFilter from "./ModalFilter";
import StackFilter from "./StackFilter";
import Label from "../createlabel/Label";
import _ from "lodash";
import { searchQuery } from "./module/searchQuery";


function MyFriendV2() {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.data);
  const { state } = useLocation();
  const navigate = useNavigate();

  // add friend modal
  const [showAddfriendModal, setShowAddfriendModal] = useState(false);
  const [findDooNineId, setFindDooNineId] = useState("");
  const [resultDooNine, setResultDooNine] = useState({});
  const [resultDooNineState, setResultDooNineState] = useState("");
  const [isFriendRequest, setIsFriendRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [QRCodeModal, setQRCodeModal] = useState(false);
  const [scanQRCodeModal, setScanQRCodeModal] = useState(false);

  // filter modal
  const [showSortAndFilter, setShowSortAndFilter] = useState(false);
  const [sortData, setSortData] = useState({
    isStar: { state: false },
    isPro: { state: false },
    isLabel: { state: false, selectedLabels: [] },
    isFavorites: { state: false },
    age: { state: false, min: 18, max: 40 },
    isBlocks: { state: false },
  });
  const [tempSortData, setTempSortData] = useState({
    isStar: { state: true },
    isPro: { state: true },
    isLabel: { state: true, selectedLabels: [] },
    isFavorites: { state: true },
    age: { state: true, min: 18, max: 40 },
    isBlocks: { state: true },
  });

  // label modal
  const [triggerFriendList, setTriggerFriendList] = useState(true);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [selectedUserLabel, setSelectedUserLabel] = useState(null);
  const [triggerGetLabel, setTriggerGetLabel] = useState(false);

  const [lastVisible, setLastVisible] = useState(null);
  const [userList, setUserList] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState(userList);
  const [initLoading, setInitLoading] = useState(true);
  const [searchLoad, setSearchLoad] = useState(false);

  const [search, setSearch] = useState({
    text: {
      state: false,
      value: "",
    },
  });

  const friendListIds = useRef([]);
  const myLabelList = useRef([]);

  const handleClose = () => {
    setShowAddfriendModal(false);
    setIsFriendRequest(false);
    setResultDooNineState("");
    setResultDooNine({});
  };
  const handleShow = () => {
    setShowAddfriendModal(true);
    setResultDooNineState("");
    setResultDooNine({});
  };

  useEffect(() => {

    async function initialFetch() {
      try {
        const lableList = await getMyLabel(userData);
        myLabelList.current = lableList

        await initialFetchFriend
          (
            userData,
            setHasMore,
            setLastVisible,
            setUserList,
            friendListIds,
            lableList
          );
      } catch (error) {
        console.error('Error on init', error);
      } finally {
        setInitLoading(false);
      }
    }

    if (userData) initialFetch();

  }, [userData, triggerFriendList, triggerGetLabel]);

  useEffect(() => {
    if (document.documentElement.scrollHeight <= window.innerHeight) {
      nextFetchFriend(userData, setHasMore, setLastVisible, setUserList, friendListIds, lastVisible, myLabelList.current);
    }
  }, [lastVisible, userList, userData]);

  useEffect(() => {
    const filteredList = userList.filter((member) => {
      const isStarMatch = !sortData.isStar.state || member.isStar;
      const isProMatch = !sortData.isPro.state || member.isPro;
      const hasValidBirthDate = member.birthDate.year;

      const isAgeMatch =
        !sortData.age.state ||
        (hasValidBirthDate &&
          2024 - member.birthDate.year >= sortData.age.min &&
          2024 - member.birthDate.year <= sortData.age.max);

      const isFavoriteMatch = !sortData.isFavorites.state || member.isFav;

      const selectedLabels =
        sortData.isLabel?.selectedLabels.map((option) => option.label) || [];
      const isLabelMatch =
        !sortData.isLabel?.state ||
        selectedLabels.length === 0 ||
        (member.labels &&
          selectedLabels.every((label) => member.labels.includes(label)));

      const searchText = search.text.value.toLocaleLowerCase();

      const isSearchMatch = !search.text.state || (
        member.firstName.toLowerCase().includes(searchText) ||
        member.lastName.toLowerCase().includes(searchText) ||
        member.dooNineId.toLowerCase().includes(searchText) ||
        member.role.toLowerCase().includes(searchText) ||
        (member.labels &&
          member.labels.some((label) =>
            label.toLowerCase().includes(searchText)
          ))
      );

      return (
        isStarMatch &&
        isProMatch &&
        isFavoriteMatch &&
        isLabelMatch &&
        isAgeMatch &&
        isSearchMatch
      );
    });

    setFilteredUsers(filteredList);
  }, [sortData, search, userList]);

  const trueStates = Object.keys(sortData).filter(
    (key) => sortData[key].state === true
  );

  useEffect(() => {
    if (state && userData) {
      if (findDooNineId) {
        handleFindFriendByDooNineId(userData, findDooNineId, setResultDooNineState, setResultDooNine, setIsFriendRequest);
      }
    }
  }, [findDooNineId, state, userData]);

  const handleShowSortAndFilter = () => {
    setTempSortData(sortData);

    setShowSortAndFilter(true);
  };

  const handleCloseSortAndFilter = () => {
    setTempSortData(sortData);
    setShowSortAndFilter(false);
  };
  const handleSubmit = () => {
    setSortData(tempSortData);
    handleCloseSortAndFilter();
  };
  const handleSwitchChange = (checked, switchId) => {
    setTempSortData((prevState) => ({
      ...prevState,
      [switchId]: { ...prevState[switchId], state: checked },
    }));
  };
  const handleInputChange = (e, field, type) => {
    const value = e && e.target ? e.target.value : e;

    setTempSortData((prevState) => ({
      ...prevState,
      [field]: { ...prevState[field], [type]: value },
    }));
  };

  useEffect(() => {

    if (search?.text?.value.trim() === "") {
      setSearchLoad(false);
    } else {
      setSearchLoad(true);
    }

    const searchDeb = _.debounce(async () => {
      if (!userData) return;

      const searchRes = await searchQuery(userData, search, myLabelList.current);
      setSearchLoad(false)

      if (searchRes.length === 0) return;

      setUserList(prev => {
        const mergedUsers = [...prev, ...searchRes];

        const uniqueUsers = Array.from(new Map(mergedUsers.map(user => [user.uid, user])).values());

        return uniqueUsers;
      });

    }, 1000);

    searchDeb();

    return () => {
      searchDeb.cancel();
    };

  }, [search, userData]);



  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left pe-0">
            <div className="row">
              <div className="col-xl-12 pageMyFriend">
                <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-2">
                  <div className="d-flex w-100 flex-column flex-md-row ">
                    <h2 className="d-flex fw-700 mb-2 mb-md-0 mt-0 font-md text-grey-900 align-items-center">
                      {t(textConvert('My Friends'))}
                    </h2>
                    <div className="d-flex align-items-center ms-md-auto mt-2 mt-md-0 w-md-auto">
                      <Search
                        filteredMembers={filteredUsers}
                        setFilteredMembers={setFilteredUsers}
                        search={search}
                        setSearch={setSearch}
                      />

                      <div
                        onClick={() => handleShowSortAndFilter()}
                        style={{ cursor: "pointer" }}
                        className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3 d-flex align-items-center justify-content-center"
                      >
                        <i className="feather-filter font-xss text-grey-500"></i>
                      </div>

                      <div
                        onClick={handleShow}
                        id="add-friend-btn"
                        className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3 d-flex align-items-center justify-content-center"
                        style={{ cursor: "pointer" }}
                      >
                        <ReactSVG
                          src="assets/images/myFriend/CaddieFriendFav-Add FriendID.svg"
                          style={{ width: "25px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <StackFilter
                    trueStates={trueStates}
                    sortData={sortData}
                    setSortData={setSortData}
                    setTempSortData={setTempSortData}
                    search={search}
                    setSearch={setSearch}
                  ></StackFilter>
                </div>

                <InfiniteScroll
                  dataLength={userList.length}
                  next={
                    () => nextFetchFriend
                      (
                        userData,
                        setHasMore,
                        setLastVisible,
                        setUserList,
                        friendListIds,
                        lastVisible,
                        myLabelList.current
                      )
                  }
                  hasMore={hasMore}
                  endMessage={!userList.length && !initLoading ? <div className="text-mute fs-6 fw-600 text-center">{t(textConvert('You have no friend'))}</div> : ''}
                  loader={<>{search?.text?.value.trim() === "" && <Load />}</>}
                >
                  <div className="myfriends-container" >
                    <div className="d-flex flex-wrap align-items-start">
                      {filteredUsers.map((user, index) =>
                        <div key={index} className='col-sm-6 col-12 p-1'>
                          <div className="p-3 rounded-3 h-100 bg-white shadow-xss d-flex gap-4 align-items-start">
                            <Link to={`/user?uid=${user.uid}`}>
                              <img src={user.profileImage} alt={user.uid} width={80} height={80} className={clsx("rounded-circle", {
                                'player-color-outline': user?.role !== 'caddie',
                                'caddie-color-outline': user?.role === 'caddie',
                              })} />
                            </Link>
                            <div className="w-100 text-truncate">
                              <Link to={`/user?uid=${user.uid}`}>
                                <h5 className="fw-bold text-truncate">{`${user.firstName} ${user.lastName}`}</h5>
                              </Link>

                              {user.isStar || user.isPro ?
                                <div className="d-inline-flex align-items-center gap-1">
                                  {user?.isStar &&
                                    <ReactSVG
                                      src="assets/icon/CaddieFriendFav-CaddieStar.svg"
                                      style={{ width: "25px" }}
                                    />
                                  }
                                  {user?.isPro &&
                                    <ReactSVG
                                      src="assets/icon/CaddieFriendFav-ProCaddie.svg"
                                      style={{ width: "25px" }}
                                    />
                                  }
                                </div>
                                : null}

                              <div className=" d-flex flex-wrap mt-1 gap-1">
                                {user.labels.map((item, idx) =>
                                  <span key={idx} className="badge bg-light text-black-50 rounded-pill p-2">{item}</span>
                                )}
                              </div>
                            </div>
                            <FriendDropdown
                              userData={userData}
                              user={user}
                              updateUser={(user) => {
                                const foundUser = filteredUsers.find(item => item.uid === user.uid);
                                if (foundUser) {
                                  const updatedfilteredUserList = filteredUsers.filter(item => item.uid !== foundUser.uid);
                                  const updatedUserList = userList.filter(item => item.uid !== foundUser.uid);
                                  setUserList(updatedUserList);
                                  setFilteredUsers(updatedfilteredUserList);
                                }
                              }}
                              selectLabel={(user) => {
                                setSelectedUserLabel(user);
                                setShowLabelModal(true);
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {searchLoad && <Load />}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div >
      <Popupchat />
      <Appfooter />

      <AddFriendModal
        show={showAddfriendModal}
        handleClose={() => setShowAddfriendModal(false)}
        setFindDooNineId={setFindDooNineId}
        findDooNineId={findDooNineId}
        handleFindFriendByDooNineId={() => handleFindFriendByDooNineId(userData, findDooNineId, setResultDooNineState, setResultDooNine, setIsFriendRequest)}
        resultDooNineState={resultDooNineState}
        resultDooNine={resultDooNine}
        handleProfileClick={(uid) => handleProfileClick(userData, uid, navigate)}
        handleChat={(dooNineId) => handleChat(userData, dooNineId, navigate)}
        handleAddFriend={(friendId, userId) => handleAddFriend(friendId, userId, isFriendRequest, setIsLoading, setIsFriendRequest)}
        isLoading={isLoading}
        iconSrc={isFriendRequest ?
          "assets/images/myFriend/CaddieFriendFav-CancelRequest.svg" // SVG for "Cancel Request"
          :
          "assets/images/myFriend/CaddieFriendFav-AddFriend.svg"}
        isFriendRequest={isFriendRequest}
        setQRCodeModal={setQRCodeModal}
        setScanQRCodeModal={setScanQRCodeModal}
        handleUnblock={(user) => handleUnblock(userData, user, handleClose)}
      />

      <MyQRCodeModal
        QRCodeModal={QRCodeModal}
        setQRCodeModal={setQRCodeModal}
      />

      <ScanQRCodeModal
        scanQRCodeModal={scanQRCodeModal}
        setScanQRCodeModal={setScanQRCodeModal}
        findDooNineId={findDooNineId}
        setFindDooNineId={setFindDooNineId}
        handleFindFriendByDooNineId={() => handleFindFriendByDooNineId(userData, findDooNineId, setResultDooNineState, setResultDooNine, setIsFriendRequest)}
      />

      <ModalFilter
        showSortAndFilter={showSortAndFilter}
        handleCloseSortAndFilter={handleCloseSortAndFilter}
        tempSortData={tempSortData}
        handleSubmit={handleSubmit}
        handleSwitchChange={handleSwitchChange}
        handleInputChange={handleInputChange}
        userLabels={myLabelList.current}
      />

      <Label
        triggerFriendList={triggerFriendList}
        setTriggerFriendList={setTriggerFriendList}
        showLabelModal={showLabelModal}
        setShowLabelModal={setShowLabelModal}
        setSelectedUserLabel={setSelectedUserLabel}
        selectedUserLabel={selectedUserLabel}
        userLabels={myLabelList.current}
        triggerGetLabel={triggerGetLabel}
        setTriggerGetLabel={setTriggerGetLabel}
      />

    </>
  )
}

export default MyFriendV2