import React, { useEffect, useState } from 'react';

import LanguageDropdown from '../../components/language/dropdown/DropDownLang';

import { useAuth } from '../../components/auth/UserContext';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { clearUserData } from '../../components/redux/feature/user/userSlice';
import { textConvert } from '../../components/language/dropdown/textconvert';

const logo = 'assets/images/banner/doonine_revB.png';
const text = 'assets/images/banner/doonine_text_2.png';

const Login = () => {

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch()

    const { t } = useTranslation();

    const { emailLogin, googleLogin } = useAuth();

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setLoading(true);
            const user = await emailLogin(email, password);
            if (!user.uid) {
                setErrorMessage('Wrong email or Password');
                setLoading(false);
                return;
            } else {
                setErrorMessage('');
                localStorage.setItem(`lastActivity_${user.uid}`, Date.now());
            }

        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        dispatch(clearUserData())
    }, [])

    const handlePasswordVisible = (e) => {
        const isChecked = e.target.checked;
        let passwordType = document.getElementById('password');
        if (isChecked) {
            passwordType.type = 'text'
        } else {
            passwordType.type = 'password'
        }
    }

    return (
        <>
            <div className="main-wrap">
                <div className="nav-header bg-transparent shadow-none border-0 d-md-none d-block" style={{ backdropFilter: 'blur(2px)' }}>
                    <div className="nav-top w-100 d-flex justify-content-between">
                        <div className='log-container d-flex align-items-center'>
                            <img src={logo} alt='logo' width={55} />
                            <img src={text} alt='logo' width={130} />
                        </div>
                        {/* <button className="nav-menu me-0 ms-auto"></button> */}

                        <LanguageDropdown />
                        {/* <a href="/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                        <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a> */}
                    </div>
                </div>

                <div className="nav-header bg-transparent shadow-none border-0 d-none d-md-block">
                    <div className="nav-top w-100 d-flex justify-content-between">
                        <div className='log-container d-flex align-items-center p-2'>
                            <img src={logo} alt='logo' width={55} />
                            <img src={text} alt='logo' width={180} />
                        </div>
                        {/* <button className="nav-menu me-0 ms-auto"></button> */}

                        <LanguageDropdown />
                        {/* <a href="/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                        <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-7 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
                        style={{ backgroundImage: `url('assets/images/login/login_resized.jpg')` }}>
                    </div>
                    <div className="col-xl-5 vh-100 align-items-center d-flex bg-white theme-dark-bg rounded-3 overflow-hidden">
                        <div className="card shadow-none border-0 ms-auto me-auto login-card">
                            <div className="card-body rounded-0 text-left">


                                <h2 className="fw-700 display1-size display2-md-size mb-3">{t(textConvert('Log in to your account'))}</h2>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group icon-input mb-3">
                                        <i className="font-sm feather-mail text-grey-500 pe-0"></i>
                                        <input
                                            type="email"
                                            className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                            value={email}
                                            placeholder={t('email')}
                                            onChange={(e) => setEmail(e.target.value)}
                                            id='email'
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <div className='form-group icon-input'>
                                            <i className="font-sm feather-lock text-grey-500 pe-0"></i>
                                            <input
                                                type="password"
                                                className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                                                value={password}
                                                placeholder={t('password')}
                                                onChange={(e) => setPassword(e.target.value)}
                                                id='password'
                                            />
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="passwordVis" onChange={handlePasswordVisible} />
                                            <label className="form-check-label text-grey-700 fw-600" htmlFor="passwordVis">
                                                {t(textConvert('See Password'))}
                                            </label>
                                        </div>
                                        <div className='text-end'>
                                            <a href="/forgot" className="fw-600 font-xsss text-grey-700 mt-1">{t('Forgot_your_Password')}</a>
                                        </div>
                                    </div>
                                    <span className='text-danger fw-500'>{t(textConvert(errorMessage))}</span>
                                    <div className="col-sm-12 p-0 text-left mb-2">
                                        <button className='btn btn bg-current text-white w-100 p-2 fs-3 d-flex align-items-center justify-content-center'
                                            id='login-button'
                                            style={{ minHeight: '3rem' }}
                                        >
                                            {loading ? (
                                                <div className="spinner-border spinner-border-sm ms-2" role="status"> </div>
                                            ) : (<>{t('log_in')}</>)}
                                        </button>
                                    </div>
                                    {/* <span className="fw-600 font-xsss text-grey-700">{t('do_not_have_account')}</span> */}
                                    <div className='fw-600 text-center'>{t(textConvert('Or'))}</div>
                                    <a href="/register"><button className='btn player-color p-2 fs-3 fw-600 d-block w-100 text-white' type='button'>{t('register')}</button></a>
                                </form>
                                {/* <div className='text-center mt-2 fw-600 font-xsss text-grey-700'>or login with</div>
                                <div className='d-flex justify-content-center'>
                                    <button className='btn btn-outline-dark d-flex align-items-center fw-600 fs-4' onClick={googleLogin}><i className="bi bi-google fs-1 me-1"></i>Google</button> */}
                                {/* <button className='btn btn-outline-dark d-flex align-items-center fw-600 fs-4'><i className="bi bi-microsoft fs-1 me-1"></i>Microsoft</button> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
}

export default Login;