import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function LoadCircle({ size = "sm", variant = "light", className = "" }) {
    return (
        <div className={className}>
            <Spinner variant={variant} animation="border" role="status" size={size}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}