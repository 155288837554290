import React, { useState } from 'react'
import { ReactSVG } from 'react-svg';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../../components/language/dropdown/textconvert';

function SelectedPriv(selectedPriv) {
    const { t } = useTranslation()
    switch (selectedPriv) {
        case "public":
            return (
                <div className='d-flex justify-content-center align-items-center'>
                    <ReactSVG src='assets/images/dooNineIconV2/others/post-Public.svg' className='priv-icon me-2' />
                    <h4 className='m-0 fw-600'>{t(textConvert('public'))}</h4>
                </div>
            );
        case "friends":
            return (
                <div className='d-flex justify-content-center align-items-center'>
                    <ReactSVG src='assets/images/dooNineIconV2/others/post-Friend.svg' className='priv-icon me-2' />
                    <h4 className='m-0 fw-600'>{t(textConvert('friend'))}</h4>
                </div>
            );
        case "only_me":
            return (
                <div className='d-flex justify-content-center align-items-center'>
                    <ReactSVG src='assets/images/dooNineIconV2/others/post-Private.svg' className='priv-icon me-2' />
                    <h4 className='m-0 fw-600'>{t(textConvert('only_me'))}</h4>
                </div>
            );

        default:
            break;
    }
}

function SelectedPrivBtn({ handleSelectPriv, isPreSelected, isComment }) {
    const { t } = useTranslation()

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedPrivacy, setSelectedPrivacy] = useState(isPreSelected || 'public');

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const handlePrivSelect = (privacyOption) => {
        setSelectedPrivacy(privacyOption);
        handleSelectPriv(privacyOption)
    };

    return (
        <>
            <style>{`.private-menu :hover {
  background-color: rgba(170, 170, 170, 0.1);
}
.private-menu a :hover {
  background-color: transparent;
}
`}</style>

            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="priv-dropdown-container">
                <Dropdown.Toggle caret className='btn h-100 rounded-pill priv-toggle-btn theme-light-bg'>
                    {SelectedPriv(selectedPrivacy)}
                </Dropdown.Toggle>
                <Dropdown.Menu className='theme-light-bg private-menu'>
                    <Dropdown.Item onClick={() => handlePrivSelect('public')}>
                        <div className='d-flex '>
                            <ReactSVG src='assets/images/dooNineIconV2/others/post-Public.svg' className='priv-icon me-2' />
                            <div className='d-block'>
                                <h4>{t(textConvert('public'))}</h4>
                                <h6 className='text-muted'>Anyone on doonine</h6>
                            </div>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handlePrivSelect('friends')}>
                        <div className='d-flex'>
                            <ReactSVG src='assets/images/dooNineIconV2/others/post-Friend.svg' className='priv-icon me-2' />
                            <div className='d-block'>
                                <h4>{t(textConvert('friend'))}</h4>
                                <h6 className='text-muted'>Your friends on doonine</h6>
                            </div>
                        </div>
                    </Dropdown.Item>
                    {!isComment && (
                        <Dropdown.Item onClick={() => handlePrivSelect('only_me')}>
                            <div className='d-flex'>
                                <ReactSVG src='assets/images/dooNineIconV2/others/post-Private.svg' className='priv-icon me-2' />
                                <div className='d-block'>
                                    <h4>{t(textConvert('only_me'))}</h4>
                                    <h6 className='text-muted'>Visible only to you</h6>
                                </div>
                            </div>
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}

export default SelectedPrivBtn