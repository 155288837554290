export const filterPosts = (userData, posts, blockListRef, friendsListRef) => {
    if (!userData) return posts;

    const { privacy } = userData;

    return posts.filter(post => {
        const isBlocked = blockListRef.includes(post.userId);
        const isFriend = post.audience === 'friends' && friendsListRef.includes(post.userId);
        const isPublic = post.audience === 'public';

        if (isBlocked) return false; // Exclude blocked users

        // If post is from a friend, ignore role and just include it
        if (isFriend) return true;

        // If both playerPost and caddiePost are false, show only friends' posts
        if (!privacy?.playerPost && !privacy?.caddiePost) return false;

        // If both playerPost and caddiePost are true (or undefined), show all public posts
        if ((privacy?.playerPost && privacy?.caddiePost) || (privacy?.playerPost === undefined && privacy?.caddiePost === undefined)) {
            return isPublic;
        }

        // If only player posts are allowed
        if (privacy?.playerPost && !privacy?.caddiePost) {
            return isPublic && post.role === 'player';
        }

        // If only caddie posts are allowed
        if (!privacy?.playerPost && privacy?.caddiePost) {
            return isPublic && post.role === 'caddie';
        }

        // Default: only show public posts
        return isPublic;
    });
};
