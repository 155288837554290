import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where, limit } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
function CourseCaddie({ courseData }) {
  // console.log(courseData);

  const [caddie, setCaddie] = useState([]);

  useEffect(() => {
    async function fetchData() {
      let caddie = [];

      const querySnapshot = await getDocs(
        query(
          collection(firestore, "caddieAssignments"),
          where("courseId", "==", courseData.courseId || courseData.id),
          limit(6)
        )
      );

      const promises = [];
      querySnapshot.forEach((doc) => {
        const promise = getDocs(
          query(
            collection(firestore, "users"),
            where("uid", "==", doc.data().caddieId)
          )
        ).then((querySnapshot2) => {
          querySnapshot2.forEach((doc2) => {
            caddie.push(doc2.data().profileImage);
          });
        });
        promises.push(promise);
      });

      await Promise.all(promises);
      setCaddie(caddie);
    }

    if (courseData) {
      fetchData();
    }
  }, [courseData]);

  return (
    <>
      {caddie.length ?
        <>
          {
            caddie.map((value, index) => (
              <h5
                className="mt-0 d-inline-block font-xssss fw-600 text-grey-500 me-0"
                key={index}
              >
                <img
                  src={value}
                  alt="avatar"
                  className="bg-image-cover rounded-circle"
                  style={{
                    backgroundColor: "#EE3D7F",
                    width: "35px",
                    height: "35px",
                    padding: "2px",
                  }}
                />
              </h5>
            ))
          }
        </>
        :
        <div className="rounded-circle bg-transparent" style={{
          backgroundColor: "#EE3D7F",
          width: "35px",
          height: "35px",
          padding: "2px",
        }}></div>
      }
    </>
  );
}

export default CourseCaddie;
