import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { collection, query, where, getDocs, or, and } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";

import { ReactSVG } from "react-svg";
import { useNavigate } from "react-router-dom";

import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../../components/language/dropdown/textconvert";

const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;

function ChatRoomInvite({ showGroupInviteModal, setShowGroupInviteModal }) {
  const { t } = useTranslation();
  const userData = useSelector((state) => state.user.data);
  const [channelId, setChannelId] = useState();
  const navigate = useNavigate();

  const [channelMember, setChannelMember] = useState([]);

  const [triggerData, setTriggerData] = useState(true);

  const [playerList, setPlayerList] = useState([]);
  const [selectFriends, setSelectFriends] = useState([]);

  const [filteredPlayerList, setFilteredPlayerList] = useState([]); // Add this for the filtered list
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  const playerIdRef = useRef([]);
  const favPlayerRef = useRef([]);
  const friendPlayerRef = useRef([]);
  const PlayerDataRef = useRef([]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const channelId_ = params.get("channelId");
    setChannelId(channelId_);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}?&show_member=true`,
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );
        setChannelMember(response.data.members);
      } catch (error) {
        console.log(error);
      }
    }
    if (channelId) {
      fetchData();
    }
  }, [channelId, triggerData, showGroupInviteModal]);

  useEffect(() => {
    async function getYourfriend() {
      friendPlayerRef.current = [];
      try {
        const friendCollection = collection(firestore, "friendRequests");
        const q = query(
          friendCollection,
          or(
            and(
              where("fromUserId", "==", userData?.uid),
              where("status", "==", "accept")
            ),
            and(
              where("toUserId", "==", userData?.uid),
              where("status", "==", "accept")
            )
          )
        );
        const friendSnapshot = await getDocs(q);
        if (!friendSnapshot.empty) {
          friendSnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.toUserId === userData?.uid) {
              friendPlayerRef.current.push(data.fromUserId);
            } else {
              friendPlayerRef.current.push(data.toUserId);
            }
          });
        }

        await getYourFav();
        await getPlayerData();
        sortPlayer();
      } catch (error) {
        console.error("Fail to get friend caddie", error);
      }
    }

    const getYourFav = async () => {
      favPlayerRef.current = [];
      try {
        const favCollection = collection(firestore, "favoritePlayers");
        const q = query(
          favCollection,
          where("userId", "==", userData.uid),
          where("status", "==", "active")
        );
        const favSnapshot = await getDocs(q);
        if (!favSnapshot.empty) {
          favSnapshot.forEach((doc) => {
            const data = doc.data();
            if (!favPlayerRef.current.includes(data.playerId)) {
              favPlayerRef.current.push(data.playerId);
            }
          });
        }

        const favCollectionFriends = collection(firestore, "favoriteFriends");
        const qFriends = query(
          favCollectionFriends,
          where("userId", "==", userData.uid),
          where("status", "==", "active")
        );
        const favSnapshotFriends = await getDocs(qFriends);
        if (!favSnapshotFriends.empty) {
          favSnapshotFriends.forEach((doc) => {
            const data = doc.data();
            if (!favPlayerRef.current.includes(data.caddieId)) {
              favPlayerRef.current.push(data.caddieId);
            }
          });
        }
      } catch (error) {
        console.error("Fail to get fav caddie", error);
      }
    };

    const getPlayerData = async (playerList = [...friendPlayerRef.current]) => {
      if (!playerList.length) return;

      playerIdRef.current = playerList;
      const playerDataListArray = [];
      try {
        const userCollection = collection(firestore, "users");
        const q = query(userCollection, where("uid", "in", playerList));
        const userSnapshot = await getDocs(q);
        if (!userSnapshot.empty) {
          userSnapshot.forEach((doc) => {
            playerDataListArray.push(doc.data());
          });
          PlayerDataRef.current = playerDataListArray;
          // sortCaddie()
        }
      } catch (error) {
        console.error("Fail to get caddie data", error);
      }
    };

    function sortPlayer() {
      const updatedPlayerDataList = PlayerDataRef.current.map((player) => {
        return {
          ...player,
          isFav: favPlayerRef.current.includes(player.uid),
          isFriend: friendPlayerRef.current.includes(player.uid),
        };
      });
      // console.log(updatedPlayerDataList);

      setPlayerList(() => {
        return updatedPlayerDataList.sort((a, b) => {
          if (a.isFav !== b.isFav) {
            return b.isFav - a.isFav;
          }
          if (a.isFriend !== b.isFriend) {
            return b.isFriend - a.isFriend;
          }
          return 0;
        });
      });
    }

    getYourfriend();
  }, [userData, triggerData, showGroupInviteModal]);

  const toggleFriendSelection = (friend) => {
    setSelectFriends((prev) => {
      const isAlreadySelected = prev.find((f) => f.uid === friend.uid);
      if (isAlreadySelected) {
        return prev.filter((f) => f.uid !== friend.uid);
      } else {
        return [...prev, friend];
      }
    });
  };

  const handleCloseModal = () => {
    setShowGroupInviteModal(false);
    setSearchQuery("");
  };

  const handleInviteMember = async () => {
    if (selectFriends.length === 0) {
      Swal.fire({
        title: "Error",
        html: `<h5 class="fw-600">Please select at least one member to invite.</h5>`,
        icon: "error",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      });
      return;
    }
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const dooNineIds = selectFriends
      .filter((person) => person.dooNineId !== userData.dooNineId) // Exclude the specific dooNineId
      .map((person) => person.dooNineId); // Extract remaining dooNineId values

    try {
      // disable auto accept
      for (const ids of dooNineIds) {
        const response3 = await axios.put(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/users/${ids}/channel_invitation_preference`,
          {
            auto_accept: false,
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": sendBird_api_token,
            },
          }
        );
        console.log(response3);
      }

      // invite
      const response4 = await axios.post(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}/invite`,
        {
          user_ids: dooNineIds,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": sendBird_api_token,
          },
        }
      );

      // Message to invited members
      for (let index = 0; index < selectFriends.length; index++) {
        const element = selectFriends[index];
        const response2 = await axios.post(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}/messages`,
          {
            message_type: "MESG",
            user_id: userData.dooNineId,
            message: `${userData.firstName} ${userData.lastName} invited ${element.firstName} ${element.lastName} to this group chat.`,
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": sendBird_api_token,
            },
          }
        );
      }

      if (response4.status === 200) {
        setSelectFriends([]);
        setShowGroupInviteModal(false);
        setTriggerData((prev) => !prev);
        setPlayerList([]);

        Swal.fire({
          title: t(textConvert('success')),
          html: `<h5 class="fw-600">${t(textConvert('Invite member to chat complete'))}</h5>`,
          icon: "success",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Error",
          html: `<h5 class="fw-600">Error in creating group chat</h5>`,
          icon: "error",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });

        console.log("Error in creating group chat");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFilteredPlayerList(playerList);
  }, [playerList]);

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = playerList.filter((player) => {
      const fullName = `${player.firstName} ${player.lastName}`.toLowerCase();
      return fullName.includes(query);
    });
    setFilteredPlayerList(filtered);
  };

  return (
    <>
      <Modal
        show={showGroupInviteModal}
        onHide={() => handleCloseModal()}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex justify-content-between align-items-center gap-4 w-100">
            <h2 className="fw-600 text-nowrap">{t(textConvert('Invite member'))}</h2>
            <div className="search-form-2 flex-grow-1">
              <i className="ti-search font-xss"></i>
              <input
                type="text"
                className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
                placeholder="Search here."
                value={searchQuery}
                onChange={handleSearchChange} // Update search query
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="right-scroll-bar mb-3" style={{ maxHeight: "60vh" }}>
            <ListGroup>
              {filteredPlayerList.map((player, index) => {
                const isInChannelMember = channelMember.some(
                  (member) => member.user_id === player.dooNineId
                );

                return !isInChannelMember ? (
                  <ListGroup.Item
                    key={index}
                    className="sel-player-item pointer"
                    style={{
                      backgroundColor: selectFriends.some(
                        (f) => f.uid === player.uid
                      )
                        ? "#FCD8E5"
                        : "",
                    }}
                    onClick={() => toggleFriendSelection(player)}
                  >
                    <div className="d-flex align-items-center">
                      <div className="col-3">
                        <img
                          src={player.profileImage}
                          alt={`user${index}`}
                          width={50}
                          height={50}
                          className={`rounded-circle ${player?.role === "player"
                              ? "player-color-outline"
                              : "caddie-color-outline"
                            }`}
                          style={{ objectFit: "cover" }}
                        />
                      </div>
                      <div className="col-6">
                        <h4 className="fw-600">{`${player.firstName} ${player.lastName}`}</h4>
                      </div>
                      <div className="col-3 text-end">
                        {player.isFav ? (
                          <ReactSVG
                            src="assets/images/dooNineIconV3/create-round/createRound-favCaddie.svg"
                            wrapper="svg"
                            width={30}
                            height={30}
                          />
                        ) : null}
                      </div>
                    </div>
                  </ListGroup.Item>
                ) : null;
              })}
            </ListGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn bg-current text-white mx-1 fs-5"
            onClick={() => handleInviteMember()}
            disabled={selectFriends.length === 0}
          >
            {t(textConvert('Invite'))}
          </button>

          <button
            className="btn bg-greylight"
            onClick={() => handleCloseModal()}
          >
            <h4 className="fw-600 m-0 text-grey-700 p-1">{t(textConvert('close'))}</h4>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChatRoomInvite;
