import React, { useState, useMemo, useEffect } from 'react';
import Chart from "react-apexcharts";
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../../../components/language/dropdown/textconvert';

const AdviceChartCaddie = ({ userId = null, completedRound, reviewData }) => {
    const { t } = useTranslation();
    // console.log(completedRound);
    // console.log(reviewData);

    const [series, setSeries] = useState([
        {
            name: '',
            data: [0, 0, 0, 0, 0, 0]
        }
    ])

    useEffect(() => {
        function getStar() {

            let oneStarAdvice = 0;
            let twoStarAdvice = 0;
            let threeStarAdvice = 0;
            let fourStarAdvice = 0;
            let fiveStarAdvice = 0;
            let noStarAdvice = 0;

            // Count each star rating
            completedRound.forEach(round => {
                const foundReview = reviewData.find(item => item.roundId === round.roundId);
                if (foundReview) {
                    switch (foundReview.adviceRating) {
                        case 1:
                            oneStarAdvice += 1;
                            break;
                        case 2:
                            twoStarAdvice += 1;
                            break;
                        case 3:
                            threeStarAdvice += 1;
                            break;
                        case 4:
                            fourStarAdvice += 1;
                            break;
                        case 5:
                            fiveStarAdvice += 1;
                            break;
                        default:
                            noStarAdvice += 1;
                            break;
                    }
                } else {
                    noStarAdvice += 1;
                }
            });

            // Calculate total reviews
            const totalReviews = oneStarAdvice + twoStarAdvice + threeStarAdvice + fourStarAdvice + fiveStarAdvice + noStarAdvice;

            // Calculate percentages for each rating
            const oneStarAdvicePercentage = (oneStarAdvice / totalReviews) * 100;
            const twoStarAdvicePercentage = (twoStarAdvice / totalReviews) * 100;
            const threeStarAdvicePercentage = (threeStarAdvice / totalReviews) * 100;
            const fourStarAdvicePercentage = (fourStarAdvice / totalReviews) * 100;
            const fiveStarAdvicePercentage = (fiveStarAdvice / totalReviews) * 100;
            const noStarAdvicePercentage = (noStarAdvice / totalReviews) * 100;

            // Set the series data with percentages
            setSeries([
                {
                    name: '',
                    data: [
                        fiveStarAdvicePercentage.toFixed(2),
                        fourStarAdvicePercentage.toFixed(2),
                        threeStarAdvicePercentage.toFixed(2),
                        twoStarAdvicePercentage.toFixed(2),
                        oneStarAdvicePercentage.toFixed(2),
                        noStarAdvicePercentage.toFixed(2)
                    ]
                },
            ]);
        }

        if (completedRound?.length) {
            getStar();
        }
    }, [reviewData, completedRound]);


    const renderChart = useMemo(() => {
        if (!userId) return

        const options = {
            chart: {
                id: 'responsive-bar',
            },
            xaxis: {
                categories: [t(textConvert('five Stars')), t(textConvert('four Stars')), t(textConvert('three Stars')), t(textConvert('two Stars')), t(textConvert('one Stars')), t(textConvert('didnt_review'))],
            },
            yaxis: {
                max: 100,
                show: false,
                labels: {
                    formatter: (value) => `$${value}`, // Add unit to y-axis labels
                },
            },
            title: {
                text: t(textConvert('Advice and Guidance')),
                align: 'center',
                style: {
                    fontSize: '16px',
                    color: '#333'
                }
            },
            colors: ['#93e8ff80', '#a3b8e380', '#88db8580', '#ffeb9980', '#ffceb280', '#88888880'],
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    horizontal: false,
                    distributed: true,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val + "%";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            tooltip: {
                y: {
                    formatter: (value) => `${value}%`
                }
            },
            legend: {
                show: false
            }
        }

        return (
            <div className="chart-container" style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="100%"
                    height="300"
                />
            </div>
        )
    }, [series, t, userId])

    return (
        <>
            {renderChart}
        </>
    );
}

export default AdviceChartCaddie