/* eslint-disable jsx-a11y/heading-has-content */
import clsx from 'clsx';
import React, { useState, useEffect } from 'react'
import { Modal, Carousel, Tab, Tabs } from 'react-bootstrap'
import ExpandableContent from '../../../../home/postview/utilsfunction/ExpandableContent';
import { where, getDocs, collection, query, limit } from 'firebase/firestore';
import { firestore } from '../../../../../components/firebase/FirebaseConfig';
import LoadCircle from '../../../../../components/LoadCircle';
import { useTranslation } from 'react-i18next';
import { textConvert } from '../../../../../components/language/dropdown/textconvert';

const CourseDetail = ({ isOpen, handleClose, course, handleSelectCourse, handleCourseFav }) => {
    // console.log(course);
    const { t, i18n } = useTranslation();
    const [selectedTab, setSelectedTab] = useState("about");
    const [courseHole, setCourseHole] = useState([]);
    const [loadingHole, setLoadingHole] = useState(true);
    const [courseImageList, setCourseImageList] = useState([]);
    // const [selectedHole, setSelectedHole] = useState(null);

    useEffect(() => {
        async function getHoles(courseId) {
            const numberOfHoleList = [];
            try {
                const holesCollection = collection(firestore, "courseHoles");
                const q = query(holesCollection, where('courseId', '==', courseId));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    querySnapshot.forEach(doc => {
                        const data = doc.data();
                        const existingHole = numberOfHoleList.find(hole => hole.numberOfHole === data.numberOfHole);

                        if (existingHole) {
                            if (!existingHole.courseHoleNames.includes(data.courseHoleName)) {
                                existingHole.courseHoleNames.push(data.courseHoleName);
                            }
                            // Add doc.id if not already in the list
                            if (!existingHole.id.includes(doc.id)) {
                                existingHole.id.push(doc.id);
                            }
                        } else {
                            numberOfHoleList.push({
                                numberOfHole: data.numberOfHole,
                                courseHoleNames: [data.courseHoleName],
                                id: [doc.id]
                            });
                        }
                    });
                }
                setCourseHole(numberOfHoleList);
                setLoadingHole(false);
            } catch (error) {
                setLoadingHole(false);
                console.error('fail to load course', error);
            }
        }

        if (course) {
            getHoles(course.id)
        }

    }, [course])

    useEffect(() => {
        async function getCourseImage(courseId) {
            const q = query(collection(firestore, 'courseImage'), where('courseId', '==', courseId), limit(10));
            const courseImgSnap = await getDocs(q);
            if (!courseImgSnap.empty) {
                const imageArr = [];
                courseImgSnap.forEach(doc => {
                    const data = doc.data();
                    imageArr.push(data);
                });
                setCourseImageList(imageArr);
            }
        }

        if (course) {
            getCourseImage(course.id)
        }
    }, [course])


    if (!course) return;

    // const handleHoleChange = (id, numberOfHole) => {
    //     let convertedHole = "1";

    //     setSelectedHole(id);
    //     switch (numberOfHole) {
    //         case "9holes":
    //             convertedHole = "1"
    //             break;

    //         case "18holes":
    //             convertedHole = "2"
    //             break;

    //         default:
    //             break;
    //     }
    //     handleHoleCourseChange(id, convertedHole);
    // }

    return (
        <>
            <Modal show={isOpen} onHide={() => { handleClose(); setSelectedTab('about') }} centered>

                <div className='right-scroll-bar' style={{ overflow: 'auto', height: '85vh' }}>
                    <Modal.Body>
                        <Carousel>
                            <Carousel.Item style={{ height: 250 }}>
                                <img src={course.coverImage} alt="cover"
                                    className='w-100 h-100'
                                    style={{ objectFit: 'cover' }}
                                />

                            </Carousel.Item>
                            {courseImageList.map((image, idx) =>
                                <Carousel.Item key={idx} style={{ height: 250 }}>
                                    <img src={image.url} alt="cover"
                                        className='w-100 h-100'
                                        style={{ objectFit: 'cover' }}
                                    />
                                </Carousel.Item>
                            )}

                        </Carousel>
                        <div className='position-relative'>
                            <img src={course.profileImage} alt="profile" className='position-absolute shadow-sm'
                                style={{ width: 95, height: 95, borderRadius: '50%', top: -40, left: 10, objectFit: 'cover', outline: '5px solid lightgreen' }} />
                            <div className='my-3' style={{ marginLeft: 120, marginRight: 25 }}>
                                <h4 className='fw-600'>
                                    {i18n.language === 'th' ?
                                        `${course?.courseNameThai}`
                                        :
                                        `${course?.courseName}`
                                    }
                                </h4>
                                <h6 className='fw-600 text-black-50'>
                                    {i18n.language === 'th' ?
                                        `${course.location.district || ""}, ${course.location.province || ""}`
                                        :
                                        `${course.location.districtEng || ""}, ${course.location.provinceEng || ""}`
                                    }
                                </h6>
                            </div>
                            {course.isFavorite ?
                                <i className='fs-1 bi bi-bookmark-fill ms-2 fs-4 pointer position-absolute' style={{ color: 'rgb(229, 158, 73)', top: -10, right: 0 }}
                                    onClick={(e) => { e.stopPropagation(); handleCourseFav('unfav', course) }}
                                ></i>
                                :
                                <i className='fs-1 bi bi-bookmark ms-2 fs-4 pointer position-absolute' style={{ top: -10, right: 0 }}
                                    onClick={(e) => { e.stopPropagation(); handleCourseFav('fav', course) }}
                                ></i>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Body>
                        <Tabs
                            defaultActiveKey={selectedTab}
                            className="mb-3 course-tabs"
                            onSelect={(k) => setSelectedTab(k)}
                        >

                            <Tab eventKey="about" title={<span className={clsx("fs-5 fw-600", {
                                "text-muted": selectedTab !== "about"
                            })}>{t(textConvert("About"))}</span>}>

                                <section className='mb-3'>
                                    <h5 className='fw-600 mb-1'>
                                        {i18n.language === 'th' ?
                                            `${course?.courseNameThai}`
                                            :
                                            `${course?.courseName}`
                                        }
                                    </h5>
                                    <h6><ExpandableContent content={course.descriptionCourse.longDescriptionCourse} charLimit={200} lineLimit={3} /></h6>
                                </section>
                                <section className='d-flex flex-column gap-2'>
                                    <div className='d-flex align-items-center gap-3'>
                                        <h3 className='bi-pin-map'></h3>
                                        <h6 className='text-black-50 fw-600'>
                                            {i18n.language === 'th' ?
                                                `${course.location.province || '-'}, ${course.location.district || '-'}, ${course.location.subDistrict || '-'}`
                                                :
                                                `${course.location.provinceEng || '-'}, ${course.location.districtEng || '-'}, ${course.location.subDistrictEng || '-'}`
                                            }
                                        </h6>
                                    </div>

                                    <div className='d-flex align-items-center gap-3'>
                                        <h3 className='bi-telephone'></h3>
                                        <h6 className='text-black-50 fw-600'>{`${course.contactPhone || course.contact.phone || '-'}`}</h6>
                                    </div>

                                    <div className='d-flex align-items-center gap-3'>
                                        <h3 className='bi-globe'></h3>
                                        <h6 className='text-black-50 fw-600'>{`${course.companySite || course.contact.website || '-'}`}</h6>
                                    </div>

                                    <div className='d-flex align-items-center gap-3'>
                                        <h3 className='bi-envelope'></h3>
                                        <h6 className='text-black-50 fw-600'>{`${course.contact.email || '-'}`}</h6>
                                    </div>

                                </section>
                            </Tab>

                            <Tab eventKey="course" title={<span className={clsx("fs-5 fw-600", {
                                "text-muted": selectedTab !== "course"
                            })}>{t(textConvert("Course"))}</span>}>

                                {!loadingHole ? (<>
                                    {courseHole.map((hole, idx) =>
                                        <React.Fragment key={idx}>
                                            {hole.courseHoleNames.map((courseName, courseIdx) =>
                                                <div key={courseIdx} className='p-3 p-sm-4 mb-2 bg-light theme-dark-bg border rounded-3'
                                                // onClick={() => handleHoleChange(hole.id[courseIdx], hole.numberOfHole)}
                                                >
                                                    <h5 className='fw-600'>{`${courseName} (${hole.numberOfHole.replace("holes", "")})`}</h5>
                                                    <h6 className='fw-600 text-muted text-truncate'>{`${course.courseName}`}</h6>
                                                    {/* <i className="bi bi-check-circle-fill text-current step-one-checked-icon"></i> */}
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                    <h5 className={clsx('fw-600 text-center', { 'd-none': courseHole.length > 0 })}>No Data</h5>
                                </>) : (
                                    <div className='w-100 text-center'>
                                        <LoadCircle size='md' variant='dark' />
                                    </div>
                                )}

                            </Tab>
                        </Tabs>
                    </Modal.Body>
                </div>
                <Modal.Footer className='p-1 pb-0'>
                    <button className='btn bg-current'><h4 className='fw-600 text-white' onClick={() => { handleSelectCourse(course); handleClose() }}>{t(textConvert("Select"))}</h4></button>
                    <button className='btn btn-light theme-dark-bg'><h4 className='fw-600' onClick={() => { handleClose(); setSelectedTab('about') }}>{t(textConvert("Close"))}</h4></button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CourseDetail