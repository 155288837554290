import React, { useEffect, useState, useMemo } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../../../../components/firebase/FirebaseConfig";
import { epochToDateTime } from "../../../component/wizard/module/covertTime";
import { gameModeSelection } from "../../../../LeaderboardV2/module/scoreCalculation";

import { useRef } from 'react';


export function Score(roundData) {
  const [userStatList, setUserStatList] = useState(null);
  const [scoreList, setScoreList] = useState([]);
  const processedRoundIds = useRef(new Set()); // Track processed round IDs

  // Memoize the uids to avoid recalculating them on every render
  const uids = useMemo(() => {
    const uniqueUids = new Set();

    roundData?.forEach((round) => {
      round.userList?.forEach((user) => {
        if (user.player) uniqueUids.add(user.player.uid);
        if (user.caddie) uniqueUids.add(user.caddie.uid);
      });
    });

    return Array.from(uniqueUids);
  }, [roundData]);

  // Effect for fetching user statistics when required
  useEffect(() => {
    if (roundData?.some((round) => round.handicap === "world_handicap_system") && uids.length > 0 && !userStatList) {
      getStat(uids);
    }
  }, [uids, roundData, userStatList]);

  async function getStat(uids) {
    try {
      const statCollection = collection(firestore, "playerStatistic");
      const q = query(statCollection, where("userId", "in", uids));
      const statSnapshot = await getDocs(q);
      if (!statSnapshot.empty) {
        const statArr = [];
        statSnapshot.forEach((doc) => {
          statArr.push(doc.data());
        });
        setUserStatList(statArr);
      }
    } catch (error) {
      console.error("Failed to get user stat", error);
    }
  }

  useEffect(() => {
    if (!userStatList) return; // Don't run the second effect if userStatList is not loaded yet

    // Using a single onSnapshot for all rounds
    const scoreCollection = collection(firestore, "scoreCards");
    const unsubscribe = roundData?.map((round) => {
      const q = query(scoreCollection, where("roundId", "==", round.roundId));
      
      return onSnapshot(q, (snapshot) => {
        if (!snapshot.empty) {
          const scoreArr = [];
          snapshot.forEach((doc) => {
            scoreArr.push({ id: doc.id, ...doc.data() });
          });

          // Only process this round if it hasn't been processed yet
          if (!processedRoundIds.current.has(round.roundId)) {
            const sortedScore = gameModeSelection(scoreArr, round, userStatList) || [];
            const finishedScore = sortedScore
              .filter((item) => item.status === "finished")
              .map((item) => ({
                ...item,
                gameMode: round.gameMode, // Add gameMode to each score
              }));
            setScoreList((prevScoreList) => [...prevScoreList, ...finishedScore]);
            // Mark this round as processed
            processedRoundIds.current.add(round.roundId);
          }
        }
      });
    });

    // Cleanup on component unmount
    return () => {
      if (unsubscribe) {
        unsubscribe.forEach((unsub) => unsub());
      }
    };
  }, [roundData, userStatList]);

  return scoreList; // Return the scoreList for use elsewhere in your component
}



