import axios from "axios";


export async function sendInviteRound(lineId, roundId, data1, data3) {

    try {

        let dateText = '';
        let timeText = '';

        if (data3.teeTimeBtn == 'now') {
            dateText = 'เริ่มเลยตอนนี้';
            timeText = '   ';

            const response = await axios.post(
                `${process.env.REACT_APP_API_LINE_NOTIFY}/v1/invite-round/now`,
                {
                    lineUid: lineId,
                    roundId: roundId,
                    roundName: data1.roundName || "",
                    courseId: data1.course.id || "",
                    dateText: dateText || "",
                },
                {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_LINE_NOTIFY_TOKEN}`,
                    },
                }
            );

            if (response.status === 200) {
                return response;
            } else {
                return response;
            }

        } else {
            dateText = await formatDateToThai(data3.scheduleTime);
            timeText = await formatTimeToThai(data3.scheduleTime);

            const response = await axios.post(
                `${process.env.REACT_APP_API_LINE_NOTIFY}/v1/invite-round`,
                {
                    lineUid: lineId,
                    roundId: roundId,
                    roundName: data1.roundName || "",
                    courseId: data1.course.id || "",
                    dateText: dateText || "",
                    timeText: timeText || "",
                },
                {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_LINE_NOTIFY_TOKEN}`,
                    },
                }
            );

            if (response.status === 200) {
                return response;
            } else {
                return response;
            }

        }

    } catch (error) {
        return error;
    }

}


export async function sendInviteRoundCreator(lineId, roundId, data1, data3) {

    try {

        let dateText = '';
        let timeText = '';

        if (data3.teeTimeBtn == 'now') {
            dateText = 'เริ่มเลยตอนนี้';

            const response = await axios.post(
                `${process.env.REACT_APP_API_LINE_NOTIFY}/v1/invite-round/creator-now`,
                {
                    lineUid: lineId,
                    roundId: roundId,
                    roundName: data1.roundName || "",
                    courseId: data1.course.id || "",
                    dateText: dateText || "",
                },
                {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_LINE_NOTIFY_TOKEN}`,
                    },
                }
            );

            if (response.status === 200) {
                return response;
            } else {
                return response;
            }


        } else {
            dateText = await formatDateToThai(data3.scheduleTime);
            timeText = await formatTimeToThai(data3.scheduleTime);

            const response = await axios.post(
                `${process.env.REACT_APP_API_LINE_NOTIFY}/v1/invite-round/creator`,
                {
                    lineUid: lineId,
                    roundId: roundId,
                    roundName: data1.roundName || "",
                    courseId: data1.course.id || "",
                    dateText: dateText || "",
                    timeText: timeText || "",
                },
                {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_API_LINE_NOTIFY_TOKEN}`,
                    },
                }
            );

            if (response.status === 200) {
                return response;
            } else {
                return response;
            }

        }

    } catch (error) {
        console.log(error);

        return error;
    }

}






async function formatDateToThai(timestamp) {
    const thaiDays = ["วันอาทิตย์", "วันจันทร์", "วันอังคาร", "วันพุธ", "วันพฤหัสบดี", "วันศุกร์", "วันเสาร์"];

    const thaiMonths = [
        "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
        "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
    ];

    if (timestamp.toString().length === 10) {
        timestamp *= 1000;
    }

    const date = new Date(timestamp);

    const day = thaiDays[date.getDay()]; // ชื่อวัน
    const dateNum = date.getDate(); // วันที่
    const month = thaiMonths[date.getMonth()]; // เดือน
    const year = date.getFullYear() + 543; // ปีพุทธศักราช

    return `${day}ที่ ${dateNum} ${month} ${year}`;
}

async function formatTimeToThai(timestamp) {
    if (timestamp.toString().length === 10) {
        timestamp *= 1000;
    }

    const date = new Date(timestamp);

    const hours = date.getHours().toString().padStart(2, '0'); // ชั่วโมง
    const minutes = date.getMinutes().toString().padStart(2, '0'); // นาที

    return `เวลา ${hours}:${minutes} น.`;
}