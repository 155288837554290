import axios from "axios";

export async function sendLinePlainText(lineUid, message) {

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_LINE_NOTIFY}/v1/plain-text`,
            {
                text: message || '',
                lineUid: lineUid,
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_API_LINE_NOTIFY_TOKEN}`,
                },
            }
        );

        if (response.status === 200) {
            return response;
        } else {
            return response;
        }
    } catch (error) {
        return error;
    }

}