import React from "react";
import { Link } from "react-router-dom";

import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useAuth } from "../../components/auth/UserContext";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { textConvert } from "../../components/language/dropdown/textconvert";
import { useNavigate } from "react-router-dom";
const liff = window.liff;

function Settings() {
  const { t } = useTranslation();
  const { logout } = useAuth();
  const userData = useSelector((state) => state.user.data);
  const navigate = useNavigate();

  const handleLineConnect = () => {
    const { userAgent } = navigator;
    if (!liff.isInClient() && userAgent.includes("Line")) {
      navigate("/line-connect");
    } else {
      window.location.href = "https://liff.line.me/2006737261-qGvE2bXJ";
    }
  };

  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-lg-5 p-4 w-100 border-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <h4 className="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">
                        {t(textConvert("Settings"))}
                      </h4>
                      <ul className="list-inline">
                        <li className="list-inline-item d-block border-bottom me-0 my-3">
                          <Link
                            to="/accountinformation"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <ReactSVG
                              src="assets/images/dooNineIconV2/profile/setting-accountInformation.svg"
                              className="w50 me-2"
                            />
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              {t(textConvert("Acount Information"))}
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>

                        <li className="list-inline-item d-block border-bottom me-0 my-3">
                          <div
                            className="pt-2 pb-2 d-flex align-items-center pointer"
                            onClick={() => handleLineConnect()}
                          >
                            {/* <Link
                              to="/line-connect"
                              className="pt-2 pb-2 d-flex align-items-center"
                            > */}
                            <ReactSVG
                              src="assets/images/dooNineIconV2/profile/setting-notification.svg"
                              className="w50 me-2"
                            />

                            {/* <h4 className="fw-600 font-xsss mb-0 mt-0">Line Notification</h4><i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></Link></li> */}

                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              {t(textConvert("Notification line"))}
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                            {/* </Link> */}
                          </div>
                        </li>

                        <li className="list-inline-item d-block border-bottom me-0 my-3">
                          <Link
                            to="/blocklist"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <ReactSVG
                              src="assets/images/dooNineIconV2/profile/setting-blockList.svg"
                              className="w50 me-2"
                            />
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              {t(textConvert("Block List"))}
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>

                        <li
                          className="list-inline-item d-block border-bottom me-0 my-3"
                          style={{
                            pointerEvents:
                              userData?.providerData[0]?.providerId !==
                              "password"
                                ? "none"
                                : "",
                          }}
                        >
                          <Link
                            to="/forgot"
                            state="reset"
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <ReactSVG
                              src="assets/images/dooNineIconV2/profile/setting-resetpassword.svg"
                              className="w50 me-2"
                            />
                            <h4
                              className={clsx("fw-600 font-xsss mb-0 mt-0", {
                                "text-muted":
                                  userData?.providerData[0]?.providerId !==
                                  "password",
                              })}
                            >
                              {t(textConvert("Reset Password"))}
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>

                        <li className="list-inline-item d-block me-0 mt-3">
                          <Link
                            to="#"
                            onClick={logout}
                            className="pt-2 pb-2 d-flex align-items-center"
                          >
                            <ReactSVG
                              src="assets/images/dooNineIconV2/profile/setting-logout.svg"
                              className="w50 me-2"
                            />
                            <h4 className="fw-600 font-xsss mb-0 mt-0">
                              {t(textConvert("Logout"))}
                            </h4>
                            <i className="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Popupchat />
      <Appfooter />
    </>
  );
}

export default Settings;
