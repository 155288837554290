import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import Swal from "sweetalert2";
import { bestScoreCalculation } from "../../user/myperfprofile/module/statCalculation";
import { textConvert } from "../../../components/language/dropdown/textconvert";

export async function finishRound(playerScore, courseHoles, navFinishRound = () => console.warn('not found nav function'), t) {
    Swal.fire({
        icon: 'warning',
        text: t(textConvert('are_you_sure_you_want_to_end_this_round')),
        confirmButtonColor: '#EE3C7F',
        showCancelButton: true,
        confirmButtonText:t(textConvert('OK')),
        cancelButtonText:t(textConvert('cancel')),
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                const scoreRef = doc(firestore, 'scoreCards', playerScore.docId);
                await updateDoc(scoreRef, {
                    status: 'finished'
                })
                await bestScoreCalculation(playerScore, courseHoles);

                navFinishRound();
            } catch (error) {
                console.error('Fail to finish round', error);
            }
        }
    })

}